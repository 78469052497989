import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-address-dialog',
  templateUrl: './confirm-address-dialog.component.html',
  styleUrls: ['./confirm-address-dialog.component.scss'],
})
export class ConfirmAddressDialogComponent implements OnInit {
  isActionConfirmed = false;
  confirmAddress: String;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmAddressDialogComponent>
  ) {
    this.confirmAddress = this.data.confirmAddress.toLowerCase();
  }
  ngOnInit() {}

  submit() {
    this.dialogRef.close('submit');
  }

  cancel() {
    this.dialogRef.close();
  }

  onConfirmChange(event) {
    this.isActionConfirmed = event.target.value.trim().toLocaleLowerCase() === this.confirmAddress;
    if (this.isActionConfirmed && event.key === 'Enter') {
      this.submit();
    } else if (event.key === 'Escape') {
      this.cancel();
    }
  }
}
