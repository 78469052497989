import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sw-update-dialog',
  templateUrl: './sw-update-dialog.component.html',
  styleUrls: ['./sw-update-dialog.component.scss'],
})
export class SwUpdateDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SwUpdateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onConfirm(): void {
    this.dialogRef.close({
      confirm: true,
    });
  }

  ngOnInit(): void {}
}
