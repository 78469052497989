import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmbedVideoService } from '../services';

export class EmbeddableVideoValidator {
  static createValidator(embedService: EmbedVideoService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return embedService.isVideoEmbeddable(control.value).pipe(
        map((result: boolean) => {
          return result ? null : { videoIsNotEmbeddable: true };
        })
      );
    };
  }
}
