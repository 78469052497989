import { UserStore } from './user.store';
import { UserQuery } from './user.query';
import { User, UserRoleRequest, UserVerificationStatusRequest } from '../../../../app.datatypes';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserStateService {
  constructor(private userStore: UserStore, private userQuery: UserQuery, private apiService: ApiService) {}

  getAll(): Observable<User[]> {
    this.userStore.setLoading(true);
    let api = 'api/users?&limit=' + environment.grid_skip_limit + '&skip=' + this.userQuery.getValue().skip;
    if (this.userQuery.getValue().filters.search) {
      api += '&search=' + encodeURIComponent(this.userQuery.getValue().filters.search);
    }

    return this.apiService.get(api).pipe(
      tap(users => {
        if (this.userQuery.getValue().skip === 0) {
          this.userStore.set(users);
          if (users.length < environment.grid_skip_limit) {
            this.userStore.update({ apiEndReached: true });
          }
        } else {
          this.userStore.add(users);
        }
        this.userStore.setLoading(false);
        if (users.length < environment.grid_skip_limit) {
          this.userStore.update({ apiEndReached: true });
        }
      }),
      catchError(error => {
        this.userStore.setError(error);
        this.userStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  setUserRole(id, userRole: UserRoleRequest): Observable<User> {
    this.userStore.setLoading(true);
    return this.apiService.put('api/user/' + id + '/role', userRole).pipe(
      tap(user => {
        this.userStore.update(user._id, { user_type_id: userRole.user_role_code });
        this.userStore.setLoading(false);
      }),
      catchError(error => {
        this.userStore.setError(error);
        this.userStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  setUserVerificationStatus(id, verificationStatus: UserVerificationStatusRequest): Observable<User> {
    this.userStore.setLoading(true);
    return this.apiService.put('api/user/' + id + '/verification-status', verificationStatus).pipe(
      tap(user => {
        this.userStore.update(user._id, { user_verification_status: verificationStatus.verification_status_code });
        this.userStore.setLoading(false);
      }),
      catchError(error => {
        this.userStore.setError(error);
        this.userStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  setUserReputation(id: string, reputation: number): Observable<User> {
    const payload = { reputation: reputation };

    this.userStore.setLoading(true);
    return this.apiService.put('api/users/' + id + '/reputation', payload).pipe(
      tap(user => {
        this.userStore.update(user._id, { reputation });
        this.userStore.setLoading(false);
      }),
      catchError(error => {
        this.userStore.setError(error);
        this.userStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  toggleSuspended(id: string): Observable<User> {
    this.userStore.setLoading(true);
    return this.apiService.post('api/users/' + id + '/toggle-suspended', []).pipe(
      tap(user => {
        this.userStore.update(user._id, { is_suspended: user.is_suspended });
        this.userStore.setLoading(false);
      }),
      catchError(error => {
        this.userStore.setError(error);
        this.userStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateSkip() {
    this.userStore.update({ skip: this.userQuery.getValue().skip + environment.grid_skip_limit });
  }

  updateFilters(filters) {
    this.userStore.update({ filters, skip: 0, apiEndReached: false });
  }

  invalidateCache() {
    this.userStore.setHasCache(false);
    this.userStore.setActive(null);
  }
}
