import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'payoutTypeText',
})
export class PayoutTypeTextPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value === 'string') {
      switch (value) {
        case 'proposal_stake_unlock_tokens':
          return 'Unlock Stake';
        case 'moderate_proposal_review_tokens':
          return 'Moderate Review';
        case 'like_proposal_review_tokens':
          return 'Like Review';
        case 'review_proposal_tokens':
          return 'Review Proposal';
        case 'review_proposal_reputation':
          return 'Review Proposal';
        case 'moderate_proposal_tokens':
          return 'Moderate Proposal';
        default:
          return value;
      }
    }
    return value;
  }
}
