import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-quick-view',
  templateUrl: './round-quick-view.component.html',
  styleUrls: ['./round-quick-view.component.scss'],
})
export class RoundQuickViewComponent implements OnInit {
  displayedColumns: string[] = [
    'number',
    'title',
    'score',
    'votes',
    'reviews',
    'evaluations',
    'likes',
    'staked',
    'bounty',
  ];

  @Input() proposalsTableData = [];
  constructor() {}
  ngOnInit() {}
}
