import { Component, OnInit, Input } from '@angular/core';
import { Question } from '../../../../app/app.datatypes';
import { EmbedVideoService, FilePreviewOverlayService } from '../../../shared/services';

import { FilePreviewOverlayRef } from '../../../../app/shared/components/file-preview-overlay/file-preview-overlay-ref';

@Component({
  selector: 'app-preview-create-query',
  templateUrl: './preview-create-query.component.html',
  styleUrls: ['./preview-create-query.component.scss'],
})
export class PreviewCreateQueryComponent implements OnInit {
  @Input() questions: Question[];

  videos = {};

  constructor(private embedVideoService: EmbedVideoService, private previewDialog: FilePreviewOverlayService) {}

  ngOnInit() {
    this.initializeVideos();
  }

  initializeVideos() {
    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i].options) {
        for (let j = 0; j < this.questions[i].options.length; j++) {
          if (this.questions[i].options[j].type === 'video') {
            this.videos[j] = this.embedVideoService.embed(this.questions[i].options[j].value);
          }
        }
      }
    }
  }

  showPreview(event, file) {
    const dialogRef: FilePreviewOverlayRef = this.previewDialog.open({
      image: file,
    });
    event.stopPropagation();
  }
}
