import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransactionStatus } from '../../../app.datatypes';
import { PaymentService, ProgressService } from '../../services';
import { AuthenticatedUserQuery, AuthenticatedUserService } from '../../state';
import { environment } from '../../../../environments';

@Component({
  selector: 'app-wallet-transactions',
  templateUrl: './wallet-transactions.component.html',
  styleUrls: ['./wallet-transactions.component.scss'],
})
export class WalletTransactionsComponent implements OnInit {
  @Input() user: any;
  @Input() type: any;
  transactions = null;
  dataSourceTransactions: any;
  isMoreAvailable = true;
  skip = 0;
  limit = 10;
  transactionStatus = TransactionStatus;
  progress: any;
  explorerEventURL = `${environment.explorer_url}event/`;
  service = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public authenticatedUserService: AuthenticatedUserService,
    private paymentService: PaymentService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.loadTransactions();
  }

  processTransactions(transactions) {
    this.transactions = transactions;
    this.dataSourceTransactions = new MatTableDataSource(transactions);
  }

  private loadTransactions() {
    this.paymentService
      .getUserTransactions(this.user._id, this.type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(transactions => {
        this.processTransactions(transactions);
        this.skip = transactions.length;
        if (transactions.length < this.limit) {
          this.isMoreAvailable = false;
        }
      });
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.paymentService
      .getUserTransactions('', this.type, this.skip, this.limit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.dataSourceTransactions.data;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.dataSourceTransactions.data = oldData;
            this.skip = this.skip + result.length;
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }
}
