import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';

@Component({
  selector: 'app-creative-query-video',
  templateUrl: './creative-query-video.component.html',
  styleUrls: ['./creative-query-video.component.scss'],
})
export class CreativeQueryVideoComponent implements OnInit {
  @Input() data: any;
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;
  videoUrl: any;
  options: Plyr.Options = {
    blankVideo: '/assets/videos/blank.mp4',
  };

  constructor() {}

  ngOnInit(): void {
    const testPlyr = setInterval(() => {
      if (this.plyr) {
        this.videoUrl = this.data?.source;
        clearInterval(testPlyr);
      }
    }, 1000);
  }

  played(event: Plyr.PlyrEvent) {
    //
  }
}
