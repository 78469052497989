import { Directive, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { SharedService } from './shared/services';

@Directive({
  selector: '[appCoinanimation]',
})
export class CoinAnimationDirective {
  @Input('appCoinanimation') tooltipTitle: string;
  @Input() delay: string;
  @Input() placement: string;

  coinAnimation: HTMLElement;
  offset = 10;
  canShow = true;

  constructor(
    public el: ElementRef,
    public wrapper: ElementRef,
    private renderer: Renderer2,
    private sharedService: SharedService
  ) {}

  @HostListener('click') onMouseClick() {
    this.canShow = true;
    this.show();
    window.setTimeout(() => {
      this.canShow = false;
      this.hide();
    }, 3000);
  }

  removeElementsByClass(className) {
    const elements = this.renderer.selectRootElement(`.${className}`, true);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  hide() {
    this.sharedService.setCoinAnimation();
    this.removeElementsByClass('coins-group');
    window.setTimeout(() => {
      if (this.coinAnimation) {
        this.renderer.removeChild(document.body, this.coinAnimation);
      }
      this.coinAnimation = null;
    }, 1000);
  }

  show() {
    if (this.canShow) {
      this.create();
      this.setPosition();
    }
  }

  create() {
    this.coinAnimation = this.renderer.createElement('div');
    this.renderer.addClass(this.coinAnimation, 'coins-group');
    this.coinAnimation.innerHTML = `
    <div class="coins-wrapper" >
      <span class="coin" > </span>
      <span class="coin"></span>
      <span class="coin"></span>
    </div>
    `;
    this.renderer.appendChild(document.body, this.coinAnimation);
  }

  setPosition() {
    const hostPos = this.el?.nativeElement.getBoundingClientRect();
    const coinPos = this.coinAnimation.getBoundingClientRect();
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - coinPos.height - this.offset;
      left = hostPos.left + (hostPos.width - coinPos.width) / 2;
    }

    this.renderer.setStyle(this.coinAnimation, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.coinAnimation, 'left', `${left}px`);
  }
}
