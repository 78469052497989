import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PasswordQuery } from '../../state/password/password.query';
import { CryptoUtil } from '../../util/crypto-util';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
})
export class PasswordDialogComponent implements OnInit {
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    private passwordQuery: PasswordQuery
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
    });
    this.form.addControl('rememberPassword', new FormControl(this.passwordQuery.getValue().password ? true : false));
    if (this.data.error) {
      const formControl = this.form.get('password');
      setTimeout(() => {
        formControl.markAsDirty();
        formControl.markAsTouched();
        formControl.setErrors({
          serverError: true,
          error: this.data.error.message,
        });
      }, 1);
    }
  }

  submit() {
    this.dialogRef.close({
      password: CryptoUtil.bcryptHash(this.form.value.password),
      rememberPassword: this.form.value.rememberPassword,
    });
  }
}
