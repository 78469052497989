import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-instruction-video-only',
  templateUrl: './instruction-video-only.component.html',
  styleUrls: ['./instruction-video-only.component.scss'],
})
export class InstructionVideoOnlyComponent implements OnInit {
  @Input() videoUrl: any;

  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;

  constructor() {}

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  ngOnInit() {}
}
