import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private url = environment.url;
  ignoreListForSnack = [`${this.url}api/username-check`, `${this.url}api/oauth/token`];

  constructor(private http: HttpClient, private toasterService: ToasterService) {}

  /**
   * Generic http functions
   */
  public get(uri: string, options?: any): Observable<any> {
    return this.http
      .get(this.url + uri, options)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public post(url: string, data: any): Observable<any> {
    return this.http
      .post(this.url + url, data)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public put(url: string, data: any): Observable<any> {
    return this.http
      .put(this.url + url, data)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public delete(uri: string): Observable<any> {
    return this.http
      .delete(this.url + uri)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public postWithAttachment(url: string, data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.url + url, data, { headers: headers, observe: 'events', reportProgress: true });
  }

  /**
   * Error handling
   */
  public catchError(error: HttpErrorResponse) {
    let message;
    let statusText;
    if (error.error && error.error.message) {
      message = error.error.message;
    } else if (error.message) {
      message = error.message;
    }

    if (error.error) {
      statusText = error.error.status;
    } else if (error.status) {
      statusText = error.statusText;
    }

    /** Display 422 validation errors */
    if (error.status === 422 && error.error.errors) {
      this.toasterService.openValidationErrorsSnackbar(
        'The given data was invalid.',
        Object.values(error.error.errors)
      );
      return throwError(error);
    }

    if (this.ignoreListForSnack.indexOf(error.url) !== -1) {
      console.log(error);
    }
    return throwError(error);
  }
}
