import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { ToasterService } from '../shared';
import { AuthenticationService } from '../shared';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private authService: AuthenticationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        event => {},
        err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            if (localStorage.getItem('refresh_token')) {
              this.authService.clientSideLogOut();
            }
            if (!this.router.url.endsWith('login')) {
              // Redirect the browser to login, rather than navigate to reload state.
              window.location.href = '/login';
            }
          } else if (err instanceof HttpErrorResponse) {
            // @todo improve error handling ...
          }
        }
      )
    );
  }
}
