import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  EventEmitter,
  AfterContentInit,
  Output,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ToasterService,
  EmbedVideoService,
  AuthenticatedUserQuery,
  CropperDialogComponent,
  SEOService,
  ProgressService,
  ErrorsHandlerService,
  ProposalRoundService,
  PaymentService,
  AuthenticatedUserState,
  PasswordService,
  WalletCheckComponent,
  ProposalStateService,
  ProposalQuery,
  WindowScrollService,
} from '../../../shared';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Genre,
  Language,
  PricingFees,
  Proposal,
  ProposalRound,
  SubmitProposalRequest,
  ProposalStatus,
} from '../../../app.datatypes';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subject, forkJoin } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ComponentCanDeactivateDirective } from '../../../shared/services/component-can-deactivate';
import { panelIn, fade } from '../../../app.animations';
import { ProposalRoundData } from './proposal-create-steps/proposal-create-step-one/proposal-create-step-one.component';
import { AboutProposalData } from './proposal-create-steps/proposal-create-step-two/proposal-create-step-two.component';
import { BountyData } from './proposal-create-steps/proposal-create-step-three/proposal-create-step-three.component';
import { CheckoutData } from './proposal-create-steps/proposal-create-step-five/proposal-create-step-five.component';
import { environment } from '../../../../../src/environments';

@Component({
  selector: 'app-proposal-create',
  templateUrl: './proposal-create.component.html',
  styleUrls: ['./proposal-create.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
    panelIn,
    fade,
  ],
})
export class ProposalCreateComponent extends ComponentCanDeactivateDirective
  implements OnInit, AfterContentInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() createMode?: boolean;
  @Output() closeCreateProposalSidebar = new EventEmitter<any>();
  @ViewChild('imageInput', { static: true }) imageInput: ElementRef;
  @ViewChild('docInput', { static: true }) docInput: ElementRef;
  @ViewChild('title', { static: false }) titleElem: ElementRef;
  @ViewChild('additionalBounty', { static: false }) additionalBountyElem: ElementRef;
  @ViewChild('tab1', { static: false }) tab1Elem: ElementRef;
  @ViewChild('tab2', { static: false }) tab2Elem: ElementRef;
  @ViewChild('tab3', { static: false }) tab3Elem: ElementRef;
  @ViewChild('tab4', { static: false }) tab4Elem: ElementRef;
  @ViewChild('tab5', { static: false }) tab5Elem: ElementRef;
  @ViewChild('tab6', { static: false }) tab6Elem: ElementRef;

  // @splitting form into groups
  days = 20;
  addVideoItem = false;
  addImageItem = false;
  imageFile: any;
  isImageOpened = false;
  isActiveUpload = false;
  isStepOneReady = false;
  isStepTwoReady = false;
  isStepThreeReady = false;
  isStepFourReady = false;

  tabOneActive = true;
  tabTwoActive = false;
  tabThreeActive = false;
  tabFourActive = false;
  tabFiveActive = false;
  stepperSelectedIndex = 0;
  selectedRound: ProposalRound;
  isNew = true;
  user: AuthenticatedUserState;
  proposalStatus = ProposalStatus;
  proposal: Proposal;
  proposalReview: Proposal;
  minBountyToBeAdded: number;
  roundList: ProposalRound[];
  languageList: Language[];
  topLanguageList: Language[];
  genreList: Genre[];
  submitFeeFilm = null;
  submitFeeDollars = null;
  prices: PricingFees;
  readyToSave = false;
  submitting = false;
  proposal_round_id: string;
  makeCoverIndex = -1;
  completed = false;
  itemType = [];
  private matBottomSheetWalletRef: MatBottomSheetRef<WalletCheckComponent>;

  // Variable to show hint or not on bounty
  showBountyHelp = false;
  showRewardHelp = false;
  docFiles: any = [];
  imageFiles: any = [];
  maxImagesAllowed = environment.max_images_allowed;
  maxDocsAllowed = environment.max_docs_allowed;
  maxImageSize = environment.max_image_size;
  maxDocSize = environment.max_pdf_doc_size;
  private reenableAddVideoButton = new EventEmitter<boolean>();
  private reenableAddImageButton = new EventEmitter<boolean>();
  // Spinner variables
  progress: any;
  uploadedCroppedCoverImage: any;
  mediaList: any[] = [];
  coverId: any;
  proposalMediaData: any;
  isCoverUploaded = false;
  stepOneData: ProposalRoundData;
  stepTwoData: AboutProposalData;
  stepThreeData: BountyData;
  stepFiveData: CheckoutData;

  constructor(
    private proposalQuery: ProposalQuery,
    private proposalStateService: ProposalStateService,
    private proposalRoundService: ProposalRoundService,
    private paymentService: PaymentService,
    private appService: AuthenticatedUserQuery,
    private toastService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private bottomSheet: MatBottomSheet,
    private _seoService: SEOService,
    private passwordService: PasswordService,
    private errorService: ErrorsHandlerService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    public windowScrollService: WindowScrollService,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private embedService: EmbedVideoService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
    this.activatedRoute.queryParams.subscribe(params => {
      this.stepperSelectedIndex = params['step'] || 0;
    });
  }

  ngOnInit() {
    this.windowScrollService.hideFooter();
    this.proposalQuery.selectLoading().subscribe(loading => {
      if (loading) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      } else {
        this.progressService.hideSpinner(this.progress);
      }
    });

    this.authenticatedUserQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
        // this.refreshBountyValidation();
      });
    this._seoService.createTitleForPage();

    forkJoin([
      // TODO Get all proposal rounds from proposal round list
      this.proposalRoundService.getOpenProposalRounds().pipe(takeUntil(this.ngUnsubscribe)),
      this.proposalStateService.getGenres().pipe(takeUntil(this.ngUnsubscribe)),
      this.proposalStateService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)),
    ]).subscribe(results => {
      this.roundList = results[0];
      this.genreList = results[1];
      this.languageList = results[2];
      this.topLanguageList = this.languageList.filter((elem, index) => {
        const topOfTheList = ['en', 'es', 'fr'];
        if (topOfTheList.includes(elem.iso)) {
          this.languageList.splice(index, 1);
          return true;
        }
      });
    });

    this.route.params.subscribe(async params => {
      if (params['id'] !== undefined) {
        this.proposalStateService
          .getProposal(params['id'])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            proposalResponse => {
              this.proposal = proposalResponse;
              this.proposalRoundService.getProposalRound(this.proposal.proposal_round_id).subscribe(async data => {
                this.selectedRound = await data;
                await this.calculateMinBountyToBeAdded();
                this.isNew = false;
                this.isStepOneReady = true;
                const stepOne = { proposal_round_id: this.proposal?.proposal_round_id };
                this.stepOneData = stepOne;
                this.buildMedia();
                this.updateSubmissionFeePrice();
                this.onChanges();
                this.setAllStepsData();
              });
            },
            error => {}
          );
      } else {
        this.proposal = new Proposal();
        this.proposal.rewards = [];
        this.isNew = true;
        this.isStepOneReady = true;
        this.onChanges();
      }
    });

    this.refreshFees();
    // this.initUpdateExtraBountyObservable();
    this.activateTab(1);
  }

  // New Functions - 13-09-2021

  setStepOne(data: ProposalRoundData): void {
    this.isStepTwoReady = true;
    this.stepOneData = data;
    this.onChanges();
    this.updateSubmissionFeePrice();
    this.cdRef.detectChanges();
  }

  setStepTwo(data: AboutProposalData): void {
    this.stepTwoData = data;
    if (this.stepTwoData.isValid) {
      this.saveProposalMedia(this.stepTwoData);
      this.isStepThreeReady = true;
    }
    this.cdRef.detectChanges();
  }

  setStepThree(data: BountyData): void {
    this.stepThreeData = data;
    this.isStepFourReady = true;
    this.cdRef.detectChanges();
  }

  setStepFive(data: CheckoutData): void {
    this.stepFiveData = data;
    this.cdRef.detectChanges();
  }

  updateSubmissionFeePrice(): void {
    if (!this.stepOneData || !this.prices) {
      return;
    }
    const roundId = this.stepOneData.proposal_round_id;
    const selectedRound = this.roundList ? this.roundList.find(round => round._id === roundId) : null;
    if ((this.isNew || !this.proposal.blockchain_confirmed) && selectedRound) {
      this.submitFeeDollars = selectedRound.submission_fee;
      this.submitFeeFilm = this.submitFeeDollars / this.prices.price_of_film;
    } else if (this.proposal.blockchain_confirmed && !this.isNew) {
      this.submitFeeDollars = this.prices.proposal_resubmission_fee_in_dollar;
      this.submitFeeFilm = this.prices.proposal_resubmission_fee;
    }
  }

  getSelectedRound(): void {
    if (!this.selectedRound) {
      this.roundList.forEach(round => {
        if (round._id === this.stepOneData?.proposal_round_id) {
          this.selectedRound = round;
          this.updateSubmissionFeePrice();
          this.calculateMinBountyToBeAdded();
          // this.refreshBountyValidation();
          // this.updateBounty();
          return;
        }
      });
    }
  }

  setAllStepsData() {
    if (this.proposal) {
      this.isStepTwoReady = true;
      this.isStepThreeReady = true;

      const stepTwo = {
        title: this.proposal?.title,
        content: this.proposal?.content,
        summary: this.proposal.summary,
        genres: this.proposal?.genres,
        languages: this.proposal?.languages,
        genreAdd: null,
        isValid: true,
        isCoverUploaded: true,
        mediaList: this.mediaList,
        coverId: this.proposal?.cover_id,
        uploadedCroppedCoverImage: this.proposal?.cover_url,
      };
      this.stepTwoData = stepTwo;

      const stepThree = {
        bounty: this.proposal.bounty ? this.proposal.bounty : 0,
        extraBounty:
          !this.proposal.blockchain_confirmed && this.proposal.bounty
            ? this.proposal.bounty - this.selectedRound.minimum_bounty
            : 0,
      };
      this.stepThreeData = stepThree;
    }
  }

  buildMedia() {
    if (this.proposal) {
      let index = 1;
      if (this.proposal?.cover_url) {
        this.uploadedCroppedCoverImage = this.proposal?.cover_url;
        this.isCoverUploaded = true;
      }

      if (this.proposal?.video_url?.length > 0) {
        for (const video of this.proposal?.video_url) {
          const data = {
            index: index,
            type: 'video',
            value: video?.url,
            title: video?.mediaTitle,
            description: video?.description,
            videoData: this.embedService.embed(video?.url),
            isDirectVideoUpload: video?.isDirectVideoUpload,
            file_id: video?.file_id,
          };
          this.mediaList.push(data);
          index = index + 1;
        }
      }

      if (this.proposal?.images?.length > 0) {
        for (const image of this.proposal?.images) {
          const data = {
            index: index,
            type: 'image',
            value: image?.url,
            title: image?.mediaTitle,
            description: image?.description,
          };
          this.mediaList.push(data);
          index = index + 1;
        }
      }

      if (this.proposal?.docs?.length > 0) {
        for (const doc of this.proposal?.docs) {
          const data = {
            index: index,
            type: 'file',
            value: doc?.url,
            title: doc?.docTitle,
            description: doc?.description,
            fileData: doc?.file_preview_url,
          };
          this.mediaList.push(data);
          index = index + 1;
        }
      }
      this.proposalMediaData = {
        video_url: this.proposal?.video_url,
        images: this.proposal?.images,
        docs: this.proposal?.docs,
        cover_id: this.proposal?.cover_id,
        cover_url: this.proposal?.cover_url,
        cover_type: this.proposal?.cover_type,
      };
      this.refreshFees();
    }
  }

  saveProposalMedia(data: AboutProposalData) {
    this.mediaList = data?.mediaList;
    this.coverId = data?.coverId;
    this.uploadedCroppedCoverImage = data?.uploadedCroppedCoverImage;
    this.isCoverUploaded = data?.isCoverUploaded;
    const videoUrl = [];
    const imageUrl = [];
    const docUrl = [];
    const uploadedFilesId = [];
    if (this.mediaList) {
      for (const item of this.mediaList) {
        if (item?.type === 'video') {
          const videoData = {
            url: item?.value,
            mediaTitle: item?.title,
            description: item?.description,
            thumbnailUrl: item?.thumbnailUrl,
            isDirectVideoUpload: item?.isDirectVideoUpload,
            file_id: item?.file_id,
          };
          videoUrl.push(videoData);
        }
        if (item?.type === 'image') {
          const imageData = {
            url: item?.value,
            mediaTitle: item?.title,
            description: item?.description,
          };
          imageUrl.push(imageData);
        }
        if (item?.type === 'file') {
          const fileData = {
            url: item?.value,
            file_preview_url: item?.fileData,
            docTitle: item?.title,
            description: item?.description,
          };
          docUrl.push(fileData);
        }
      }
    }

    this.proposalMediaData = {
      video_url: videoUrl,
      images: imageUrl,
      docs: docUrl,
      cover_id: this.coverId,
      cover_url: this.uploadedCroppedCoverImage,
      cover_type: 'image',
    };

    this.refreshFees();
  }

  refreshFees() {
    this.proposalReview = new Proposal();
    this.proposalReview.images = [];
    this.proposalReview.video_url = [];
    this.proposalReview.cover_type = 'image';

    let images = [];
    let videos = [];

    if (this.mediaList?.length > 0 || this.isCoverUploaded) {
      images = this.proposalMediaData.images;
      videos = this.proposalMediaData.video_url;
      this.proposalReview.cover_url = this.proposalMediaData.cover_url;
      this.proposalReview.cover_type = this.proposalMediaData.cover_type;
    }

    for (const video of videos) {
      this.proposalReview.video_url.push({
        file_id: video?.file_id,
        url: video?.url,
        thumbnailUrl: video?.thumbnailUrl,
        mediaTitle: video?.mediaTitle,
        description: video?.description,
        isDirectVideoUpload: video?.isDirectVideoUpload,
      });
    }

    for (const image of images) {
      this.proposalReview.images.push({
        url: image?.url,
      });
    }

    this.paymentService
      .getFees()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(fees => {
        this.prices = fees;
        this.updateSubmissionFeePrice();
      });
  }

  activateTab(index) {
    this.tabOneActive = false;
    this.tabTwoActive = false;
    this.tabThreeActive = false;
    this.tabFourActive = false;
    this.tabFiveActive = false;
    switch (index) {
      case 1:
        this.tabOneActive = true;
        break;
      case 2:
        this.tabTwoActive = true;
        break;
      case 3:
        this.tabThreeActive = true;
        break;
      case 4:
        this.tabFourActive = true;
        break;
      case 5:
        this.tabFiveActive = true;
        break;
      default:
        this.tabOneActive = true;
        break;
    }
    if (index > 3) {
      this.refreshFees();
    }
  }

  moveToTab1() {
    document.getElementById('tab1').click();
    this.activateTab(1);
  }

  moveToTab2() {
    document.getElementById('tab2').click();
    this.activateTab(2);
  }

  moveToTab3() {
    // this.save();
    document.getElementById('tab3').click();
    this.activateTab(3);
  }

  moveToTab4() {
    // this.save();
    // this.saveProposalMedia();
    this.getSelectedRound();
    document.getElementById('tab4').click();
    this.activateTab(4);
  }

  moveToTab5() {
    // this.save();
    document.getElementById('tab5').click();
    this.activateTab(5);
  }

  calculateMinBountyToBeAdded(): void {
    const roundBounty = this.selectedRound ? this.selectedRound.minimum_bounty : 0;
    const proposalBounty = this.proposal && this.proposal.bounty ? this.proposal.bounty : 0;
    if (this.proposal.blockchain_confirmed) {
      this.minBountyToBeAdded = Math.max(roundBounty, proposalBounty);
    } else {
      this.minBountyToBeAdded = roundBounty;
    }
  }

  onChanges(): void {
    if (this.stepOneData) {
      this.roundList.forEach(round => {
        if (round._id === this.stepOneData?.proposal_round_id) {
          this.selectedRound = round;
          this.updateSubmissionFeePrice();
          this.calculateMinBountyToBeAdded();
          // this.refreshBountyValidation();
          // this.updateBounty();
          return;
        }
      });
    }
  }

  canDeactivate(): boolean {
    return !this.readyToSave;
  }

  closeCreateForm() {
    if (!this.canDeactivate()) {
      if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
        this.closeCreateProposalSidebar.emit();
        return true;
      } else {
        return false;
      }
    } else {
      this.closeCreateProposalSidebar.emit();
    }
  }

  canEdit() {
    return (
      !this.createMode &&
      this.user._id === this.proposal.user_id &&
      (this.proposal.status === ProposalStatus.WAITING_FOR_SUBMISSION || this.proposal.status === ProposalStatus.DENIED)
    );
  }

  save(redirect = false) {
    this.readyToSave = false;
    const submission_fee = Number(this.submitFeeFilm.toFixed(8));
    const merged = {
      ...this.stepOneData,
      ...this.stepTwoData,
      ...this.stepThreeData,
      submission_fee,
    };
    const errorHandler = error => {
      this.refreshFees();
      this.toastService.openSnackBar('There was a problem saving your proposal', 'Saving Error');
    };
    if (this.isNew) {
      this.proposalStateService
        .saveProposalAsDraft(merged)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(proposal => {
          this.proposal = proposal;
          this.readyToSave = false;
          if (redirect) {
            this.router.navigate(['/account/wallet'], {
              queryParams: {
                redirectUri: `/proposal/${proposal._id}/edit`,
              },
            });
          }
          // this.toastService.openSnackBar('Your proposal draft has been created successfully', 'Saved', 'check', 3000);
          this.isNew = false;
        }, errorHandler);
    } else {
      this.proposalStateService
        .updateProposalDraft(this.proposal._id, merged)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(proposal => {
          this.readyToSave = false;
          // this.toastService.openSnackBar('Your proposal draft has been updated successfully', 'Saved', 'check', 3000);
          if (redirect) {
            this.router.navigate(['/account/wallet'], {
              queryParams: {
                redirectUri: `/proposal/${proposal._id}/edit`,
              },
            });
          }
        }, errorHandler);
    }
  }

  async openPasswordDialog(error = null) {
    this.submitting = true;
    if (this.appService.isActiveWallet()) {
      if (!this.submitFeeFilm) {
        alert('There was a problem determining the submission fee.  Please contact support');
        this.submitting = false;
        return;
      }
      if (error) {
        this.submitting = false;
        alert('There was a problem submitting your proposal. Please check your password.');
      }
      const charges = this.stepThreeData?.bounty + this.submitFeeFilm;
      if (this.appService.balance < charges) {
        if (this.isNew && !this.proposal._id) {
          alert('There was a problem determining the submission fee.  Please contact support');
          this.submitting = false;
          return;
        }
      }

      const pass = await this.passwordService.openPasswordDialog();
      if (pass) {
        this.submit(pass);
        return;
      } else {
        this.submitting = false;
      }
    } else {
      if (this.readyToSave) {
        this.matBottomSheetWalletRef = this.bottomSheet.open(WalletCheckComponent, {
          data: { walletStatus: this.appService.getWalletStatus() },
        });
        this.matBottomSheetWalletRef.afterDismissed().subscribe(data => {
          if (data.save) {
            this.save(true);
          }
        });
      } else {
        this.appService.openWalletCreate(`/proposal/${this.proposal._id}/edit`);
        this.submitting = false;
      }
    }
  }

  submit(password) {
    if (this.appService.isActiveWallet()) {
      this.proposalMediaData.video_url = this.proposalMediaData.video_url.map(value => {
        return value;
      });
      this.proposalMediaData.images = this.proposalMediaData.images.map(value => {
        return value;
      });
      this.proposalMediaData.docs = this.proposalMediaData.docs.map(value => {
        return value;
      });
      if (!this.submitFeeFilm) {
        this.submitting = false;
        throw new Error('There was a problem determining the submission fee');
      }
      if (this.isNew || !this.proposal.blockchain_confirmed) {
        const submission_fee = Number(this.submitFeeFilm.toFixed(8));
        const merged = {
          ...this.stepOneData,
          ...this.stepTwoData,
          ...this.stepThreeData,
          ...this.proposalMediaData,
          password,
          submission_fee,
        };
        merged.rewards = this.proposal.rewards ? this.proposal.rewards : [];
        if (this.proposal._id) {
          merged.proposal_id = this.proposal._id;
        }

        const errorHandler = async error => {
          this.refreshFees();
          if (error.status === 422 || error.status === 403) {
            const pass = await this.passwordService.openPasswordDialog(error.error);
            if (!pass) {
              return;
            }
            this.submit(pass);
            return;
          } else {
            this.toastService.openSnackBar(
              'There was a problem submitting your proposal',
              'Submission Error',
              'error',
              3000
            );
            this.errorService.handleSubmitError(error);
            this.submitting = false;
          }
        };

        this.proposalStateService
          .prepareProposalForSubmit(merged)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(async proposal => {
            // Proposal is now created
            this.proposal = proposal;
            const submitReq = new SubmitProposalRequest();
            submitReq.proposalId = this.proposal._id;
            submitReq.password = password;
            if (this.appService.clientWallet) {
              // const signer = new ClientWalletSigning(await CryptoUtil.decrypt(this.appService.clientWallet, password), [
              //   { t: 'string', v: proposal.blockchain_id },
              //   {
              //     t: 'uint256',
              //     v: ClientWalletSigning.multIn(submission_fee),
              //   },
              //   {
              //     t: 'uint256',
              //     v: ClientWalletSigning.multIn(proposal.bounty),
              //   },
              //   {
              //     t: 'address',
              //     v: this.appService.walletPublicAddress,
              //   },
              //   {
              //     t: 'string',
              //     v: `${proposal.submission_contribution}-${proposal.submission_transaction}`,
              //   },
              // ]);
              // const clientSig = signer.doSignature();
              // submitReq.signature = clientSig.signature;
              // submitReq.nonce = clientSig.nonce;
            }
            this.proposalStateService
              .submitProposal(submitReq)
              .pipe(
                switchMap(data => {
                  this.submitting = false;
                  if (proposal.status === this.proposalStatus.WAITING_FOR_SUBMISSION) {
                    return this.proposalStateService.updateDraftProposal(proposal._id);
                  } else {
                    return data;
                  }
                }),
                takeUntil(this.ngUnsubscribe)
              )
              .subscribe(res => {
                // this.toastService.openSnackBarKeepOpen(
                //   'Thank you for your submission. Your proposal must be moderated before going live and becoming visible to other users in the review section.',
                //   'Proposal Submitted'
                // );
                this.toastService.openSnackBarWithLink(
                  'Thank you for your submission. Your proposal must be moderated before going live and becoming visible to other users in the review section.',
                  'Proposal Submitted',
                  'info',
                  5000,
                  'account/activity',
                  'proposals',
                  'Check Activity'
                );
                this.readyToSave = false;
                this.closeCreateProposalSidebar.emit();
                this.router.navigate(['/proposal', proposal._id]);
              }, errorHandler);
          }, errorHandler);
      } else {
        const resubmission_fee = Number(this.submitFeeFilm.toFixed(8));
        const merged = {
          ...this.stepOneData,
          ...this.stepTwoData,
          ...this.stepThreeData,
          ...this.proposalMediaData,
          resubmission_fee,
          password,
          extra_bounty: this.stepThreeData.bounty - this.proposal.bounty,
          blockchain_id: this.proposal.blockchain_id,
          rewards: this.proposal.rewards ? this.proposal.rewards : [],
        };

        const errorHandler = async err => {
          this.submitting = false;
          if (err.status === 403) {
            const pass = await this.passwordService.openPasswordDialog(err.error);
            if (!pass) {
              return;
            }
            this.submit(pass);
            return;
          } else {
            this.toastService.openSnackBar('There was a problem updating your proposal', 'Update Error', 'error', 3000);
            this.errorService.handleSubmitError(err);
          }
        };

        this.proposalStateService.prepareProposalForResubmit(this.proposal._id, merged).subscribe(async proposal => {
          const submitReq = new SubmitProposalRequest();
          submitReq.proposalId = proposal._id;
          submitReq.password = password;
          if (this.appService.clientWallet) {
            // const signer = new ClientWalletSigning(await CryptoUtil.decrypt(this.appService.clientWallet, password), [
            //   { t: 'string', v: proposal.blockchain_id },
            //   {
            //     t: 'uint256',
            //     v: ClientWalletSigning.multIn(resubmission_fee),
            //   },
            //   {
            //     t: 'uint256',
            //     v: ClientWalletSigning.multIn(proposal.extra_bounty),
            //   },
            //   {
            //     t: 'address',
            //     v: this.appService.walletPublicAddress,
            //   },
            //   {
            //     t: 'string',
            //     v: `${proposal.resubmission_contribution}-${proposal.resubmission_transaction}`,
            //   },
            // ]);
            // const clientSig = signer.doSignature();
            // submitReq.signature = clientSig.signature;
            // submitReq.nonce = clientSig.nonce;
          }
          this.proposalStateService
            .resubmitProposal(submitReq)
            .pipe(
              switchMap(data => {
                this.submitting = false;
                if (
                  proposal.status === ProposalStatus.WAITING_FOR_SUBMISSION ||
                  proposal.status === ProposalStatus.DENIED ||
                  proposal.status === ProposalStatus.APPROVED
                ) {
                  return this.proposalStateService.updateDraftProposal(proposal._id);
                } else {
                  return data;
                }
              }),
              takeUntil(this.ngUnsubscribe)
            )
            .subscribe(res => {
              this.readyToSave = false;
              return this.router.navigate(['/proposal/' + this.proposal._id]);
            }, errorHandler);
        }, errorHandler);
      }
    } else {
      this.submitting = false;
      const redirectURL = this.proposal._id ? `/proposal/${+this.proposal._id}/edit` : `/proposal/`;
      this.appService.openWalletCreate(redirectURL);
    }
  }

  ngAfterContentInit() {
    this.windowScrollService.showFooterOnScroll();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
