import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService, ProgressService } from '../../../shared/services';

import { AuthenticatedUserQuery, AuthenticatedUserService, AuthenticatedUserState } from '../../../shared/state';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../shared/services/auth.service';
import { UserService } from '../../../shared/services/user.service';

import { untilDestroyed } from 'ngx-take-until-destroy';
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  user: AuthenticatedUserState;

  form: FormGroup;
  protected isGoogTwoFactorEnabled: boolean;
  protected qrCodeURI: string;
  protected secret: string;
  // Spinner variables
  progress: any;

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public authenticatedUserService: AuthenticatedUserService,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastService: ToasterService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.authenticatedUserQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
      });
    if (this.user.google2fa_enabled) {
      this.isGoogTwoFactorEnabled = true;
    }
  }

  onUserSettingChange($event) {
    const settingName = $event.source.name;
    const settingValue = $event.checked;

    this.authService
      .postSetUserSettings(settingName, settingValue)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.toastService.openSnackBar('Setting has been saved!', 'Info', 'info', 3000);
        },
        err => {
          this.toastService.openErrorSnackBar('Something went wrong...', 'Error', 'error');
        }
      );
  }

  onGoogleTwoFactorChanged() {
    if (this.isGoogTwoFactorEnabled) {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      this.authService
        .enableGoogle2FA()
        .pipe(untilDestroyed(this))
        .subscribe(
          resp => {
            this.progressService.hideSpinner(this.progress);
            if (resp.qrCodeURI) {
              this.qrCodeURI = resp.qrCodeURI;
            }
            if (resp.secret) {
              this.secret = resp.secret;
            }
            this.form = this.formBuilder.group({
              code: ['', Validators.required],
            });
          },
          error => this.progressService.hideSpinner(this.progress)
        );
    } else {
      this.authService
        .disableGoogle2FA()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          resp => {},
          error => console.log(error)
        );
    }
  }

  confirmGoogleTwoFactor() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.authService
      .confirmEnableGoogle2FA(this.form.value.code)
      .pipe(untilDestroyed(this))
      .subscribe(
        resp => {
          this.progressService.hideSpinner(this.progress);
          this.qrCodeURI = null;
          this.secret = null;
          this.toastService.openSnackBar('Google Two-Factor Authentication Enabled', 'Saved', 'info', 3000);
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  cancelConfirmGoogleTwoFactor() {
    this.qrCodeURI = null;
    this.secret = null;
    this.isGoogTwoFactorEnabled = false;
  }
  ngOnDestroy() {}
}
