import { Directive, Output, EventEmitter, AfterContentInit } from '@angular/core';
// ref :https://stackoverflow.com/questions/42266551/call-function-for-every-row-of-ngfor-angular2
@Directive({
  selector: '[appInvoke]',
})
export class InvokeDirective implements AfterContentInit {
  @Output() appInvoke: EventEmitter<any> = new EventEmitter<any>();
  ngAfterContentInit() {
    this.appInvoke.emit(null);
  }
}
