import { Component, OnInit, Input } from '@angular/core';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
})
export class ButtonLoaderComponent implements OnInit {
  @Input()
  color = 'black';
  @Input()
  width = '15px';

  constructor() {}

  ngOnInit() {}
}
