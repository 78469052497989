import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import Echo from 'laravel-echo';
import { environment } from '../../../environments';
import { ProposalStateService } from '../state/proposal';
import { ReviewStateService } from '../state/review';
import { CreativeQueryServices } from '../state/creative-query';
import { AuthenticatedUserService } from '../state/authenticated-user';
import { PopupMessageService } from './popup-message.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private host = environment.echo_server_url;
  private echoServerAuthKey = environment.echo_server_key;
  private echo: any;
  private authToken;

  constructor(
    private proposalState: ProposalStateService,
    private creativeQueryState: CreativeQueryServices,
    private reviewState: ReviewStateService,
    private userState: AuthenticatedUserService,
    private popupMessage: PopupMessageService
  ) {}

  public connect(authToken): Promise<SocketService> {
    // If already connected, disconnect and create another connection with latest auth token
    if (this.echo) {
      this.disconnect();
    }
    return new Promise((resolve, reject) => {
      try {
        if (this.echo) {
          resolve(this);
        }
        this.authToken = authToken;
        this.echo = new Echo({
          auth: { headers: { Authorization: 'Bearer ' + this.authToken } },
          client: io,
          host: this.host,
          broadcaster: 'socket.io',
          key: this.echoServerAuthKey,
        });
        resolve(this);
      } catch (e) {
        /** ToDo catch net::ERR_CONNECTION_RESET */
        reject(e);
      }
    });
  }

  public disconnect() {
    if (this.echo) {
      this.echo.disconnect();
      this.echo = null;
    }
  }

  public joinMandatoryChannels() {
    /** ToDO what should we do if there is an error on .join(). */
    this.echo
      .private('auth-users')
      .listen('.proposal-status-change', event => {
        this.proposalState.setOnChangeStatus(
          event.data._id,
          event.data.status,
          event.data.blockchain_confirmed,
          event.data.blockchain_id
        );
      })
      .listen('.proposal-score-change', event => {
        this.proposalState.setScores(
          event.data._id,
          event.data.votes_score,
          event.data.balance,
          event.data.stake_score,
          event.data.score
        );
      })
      .listen('.proposal-toggle-lock', event => {
        this.proposalState.setLockedData(
          event.data._id,
          event.data.locked_by ? event.data.locked_by : null,
          event.data.locked_at ? event.data.locked_at : null
        );
      })
      .listen('.creative-query-status-change', event => {
        this.creativeQueryState.setStatus(event.data._id, event.data.status);
      })
      .listen('.creative-query-updated', event => {
        this.creativeQueryState.setStatus(event.data._id, event.data.status);
      })
      .listen('.creative-query-answered', event => {
        this.creativeQueryState.onAnswerCreateUpdate(event.data._id, event.data.total_votes, event.data.total_stake);
      })
      .listen('.creative-query-toggle-lock', event => {
        this.creativeQueryState.setLockedData(
          event.data._id,
          event.data.locked_by ? event.data.locked_by : null,
          event.data.locked_at ? event.data.locked_at : null
        );
      })
      .listen('.review-liked', event => {
        this.reviewState.updateReview(event.data._id, {
          total_number_of_likes: event.data.total_number_of_likes,
          average_score: event.data.average_score,
        });
      })
      .listen('.review-status-change', event => {
        this.reviewState.setStatus(event.data._id, event.data.status);
      })
      .listen('.review-toggle-lock', event => {
        this.reviewState.setLockedData(
          event.data._id,
          event.data.locked_by ? event.data.locked_by : null,
          event.data.locked_at ? event.data.locked_at : null
        );
      });

    /** User related events */
    this.userState.userByToken().subscribe(async user => {
      const userID = (await user) ? user._id : null;

      this.echo
        .private('user-updates.' + userID)
        .listen('.user-balance-update', event => {
          this.userState.setUserBalance(event.data.balance);
          this.userState.setUserLockedBalance(event.data.locked);
        })
        .listen('.user-reputation-update', event => {
          this.userState.setUserReputation(event.data.reputation);
        })
        .listen('.user-email-verified', event => {
          this.userState.setUserTypeID(event.data.user_type_id);
        })
        .listen('.moderator-access-requested', event => {
          this.userState.setModeratorAttributes(event.data.user_type_id, event.data.awaiting_become_moderator_request);
          this.popupMessage.onWsEvent('moderator-access-flow', 'access-requested');
        })
        .listen('.moderator-access-granted', event => {
          this.userState.setModeratorAttributes(event.data.user_type_id, event.data.awaiting_become_moderator_request);
          this.popupMessage.onWsEvent('moderator-access-flow', 'access-granted');
        })
        .listen('.moderator-access-refused', event => {
          this.userState.setModeratorAttributes(event.data.user_type_id, event.data.awaiting_become_moderator_request);
          this.popupMessage.onWsEvent('moderator-access-flow', 'access-refused');
        })
        .listen('.proposal-status-change-owner', event => {
          /**
           * ToDO
           * All concerns here should be in the proposalService
           * In other words: we should not call the proposalStateService
           * directly in this module, because of potential circular dependencies
           */
          this.proposalState.getProposal(event.data._id).subscribe(proposal => {
            this.popupMessage.onWsEvent('proposal-status-change', proposal);
          });
        })
        .listen('.creative-query-status-change-owner', event => {
          /**
           * ToDO
           * All concerns here should be in the proposalService
           * In other words: we should not call the proposalStateService
           * directly in this module, because of potential circular dependencies
           */
          this.creativeQueryState.getCreativeQuery(event.data._id).subscribe(creativeQuery => {
            this.popupMessage.onWsEvent('creative-query-status-change', creativeQuery);
          });
        })
        .listen('.review-status-change-owner', event => {
          this.reviewState.getReview(event.data._id).subscribe(review => {
            this.popupMessage.onWsEvent('review-status-change', review);
          });
        })
        .listen('.proposal-updated', event => {
          this.proposalState.updateFullProposalState(event.data._id, event.data);
        })
        .listen('proposal-extend-general-review', event => {
          this.proposalState.updateFullProposalState(event.data._id, event.data);
          this.popupMessage.onWsEvent('proposal-extend-general-review', event.data);
        })
        .listen('.moderation-process-toggle', event => {
          this.userState.setModeratedEntity(event.data.to_be_moderated_entity);
        });
    });
  }
}
