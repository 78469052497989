import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit, OnDestroy {
  @Input() user: any;
  selectedTab: number;
  selectedGroup: string;
  isOpen = false;

  tabs2 = [
    { name: 'Proposals', slug: 'proposals' },
    { name: 'Creative Queries', slug: 'creative-queries' },
    { name: 'Reviews', slug: 'reviews' },
    { name: 'Likes', slug: 'likes' },
    { name: 'Evaluations', slug: 'evaluations' },
    { name: 'Moderations', slug: 'moderations' },
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.selectedGroup = params.type;
    });
    if (!this.selectedGroup) {
      this.selectedTab = 0;
      this.selectedGroup = 'proposals';
    }
  }

  selectItems(id) {
    this.selectedGroup = id;
    this.isOpen = false;
  }

  selectItemsDD(item) {
    this.selectedGroup = item.slug;
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }

  ngOnDestroy() {}
}
