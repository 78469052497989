import { Component, EventEmitter, OnInit, Output, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmbedVideoService, ProposalService } from '../../../../shared';
import { catchError, last, map, tap } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { environment } from '../../../../../environments';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit, OnDestroy, OnChanges {
  addImageForm: FormGroup;
  loading = false;
  imgUrl = null;
  imageFiles: any = [];
  video = null;
  @Output() addImage = new EventEmitter<any>();
  @Output() cancelImage = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private embedService: EmbedVideoService,
    private proposalService: ProposalService
  ) {}

  ngOnInit() {
    this.addImageForm = this.formBuilder.group({
      url: ['', [Validators.required]],
      mediaTitle: [''],
      description: [''],
    });
  }

  submit() {
    if (this.addImageForm.valid) {
      this.addImage.emit(this.addImageForm.value);
    }
  }

  imageUploadChange(event) {}

  cancel() {
    this.cancelImage.emit();
  }

  imageUpload(ev) {
    const files = ev.srcElement.files;
    if (!files) {
      return;
    }
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append('cover', files[0], files[0].name);

    if (!files[0].type.split('/').includes('image')) {
      alert('File is not image type');
      this.loading = false;
      return;
    }

    // Validate for Filesize
    if (files[0].size / 5242880 >= environment.max_image_size) {
      alert('Upload File Size is greater than 5 Mb ');
      this.loading = false;
      return;
    }

    this.imageFiles.push({
      data: files[0],
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
    });
    this.uploadDocFiles();
  }

  uploadDocFiles() {
    this.imageFiles.forEach((file, index) => {
      if (!file.inProgress) {
        this.uploadDocFile(file);
      }
    });
  }

  private uploadDocFile(file: any) {
    const fd = new FormData();
    fd.append('cover', file.data);
    const req = this.proposalService
      .uploadCoverImage(fd)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.addImageForm.controls['url'].setValue(event.body.data.file_url);
            this.imgUrl = event.body.data.file_url;
            const index = this.imageFiles.indexOf(file);
            if (index > -1) {
              this.imageFiles.splice(index, 1);
            }
            this.loading = false;
            // this.mode = 'view';
          }
        },
        error => {
          this.loading = false;
          // @todo handle Errors
          console.log('FILE UPLOAD ERROR ', error);
        }
      );
  }

  ngOnDestroy() {}

  ngOnChanges() {}
}
