import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-moderation-in-progress-dialog',
  templateUrl: './moderation-in-progress-dialog.component.html',
  styleUrls: ['./moderation-in-progress-dialog.component.scss'],
})
export class ModerationInProgressDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModerationInProgressDialogComponent>
  ) {}

  ngOnInit() {}
}
