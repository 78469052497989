import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService, ToasterService } from '../../services';
import { LoaderService } from '../loader/loader.component';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class DcpCropperComponent implements OnChanges, OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageBase64: string;

  @Input()
  imageFile: any;

  @Input()
  roundCropper = false;

  @Input()
  resizeToWidth = 0;

  @Input()
  aspectRatio = 16 / 9;

  @Output()
  croppedImageFile = new EventEmitter();

  @Output()
  close = new EventEmitter();

  @Output()
  open = new EventEmitter();

  @Output()
  saveNewImage = new EventEmitter();

  isImageCropped = false;

  constructor(public loader: LoaderService) {}

  ngOnInit() {
    this.imageChangedEvent = this.imageFile;
    this.isImageCropped = false;
  }

  imageCropped(event: any) {
    this.croppedImage = event.file;
    this.croppedImageBase64 = event.base64;
  }

  loadImageFailed() {
    // TODO fixme
  }

  cancelCrop() {
    this.close.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileChangeEvent) {
      this.imageChangedEvent = this.imageFile;
    }
  }

  openedImage(isLoadead) {
    this.open.emit(isLoadead);
  }

  upload() {
    this.isImageCropped = true;
    this.croppedImageFile.emit({
      croppedImageBase64: this.croppedImageBase64,
      croppedImage: this.croppedImage,
    });

    this.close.emit(true);
  }

  uploadImage() {
    this.saveNewImage.emit();
  }
}
