import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { base64ToFile } from 'ngx-image-cropper';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { Genre, Language, Proposal } from '../../../../../app.datatypes';
import { ToasterService, ProposalService, ImageCroppedEvent, EmbedVideoService } from '../../../../../shared';
import { environment } from '../../../../../../environments';

export interface AboutProposalData {
  title: string;
  content: string;
  summary: string;
  genres: any[];
  languages: any[];
  genreAdd: string;
  isValid: boolean;
  isCoverUploaded: boolean;
  mediaList: any[];
  coverId: any;
  uploadedCroppedCoverImage: any;
}

@Component({
  selector: 'app-proposal-create-step-two',
  templateUrl: './proposal-create-step-two.component.html',
  styleUrls: ['./proposal-create-step-two.component.scss'],
})
export class ProposalCreateStepTwoComponent implements OnInit, OnDestroy {
  @Input() proposal: Proposal;
  @Input() languageList: Language[];
  @Input() topLanguageList: Language[];
  @Input() genreList: Genre[];
  @Output() setStepTwo = new EventEmitter<AboutProposalData>();

  stepTwoForm: FormGroup;
  uploadedCroppedCoverImage: any;
  isImageLoaded = false;
  proposalCoverImageData: any;
  isCropperLoaded = false;
  coverId: any;
  isCoverUploaded = false;
  coverImageFiles: any;
  isOpenCropper = false;
  croppedCoverImage: any = '';
  isOpenModal = false;
  uploadType: string;
  proposalMode: string;
  proposalImageUploaded: string;
  proposalFileUploaded: string;
  proposalVideoUploaded: string;
  proposalModalTitle: string;
  proposalModalValue: string;
  proposalModalDescription: string;
  proposalIndex: number;
  mediaList: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToasterService,
    private proposalService: ProposalService,
    private embedService: EmbedVideoService
  ) {}

  ngOnInit(): void {
    this.stepTwoForm = this.formBuilder.group({
      title: [this.proposal.title ? this.proposal.title : null, Validators.required],
      content: [this.proposal.content ? this.proposal.content : null, [Validators.required, Validators.maxLength(500)]],
      summary: [this.proposal.summary ? this.proposal.summary : null, Validators.required],
      genres: [this.proposal.genres ? this.proposal.genres.map(value => value.slug) : null, Validators.required],
      languages: [
        this.proposal.languages ? this.proposal.languages.map(value => value.iso) : null,
        Validators.required,
      ],
      genreAdd: null,
    });
    this.buildMedia();
  }

  coverImageUpload(event: any): void {
    this.proposalCoverImageData = {};
    this.isCropperLoaded = false;
    this.uploadedCroppedCoverImage = '';
    this.coverId = '';
    this.isCoverUploaded = false;
    const files = event.srcElement.files;
    if (!files) {
      return;
    }
    const formData: FormData = new FormData();
    formData.append('cover', files[0], files[0].name);
    if (!files[0].type.split('/').includes('image')) {
      this.toastService.openSnackBar('File is not image type', 'Image', 'error', 3000);
      return;
    }
    // Validate for Filesize
    if (files[0].size / 5242880 >= environment.max_image_size) {
      this.toastService.openSnackBar('Upload File Size is greater than 5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
      return;
    }

    this.coverImageFiles = {
      data: files[0],
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
    };
    this.uploadCoverImage(this.coverImageFiles);
  }

  imageLoadedImg(): void {
    this.isImageLoaded = true;
  }

  uploadCoverImage(file: any) {
    const fd = new FormData();
    fd.append('cover', file.data);
    const req = this.proposalService
      .uploadCoverImage(fd)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.proposalCoverImageData = {
              url: event.body.data.file_url,
              type: 'image',
              thumb: event.body.data.file_url,
            };
            this.isCropperLoaded = false;
            this.isOpenCropper = true;
          }
        },
        error => {
          // @todo handle Errors
        }
      );
  }

  // Cropper Functions
  imageCropped(event: ImageCroppedEvent) {
    this.croppedCoverImage = event.base64;
  }

  imageLoaded(image: HTMLImageElement) {
    this.isCropperLoaded = true;
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  imageCroppedCancel() {
    this.isOpenCropper = false;
  }

  imageCroppedReturn(event: ImageCroppedEvent) {
    const file = base64ToFile(this.croppedCoverImage);
    file['name'] = 'Prepare cover image';
    this.coverImageFiles = {
      data: file,
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
      cover: true,
      type: 'image',
    };
    this.uploadCroppedCoverImage(this.coverImageFiles);
  }

  uploadCroppedCoverImage(file: any) {
    const fd = new FormData();
    fd.append('cover', file.data);
    const req = this.proposalService
      .uploadCoverImage(fd)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          this.toastService.openSnackBar(error?.error?.errors?.cover[0], 'Image Size', 'error', 3000);
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.uploadedCroppedCoverImage = event.body.data.file_url;
            this.coverId = event.body.data._id;
            this.isCoverUploaded = true;
            this.isOpenCropper = false;
            this.onBlur();
          }
        },
        error => {
          // @todo handle Errors
        }
      );
  }

  openModal(type: string) {
    this.uploadType = type;
    this.proposalMode = 'add';
    this.proposalImageUploaded = '';
    this.proposalFileUploaded = '';
    this.proposalVideoUploaded = '';
    this.proposalModalTitle = '';
    this.proposalModalValue = '';
    this.proposalModalDescription = '';
    this.isOpenModal = true;
  }

  cancelModal() {
    this.isOpenModal = false;
  }

  saveMedia(data: any) {
    if (data?.index > 0) {
      for (let i = 0; i < this.mediaList?.length; i++) {
        if (this.mediaList[i]?.index === data?.index) {
          this.mediaList[i].value = data?.value;
          this.mediaList[i].title = data?.title;
          this.mediaList[i].description = data?.description;
          this.mediaList[i].fileData = data?.fileData;
          this.mediaList[i].videoData = data?.videoData;
          this.mediaList[i].thumbnailUrl = data?.thumbnailUrl;
          this.mediaList[i].file_id = data?.file_id;
          this.mediaList[i].isDirectVideoUpload = data?.isDirectVideoUpload;
          break;
        }
      }
    } else {
      const index = this.mediaList.length;
      data.index = index + 1;
      this.mediaList.push(data);
    }
    this.onBlur();
    this.isOpenModal = false;
  }

  deleteMedia(index) {
    if (index) {
      this.mediaList = this.mediaList.filter(x => x?.index !== index);
      this.onBlur();
    }
  }

  editMedia(data: any) {
    if (data) {
      this.uploadType = data?.type;
      this.proposalMode = 'edit';
      this.proposalIndex = data?.index;
      this.proposalImageUploaded = data?.value;
      this.proposalFileUploaded = data?.fileData;
      this.proposalVideoUploaded = data?.videoData;
      this.proposalModalTitle = data?.title;
      this.proposalModalValue = data?.value;
      this.proposalModalDescription = data?.description;
      this.isOpenModal = true;
    }
  }

  selectGenre(evt) {
    const result = [];
    if (evt.length !== 0) {
      for (let i = 0; i < evt.length; i++) {
        result.push(evt[i].slug);
      }
    }
    this.stepTwoForm.controls['genres'].setValue(result);
    this.onBlur();
  }

  selectLanguage(evt) {
    const result = [];
    if (evt.length !== 0) {
      for (let i = 0; i < evt.length; i++) {
        result.push(evt[i].iso);
      }
    }
    this.stepTwoForm.controls['languages'].setValue(result);
    this.onBlur();
  }

  onBlur() {
    let data = this.stepTwoForm.value;
    if (
      this.stepTwoForm.value.title &&
      this.stepTwoForm.value.content &&
      this.stepTwoForm.value.summary &&
      this.stepTwoForm.value.genres &&
      this.stepTwoForm.value.languages &&
      this.isCoverUploaded
    ) {
      const isValid = { isValid: true };
      const mediaData = {
        mediaList: this.mediaList,
        isCoverUploaded: this.isCoverUploaded,
        coverId: this.coverId,
        uploadedCroppedCoverImage: this.uploadedCroppedCoverImage,
      };
      data = { ...data, ...isValid, ...mediaData };
      this.setStepTwo.emit(data);
    } else {
      data = { isValid: false };
    }
  }

  buildMedia() {
    if (this.proposal) {
      let index = 1;
      if (this.proposal?.cover_url) {
        this.uploadedCroppedCoverImage = this.proposal?.cover_url;
        this.isCoverUploaded = true;
      }

      if (this.proposal?.video_url?.length > 0) {
        for (const video of this.proposal?.video_url) {
          const data = {
            index: index,
            type: 'video',
            value: video?.url,
            title: video?.mediaTitle,
            description: video?.description,
            videoData: this.embedService.embed(video?.url),
            thumbnailUrl: video?.thumbnailUrl,
            isDirectVideoUpload: video?.isDirectVideoUpload,
            file_id: video?.file_id,
          };
          this.mediaList.push(data);
          index = index + 1;
        }
      }

      if (this.proposal?.images?.length > 0) {
        for (const image of this.proposal?.images) {
          const data = {
            index: index,
            type: 'image',
            value: image?.url,
            title: image?.mediaTitle,
            description: image?.description,
          };
          this.mediaList.push(data);
          index = index + 1;
        }
      }

      if (this.proposal?.docs?.length > 0) {
        for (const doc of this.proposal?.docs) {
          const data = {
            index: index,
            type: 'file',
            value: doc?.url,
            title: doc?.docTitle,
            description: doc?.description,
            fileData: doc?.file_preview_url,
          };
          this.mediaList.push(data);
          index = index + 1;
        }
      }
    }
  }
  ngOnDestroy() {
    //
  }
}
