import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scoreToPercent',
})
export class ScoreToPercentPipe implements PipeTransform {
  transform(value: number): string {
    const truncateUntilTwoDecimals = /^-?\d+(?:\.\d{0,2})?/;
    if (value === null || value === undefined) {
      return '0';
    } else {
      value *= 10;
      return value.toString().match(truncateUntilTwoDecimals)[0];
    }
  }
}
