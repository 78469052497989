import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CreativeQuery, CreativeQueryStatus } from '../../../app.datatypes';
import { WindowSizeService } from '../../services';
import { CreativeQueryServices } from '../../state';

@Component({
  selector: 'app-creative-query-card-view',
  templateUrl: './creative-query-card-view.component.html',
  styleUrls: ['./creative-query-card-view.component.scss'],
  host: {
    class: 'block relative ',
  },
})
export class CreativeQueryCardViewComponent implements OnInit {
  @Input() query: CreativeQuery;
  @Input() isOwner = false;
  @Input() active = false;
  @Output() cancelCreativeQuery = new EventEmitter();
  creativeQueryStatus = CreativeQueryStatus;
  days: number;
  lockEnd = new Date();
  differenceTime: any;
  hours: number;
  minutes: number;
  seconds: number;

  constructor(
    public dialog: MatDialog,
    private windowSizeService: WindowSizeService,
    private creativeQueryService: CreativeQueryServices
  ) {}

  diff(dt2, dt1) {
    const diff = dt2.getTime() - dt1.getTime();
    return Math.abs(Math.round(diff));
  }

  CountDown() {
    this.lockEnd.setTime(this.lockEnd.getTime() - 1000);
    this.hours = this.lockEnd.getHours();
    this.minutes = this.lockEnd.getMinutes();
    this.seconds = this.lockEnd.getSeconds();
  }

  InitCountDown() {
    const lockedDate = new Date(this.query.locked_at);
    this.lockEnd.setTime(lockedDate.getTime() + 4 * 60 * 60 * 1000);
    const diff = this.diff(this.lockEnd, new Date());
    this.lockEnd = new Date(diff);
    setInterval(() => {
      this.CountDown();
    }, 1000);
  }

  ngOnInit() {
    if (this.query.locked_by) {
      this.InitCountDown();
    }

    this.days = this.creativeQueryService.calculateDaysRemaining(this.query);
  }

  triggerFunction() {}
}
