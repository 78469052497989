import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-cancel-remove-dialog',
  templateUrl: './cancel-remove-dialog.component.html',
  styleUrls: ['./cancel-remove-dialog.component.scss'],
})
export class CancelRemoveDialogComponent implements OnInit {
  type: string;
  action = 'cancel';
  actionName = 'Cancel';
  actionPastTense = 'cancelling';
  typeName: string;
  confirmRequired = false;
  isActionConfirmed = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CancelRemoveDialogComponent>) {
    if (data.action === 'remove') {
      this.action = data.action;
      this.actionName = 'Remove';
      this.actionPastTense = 'removing';
    }
    this.type = data.type;
    if (this.type === 'proposal') {
      this.typeName = 'Proposal';
    } else if (this.type === 'creative query') {
      this.typeName = 'Creative Query';
    } else if (this.type === 'review') {
      this.typeName = 'Review';
    }
    if (data.confirmRequired) {
      this.confirmRequired = true;
    }
  }
  ngOnInit() {}

  submit() {
    this.dialogRef.close('submit');
  }

  cancel() {
    this.dialogRef.close();
  }

  onConfirmChange(event) {
    this.isActionConfirmed = event.target.value.trim().toLocaleLowerCase() === this.action;
    if (this.isActionConfirmed && event.key === 'Enter') {
      this.submit();
    } else if (event.key === 'Escape') {
      this.cancel();
    }
  }
}
