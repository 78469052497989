import { Injectable } from '@angular/core';
import { StoreConfig, Store, ID } from '@datorama/akita';

export interface AuthenticationState {
  access_token: string;
  expires_in?: number;
  refresh_at?: Date;
  refresh_token?: string;
  token_type?: string;
}

function createInitialState(): AuthenticationState {
  return {
    access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
    refresh_token: localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null,
    refresh_at: localStorage.getItem('refresh_at') ? new Date(localStorage.getItem('refresh_at')) : null,
  } as AuthenticationState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'authentication',
  resettable: true,
})
export class AuthenticationStore extends Store<AuthenticationState> {
  constructor() {
    super(createInitialState());
  }

  reset() {
    super.reset();
    this.update(createInitialState());
  }
}
