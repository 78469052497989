import { Component, Input, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

export class LoaderService {
  visible = false;
  finished = false;
  on() {
    this.finished = false;
    return (this.visible = true);
  }
  off() {
    this.finished = true;
    return (this.visible = false);
  }
}

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input()
  color = 'black';
  @Input()
  width = '20px';
  @Input()
  hideOnOff = false;
  @Input()
  overlay = false;

  constructor(public loader: LoaderService, private router: Router, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {}
}
