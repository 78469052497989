import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-low-balance',
  styles: [``],
  template: `
    <div class="info-box error">
      <app-icon [icon]="'warning'"></app-icon>
      <div>
        <h5>Low film balance!</h5>

        <div class="">You need {{ data.minBuy | filmPrice }} Films more to complete this action</div>
        <div class="flex pt-16">
          <button class="btn btn-secondary btn-erro mr-16" (click)="close()">Cancel</button>
          <button class="btn btn-primary btn-error" (click)="buy($event)">Buy</button>
        </div>
      </div>
    </div>
  `,
})
export class LowBalanceComponent {
  constructor(
    private router: Router,
    private dialogRef: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<LowBalanceComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  buy(event: MouseEvent) {
    this.router.navigate(['payment/buy'], {
      queryParams: {
        redirectUri: this.data.redirectUri,
        minBuy: this.data.minBuy,
      },
    });
    this.close();
  }

  close() {
    this.bottomSheetRef.dismiss();
    // this.dialogRef.closeAll();
    // event.preventDefault();
  }
}
