import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ThemeService {
  public currentTheme$: BehaviorSubject<boolean>;
  public currentScale$: BehaviorSubject<boolean>;

  darkThemeClass = 'dcp-dark-theme';
  lightThemeClass = 'dcp-light-theme';

  constructor() {
    this.currentTheme$ = new BehaviorSubject<boolean>(false);
    this.currentScale$ = new BehaviorSubject<boolean>(false);
    //  this.currentTheme$.next(false);
  }

  public toggleScale(wrapper, overlayContainer) {
    this.currentScale$.next(!this.currentScale$.value);
  }

  // @todo : create user specific theme
  public toggleTheme(wrapper, overlayContainer) {
    this.currentTheme$.next(!this.currentTheme$.value);
    if (this.currentTheme$.value) {
      wrapper.nativeElement.classList.remove(this.lightThemeClass);
      overlayContainer.getContainerElement().classList.remove(this.lightThemeClass);
      wrapper.nativeElement.classList.add(this.darkThemeClass);
      overlayContainer.getContainerElement().classList.add(this.darkThemeClass);
    } else {
      wrapper.nativeElement.classList.remove(this.darkThemeClass);
      overlayContainer.getContainerElement().classList.remove(this.darkThemeClass);
      wrapper.nativeElement.classList.add(this.lightThemeClass);
      overlayContainer.getContainerElement().classList.add(this.lightThemeClass);
    }
  }

  public inverseToggleTheme(wrapper, overlayContainer) {
    if (this.currentTheme$.value) {
      wrapper.nativeElement.classList.remove(this.darkThemeClass);
      overlayContainer.getContainerElement().classList.remove(this.darkThemeClass);
      wrapper.nativeElement.classList.add(this.lightThemeClass);
      overlayContainer.getContainerElement().classList.add(this.lightThemeClass);
    } else {
      wrapper.nativeElement.classList.remove(this.lightThemeClass);
      overlayContainer.getContainerElement().classList.remove(this.lightThemeClass);
      wrapper.nativeElement.classList.add(this.darkThemeClass);
      overlayContainer.getContainerElement().classList.add(this.darkThemeClass);
    }
  }
}
