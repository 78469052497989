import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  AfterContentInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap, shareReplay, switchMap, catchError, last, map } from 'rxjs/operators';
import {
  ToasterService,
  LoaderService,
  PasswordService,
  AuthenticatedUserQuery,
  LowBalanceComponent,
  SEOService,
  ProgressService,
  ErrorsHandlerService,
  PaymentService,
  ProposalRoundService,
  CreativeQueryServices,
  CreativeQueryQuery,
  UserService,
  WindowScrollService,
  AuthenticatedUserState,
  ProposalService,
  EmbedVideoService,
} from '../../../shared';
import { CreativeQuery, SubmitCreativeQueryRequest, Region, Question, Option } from '../../../app.datatypes';
import { merge } from 'rxjs';
import { ComponentCanDeactivateDirective } from '../../../shared/services/component-can-deactivate';
import { panelIn } from '../../../app.animations';
import { environment } from '../../../../environments';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-query-create',
  templateUrl: './query-create.component.html',
  styleUrls: ['./query-create.component.scss'],
  animations: [panelIn],
})
export class QueryCreateComponent extends ComponentCanDeactivateDirective
  implements OnInit, AfterContentInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() user: AuthenticatedUserState;
  @Output() closeCreateCQSidebar = new EventEmitter<any>();

  @ViewChild('tab1', { static: false }) tab1: ElementRef;
  @ViewChild('tab2', { static: false }) tab2: ElementRef;
  @ViewChild('tab3', { static: false }) tab3: ElementRef;

  loading = false;
  activeIndex = 0;
  ageGroupList = [
    { slug: '15-24 years', name: '15-24 years' },
    { slug: '25-54 years', name: '25-54 years' },
    { slug: '55-64 years', name: '55-64 years' },
    { slug: '65 years and over', name: '65 years and over' },
  ];
  colorList = [
    { slug: 'red', name: 'Coral' },
    { slug: 'blue', name: 'Sky Blue' },
    { slug: 'green', name: 'Dark Sea Green' },
    { slug: 'purple', name: 'Medium Purple' },
    { slug: 'cyan', name: 'Pale Violet Red' },
    { slug: 'indigo', name: 'Cornflower Blue' },
    { slug: 'olive', name: 'Olive' },
  ];
  brandList = [
    { slug: 'artist', name: 'Artist' },
    { slug: 'director', name: 'Director' },
    { slug: 'film', name: 'Film' },
    { slug: 'movie', name: 'Movie' },
    { slug: 'music', name: 'Music' },
    { slug: 'video', name: 'Video' },
    { slug: 'writer', name: 'Writer' },
  ];
  genderList = [
    { slug: 'M', name: 'Male' },
    { slug: 'F', name: 'Female' },
    { slug: 'O', name: 'Other' },
  ];
  countryList$: Observable<Region[]>;
  creativeQuery: CreativeQuery;
  submitFeeFilm: number;
  submitFeeDollars: number;
  checkoutFormGroup: FormGroup;
  preview: FormGroup;
  readyToSave = false;
  isLinear = true;
  form: FormGroup;
  questions: Question[];
  questionsPreview: Question[];
  isNew = true;
  creativeQuery$: Observable<CreativeQuery>;
  creativeQueryId: string;
  minimumBounty: number;
  maximumBounty: number;
  private matBottomSheetRef: MatBottomSheetRef<LowBalanceComponent>;
  password: string;
  hasQuestionError: boolean;
  errorQuestionMessage: string;
  hasQptionError: boolean;
  errorOptionMessage: string;

  dark = false;
  submitting = false;
  selectedColor = '';
  selectedBrand = '';
  ageMinValue = 1;
  ageMaxValue = 30;
  tabOneActive = true;
  tabTwoActive = false;
  tabThreeActive = false;
  progress: any;

  // New
  isQuestionValid = false;
  options: any;
  optionCount = 0;
  addOptionType: string;
  optionForm: FormGroup;
  imageFiles: any = [];
  isOpenModal = false;
  optionModalIndex = 0;
  optionImageUploaded: string;
  optionVideoUploaded: string;
  optionModalTitle: string;
  optionModalValue: string;
  optionModalDescription: string;
  optionTitle: string;
  optionUpdatedTitle: string;
  hasError = false;
  isCreativeQueryEdit = false;

  constructor(
    private cqService: CreativeQueryServices,
    private cqQuery: CreativeQueryQuery,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private proposalRoundService: ProposalRoundService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private toastService: ToasterService,
    public dialog: MatDialog,
    public loader: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private _seoService: SEOService,
    private passwordService: PasswordService,
    private errorService: ErrorsHandlerService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private userService: UserService,
    public windowScrollService: WindowScrollService,
    private proposalService: ProposalService,
    private embedVideoService: EmbedVideoService,
    private location: Location
  ) {
    super();
  }

  ngOnInit() {
    this.windowScrollService.hideFooter();
    this._seoService.createTitleForPage();
    this.cqQuery.selectLoading().subscribe(loading => {
      if (loading) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      } else {
        this.progressService.hideSpinner(this.progress);
      }
    });
    this.route.params
      .pipe(
        tap(params => (this.isNew = !params['id'])),
        switchMap(params => this.cqService.getCreativeQuery(params['id']))
      )
      .subscribe(cq => {
        this.creativeQuery = JSON.parse(JSON.stringify(cq));
        this.creativeQueryId = cq._id ? cq._id : null;
        if (this.creativeQueryId !== null) {
          this.hasQuestionError = false;
          this.readyToSave = true;
        }
        // Make copy of creative query object from store.
        this.userService
          .getSystemSetting('creative_query_minimum_bounty')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(setting => {
            this.minimumBounty = setting;
            // Testing localhost not return MinBounty
            if (setting.data === null) {
              this.minimumBounty = 15;
            }
            this.buildForm(cq);
            if (this.creativeQuery._id && this.creativeQuery.questions && this.creativeQuery.questions.length > 0) {
              this.isCreativeQueryEdit = true;
              this.isQuestionValid = true;
              this.questions = this.creativeQuery.questions;
              for (let i = 0; i < this.questions.length; i++) {
                if (this.questions[i].options) {
                  for (const option of this.questions[i].options) {
                    option.isSaved = true;
                  }
                  // for (let j = 0; j < this.questions[i].options.length; j++) {
                  //   this.questions[i].options[j].isSaved = true;
                  // }
                }
              }
            } else {
              this.questions = [
                {
                  type: null,
                  description: null,
                  creative_query_id: null,
                  index: 0,
                },
              ];
            }
            if (this.route.snapshot.queryParamMap.get('step')) {
              this.activeIndex = parseInt(this.route.snapshot.queryParamMap.get('step'), 10);
              this.router.navigate([], { queryParams: {} });
            }

            if (this.isCreativeQueryEdit) {
              this.options = this.questions[0]?.options;
              this.optionCount = this.options?.length - 1;
              for (const option of this.options) {
                if (option?.type === 'video') {
                  const videoData = this.embedVideoService.embed(option?.value);
                  option.videoData = videoData;
                }
                option.isEdit = false;
              }
              console.log('inside edit', this.options);
            } else {
              this.options = [
                {
                  type: 'text',
                  value: null,
                  isSaved: false,
                  question_id: this.questions[0]._id ? this.questions[0]._id : null,
                  index: this.optionCount,
                },
                {
                  type: 'text',
                  value: null,
                  isSaved: false,
                  question_id: this.questions[0]._id ? this.questions[0]._id : null,
                  index: ++this.optionCount,
                },
              ];
              console.log('inside create', this.questions);
            }
          });
      });

    // TODO replace to some store
    this.countryList$ = this.proposalRoundService.getRegions().pipe(shareReplay(1));
    this.paymentService.getFees().subscribe(values => {
      // all fees
      this.updateSubmissionFeePrice(values);
    });
  }

  ngOnDestroy() {}

  buildPreview() {
    this.questionsPreview = this.questions;
    this.form.markAllAsTouched();
    if (
      this.isValidStateErrorState() &&
      this.form.valid &&
      this.form.get('cover_color').value &&
      this.form.get('cover').value
    ) {
      this.moveToTab2();
    } else {
      setTimeout(() => {
        this.hasError = true;
        this.scrollToFirstInvalidControl();
      }, 500);
    }
  }

  removePreview() {
    this.questionsPreview = null;
  }

  buildSubmit() {}

  buildForm(creativeQuery) {
    console.log('creativeQuery', creativeQuery);
    const genders = this.adaptField(creativeQuery, 'gender');
    const regions = this.adaptField(creativeQuery, 'region');
    const age_groups = this.adaptField(creativeQuery, 'age_group');
    this.form = this.formBuilder.group({
      title: [creativeQuery ? creativeQuery.title : null, [Validators.required, Validators.maxLength(191)]],
      question: [
        creativeQuery?.questions?.length > 0 ? creativeQuery?.questions[0]?.description : null,
        [Validators.required, Validators.minLength(6)],
      ],
      bounty: [
        {
          value: creativeQuery ? creativeQuery.bounty : this.minimumBounty,
          disabled: !this.isNew && creativeQuery.status !== 'waiting-for-submission',
        },
        [Validators.required, Validators.min(this.minimumBounty), Validators.max(this.authenticatedUserQuery.balance)],
      ],
      query_age: [
        creativeQuery ? creativeQuery.query_age : null,
        [Validators.required, Validators.min(this.ageMinValue), Validators.max(this.ageMaxValue)],
      ],
      demography: this.formBuilder.group({
        gender: [genders],
        age_group: [age_groups],
        region: [regions],
      }),

      cover: [creativeQuery ? creativeQuery.cover : 'default', [Validators.required]],
      cover_color: [creativeQuery ? creativeQuery.cover_color : 'default', [Validators.required]],
    });

    this.checkoutFormGroup = this.formBuilder.group({
      termsAccepted: [null, Validators.requiredTrue],
    });
    this.onChanges();
  }

  public adaptField(creativeQuery, field) {
    if (creativeQuery && creativeQuery.demography && creativeQuery.demography[field]) {
      return typeof creativeQuery.demography[field][0] === 'string'
        ? creativeQuery.demography[field]
        : creativeQuery.demography[field].map(object => object[field]);
    }
    return [];
  }

  addQuestion(lastIndex = 0) {
    if (this.creativeQuery.questions === undefined) {
      this.creativeQuery.questions = [];
    }
    this.questions.push({
      type: null,
      description: '',
      creative_query_id: this.creativeQuery && this.creativeQuery._id ? this.creativeQuery._id : null,
      index: 0,
    });
  }

  async createCreativeQuery(error = null) {
    if (!this.form.valid || !this.questions) {
      return;
    }

    const password = await this.passwordService.openPasswordDialog(error);
    if (!password) {
      this.submitting = false;
      return;
    }

    const errorHandler = err => {
      if (err.status === 403) {
        this.createCreativeQuery(err.error);
        return;
      } else {
        this.toastService.openSnackBar('There was a problem submitting your creative query', 'error');
        this.errorService.handleSubmitError(err);
      }
    };

    const req = this.createCreateOrUpdateRequest(password);
    this.cqService
      .postPrepareForSubmitCreativeQuery(req)
      .pipe(untilDestroyed(this))
      .subscribe(async cQuery => {
        this.creativeQuery = cQuery;
        await this.submitCreativeQuery(password);
      }, errorHandler);
  }

  async updateCreativeQuery(error = null) {
    if (!this.form.valid) {
      this.submitting = false;
      return;
    }

    const password = await this.passwordService.openPasswordDialog(error);
    if (!password) {
      this.submitting = false;
      return;
    }

    const errorHandler = err => {
      if (err.status === 403) {
        this.updateCreativeQuery(err.error);
        return;
      } else {
        this.submitting = false;
        this.toastService.openSnackBar('There was a problem (re)submitting your creative query', 'error');
        this.errorService.handleSubmitError(err);
      }
    };

    const req = this.createCreateOrUpdateRequest(password);
    // update a cq that is created, and on the blockchain (resubmit)
    if (this.creativeQuery.blockchain_confirmed) {
      this.cqService
        .putPrepareForReSubmitCreativeQuery(this.creativeQuery._id, req)
        .pipe(untilDestroyed(this))
        .subscribe(async cQuery => {
          this.creativeQuery = cQuery;
          this.resubmitCreativeQuery(password);
        }, errorHandler);
      // update a cq that is created, but not on the blockchain yet (submit)
    } else {
      this.cqService
        .postPrepareForSubmitCreativeQuery(req)
        .pipe(untilDestroyed(this))
        .subscribe(async cQuery => {
          this.creativeQuery = cQuery;
          this.submitCreativeQuery(password);
        }, errorHandler);
    }
  }

  /*
   * currently not used
   */
  saveCreativeQuery() {
    if (this.form.valid) {
      const isSure = confirm('Are you sure you want to save the content of your creative query as draft?');
      if (!isSure) {
        return false;
      }
      const merged = this.form.value;

      // Check if question is not null/unedited
      if (this.questions && this.questions[0].description) {
        merged.questions = this.questions;
      }

      if (this.creativeQuery && this.creativeQuery._id) {
        this.cqService
          .putCreativeQuery(this.creativeQuery._id, merged)
          .pipe(untilDestroyed(this))
          .subscribe(cQuery => {
            this.readyToSave = false;
            this.router.navigate([`/creative-query/create/${cQuery._id}`]);
            this.toastService.openSnackBar('Creative query saved ', 'Success', 'check', 3000);
          });
      } else {
        this.cqService
          .postDraftCreativeQuery(merged)
          .pipe(untilDestroyed(this))
          .subscribe(cQuery => {
            this.readyToSave = false;
            this.router.navigate([`/creative-query/create/${cQuery._id}`]);
            this.toastService.openSnackBar('Creative query saved ', 'Success', 'check', 3000);
          });
      }
    }
  }

  public async submitCreativeQuery(password) {
    const req = await this.createSubmitRequest(
      password,
      this.creativeQuery.submission_fee,
      this.creativeQuery.submission_transaction
    );

    this.cqService
      .postCreativeQuerySubmit(req)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.closeCreateCQSidebar.emit();
          this.toastService.openSnackBar(
            'Thank you for your creative query. It must be moderated before becoming visible to other users',
            'Query Submitted',
            'check',
            5000
          );
          this.readyToSave = false;
          this.router.navigate(['/creative-query/' + res._id]);
        },
        error => {
          this.submitting = false;
          this.toastService.openSnackBar('There was a problem in submitting your creative query', 'error');
          this.errorService.handleSubmitError(error);
        }
      );
  }

  public async resubmitCreativeQuery(password) {
    const req = await this.createSubmitRequest(
      password,
      this.creativeQuery.resubmission_fee,
      this.creativeQuery.resubmission_transaction
    );

    this.cqService
      .postCreativeQueryResubmit(req)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.toastService.openSnackBarWithLink(
            'Thank you for your creative query. It must be moderated before becoming visible to other users',
            'Query Submitted',
            'check',
            5000,
            'account/activity',
            'creative-queries',
            'Check Activity'
          );
          this.readyToSave = false;
          this.router.navigate(['/creative-query/' + res._id]);
        },
        error => {
          this.submitting = false;
          this.toastService.openSnackBar(
            'There was a problem in submitting your creative query',
            'Error',
            'error',
            5000
          );
          this.errorService.handleSubmitError(error);
        }
      );
  }

  public async createSubmitRequest(password, fee, transactionId) {
    const req = new SubmitCreativeQueryRequest();
    req.creativeQueryId = this.creativeQuery._id;
    req.password = password;
    return req;
  }

  public createCreateOrUpdateRequest(password) {
    const req = {
      ...this.form.value,
      questions: this.questions,
      password: password,
    };

    if (this.creativeQuery._id) {
      req.creative_query_id = this.creativeQuery._id;
      req.bounty = this.form.value.bounty || 0;
    }

    const fee = Number(this.submitFeeDollars.toFixed(8));
    if (this.creativeQuery.blockchain_confirmed) {
      req.resubmission_fee = fee;
    } else {
      req.submission_fee = fee;
    }

    return req;
  }

  public updateSubmissionFeePrice(freeValues) {
    if (this.creativeQuery?.blockchain_confirmed) {
      this.submitFeeDollars = freeValues.creative_query_resubmission_fee;
      this.submitFeeFilm = freeValues.creative_query_resubmission_fee / freeValues.price_of_film;
    } else {
      this.submitFeeDollars = freeValues.creative_query_submission_fee;
      this.submitFeeFilm = this.submitFeeDollars / freeValues.price_of_film;
    }

    this.maximumBounty = this.authenticatedUserQuery.balance - this.submitFeeDollars;
  }

  trackQuestion(index: number, question) {
    return index.toString();
  }

  isValidState(): boolean {
    let isValid =
      this.questions &&
      this.questions.length >= 1 &&
      this.questions.length ===
        this.questions.filter(question => (question.options ? question.options.length >= 2 : false)).length;

    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i].options) {
        for (let j = 0; j < this.questions[i].options.length; j++) {
          if (this.questions[i].options[j].isSaved !== true) {
            isValid = false;
          }
        }
      }
    }
    return isValid;
  }

  isValidStateErrorState(): boolean {
    if (this.questions[0].description !== null && this.options?.length > 1) {
      let isSavedAllOption = true;
      for (const option of this.options) {
        if (option?.isSaved === false) {
          isSavedAllOption = false;
        }
      }
      if (isSavedAllOption === false) {
        this.hasQptionError = true;
        this.errorOptionMessage = 'All options should be saved.';
        return false;
      }
    }
    this.hasQptionError = false;
    return true;
  }

  async openPasswordDialog(error = null) {
    this.submitting = true;
    if (this.creativeQuery._id && this.creativeQuery.blockchain_confirmed) {
      await this.updateCreativeQuery();
    } else {
      await this.createCreativeQuery();
    }
  }

  openLowBalanceSheet(minBuy: number, id): void {
    const redirectUri = '/creative-query/create/' + id;
    this.matBottomSheetRef = this.bottomSheet.open(LowBalanceComponent, {
      data: { redirectUri, minBuy },
    });
    this.matBottomSheetRef.afterDismissed().subscribe(data => {});
  }

  onChanges(): void {
    merge(this.form.valueChanges, this.checkoutFormGroup.valueChanges).subscribe(value => {
      if (!this.readyToSave) {
        this.readyToSave = true;
      }
    });
  }

  stepperChange(event) {
    this.activeIndex = event.selectedIndex;
  }

  questionChanged(question) {
    this.questions[question.index] = question;
    this.readyToSave = true;
    // this.isValidStateErrorState();
  }

  deleteQuestion(question) {
    question.description = '';
    this.readyToSave = false;
  }

  setCover(name) {
    this.form.controls.cover.setValue(name);
  }

  ngAfterContentInit() {
    this.windowScrollService.showFooterOnScroll();
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elRef?.nativeElement.querySelector('form .ng-invalid');
    if (firstInvalidControl) {
      this.scrollIt(firstInvalidControl);
    }
  }

  private scrollIt(controlEl: HTMLElement) {
    const labelOffset = 128;
    const scrollElem = this.elRef.nativeElement.querySelector('.cq-scroll-top');
    const top = controlEl.getBoundingClientRect().top + scrollElem.scrollTop - labelOffset;
    scrollElem.scrollTo({ top: top, behavior: 'smooth' });
  }

  canDeactivate(): boolean {
    return !this.readyToSave;
  }

  selectGender(evt) {
    const result = [];
    if (evt.length !== 0) {
      for (let i = 0; i < evt.length; i++) {
        result.push(evt[i].slug);
      }
    }
    this.form.controls.demography['controls'].gender.setValue(result);
    // this.form.controls['gender'].setValue(result);
  }

  selectAge(evt) {
    const result = [];
    if (evt.length !== 0) {
      for (let i = 0; i < evt.length; i++) {
        result.push(evt[i].slug);
      }
    }

    this.form.controls.demography['controls'].age_group.setValue(result);
    // this.form.controls['age_group'].setValue(result);
  }

  selectCountry(evt) {
    const result = [];
    if (evt.length !== 0) {
      for (let i = 0; i < evt.length; i++) {
        result.push(evt[i].slug);
      }
    }
    this.form.controls.demography['controls'].region.setValue(result);
    // this.form.controls['region'].setValue(result);
  }

  selectColor(evt) {
    let color = 'none';
    if (evt !== null && evt.slug !== null) {
      color = evt.slug;
    }

    this.selectedColor = color;
    this.form.controls['cover_color'].setValue(color);
  }

  selectBrand(evt) {
    let brand = 'default';
    if (evt !== null && evt.slug !== null) {
      brand = evt.slug;
    }
    this.selectedBrand = brand;
    this.form.controls['cover'].setValue(brand);
  }

  activateTab(index) {
    this.tabOneActive = false;
    this.tabTwoActive = false;
    this.tabThreeActive = false;

    if (index === 1) {
      this.tabOneActive = true;
    } else if (index === 2) {
      this.tabTwoActive = true;
    } else if (index === 3) {
      this.tabThreeActive = true;
    }
  }

  moveToTab1() {
    this.questionsPreview = undefined;
    this.tab1?.nativeElement.click();
    this.activateTab(1);
  }

  moveToTab2() {
    this.tab2?.nativeElement.click();
    this.activateTab(2);
  }

  moveToTab3() {
    this.tab3?.nativeElement.click();
    this.activateTab(3);
  }

  AddQueryAge(val) {
    let newVal = this.form.get('query_age').value;
    this.form.controls['query_age'].markAsTouched();
    if (isNaN(newVal)) {
      newVal = 0;
    }
    newVal = newVal + val;
    if (newVal < this.ageMinValue) {
      newVal = this.ageMinValue;
    }
    if (newVal > this.ageMaxValue) {
      newVal = this.ageMaxValue;
    }
    this.form.get('query_age').setValue(newVal);
  }

  onBlurQueryAge() {
    if (this.form.get('query_age').value <= 1) {
      this.form.get('query_age').setValue(1);
    }
  }

  AddBounty(val) {
    let newVal = this.form.get('bounty').value;
    this.form.controls['bounty'].markAsTouched();
    if (isNaN(newVal)) {
      newVal = 0;
    }
    newVal = newVal + val;
    if (newVal < this.minimumBounty) {
      newVal = this.minimumBounty;
    }
    if (newVal > this.maximumBounty) {
      newVal = parseInt(this.maximumBounty.toString(), 10);
    }
    this.form.get('bounty').setValue(newVal);
  }

  onBlurBounty() {
    if (this.form.get('bounty').value <= this.minimumBounty) {
      this.form.get('bounty').setValue(this.minimumBounty);
    }

    if (this.form.get('bounty').value > this.maximumBounty) {
      const max = parseInt(this.maximumBounty.toString(), 10);
      this.form.get('bounty').setValue(max);
    }
  }

  closeCreateForm() {
    if (this.isCreativeQueryEdit) {
      this.location.back();
    } else {
      if (!this.canDeactivate()) {
        if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
          this.closeCreateCQSidebar.emit();
          return true;
        } else {
          return false;
        }
      } else {
        this.closeCreateCQSidebar.emit();
      }
    }
  }

  openModal(type: string, index) {
    this.form.controls.question.markAsTouched();
    const question = this.form.value.question;
    if (question !== '' && question?.length > 5) {
      this.isQuestionValid = true;
    } else {
      this.isQuestionValid = false;
    }
    if (this.isQuestionValid) {
      this.addOptionType = type;
      this.optionModalIndex = index;
      this.optionImageUploaded = '';
      this.optionVideoUploaded = '';
      this.optionModalTitle = this.optionTitle ? this.optionTitle : '';
      this.optionModalValue = '';
      this.optionModalDescription = '';
      this.isOpenModal = true;
    }
  }

  openEditModal(type: string, option: Option) {
    this.form.controls.question.markAsTouched();
    const question = this.form.value.question;
    if (question !== '' && question?.length > 5) {
      this.isQuestionValid = true;
    } else {
      this.isQuestionValid = false;
    }
    if (this.isQuestionValid && option) {
      this.addOptionType = type;
      this.optionModalIndex = option?.index;
      this.optionImageUploaded = '';
      this.optionVideoUploaded = '';
      this.optionModalTitle = option?.title;
      this.optionModalValue = option?.value;
      this.optionModalDescription = option?.description;
      if (type === 'image') {
        this.optionImageUploaded = option?.value;
      } else {
        this.optionVideoUploaded = this.embedVideoService.embed(option?.value);
      }
      this.isOpenModal = true;
    }
  }

  cancelModal() {
    this.addOptionType = '';
    this.optionModalIndex = -1;
    this.optionTitle = '';
    this.optionImageUploaded = '';
    this.optionVideoUploaded = '';
    this.optionModalTitle = '';
    this.optionModalValue = '';
    this.optionModalDescription = '';
    this.isOpenModal = false;
  }

  updateQuestion() {
    if (this.form.value.question.length < 6) {
      return;
    }
    const dataForUpdate = { ...this.questions[0], type: 'vote', description: this.form.value.question } as Question;
    this.questionChanged(dataForUpdate);
  }

  setOptionTitle(value: any) {
    this.optionTitle = value;
  }

  setOptionUpdatedTitle(value: string) {
    this.optionTitle = value;
    this.optionUpdatedTitle = value;
  }

  updateOption(option: Option) {
    if (option && this.optionTitle) {
      option.isSaved = true;
      option.isEdit = false;
      option.value = this.optionTitle;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
      this.isValidStateErrorState();
    }
    this.optionTitle = '';
    return false;
  }

  addOption() {
    const data = {
      type: 'text',
      value: null,
      isSaved: false,
      question_id: this.questions[0]._id ? this.questions[0]._id : null,
      index: ++this.optionCount,
    };
    this.options.push(data);
  }

  buildOptionForm() {
    if (this.addOptionType === 'image') {
      this.optionForm = this.formBuilder.group({
        value: ['', [Validators.required]],
        title: ['', [Validators.required]],
        description: [],
      });
    }
    if (this.addOptionType === 'video') {
      this.optionForm = this.formBuilder.group({
        value: ['', [Validators.required]],
        title: ['', [Validators.required]],
        description: [],
      });
    }
    if (this.optionTitle) {
      this.optionForm.patchValue({
        title: this.optionTitle,
      });
    }
  }

  buildEditOptionForm() {
    if (this.addOptionType === 'image') {
      this.optionForm = this.formBuilder.group({
        value: ['', [Validators.required]],
        title: ['', [Validators.required]],
        description: [],
      });
    }
    if (this.addOptionType === 'video') {
      this.optionForm = this.formBuilder.group({
        value: ['', [Validators.required]],
        title: ['', [Validators.required]],
        description: [],
      });
    }
    this.optionForm.patchValue({
      value: this.optionModalValue ? this.optionModalValue : '',
      title: this.optionModalTitle ? this.optionModalTitle : '',
      description: this.optionModalDescription ? this.optionModalDescription : '',
    });
  }

  imageUpload(ev) {
    const files = ev.srcElement.files;
    if (!files) {
      return;
    }
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append('cover', files[0], files[0].name);

    if (!files[0].type.split('/').includes('image')) {
      this.toastService.openSnackBar('File is not image type', 'Image', 'error', 3000);
      this.loading = false;
      return;
    }

    // Validate for Filesize
    if (files[0].size / 5242880 >= environment.max_image_size) {
      this.toastService.openSnackBar('Upload File Size is greater than 5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
      this.loading = false;
      return;
    }

    this.imageFiles.push({
      data: files[0],
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
    });

    this.uploadDocFiles();
  }

  uploadDocFiles() {
    this.imageFiles.forEach((file, index) => {
      if (!file.inProgress) {
        this.uploadDocFile(file);
      }
    });
  }

  private uploadDocFile(file: any) {
    const fd = new FormData();
    fd.append('cover', file.data);
    const req = this.proposalService
      .uploadCoverImage(fd)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.optionForm.controls['value'].setValue(event.body.data.file_url);
            const index = this.imageFiles.indexOf(file);
            if (index > -1) {
              this.imageFiles.splice(index, 1);
            }
            this.optionImageUploaded = event.body.data.file_url;
            // this.saveImage();
            this.loading = false;
            // this.mode = 'view';
          }
        },
        error => {
          this.loading = false;
          // @todo handle Errors
          console.log('FILE UPLOAD ERROR ', error);
        }
      );
  }

  saveImage() {
    const option = this.options.find(x => x?.index === this.optionModalIndex);
  }

  saveOption(data: any) {
    console.log('data', data);
    if (data) {
      const option = this.options.find(x => x?.index === this.optionModalIndex);
      option.isSaved = data.isSaved;
      option.isEdit = data.isEdit;
      option.type = data.type;
      option.value = data.value;
      option.title = data.title;
      option.description = data.description;
      option.videoData = data.videoData;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
      this.isValidStateErrorState();
      this.cancelModal();
    }

    // this.optionForm.markAllAsTouched();
    // if (this.optionForm.valid) {
    //   const option = this.options.find(x => x?.index === this.optionModalIndex);
    //   option.isSaved = true;
    //   option.isEdit = false;
    //   option.type = this.addOptionType;
    //   option.value = this.optionForm.value.value;
    //   option.title = this.optionForm.value.title;
    //   option.description = this.optionForm.value.description;
    //   if (this.addOptionType === 'video') {
    //     option.videoData = this.optionVideoUploaded;
    //   }
    //   const options = this.questions[0].options ? this.questions[0].options : [];
    //   options[option?.index] = option;
    //   const dataForUpdate = { ...this.questions[0], options } as Question;
    //   this.questionChanged(dataForUpdate);
    //   this.isValidStateErrorState();
    //   this.cancelModal();
    // }
  }

  AttachVideo() {
    this.optionVideoUploaded = this.embedVideoService.embed(this.optionForm.value.value);
  }

  AttachImage() {
    this.optionImageUploaded = this.optionForm.value.value;
  }

  onAddQuestion(value: string) {
    if (value && value.length > 5) {
      this.isQuestionValid = true;
    } else {
      this.isQuestionValid = false;
    }
  }

  updateTitle(value: string, option: Option) {
    if (value && option) {
      option.title = value;
      option.isEdit = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
    return false;
  }

  displayVideo(video: string) {
    const videoData = this.embedVideoService.embed(video);
    return videoData;
  }

  editOption(option: Option) {
    if (option) {
      option.isEdit = true;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
    return false;
  }

  resetOption(option: Option) {
    if (option) {
      option.type = 'text';
      option.value = null;
      option.isSaved = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
    return false;
  }

  deleteOption(index) {
    this.options = this.options.filter(o => o?.index !== index);
    for (let i = index; i < this.options.length; ++i) {
      this.options[i].index = this.options[i].index - 1;
    }
    --this.optionCount;
    this.isValidStateErrorState();
  }

  closeOption(option: Option) {
    if (option) {
      option.isEdit = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
    return false;
  }

  saveUpdatedOption(option: Option) {
    if (option) {
      if (option?.type === 'text') {
        option.value = this.optionUpdatedTitle;
      } else {
        option.title = this.optionUpdatedTitle;
      }
      option.isEdit = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
    return false;
  }

  showSaveButton(event: any, option: Option) {
    this.optionTitle = event?.target.value;
    if (option && this.optionTitle) {
      option.value = this.optionTitle;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
    return false;
  }
}
