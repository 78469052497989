import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLocalTime',
})
export class TimeLocalPipe implements PipeTransform {
  transform(value: Date): any {
    value = new Date(value);
    return new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds()
      )
    );
  }
}
