import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Proposal, PricingFees, ProposalRound } from '../../../../../../../src/app/app.datatypes';
import { BountyData } from '../proposal-create-step-three/proposal-create-step-three.component';

export interface CheckoutData {
  termsAccepted: boolean;
}
@Component({
  selector: 'app-proposal-create-step-five',
  templateUrl: './proposal-create-step-five.component.html',
})
export class ProposalCreateStepFiveComponent implements OnInit {
  @Input() proposal: Proposal;
  @Input() selectedRound: ProposalRound;
  @Input() prices: PricingFees;
  @Input() stepThreeData: BountyData;

  @Output() setStepFive = new EventEmitter<CheckoutData>();

  stepFiveForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.stepFiveForm = this.formBuilder.group({
      termsAccepted: [null, Validators.required],
    });
  }

  setChecked(event) {
    const data = this.stepFiveForm.value;
    this.setStepFive.emit(data);
  }
}
