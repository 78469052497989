import { Injectable } from '@angular/core';
import { AuthenticationState, AuthenticationStore } from './authentication.store';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class AuthenticationQuery extends Query<AuthenticationState> {
  isLoggedIn$ = this.select(authState => !!authState.access_token);
  isLoggedIn = !!this.getValue().access_token;
  constructor(protected store: AuthenticationStore) {
    super(store);
  }
}
