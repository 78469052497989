import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Report, ReportReason, ReportRequest } from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private apiService: ApiService) {}

  postReportCreate(data): Observable<Report> {
    return this.apiService.post('api/reports', data);
  }

  getReportReasons(): Observable<ReportReason[]> {
    return this.apiService.get('api/reports/reasons');
  }

  getReportsIndex(): Observable<Report[]> {
    return this.apiService.get('api/reports');
  }

  getReports(skip = 0, search = ''): Observable<Report[]> {
    return this.apiService
      .get('api/reports?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : ''))
      .pipe(catchError(error => this.apiService.catchError(error)));
  }

  getReportReason(): Observable<ReportReason[]> {
    return this.apiService.get('api/reports/reasons');
  }

  postReport(data: ReportRequest): Observable<Report> {
    return this.apiService.post('api/reports', data);
  }
}
