import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nbps',
})
export class NBPSPipe implements PipeTransform {
  transform(input: string): any {
    if (typeof input !== 'string' || typeof input === 'undefined') {
      return input;
    }
    return input.replace(/(&nbsp;)+/g, '');
  }
}
