import { SubmissionTermComponent } from './components/pages/submission-term/submission-term.component';
import { ResetComponent } from './containers/reset/reset.component';
import { NotFoundComponent } from './containers/errors/not-found/not-found.component';
import { ReputationBoardComponent } from './containers/reputation-board/reputation-board.component';
// import {AccountComponent} from './containers/account/account.component';
import {
  // ModeratorGuardService,
  WalletGuardService,
  IdentityVerifiedGuardService,
  EmailVerifiedGuardService,
  AuthGuardService,
  UnAuthGuardService,
  PublicProfileComponent,
  LoggedInAuthGuard,
} from './shared';
import { HomeComponent } from './containers/home/home.component';
import { ProposalCreateComponent } from './containers/proposal/proposal-create/proposal-create.component';
import { QueryCreateComponent } from './containers/creative-query/query-create/query-create.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LimitRepGuardService } from './shared/services/limit-rep-guard.service';
import { LimitCqGuardService } from './shared/services/limit-cq-guard.service';
import { LimitProposalGuardService } from './shared/services/limit-proposal-guard.service';

export const AppRoutes = [
  {
    path: '',
    canActivate: [AuthGuardService, EmailVerifiedGuardService],
    loadChildren: './containers/proposal/proposal.module#ProposalModule',
  },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService, EmailVerifiedGuardService],
    data: {
      title: 'DCP - Home',
      description: 'Home',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'proposal/:id/edit',
    component: ProposalCreateComponent,
    canActivate: [AuthGuardService, IdentityVerifiedGuardService, WalletGuardService, LimitProposalGuardService],
    data: {
      state: 'app-proposal-create',
      title: 'DCP - Edit Proposal',
      description: 'Edit a Proposal',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'creative-query/:id/edit',
    component: QueryCreateComponent,
    canActivate: [AuthGuardService, IdentityVerifiedGuardService, WalletGuardService, LimitCqGuardService],
    data: {
      title: 'DCP - Creative query create',
      description: 'Creative query create',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'leader-board',
    component: ReputationBoardComponent,
    canActivate: [AuthGuardService, EmailVerifiedGuardService, LimitRepGuardService],
    data: {
      title: 'DCP - Leader Board',
      description: 'Leader Board',
      ogUrl: 'http://localhost/',
    },
  },

  {
    path: 'quiz',
    loadChildren: './containers/quiz/quiz.module#QuizModule',
  },
  {
    path: 'administration',
    loadChildren: './containers/administration/administration.module#AdministrationModule',
  },
  {
    path: 'round',
    loadChildren: './containers/proposal-round/proposal-round.module#ProposalRoundModule',
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LoggedInAuthGuard],
    data: {
      title: 'DCP - Login ',
      description: 'Login',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'register',
    component: LoginPageComponent,
    canActivate: [UnAuthGuardService],
    data: {
      title: 'DCP - Register ',
      description: 'Register',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'forgot',
    component: LoginPageComponent,
    canActivate: [UnAuthGuardService],
    data: {
      title: 'DCP - Forgot password',
      description: 'Forgot password',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'password-reset',
    component: LoginPageComponent,
    canActivate: [UnAuthGuardService],
    // canActivate: [AuthGuardService, IdentityVerifiedGuardService],
    data: {
      title: 'DCP - Reset password',
      description: 'Reset password',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'submission-term.html',
    component: SubmissionTermComponent,
    canActivate: [AuthGuardService, IdentityVerifiedGuardService],
    data: {
      title: 'DCP - Submission terms',
      description: 'Submission terms',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'account',
    loadChildren: './containers/account/accountnew.module#AccountnewModule',
  },
  {
    path: 'verify',
    loadChildren: './containers/verify/verify.module#VerifyModule',
  },
  {
    path: 'payment',
    loadChildren: './containers/payment/payment.module#PaymentModule',
  },
  {
    path: 'creative-query',
    loadChildren: './containers/creative-query/query.module#QueryModule',
  },
  {
    path: 'publicprofile/:id',
    component: PublicProfileComponent,
    data: {
      title: 'DCP - Public Profile',
      description: 'Public Profile',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
];
