import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { untilDestroyed } from 'ngx-take-until-destroy';

import {
  EmbedVideoService,
  ToasterService,
  FilePreviewOverlayRef,
  FilePreviewOverlayService,
  ProposalService,
} from '../../../../shared';
import { environment } from '../../../../../environments';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class OptionComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  optionForm: FormGroup;

  @Input()
  option: any;

  @Input() index: any;

  @Input() preview = false;

  @Output()
  saveOption = new EventEmitter();
  @Output()
  deleteOption = new EventEmitter();

  @ViewChild('option', { static: false }) optionElem: ElementRef;
  loading = false;
  mode = 'edit';
  video: string;

  hasValueError = false;
  hasTitleError = false;

  imageFiles: any = [];

  optionType = [
    { slug: 'text', name: 'Text' },
    { slug: 'image', name: 'Image' },
    { slug: 'video', name: 'Video' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private proposalService: ProposalService,
    private embedVideoService: EmbedVideoService,
    private toasterService: ToasterService,
    public dialog: MatDialog,
    private previewDialog: FilePreviewOverlayService
  ) {}

  ngOnInit() {
    if (!this.option._id) {
      this.mode = 'edit';
    } else {
      if (this.option.type === 'video') {
        this.video = this.embedVideoService.embed(this.option.value);
      }
      this.mode = 'view';
    }
    this.buildForm();

    if (this.preview === true) {
    }
  }

  ngAfterViewInit() {
    // this.optionElem.nativeElement.focus();
  }

  ngOnDestroy() {}

  buildForm() {
    if (this.option.type === 'text') {
      this.optionForm = this.formBuilder.group({
        value: [
          this.option && this.option.value && this.option.type === 'text' ? this.option.value : null,
          [Validators.required],
        ],
      });
    }

    if (this.option.type === 'image') {
      this.optionForm = this.formBuilder.group({
        value: [
          this.option && this.option.value && this.option.type === 'text' ? this.option.value : null,
          [Validators.required],
        ],
        title: [
          this.option && this.option.title && this.option.type === 'image' ? this.option.title : null,
          [Validators.required],
        ],
        description: [],
      });
      this.setImageAndVideoValidators();
    }

    if (this.option.type === 'video') {
      this.optionForm = this.formBuilder.group({
        value: [
          this.option && this.option.value && this.option.type === 'video' ? this.option.value : null,
          [Validators.required],
        ],
        title: [
          this.option && this.option.title && this.option.type === 'video' ? this.option.title : null,
          [Validators.required],
        ],
        description: [],
      });
    }
  }

  AttachImage() {
    this.saveOption.emit({ ...this.option, ...this.optionForm.value });
    this.saveItem();
  }

  AttachVideo() {
    this.video = this.embedVideoService.embed(this.optionForm.value.value);
    this.saveOption.emit({ ...this.option, ...this.optionForm.value });
  }

  OptionEdit() {
    this.mode = 'edit';
    this.option.isSaved = false;
  }

  save() {
    if (this.option.type === 'video') {
      this.video = this.embedVideoService.embed(this.optionForm.value.value);
      this.mode = 'edit';
    }
    this.saveOption.emit({ ...this.option, ...this.optionForm.value });
  }

  saveItem() {
    if (this.optionForm.valid) {
      this.mode = 'view';
      this.option.isSaved = true;
      this.saveOption.emit({ ...this.option, ...this.optionForm.value });
    } else {
      this.optionForm.markAllAsTouched();
      if (this.optionForm.controls['value'].errors != null) {
        this.hasValueError = true;
      }

      if (this.optionForm.controls['title'].errors != null) {
        this.hasTitleError = true;
      }
    }
  }

  delete() {
    this.deleteOption.emit(this.option);
  }

  imageUpload(ev) {
    const files = ev.srcElement.files;
    if (!files) {
      return;
    }
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append('cover', files[0], files[0].name);

    if (!files[0].type.split('/').includes('image')) {
      this.toasterService.openSnackBar('File is not image type', 'Image', 'error', 3000);
      this.loading = false;
      return;
    }

    // Validate for Filesize
    if (files[0].size / 5242880 >= environment.max_image_size) {
      this.toasterService.openSnackBar('Upload File Size is greater than 5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
      this.loading = false;
      return;
    }

    this.imageFiles.push({
      data: files[0],
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
    });

    this.uploadDocFiles();
  }

  uploadDocFiles() {
    this.imageFiles.forEach((file, index) => {
      if (!file.inProgress) {
        this.uploadDocFile(file);
      }
    });
  }

  private uploadDocFile(file: any) {
    const fd = new FormData();
    fd.append('cover', file.data);
    const req = this.proposalService
      .uploadCoverImage(fd)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.optionForm.controls['value'].setValue(event.body.data.file_url);
            const index = this.imageFiles.indexOf(file);
            if (index > -1) {
              this.imageFiles.splice(index, 1);
            }
            this.save();
            this.loading = false;
            // this.mode = 'view';
          }
        },
        error => {
          this.loading = false;
          // @todo handle Errors
          console.log('FILE UPLOAD ERROR ', error);
        }
      );
  }

  showPreview(event, file) {
    event.stopPropagation();
    const dialogRef: FilePreviewOverlayRef = this.previewDialog.open({
      image: file,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.optionForm && this.option._id) {
      this.optionForm.get('value').setValue(this.option.value);
    }
    if (this.option._id && this.option.type === 'video') {
      this.video = this.option.value ? this.embedVideoService.embed(this.option.value) : null;
    }

    // if (this.option._id) {
    //   this.mode = 'view';
    // }
  }

  setImageAndVideoValidators() {
    this.optionForm.get('value').valueChanges.subscribe(value => {
      if (this.option.type === 'video') {
        this.optionForm
          .get('value')
          .setValidators([Validators.pattern('https://(?:www.)?(vimeo|youtube).com/(?:watch?v=)?(.*?)(?:z|$|&)')]);
      }
      if (this.option.type === 'image') {
        this.optionForm
          .get('value')
          .setValidators([Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]);
      }
    });
  }
}
