import { Component, Input, OnInit } from '@angular/core';
import { Review, ReviewStatus } from '../../../../../app.datatypes';
import { ProgressService } from '../../../../services';

@Component({
  selector: 'app-review-activity-detail',
  templateUrl: './review-activity-detail.component.html',
  styleUrls: ['./review-activity-detail.component.css'],
})
export class ReviewActivityDetailComponent implements OnInit {
  @Input() review: Review;
  reviewStatus = ReviewStatus;
  constructor() {}

  ngOnInit(): void {}
}
