import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewChecked,
  OnDestroy,
  HostListener,
  Renderer2,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {
  ThemeService,
  LoaderService,
  AuthenticationService,
  AuthenticationQuery,
  AuthenticatedUserQuery,
  AuthenticatedUserService,
  PasswordService,
  RoutingState,
  AuthenticatedUserState,
  UserService,
  UserTypes,
  SharedService,
} from './shared';
import { MatDialog } from '@angular/material/dialog';
import { SocketService } from './shared/services/socket.service';
import { environment } from '../environments';
import { SwUpdateDialogComponent } from './shared/components/sw-update-dialog/sw-update-dialog.component';
import { notificationsIn, slideInRight, announceMsg, dropdown } from './app.animations';
import { isNumber } from '@datorama/akita';
import { Router } from '@angular/router';
import { interval, Subscription, timer } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { DcpModalService } from './shared/components/dcp-modal';
import { UserEngagementData, UserRole } from './app.datatypes';
import { UserVerificationStatus } from './shared/enumerations/user-verification-status.enum';
import { TopbarComponent } from './components/layout/topbar/topbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [notificationsIn, slideInRight, announceMsg, dropdown],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  user$: AuthenticatedUserState;
  loginModalRef: any;
  sticky = false;
  shrinkToolbar = false;
  progress: any;
  newMessages: any = 0;
  timesLoop = 0;
  requestReloadKey = '_dcp_force_reload';
  hidden = true;
  showInfoBox = false;
  isCreatingProposal = false;
  isCreatingCQ = false;
  loaded = false;
  os = 'scroll';
  subsAuth: any;
  updateSwSubscription: Subscription;
  lastPing: Date;
  idleState: String;
  timedOut = false;
  private stopSubscription: Subscription;
  themePanelOpen = false;
  private engagementTimer: Subscription;
  private isUserEngagementStarted = false;
  refreshEngagementRetries = 0;
  refreshEarningsRetries = 0;
  hasRefreshTimeout = false;
  timeoutSeconds: number;

  @ViewChild('usernav', { static: true }) unav: ElementRef;
  @ViewChild('main', { static: true }) mainDiv: ElementRef;
  @ViewChild('root', { static: true }) rootElement: ElementRef;
  @ViewChild('app_wrapper', { static: false }) wrapper: ElementRef;
  @ViewChild(TopbarComponent, { static: false }) topBarComponentData: TopbarComponent;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.loaderService.off();
    }
  }

  constructor(
    private authenticationService: AuthenticationService,
    private authQuery: AuthenticationQuery,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public authenticatedUserService: AuthenticatedUserService,
    public dialog: MatDialog,
    public themeService: ThemeService,
    private swUpdate: SwUpdate,
    private loaderService: LoaderService,
    private routingState: RoutingState,
    private socketService: SocketService,
    private passwordService: PasswordService,
    private router: Router,
    private idle: Idle,
    private userService: UserService,
    private modalService: DcpModalService,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {
    this.routingState.loadRouting();
    this.initIdleTimer();
    this.sharedService.getOnRedirectActivity().subscribe(data => {
      this.isCreatingProposal = false;
      this.isCreatingCQ = false;
    });
  }

  resetIdleTimer() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stopIdleTimer() {
    this.idle.stop();
  }

  initIdleTimer() {
    this.idle.setIdle(environment.idle_timeout_seconds);
    this.idle.setTimeout(environment.idle_warning_seconds);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.modalService.close('inactivity-modal-1');
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.userIdleTimeOut();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.modalService.open('inactivity-modal-1');
    });
    this.idle.onTimeoutWarning.subscribe(countdown => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.timeoutSeconds = countdown;
    });
  }

  /**
   * When a user idles out, we show a warning modal, and if they timeout, the modal forces them to refresh.
   * Call userIdleTimeout() when they timeout completely.
   */
  startIdleTimer() {
    // Start the timer if we have a user
    if (!this.idle.isRunning() && this.user$ && this.user$._id) {
      this.resetIdleTimer();
    }
  }

  /**
   * Called when the user idles out completely due to inactivity
   * Disconnects from websockets, stops service worker upgrade checks, stops user engagement monitoring.
   */
  userIdleTimeOut() {
    if (this.updateSwSubscription) {
      this.updateSwSubscription.unsubscribe();
    }
    if (this.socketService) {
      this.socketService.disconnect();
    }
    if (this.engagementTimer) {
      this.stopUserEngagementRefresh();
    }
    // if (this.authenticationService) {
    //   this.authenticationService.logout();
    // }
  }

  ngOnInit() {
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.os = 'customScroll';
    }
    if (navigator.appVersion.indexOf('X11') !== -1) {
      this.os = 'customScroll';
    }
    if (navigator.appVersion.indexOf('Linux') !== -1) {
      this.os = 'customScroll';
    }

    this.renderer.addClass(document.body, `${this.os}`);

    this.authenticatedUserQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(async user => {
        this.user$ = await user;
        this.toggleTheme();
        this.toggleScale();
        this.startUserEngagementRefresh();
        this.startIdleTimer();
      });

    this.authenticationService.startRefreshJWTTimer();
    const token = this.authQuery.getValue().access_token ? this.authQuery.getValue().access_token : null;
    if (typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
      if (token) {
        this.socketService.connect(token).then(socket => {
          socket.joinMandatoryChannels();
        });
      }
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        return this.swUpdate.activateUpdate();
      });
      this.swUpdate.activated.subscribe(event => {
        this.showSwUpdateDialog();
      });
      // Check every five minutes for service worker update
      this.updateSwSubscription = interval(5 * 60 * 1000).subscribe(() => {
        return this.checkServiceWorkerUpdate();
      });
      this.checkServiceWorkerUpdate();
    } else {
      //
    }
    // Setup listener of storage (For handling reload commands from/to other tabs eg. on version upgrade)
    localStorage.removeItem(this.requestReloadKey);
    window.addEventListener('storage', this.onStorageChange.bind(this));

    timer(1000).subscribe(() => {
      this.hidden = false;
      // Delay calling initial user engagement refresh by 1 second.
      // Otherwise user auth state is wrong even if logged in
      this.startUserEngagementRefresh();
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subsAuth.unsubscribe();
  }

  showSwUpdateDialog() {
    const dialogRef = this.dialog.open(SwUpdateDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm === true) {
        // Request reload for all tabs
        localStorage.setItem(this.requestReloadKey, 'true');
        window.location.reload();
      } else {
        // If they didn't choose to reload, show the dialog again in 5 min
        setTimeout(() => {
          this.showSwUpdateDialog();
        }, 5 * 60 * 1000);
      }
    });
  }

  toggleTheme() {
    if (this.user$.is_dark_theme) {
      this.renderer.addClass(document.body, 'dark');
      this.renderer.removeClass(document.body, 'dcp-light-theme');
    } else {
      this.renderer.removeClass(document.body, 'dark');
      this.renderer.addClass(document.body, 'dcp-light-theme');
    }
  }

  toggleScale() {
    if (this.user$.is_grayscale) {
      this.renderer.addClass(document.body, 'gray-scale');
    } else {
      this.renderer.removeClass(document.body, 'gray-scale');
    }
  }
  getScrollPosition(event) {
    if (event) {
      return event.getElementRef().nativeElement.scrollTop;
    } else {
      return window.scrollY;
    }
  }

  checkServiceWorkerUpdate() {
    // Check for new version of the app if service worker is enabled
    if (this.swUpdate.isEnabled) {
      return this.swUpdate.checkForUpdate();
    }
  }

  logout() {
    this.authenticationService.logout();
    this.passwordService.removePassword();
    this.stopIdleTimer();
    this.stopUserEngagementRefresh();
    this.router.navigateByUrl('login');
  }

  onStorageChange(event) {
    if (localStorage.getItem(this.requestReloadKey)) {
      localStorage.removeItem(this.requestReloadKey);
      window.location.reload();
    }
  }

  showInboxBarFn() {
    this.showInfoBox = !this.showInfoBox;
  }

  CreateProposalFN() {
    this.isCreatingProposal = true;
  }

  CloseCreateProposalSidebar() {
    this.isCreatingProposal = false;
  }
  CreateCreativeQueryFN() {
    this.isCreatingCQ = true;
  }

  CloseCreativeQuerySidebar() {
    this.isCreatingCQ = false;
  }

  ngAfterViewChecked() {
    const wrapper = this.wrapper?.nativeElement;
    if (wrapper) {
      if (window.innerWidth > 991 && window.innerWidth < 1280) {
        this.renderer.addClass(wrapper, 'mini');
      } else {
        this.renderer.removeClass(wrapper, 'mini');
      }
      if (window.innerWidth > 991) {
        this.renderer.removeClass(wrapper, 'user-side-open');
      }
    }
  }

  initMenu() {
    this.themePanelOpen = false;
  }

  toggleThemePanel() {
    this.themePanelOpen = !this.themePanelOpen;
  }

  startUserEngagementRefresh() {
    let updateTime;
    let userEngagement: UserEngagementData = {
      likes: 0,
      likesRight: 0,
      review: 0,
      reviewRight: 0,
      creativeQueryRight: 0,
      creativeQuery: 0,
      all: 0,
      taken: 0,
      fillChart: 0,
      fill: 0,
      dasharay: '0',
      potentialEarning: 0.0,
      potentialEarningTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };

    const refreshUserEngagement = () => {
      this.userService.getUserEngagement().subscribe(
        res => {
          this.refreshEngagementRetries = 0;
          updateTime = res.updated_at;
          const userEngagementCopy = Object.assign({}, userEngagement);
          userEngagementCopy.potentialEarningTime = Object.assign({}, userEngagement.potentialEarningTime);
          userEngagement = this.calculateUserEngagementTime(userEngagementCopy, res.updated_at);

          if (res.no_activity_found) {
            userEngagement.all = 0;
            userEngagement.taken = 0;
            userEngagement.fillChart = 0;
          } else {
            if (res.total_proposal_bounty === undefined) {
              res.total_proposal_bounty = 0;
            }

            if (res.reviewed_creative_query_bounty === undefined) {
              res.reviewed_creative_query_bounty = 0;
            }
            userEngagement.taken = res.total_proposal_bounty + res.reviewed_creative_query_bounty;
            userEngagement.fillChart = parseInt((res.total_engagement_percent * 100).toString(), 10);
            userEngagement.fill = userEngagement.fillChart * 2.6;
            userEngagement.dasharay = userEngagement.fill + ',9999';
          }

          userEngagement.likes = res.liked_proposal_review_count;
          userEngagement.likesRight = res.active_proposal_count;

          userEngagement.review = res.reviewed_proposal_count;
          userEngagement.reviewRight = res.active_proposal_count;

          userEngagement.creativeQuery = res.reviewed_creative_query_count;
          userEngagement.creativeQueryRight = res.active_creative_query_count;

          userEngagement.all = res.active_proposal_bounty + res.active_creative_query_bounty;

          this.authenticatedUserService.updateUserEngagement(userEngagement);

          if (updateTime) {
            // Update again in 5 min from time of last update
            const dtUpdate = new Date(updateTime);
            dtUpdate.setMinutes(dtUpdate.getMinutes() + 5);
            const updateInMS = dtUpdate.getTime() - new Date().getTime() + 5000; // wait 5 seconds longer
            if (updateInMS > 0 && this.hasRefreshTimeout === false) {
              this.hasRefreshTimeout = true;
              this.engagementTimer = timer(updateInMS).subscribe(() => {
                this.hasRefreshTimeout = false;
                refreshUserEngagement();
                refreshPotentialEarnings();
              });
            }
          }
        },
        error => {
          // If the service received 503 Service Temporarily Unavailable, this is intentional.
          // Try try refresh again in 10 seconds.
          if (error.status === 503 && this.refreshEngagementRetries < 5) {
            timer(10 * 1000).subscribe(() => {
              ++this.refreshEngagementRetries;
              refreshUserEngagement();
            });
          }
        }
      );
    };
    let potentialEarning;
    const refreshPotentialEarnings = () => {
      // Also refresh potential earnings (tied to the engagement)
      this.userService.getUserPotentialEarnings().subscribe(
        res => {
          potentialEarning = res.total_proposal_tokens + res.total_creative_query_tokens;
          this.authenticatedUserService.updatePotentialEarnings(potentialEarning);
        },
        error => {
          // If the service received 503 Service Temporarily Unavailable, this is intentional.
          // Try try refresh again in 10 seconds.
          if (error.status === 503 && this.refreshEarningsRetries < 5) {
            timer(10 * 1000).subscribe(() => {
              ++this.refreshEarningsRetries;
              refreshPotentialEarnings();
            });
          }
        }
      );
    };

    if (this.user$ && this.user$._id && this.authenticatedUserQuery.hasWallet && !this.isUserEngagementStarted) {
      this.isUserEngagementStarted = true;
      refreshUserEngagement();
      refreshPotentialEarnings();
    }
  }

  stopUserEngagementRefresh() {
    if (this.engagementTimer) {
      this.engagementTimer.unsubscribe();
      this.engagementTimer = null;
    }
    this.isUserEngagementStarted = false;
  }

  calculateUserEngagementTime(info, time) {
    const dtNow = new Date();
    const dtUpdate = new Date(time);
    dtUpdate.setMinutes(dtUpdate.getMinutes() + 5);

    info.potentialEarningTime.hours = dtUpdate.getHours() - dtNow.getHours();
    info.potentialEarningTime.minutes = dtUpdate.getMinutes() - dtNow.getMinutes();

    if (info.potentialEarningTime.minutes < 0) {
      info.potentialEarningTime.minutes += 60;
      info.potentialEarningTime.hours--;
    }

    info.potentialEarningTime.seconds = dtUpdate.getSeconds() - dtNow.getSeconds();
    if (info.potentialEarningTime.seconds < 0) {
      info.potentialEarningTime.seconds += 60;
      info.potentialEarningTime.minutes--;
    }
    if (info.potentialEarningTime.minutes < 0) {
      info.potentialEarningTime.minutes += 60;
      info.potentialEarningTime.hours--;
    }
    return info;
  }

  refreshPage() {
    window.location.reload();
  }
}
