import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import { ThemeService, SEOService, ProgressService, ToasterService, WindowScrollService } from '../../services';
import { CreativeQueryServices, CreativeQueryQuery } from '../../state/creative-query';
import { Router, ActivatedRoute } from '@angular/router';
import { CreativeQuery } from '../../../app.datatypes';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticatedUserQuery, ProposalStateService } from '../../state';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-creative-query-view',
  templateUrl: './creative-query-view.component.html',
  styleUrls: ['./creative-query-view.component.scss'],
  host: {
    class: 'block h-full overflow-y-auto px-24 pb-24'
  }
})
export class CreativeQueryViewComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() queryId?: string;
  @Input() singleView = false;

  @Output() loaded = new EventEmitter();
  @Input() creativeQuery?: CreativeQuery;
  @Output() moderationAction = new EventEmitter();

  isDarkTheme: boolean;

  // Spinner variables
  progress: any;

  days: number;

  constructor(
    private creativeQueryService: CreativeQueryServices,
    private proposalStateService: ProposalStateService,
    public cqQuery: CreativeQueryQuery,
    private toasterService: ToasterService,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public appService: AuthenticatedUserQuery,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public themeService: ThemeService,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    public windowScrollService: WindowScrollService
  ) {}

  ngOnInit() {
    this._seoService.createTitleForPage();
    this.themeService.currentTheme$.subscribe(value => {
      this.isDarkTheme = value;
    });
    this.windowScrollService.hideFooter();
    this.route.params.subscribe(params => {
      let searchParam;

      if (params['id'] !== undefined) {
        searchParam = params['id'];
      } else if (this.queryId !== undefined) {
        searchParam = this.queryId;
      }

      // Initialize
      this.cqQuery.selectLoading().subscribe(loading => {
        if (loading) {
          this.progress = this.progressService.showSpinner(this.progress, this.elRef);
        } else {
          this.progressService.hideSpinner(this.progress);
        }
      });

      if (searchParam !== undefined && !this.creativeQuery) {
        this.creativeQueryService
          .getCreativeQuery(searchParam)
          .pipe(untilDestroyed(this))
          .subscribe(
            cQuery => {
              this.creativeQuery = cQuery;
              this.days = this.creativeQueryService.calculateDaysRemaining(this.creativeQuery);
              this.loaded.emit();
              if (this.creativeQuery.status === 'waiting-for-confirmation') {
                // @todo show appropriate message with
                this.router.navigate(['/creative-query']);
              }
            },
            error => {}
          );
      } else {
        // @todo handle without parameter situation
        this.days = this.creativeQueryService.calculateDaysRemaining(this.creativeQuery);
      }
    });
  }

  moderationDone(data) {
    this.creativeQueryService.queryModerated(data.moderation.creative_query);
    this.creativeQueryService.unsetActiveCq();
    this.proposalStateService.unsetActiveProposal();
    this.toasterService.openSnackBar(
      'Creative Query moderated successfully',
      'Creative Query Moderated',
      'check',
      10000
    );
  }
  ngAfterContentInit() {
    this.windowScrollService.showFooterOnScroll();
  }
  ngOnDestroy() {}
}
