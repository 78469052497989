import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import {
  LoaderService,
  PasswordValidator,
  SEOService,
  ProgressService,
  AuthenticationService,
  AuthenticatedUserService,
} from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { timer, Subject } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
  AbstractControl,
} from '@angular/forms';

import { UserService } from '../../shared';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CryptoUtil } from '../../shared/util/crypto-util';
import { openDown, fade, panelIn } from '../../app.animations';

/* WANT TO MOVE TO SEPARATE SERVICE ->ERROR EXAMPLE FOR EMAIL INPUT */
export class MyErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],

  host: {
    class: 'fixed top-0 left-0 w-full h-full z-10',
  },
  animations: [openDown, fade, panelIn],

  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  urlEmail: string;
  urlToken: string;
  registerAvailable: boolean;
  settingsLoading = true;
  form: FormGroup;
  matcher = new MyErrorStateMatcher();
  charRule = false;
  loading = false;
  suggestion: any;
  unamePattern = '^[a-zA-Z0-9._-]+$';
  progress: any;
  registerClick = false;

  @ViewChild('name', { static: true }) usernameField: ElementRef;
  @Input() outerContainer?: boolean;
  @Output() showProgress = new EventEmitter();
  @Output() hideProgress = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() hidePage = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private authenticatedUserService: AuthenticatedUserService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public loader: LoaderService,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.urlEmail = params['email'];
      this.urlToken = params['token'];
    });
    this.userService.getPublicSystemSettings().subscribe(
      data => {
        data.forEach(el => {
          if (el.name === 'INVITE_ONLY_REGISTRATION') {
            this.registerAvailable = !el.value;
            this.settingsLoading = false;
            this.cdRef.detectChanges();
          }
        });
      },
      () => {
        this.settingsLoading = false;
      }
    );
    this._seoService.createTitleForPage();
    const password = new FormControl('', [Validators.required, Validators.minLength(8), PasswordValidator.strong]);
    this.form = this.formBuilder.group({
      email: [this.urlEmail, [Validators.required, Validators.email]],
      name: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(this.unamePattern)]],
      password,
      password_repeat: ['', [Validators.required, this.passwordConfirming]],
    });
    this.onChanges();
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.value.password !== c.value.password_repeat) {
      return { invalid: true };
    }
  }

  login() {
    this.registerClick = true;
    const inputEmail = this.form.value.email.toLowerCase();
    const name = this.form.value.name.toLowerCase();
    const inputPassword = this.form.value.password;
    // hash password
    const hashedPass = CryptoUtil.bcryptHash(inputPassword);
    const authRequest = {
      email: inputEmail,
      name: name,
      password: hashedPass,
      password_confirmation: hashedPass,
      token: this.urlToken,
    };

    const errorOccurred = error => {
      this.registerClick = false;
      this.hideSpinner();
    };
    this.showSpinner();
    this.loading = true;
    this.authenticatedUserService
      .register(authRequest)
      .pipe(untilDestroyed(this))
      .subscribe(
        user => {
          this.authenticationService
            .login(inputEmail, hashedPass)
            .pipe(untilDestroyed(this))
            .subscribe(
              token => {
                this.authenticatedUserService
                  .userByToken()
                  .pipe(untilDestroyed(this))
                  .subscribe(authUser => {
                    this.hideSpinner();
                    this.close.emit();
                    this.router.navigate(['/account/user']);
                  });
              },
              loginError => errorOccurred
            );
          this.hidePage.emit();
          this.hideSpinner();
        },
        error => {
          // @todo show server Errors on form
          this.registerClick = false;
          this.loading = false;
          this.hideSpinner();
        }
      );
  }

  onChanges(): void {
    this.form.controls['name'].valueChanges.pipe(debounce(() => timer(800))).subscribe(val => {
      if (val) {
        this.userService
          .checkUserName({ name: val })
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            res => {},
            error => {
              this.form.controls['name'].setErrors({
                notAvailable: true,
              });
              this.usernameField.nativeElement.focus();
            }
          );
      }
    });
  }

  keyDownFunction(event) {
    if (this.form.valid) {
      if (event.keyCode === 13) {
        this.login();
      }
    }
  }

  onStrengthChanged($event) {}

  showSpinner() {
    if (this.outerContainer) {
      this.showProgress.emit();
    } else {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    }
  }

  hideSpinner() {
    if (this.outerContainer) {
      this.hideProgress.emit();
    } else {
      this.progress = this.progressService.hideSpinner(this.progress);
    }
  }

  suggestName() {
    this.loading = true;
    this.userService
      .suggestUserName({ name: this.form.controls['name'].value })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        res => {
          this.loading = false;
          this.suggestion = res.name_suggestion;
          this.usernameField.nativeElement.focus();
        },
        error => {
          this.loading = false;
        }
      );
  }

  useSuggestedName(event) {
    event.stopPropagation();
    this.form.controls['name'].setValue(this.suggestion);
    this.usernameField.nativeElement.focus();
  }

  ngOnDestroy() {}
}
