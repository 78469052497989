import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService, ToasterService, ProgressService, WindowScrollService } from '../../services';
import { AuthenticatedUserQuery, AuthenticatedUserState } from '../../state';
import { ActivatedRoute, Router } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { UserTypes, UserVerificationStatus } from '../../enumerations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../shared/services/user.service';
import { AuthService } from '../../../shared/services/auth.service';
import { environment } from '../../../../environments';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class UserProfileComponent implements OnInit, AfterContentInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() user: AuthenticatedUserState;

  isOwner = false;

  editProfile = false;

  @Output()
  tabChange = new EventEmitter();
  editable = true;
  profilePlaceholderURL = environment.profile_placeholder
    ? environment.profile_placeholder
    : 'https://www.gravatar.com/avatar/0.43227576652898625';

  isLoading: boolean;
  isEmailVerified: boolean;
  isMobilePhoneVerified: boolean;
  isEditingMobile: boolean;
  isSMSMessageSent: boolean;
  isUserVerified: boolean;
  isWalletCreated: boolean;
  isProfileComplete: boolean;
  hideStepper = false;
  newEmailSent: string;
  mobileForm: FormGroup;
  mobileConfirmForm: FormGroup;
  emailVerify: FormGroup;
  profileComplete: FormGroup;
  identityComplete: FormGroup;
  walletComplete: FormGroup;

  // Spinner variables
  progress: any;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private authService: AuthService,
    public appService: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private router: Router,
    public windowScrollService: WindowScrollService
  ) {
    this.isLoading = true;
    this.isEmailVerified = false;
    this.isMobilePhoneVerified = false;
    this.isEditingMobile = false;
    this.isSMSMessageSent = false;
    this.isUserVerified = false;
    this.isWalletCreated = false;
    this.newEmailSent = null;
    this.isProfileComplete = false;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (!this.user && params['id'] !== undefined) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
        this.userService
          .getAccountDashboard(params['id'])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            user => {
              this.user = user;
              this.progressService.hideSpinner(this.progress);
              this.isOwner = this.appService.isOwner(this.user._id);
              this.initilizeVariables();
            },
            error => {
              this.progressService.hideSpinner(this.progress);
              if (error instanceof HttpErrorResponse && error.status === 404) {
                this.router.navigateByUrl('404');
              }
            }
          );
      } else if (!this.user) {
        this.appService.select().subscribe(user => (this.user = user));
        this.initilizeVariables();
        this.isOwner = this.appService.isOwner(this.user._id);
      } else {
        this.isOwner = this.appService.isOwner(this.user._id);
        this.initilizeVariables();
      }
    });
    this.windowScrollService.hideFooter();
  }

  initilizeVariables() {
    if (this.user.seed_public_address) {
      this.isWalletCreated = true;
    }
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
      case UserTypes.ADMINISTRATOR:
        this.isEmailVerified = true;
        this.isUserVerified = true;
    }

    switch (this.user.user_verification_status) {
      case UserVerificationStatus.UNVERIFIED:
        break;
      case UserVerificationStatus.EMAIL_VERIFIED:
        this.isEmailVerified = true;
        break;
      case UserVerificationStatus.KYC_VERIFIED:
    }

    if (this.user.sms_verification_status) {
      this.isMobilePhoneVerified =
        this.user.sms_verification_status && this.user.sms_verification_status === 'complete';
    }
    this.isEditingMobile = !this.isMobilePhoneVerified;
  }

  resendUserEmailVerification() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.authService
      .resendUserEmailVerification()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        res => {
          this.progressService.hideSpinner(this.progress);
          this.newEmailSent = this.user.email;
          this.toasterService.openSnackBar(
            'Verification email is sent.  Please click on verification button.',
            'Email Sent',
            'check',
            3000
          );
        },
        error => {
          this.toasterService.openSnackBar('Verification email message was not sent.', 'EmailSent', 'error', 3000);
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  sendSMSVerificationMessage() {
    let mobile = this.mobileForm.value.mobile;
    if (!mobile.startsWith('+')) {
      mobile = `+${mobile}`;
    }

    this.isSMSMessageSent = true;
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.authService
      .sendSMSMobileVerification(mobile)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        res => {
          this.toasterService.openSnackBar(
            'SMS text message sent.  Please check your phone for the code.',
            'Message Sent'
          );
          this.progressService.hideSpinner(this.progress);
        },
        error => {
          this.isSMSMessageSent = false;
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  get role(): string {
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
        return 'moderator';
      case UserTypes.ADMINISTRATOR:
        return 'administrator';
      default:
        switch (this.user.user_verification_status) {
          case UserVerificationStatus.EMAIL_VERIFIED:
            return 'verified';
          case UserVerificationStatus.KYC_VERIFIED:
            return 'registered';
          default:
            return 'unverified';
        }
    }
  }

  ngAfterContentInit() {
    this.windowScrollService.showFooterOnScroll();
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
