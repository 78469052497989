import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, filter, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { User, Moderation, Proposal, UploadedFile, Country, UserNotification, Ethnicity } from '../../app.datatypes';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments';
import { AuthenticatedUserState } from '../state';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = environment.url;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getAccountPublicProfile(userId) {
    const apiUrl = 'api/public-profile/' + userId;
    return this.apiService.get(apiUrl);
  }

  getAccountDashboard(userid): Observable<AuthenticatedUserState> {
    let api = 'api/account/dashboard';
    if (userid) {
      api += '/' + userid;
    }
    return this.apiService.get(api);
  }

  getUserProfile(username): Observable<AuthenticatedUserState> {
    const api = 'api/public-profile/' + username;
    return this.apiService.get(api);
  }

  getUserModerations(userid, skip = 0, search = ''): Observable<Moderation[]> {
    return this.apiService.get(
      'api/user-moderations/' + userid + '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  getUserActivity(userid, skip = 0, search = ''): Observable<any[]> {
    let apiUrl = 'api/users/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl += 'activity?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  getUserProposals(userid, field = '', genres = 'all', search = '', skip = 0, state = null): Observable<Proposal[]> {
    let apiUrl = 'api/user-proposals/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl +=
      '?field=' +
      field +
      '&direction=desc' +
      (genres ? '&genres=' + genres : '') +
      '&skip=' +
      skip +
      (search ? '&search=' + encodeURIComponent(search) : '') +
      (state ? '&state=' + state : '');
    return this.apiService.get(apiUrl);
  }

  saveUserProfile(
    firstName: string,
    lastName: string,
    dob: Date,
    sex: string,
    street: string,
    country: string,
    zipPostal: string
  ): Observable<any> {
    return this.apiService.post('api/profile', {
      firstName,
      lastName,
      dob,
      sex,
      street,
      country,
      zipPostal,
    });
  }

  createUserProfile(data: any): Observable<any> {
    return this.apiService.post('api/profile', data);
  }

  updateUserProfile(data: any): Observable<User> {
    return this.apiService.put('api/profile', data);
  }

  uploadAvatarImage(data): Observable<UploadedFile> {
    const url = 'api/profile/set-avatar';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return (
      this.http
        .post(this.url + url, data, { headers: headers, observe: 'events', reportProgress: true })
        // Fix to remove network /undefined call
        // REF : https://stackoverflow.com/questions/43456723/http-get-always-returning-undefined-in-angular-2
        .pipe(
          map((response: any) => {
            return response.data || response;
          })
        )
    );
  }

  getSystemSettings() {
    return this.apiService.get('api/system-settings');
  }

  getSystemSetting(slug: string) {
    return this.apiService.get('api/system-settings/' + slug);
  }

  getPublicSystemSettings() {
    return this.apiService.get('api/system-settings/public');
  }

  putSystemSettings(settings: any) {
    return this.apiService.put('api/system-settings', settings);
  }

  checkUserName(name): any {
    return this.apiService.post('api/username-check', name);
  }

  suggestUserName(name): Observable<any> {
    return this.apiService.post(`api/username-suggest`, name);
  }

  getCountries(): Observable<Country[]> {
    return this.apiService.get('api/countries');
  }

  getEthnicity(): Observable<Ethnicity[]> {
    return this.apiService.get('api/ethnicity');
  }

  getUserNotifications(skip = 0, limit = 10): Observable<UserNotification[]> {
    return this.apiService.get('api/users/notification?skip=' + skip + '&limit=' + limit);
  }

  postReadUserNotification(notificationId): Observable<UserNotification> {
    return this.apiService.post('api/users/notification/' + notificationId + '/read', '');
  }

  postUnReadUserNotification(notificationId): Observable<UserNotification> {
    return this.apiService.post('api/users/notification/' + notificationId + '/unread', '');
  }

  getUserEngagement() {
    return this.apiService.get('api/users/engagement');
  }

  getUserPotentialEarnings() {
    return this.apiService.get('api/users/potential-earnings');
  }

  getUser(id: string): Observable<User> {
    return this.apiService.get('api/users/' + id);
  }

  getUserReputationEarnings(id: string, skip = 0, limit = 10) {
    return this.apiService.get('api/users/' + id + '/reputation-earnings?skip=' + skip + '&limit=' + limit);
  }

  getUserTokenEarnings(id: string, skip = 0, limit = 10) {
    return this.apiService.get('api/users/' + id + '/token-earnings?skip=' + skip + '&limit=' + limit);
  }

  readAllUserNotification() {
    return this.apiService.post('api/users/notification/read-all', '');
  }
}
