import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmbedVideoService, ProposalService, ToasterService } from '../../services';
import { environment } from '../../../../environments';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { tap, catchError, last, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FileProgressObject } from '../../../app.datatypes';
import { EmbeddableVideoValidator } from '../../validators/EmbeddableVideoValidator';
import { S3UploadService } from '../../services/s3-upload.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  @Input() type: string; // video, image, file
  @Input() from: string; // proposal, creativeQuery
  @Input() proposalMode: string;
  @Input() proposalIndex: number;
  @Input() imageUploaded: string;
  @Input() fileUploaded: string;
  @Input() videoUploaded: string;
  @Input() modalTitle: string;
  @Input() modalValue: string;
  @Input() modalDescription: string;

  videoForm: FormGroup;
  mediaForm: FormGroup;
  loading = false;
  isDirectVideoUpload = false;
  imageFiles: any = [];
  docFiles: any = [];
  isImageLoaded = false;
  file_id: string;

  @Output() cancelModal = new EventEmitter();
  @Output() saveOption = new EventEmitter();
  @Output() saveMedia = new EventEmitter();

  progress: any;

  constructor(
    private formBuilder: FormBuilder,
    private embedService: EmbedVideoService,
    private toastService: ToasterService,
    private proposalService: ProposalService,
    private s3UploadService: S3UploadService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.buildVideoForm();
    this.buildMediaForm();
  }

  buildVideoForm() {
    this.videoForm = this.formBuilder.group({
      value: [
        '',
        [
          Validators.required,
          Validators.pattern(
            'https://(?:www.)?(vimeo.com|youtube.com|m.youtube.com|youtu.be)/(?:watch?v=)?(.*?)(?:z|$|&)'
          ),
        ],
        [EmbeddableVideoValidator.createValidator(this.embedService)],
      ],
      title: ['', [Validators.required]],
      description: [],
    });

    if (this.type) {
      this.videoForm.patchValue({
        value: this.modalValue,
        title: this.modalTitle,
        description: this.modalDescription,
      });
    }
  }

  buildMediaForm() {
    this.mediaForm = this.formBuilder.group({
      value: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: [],
    });

    if (this.type) {
      this.mediaForm.patchValue({
        value: this.modalValue,
        title: this.modalTitle,
        description: this.modalDescription,
      });
    }
  }

  attachVideo() {
    // if (!this.videoForm.valid) {
    //   this.videoUploaded = null;
    //   return;
    // }
    this.embedService.isVideoEmbeddable(this.videoForm.value.value).subscribe(data => {
      if (data) {
        this.videoUploaded = this.embedService.embed(this.videoForm.value.value);
      }
    });
  }

  attachImage() {
    this.imageUploaded = this.mediaForm.value.value;
  }

  attachFile() {
    this.fileUploaded = this.mediaForm.value.value;
  }

  onVideoInputChange(ev) {
    const file = ev.target.files[0];
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
    // https://www.codegrepper.com/code-examples/javascript/random+file+name+javascript
    const fileName =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15) +
      '.' +
      fileExt;

    const uploadErrorHandler = () => {
      this.toastService.openErrorSnackBar('Something went wrong on video upload =(', 'Video upload error');
    };

    this.s3UploadService.getPreSignedURL(fileName, file.type).subscribe(preSignedUrlResponse => {
      const file_rel = preSignedUrlResponse.fileRel;
      file.arrayBuffer().then(arrayBuffer => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
        this.s3UploadService.uploadToS3(preSignedUrlResponse.uploadURL, blob).subscribe(() => {
          this.proposalService.createFileRecordOnUpload({ file_rel }).subscribe(fileDocument => {
            this.videoForm.controls['value'].setValue(file_rel);
            this.videoForm.controls['value'].setErrors(null);
            this.videoForm.controls['title'].setValue(fileName);
            this.isDirectVideoUpload = true;
            this.file_id = fileDocument._id;
          }, uploadErrorHandler);
        }, uploadErrorHandler);
      });
    }, uploadErrorHandler);
  }

  imageUpload(ev) {
    const files = ev.srcElement.files;
    if (!files) {
      return;
    }
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append('cover', files[0], files[0].name);

    if (!files[0].type.split('/').includes('image')) {
      this.toastService.openSnackBar('File is not image type', 'Image', 'error', 3000);
      this.loading = false;
      return;
    }

    // Validate for Filesize
    if (files[0].size / 5242880 >= environment.max_image_size) {
      this.toastService.openSnackBar('Upload File Size is greater than 5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
      this.loading = false;
      return;
    }

    this.imageFiles.push({
      data: files[0],
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
    });

    this.uploadImages();
  }

  uploadImages() {
    this.imageFiles.forEach((file, index) => {
      if (!file.inProgress) {
        this.uploadImage(file);
      }
    });
  }

  private uploadImage(file: any) {
    const fd = new FormData();
    fd.append('cover', file.data);
    const req = this.proposalService
      .uploadCoverImage(fd)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.mediaForm.controls['value'].setValue(event.body.data.file_url);
            const index = this.imageFiles.indexOf(file);
            if (index > -1) {
              this.imageFiles.splice(index, 1);
            }
            this.imageUploaded = event.body.data.file_url;
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
        }
      );
  }

  docUpload(event) {
    const files = event.srcElement.files;
    if (!files) {
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      // VALIDATE For PDF
      if (file.type !== 'application/pdf') {
        alert('Document File must be in PDF format');
        continue;
      }
      // Validate for Filesize
      if (file.size / 1048576 >= environment.max_pdf_doc_size) {
        alert(file.name + ' size is greater than ' + environment.max_pdf_doc_size + ' Mb ');
        continue;
      }

      this.docFiles.push({
        file: file,
        state: 'in',
        inProgress: false,
        progress: 0,
        canRetry: false,
        canCancel: true,
        type: 'pdf',
      } as FileProgressObject);
      this.uploadFiles();
    }
  }

  uploadFiles() {
    this.docFiles.forEach(file => {
      if (!file.inProgress) {
        this.uploadFile(file);
      }
    });
  }

  private uploadFile(file: any) {
    // this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    const fd = new FormData();
    fd.append('pdf', file?.file);
    const req = this.proposalService.uploadPdfDoc(fd);
    req
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1));
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file?.file.name ? file?.file.name : ''} upload failed.`);
        })
      )
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.mediaForm.controls['value'].setValue(event.body.data.image_path.file_url);
            const index = this.docFiles.indexOf(file);
            if (index > -1) {
              this.docFiles.splice(index, 1);
            }
            this.fileUploaded = event.body.data.image_path.file_preview_url;
          }
        },
        error => {}
      );
  }

  formSave() {
    if (this.type === 'video') {
      this.save(this.videoForm);
    } else if (this.type === 'image' || this.type === 'file') {
      this.save(this.mediaForm);
    }
  }

  save(form: FormGroup) {
    form.markAllAsTouched();
    if (form.valid) {
      if (this.from === 'creativeQuery') {
        const data = {
          isSaved: true,
          isEdit: false,
          type: this.type,
          value: form.value.value,
          title: form.value.title,
          description: form.value.description,
          videoData: this.videoUploaded ? this.videoUploaded : '',
        };
        this.saveOption.emit(data);
      }
      if (this.from === 'proposal') {
        let data: any;
        if (this.proposalMode === 'edit') {
          data = {
            type: this.type,
            index: this.proposalIndex,
            value: form.value.value,
            title: form.value.title,
            description: form.value.description,
            fileData: this.type === 'file' ? this.fileUploaded : '',
            videoData: this.type === 'video' ? this.videoUploaded : '',
          };
        } else {
          data = {
            type: this.type,
            value: form.value.value,
            title: form.value.title,
            description: form.value.description,
            fileData: this.type === 'file' ? this.fileUploaded : '',
            videoData: this.type === 'video' ? this.videoUploaded : '',
          };
        }
        if (data.type === 'video') {
          if (!this.isDirectVideoUpload) {
            this.embedService.getThumbnailByURL(data.value).then(thumbnailUrl => {
              data.thumbnailUrl = thumbnailUrl;
              this.saveMedia.emit(data);
            });
          } else {
            data.isDirectVideoUpload = this.isDirectVideoUpload;
            data.file_id = this.file_id;
            this.saveMedia.emit(data);
          }
        } else {
          this.saveMedia.emit(data);
        }
      }
    }
  }

  cancelUploadModal() {
    this.cancelModal.emit();
  }

  imageLoaded() {
    this.isImageLoaded = true;
  }

  ngOnDestroy() {}
}
