import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-action',
  templateUrl: './entity-action.component.html',
  styleUrls: ['./entity-action.component.css'],
})
export class EntityActionComponent implements OnInit {
  @Input() entity;
  @Input() title;
  @Input() action;
  @Input() url;
  @Input() actor;

  constructor() {}

  ngOnInit() {}
}
