import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import {
  Proposal,
  ProposalRequest,
  SubmitProposalRequest,
  FinanceType,
  FinanceTier,
  Revision,
  Genre,
  ProposalRejectReason,
  Language,
  ProposalAIReport,
  FileProgressObject,
  UserReward,
  TokenEarning,
  ReputationEarning,
} from '../../app.datatypes';
import { environment } from '../../../environments';
import { HttpHeaders, HttpClient, HttpEventType, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  private url = environment.url;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getProposal(id: string): Observable<Proposal> {
    return this.apiService.get('api/proposals/' + id);
  }

  getAllProposalUserRewards(id: string): Observable<UserReward[]> {
    return this.apiService.get('api/proposals/user-rewards');
  }

  getProposalUserRewards(id: string): Observable<UserReward[]> {
    return this.apiService.get('api/proposals/' + id + '/user-rewards');
  }

  getLanguages(): Observable<Language[]> {
    return this.apiService.get('api/languages');
  }

  getEarnings(id: string, skip = 0): Observable<TokenEarning[]> {
    return this.apiService.get('api/proposals/' + id + '/earnings?skip=' + skip);
  }

  getAllEarnings(id: string, skip = 0): Observable<TokenEarning[]> {
    return this.apiService.get('api/proposals/' + id + '/all-earnings?skip=' + skip);
  }

  getReputationEarnings(id: string, skip = 0): Observable<ReputationEarning[]> {
    return this.apiService.get('api/proposals/' + id + '/reputation-earnings?skip=' + skip);
  }

  getProposals(
    field: string,
    reviewed: string,
    genres: string,
    search: string,
    proposalRounds,
    skip = 0
  ): Observable<Proposal[]> {
    return this.apiService.get(
      'api/proposals' +
        '?field=' +
        field +
        '&direction=desc' +
        (genres ? '&genres=' + genres : '') +
        (proposalRounds ? '&proposal_rounds=' + proposalRounds : '') +
        'reviewed=reviewed_only' +
        '&skip=' +
        skip +
        (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  getRoundRelatedProposals(proposalRounds): Observable<Proposal[]> {
    return this.apiService.get('api/proposals/round-related/' + proposalRounds);
  }

  getProposalResults(id: string): Observable<any> {
    return this.apiService.get('api/proposals/' + id + '/results');
  }

  getGenres(): Observable<Genre[]> {
    return this.apiService.get('api/movie-genres');
  }

  saveProposalAsDraft(request: ProposalRequest): Observable<Proposal> {
    return this.apiService.post('api/proposals/', request);
  }

  updateProposalDraft(id: string, request: ProposalRequest): Observable<Proposal> {
    return this.apiService.put('api/proposals/' + id, request);
  }

  prepareProposalForSubmit(request: ProposalRequest): Observable<Proposal> {
    return this.apiService.post('api/proposals/prepare-for-submit', request);
  }

  prepareProposalForResubmit(id: string, request: ProposalRequest): Observable<Proposal> {
    return this.apiService.put('api/proposals/' + id + '/prepare-for-resubmit', request);
  }

  submitProposal(req: SubmitProposalRequest): Observable<any> {
    return this.apiService.post('api/proposals/' + req.proposalId + '/submit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  resubmitProposal(req: SubmitProposalRequest): Observable<any> {
    return this.apiService.post('api/proposals/' + req.proposalId + '/resubmit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  getFinanceTypes(): Observable<FinanceType[]> {
    return this.apiService.get('api/proposals/finance-types');
  }

  getFinanceTiers(): Observable<FinanceTier[]> {
    return this.apiService.get('api/proposals/finance-tires');
  }

  // getContentTypes(): Observable<ContentType[]> {
  //   return this.apiService.get('api/proposals/content-types');
  // }

  getProposalHistory(id: string): Observable<Revision> {
    return this.apiService.get('api/proposals/' + id + '/history');
  }

  postProposalEvaluations(evaluation): Observable<any[]> {
    // this endpoint is not implemented in back-end (or deleted)
    return this.apiService.post('api/proposal-evaluations', evaluation);
  }

  getProposalRejectReasons(): Observable<ProposalRejectReason[]> {
    return this.apiService.get('api/proposal-reject-reasons');
  }

  createGenre(req: Genre): Observable<Genre> {
    return this.apiService.post('api/movie-genres', req);
  }

  // @todo: file upload Progress needed to be implemented
  uploadCoverImage(data) {
    const url = 'api/proposals/update-cover';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    // const options = this.appService.isAuthenticated ? { headers, reportProgress: true } : { reportProgress: true };
    return this.http.post(this.url + url, data, { headers: headers, observe: 'events', reportProgress: true });
  }

  createFileRecordOnUpload(data) {
    return this.apiService.post('api/video-upload', data);
  }

  // @todo: file upload Progress needed to be implemented
  uploadPdfDoc(data): Observable<any> {
    const url = 'api/proposals/upload-pdf';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post(this.url + url, data, { headers: headers, observe: 'events', reportProgress: true });
  }

  getModeratableProposals(skip = 0): Observable<Proposal[]> {
    return this.apiService.get('api/proposals/moderate?skip=' + skip);
  }

  createProposalAIReport(
    proposal: Proposal,
    pdfFile: File,
    videoFile: File,
    fileProgressObject: FileProgressObject
  ): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('proposal_id', proposal._id);
    if (pdfFile) {
      formData.append('pdf_file', pdfFile);
    }
    if (videoFile) {
      formData.append('video_file', videoFile);
    }
    return this.apiService.postWithAttachment(`api/ai/report/proposal`, formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileProgressObject.progress = Math.round(
              ((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1)
            );

            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap((event: any) => {
        if (typeof event === 'object' || (event && event.body && event.body.data)) {
          return event.body.data;
        }
      }),
      last(),
      catchError((error: HttpErrorResponse) => {
        fileProgressObject.inProgress = false;
        fileProgressObject.canRetry = true;

        return of(`${fileProgressObject.file.name ? fileProgressObject.file.name : ''} upload failed.`);
      })
    );
  }

  getAIReportStatus(proposal: Proposal): Observable<ProposalAIReport> {
    return this.apiService.post(`api/ai/report/${proposal.ai_report_id}`, {});
  }

  getAIReportContent(proposal: Proposal): Observable<ProposalAIReport> {
    return this.apiService.get(`api/ai/report/${proposal.ai_report_id}`, {});
  }
}
