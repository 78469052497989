import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments';
import { akitaConfig } from '@datorama/akita';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
// Enable Sentry monitoring in the staging environment
if (environment.staging || environment.production) {
  Sentry.init({
    environment: environment.production ? 'production' : 'staging',
    release: `${environment.build_id}`,
    dsn: 'https://c173d0662d5d436ea74b6491fb0f254e@o465517.ingest.sentry.io/5478919',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          environment.production ? 'https://app.decentralized.pictures' : 'https://staging.decentralized.pictures',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: environment.production ? 0.0 : 0.05,
  });
}

if (environment.production) {
  enableProdMode();
}
// Import icons
import './icons.ts';
akitaConfig({
  resettable: true,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && (environment.production || environment.staging)) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  })
  .catch(err => console.log(err));
