import { Component, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ProgressService, ToasterService, ReportsService } from '../../services';
import { ReportReason, ReportRequest } from '../../../app.datatypes';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
})
export class ReportDialogComponent {
  reportReasons: ReportReason[];
  progress: any;
  form: FormGroup;
  type: string;
  typeValue: string;
  reasons = [];
  comments: string;
  selectable = true;
  constructor(
    private reportsService: ReportsService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ReportDialogComponent>,
    private toasterService: ToasterService
  ) {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.reportsService.getReportReason().subscribe(
      reasons => {
        this.progressService.hideSpinner(this.progress);
        this.reportReasons = reasons;
      },
      error => {
        this.progressService.hideSpinner(this.progress);
      }
    );

    if (data.type) {
      this.type = data.type;
    }
    if (this.type === 'proposal') {
      this.typeValue = 'proposal';
    } else if (this.type === 'creative_query') {
      this.typeValue = 'creative query';
    } else if (this.type === 'review') {
      this.typeValue = 'review ';
    } else if (this.type === 'quiz') {
      this.typeValue = 'quiz ';
    }
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      comments: [''],
    });
  }

  report() {
    const report: ReportRequest = {
      report_type: this.type,
      model_id: this.data.entity._id,
      comments: this.comments,
      reasons: this.reasons,
    };

    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.reportsService.postReport(report).subscribe(
      result => {
        this.progressService.hideSpinner(this.progress);
        this.toasterService.openSnackBar(
          'Your report is successfully sent to the moderators',
          'Reported',
          'info',
          5000
        );
        this.dialogRef.close({
          id: this.data.entity._id,
          report: result,
        });
      },
      error => {
        this.progressService.hideSpinner(this.progress);
      }
    );
  }
  toggleReasons(reason) {
    if (this.reasons.includes(reason)) {
      this.reasons.splice(this.reasons.indexOf(reason), 1);
    } else {
      this.reasons.push(reason);
    }
  }
  isSelectedReason(reason: string): boolean {
    return this.reasons.includes(reason);
  }
}
