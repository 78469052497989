import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Proposal } from '../../../../app.datatypes';
import { EmbedVideoService } from '../../../../shared/services/embed-video.service';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-proposal-gallery',
  templateUrl: './proposal-gallery.component.html',
  styleUrls: ['./proposal-gallery.component.scss'],
})
export class ProposalGalleryComponent implements OnChanges {
  @Input()
  proposal: Proposal;
  gallerySlides = [];
  slideCount = 0;

  // icons ..
  faYoutube = faYoutube;

  constructor(private embedService: EmbedVideoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.proposal &&
      (changes.proposal.isFirstChange() ||
        changes.proposal.previousValue._id !== changes.proposal.currentValue._id ||
        !changes.proposal.currentValue._id)
    ) {
      this.initGallery();
    }
  }

  private addVideoToGallery(url, thumbnailUrl, videoId, provider) {
    this.gallerySlides.push({
      id: this.slideCount++,
      selected: false,
      type: 'video',
      srcUrl: videoId,
      thumb: thumbnailUrl,
      src: videoId,
      plyr: [
        {
          src: url,
          provider: provider,
        },
      ],

      videoSrc: provider,
    });
  }

  private addYouTubeVideoToGallery(url, thumbnailUrl, videoId) {
    this.addVideoToGallery(url, thumbnailUrl, videoId, 'youtube');
  }

  private addVimeoVideoToGallery(url, thumbnailUrl, videoId) {
    this.addVideoToGallery(url, thumbnailUrl, videoId, 'vimeo');
  }

  private addS3VideoToGallery(url, thumbnailUrl, videoId) {
    this.addVideoToGallery(url, thumbnailUrl, videoId, 's3');
  }

  private addImageToGallery(url) {
    this.gallerySlides.push({
      id: this.slideCount++,
      selected: false,
      type: 'image',
      src: url,
    });
  }

  private initGallery() {
    this.gallerySlides = [];
    // Add cover image to gallery
    this.addImageToGallery(this.proposal.cover_url);

    // Generate embedded code
    if (this.proposal?.video_url?.length > 0) {
      this.proposal.video_url.forEach(video => {
        // // Embed YouTube
        if (this.embedService.detectYoutube(video.url)) {
          this.addYouTubeVideoToGallery(video.url, video.thumbnailUrl, this.embedService.getVideoIdByUrl(video.url));
        }
        // Embed Vimeo
        if (this.embedService.detectVimeo(video.url)) {
          this.addVimeoVideoToGallery(video.url, video.thumbnailUrl, this.embedService.getVideoIdByUrl(video.url));
        }

        if (video.isDirectVideoUpload && !video.converted) {
          this.addS3VideoToGallery(video.url, video.thumbnailUrl, null);
        }

        /** @ToDo play directly uploaded video if converted */
        if (video.isDirectVideoUpload && video.converted) {
          // in this case use video.url in player
        }
      });
    }

    // generate image gallery
    if (this.proposal && this.proposal.images && this.proposal.images.length > 0) {
      this.proposal.images.forEach(img => {
        // Exclude cover image from attachments
        if (img.url.toString() === this.proposal.cover_url) {
          return;
        }
        this.addImageToGallery(img.url);
      });
    }
  }
}
