import { Component, OnInit, Output, EventEmitter, Input, Renderer2 } from '@angular/core';
import { environment } from '../../../../../../src/environments';
import { AuthenticatedUserQuery, AuthenticatedUserState, SharedService } from '../../../../shared';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  showProposal = !environment.limited_proposal_functionality;
  showCq = !environment.limited_quiz_functionality;
  showRep = !environment.limited_reputation_functionality;

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private authService: AuthService,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {}

  @Input() userDetails = false;
  @Input() themePanelOpen = false;
  @Output() logout = new EventEmitter();
  @Output() changeTheme = new EventEmitter();
  @Output() changeScale = new EventEmitter();
  @Output() toggleThemePanelBtn = new EventEmitter();
  @Output() showInboxBar = new EventEmitter();

  user: AuthenticatedUserState;
  reputation = 0;

  ngOnInit() {
    this.authenticatedUserQuery.select().subscribe(user => {
      this.user = user;
      this.reputation = this.user.reputation;
    });
  }

  onLogout() {
    this.logout.emit();
    this.closeUserMenu();
  }

  toggleTheme() {
    this.authService.postSetUserSettings('is_dark_theme', !this.user.is_dark_theme).subscribe(res => {
      this.changeTheme.emit();
    });
  }

  toggleScale() {
    this.authService.postSetUserSettings('is_grayscale', !this.user.is_grayscale).subscribe(res => {
      this.changeScale.emit();
    });
  }

  closeUserMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    this.renderer.removeClass(wrapper, 'user-side-open');
  }

  toggleThemePanel() {
    this.toggleThemePanelBtn.emit();
  }
  showInfoBoxFn() {
    this.sharedService.setToggleUserMenu(true);
    setTimeout(() => {
      this.showInboxBar.emit();
    }, 300);
  }
}
