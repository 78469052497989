import { Injectable } from '@angular/core';
import { ReviewState, ReviewStore } from './review.store';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class ReviewQuery extends QueryEntity<ReviewState> {
  selectModerationSkip$ = this.select('moderationQuerySkip');
  selectModerationQueryScroll$ = this.select('moderationQueryScroll');
  selectModerationReviewActive$ = this.select('moderationReviewActive');
  selectModerationFilters$ = this.select('moderationFilters');

  constructor(protected store: ReviewStore) {
    super(store);
  }
  /**
   * Get reviews for proposal
   *
   * id: proposal Id
   * reviewLimit: limit of proposals to be fetched from store
   */
  getReviews(id) {
    return this.selectAll({
      filterBy: entity => {
        return entity.proposal_id === id;
      },
    });
  }

  /** Get all to-be-moderated reviews where owner is not a current user. */
  getAllModerationReview(userId) {
    return this.selectAll({
      filterBy: entity => {
        let returnValue = entity.status === 'to-be-moderated' && entity.user_id !== userId;
        if (this.getValue().moderationFilters.unlockedOnly) {
          returnValue = returnValue && !entity.locked_by;
        }
        return returnValue;
      },
      limitTo: this.getValue().moderationQuerySkip,
    });
  }
}
