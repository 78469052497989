import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToasterService } from '../services/toaster.service';

// import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class ErrorsHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const toasterService = this.injector.get(ToasterService);
    toasterService.openSnackBar(error.message, error.name);
  }
  handleSubmitError(error: any) {
    const toastService = this.injector.get(ToasterService);
    const resultMessage = [];
    if (error.status === 422 || error.status === 403) {
      const fields = error.error.errors;
      for (const field of Object.keys(fields)) {
        for (let i = 0; i < fields[field].length; i++) {
          resultMessage.push(`${fields[field][i]}`);
        }
      }

      // Server response error
      // toastService.openSnackBar(resultMessage, '', 'error', 3000, true)
    }
  }
}
