import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PricingFees, Proposal, ProposalRound } from '../../../../../../app/app.datatypes';

export interface ProposalRoundData {
  proposal_round_id: string;
}

@Component({
  selector: 'app-proposal-create-step-one',
  templateUrl: './proposal-create-step-one.component.html',
})
export class ProposalCreateStepOneComponent implements OnInit, OnChanges {
  @Input() proposal: Proposal;
  @Input() roundList: ProposalRound[];
  @Input() isNew: boolean;
  @Input() prices: PricingFees;
  @Output() setStepOne = new EventEmitter<ProposalRoundData>();

  stepOneForm: FormGroup;
  selectedRound: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.stepOneForm = this.formBuilder.group({
      proposal_round_id: [this.proposal.proposal_round_id, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.roundList?.currentValue?.length > 0) {
      this.selectedRound = this.roundList[0]._id;
      this.stepOneForm.setValue({ proposal_round_id: this.roundList[0]._id });
      this.roundList[0].showRoundDetails = true;
      this.setStepOneData();
    }
  }

  selectRound(id): void {
    if (!this.isNew) {
      return;
    }
    this.stepOneForm.setValue({ proposal_round_id: id });
    this.selectedRound = id;
    this.setStepOneData();
  }

  showRoundDetails(round): void {
    round.shownDetails = !(round.showRoundDetails === undefined);
    round.showRoundDetails = !round.showRoundDetails;
  }

  setStepOneData(): void {
    const data: ProposalRoundData = this.stepOneForm.value;
    this.setStepOne.emit(data);
  }
}
