import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filmPrice',
})
export class FilmPricePipe implements PipeTransform {
  transform(value: number): string {
    const truncateUntilTwoDecimals = /^-?\d+(?:\.\d{0,2})?/;
    if (value === null || value === undefined) {
      return '0';
    } else if (value > 1000000000) {
      return `${(value / 1000000000).toString().match(truncateUntilTwoDecimals)[0]}B`;
    } else if (value > 1000000) {
      return `${(value / 1000000).toString().match(truncateUntilTwoDecimals)[0]}M`;
    } else if (value > 1000) {
      return `${(value / 1000).toString().match(truncateUntilTwoDecimals)[0]}K`;
    } else {
      return value.toString().match(truncateUntilTwoDecimals)[0];
    }
  }
}
