import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { UserService, ProgressService } from '../../../services';
import { AuthenticatedUserState, AuthenticatedUserQuery } from '../../../state';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CreativeQueryStatus, ProposalStatus } from '../../../../app.datatypes';

@Component({
  selector: 'app-user-moderations',
  templateUrl: './user-moderations.component.html',
  styleUrls: ['./user-moderations.component.scss'],
})
export class UserModerationsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  userModerations: any[];

  @Input() user: AuthenticatedUserState;
  dataSourceModerations: any;
  displayedColumnsModerations: Array<string>;
  isMoreAvailable = true;

  // scroll Pagination variables
  skip = 0;
  currentScrollPosition = 0;
  selectedTab: number;

  proposalStatus: ProposalStatus;
  creativeQueryStatus = CreativeQueryStatus;

  // Spinner variables
  progress: any;

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private userService: UserService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {
    this.displayedColumnsModerations = ['type', 'reference', 'created', 'status'];
  }

  ngOnInit() {
    if (
      this.authenticatedUserQuery.getValue().user_type_id === 3 ||
      this.authenticatedUserQuery.getValue().user_type_id === 4
    ) {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      this.userService
        .getUserModerations(this.user._id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          result => {
            this.progressService.hideSpinner(this.progress);
            this.userModerations = result;
            this.dataSourceModerations = new MatTableDataSource(result);
            if (result.length < 10) {
              this.isMoreAvailable = false;
            }
          },
          error => {
            this.progressService.hideSpinner(this.progress);
          }
        );
    }
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.userService
      .getUserModerations(this.user._id, this.skip)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.dataSourceModerations.data;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.dataSourceModerations.data = oldData;
            this.skip = this.skip + result.length;

            // IMPORTANT SERVER side depadancy (Should match LIMIT OF Server  )
            if (result.length < 10) {
              this.isMoreAvailable = false;
            }
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadPage() {
    this.progressService.showSpinner(this.progress, this.elRef);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
