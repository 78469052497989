import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressService } from '../../services';

@Component({
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss'],
})
export class CropperDialogComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageBase64: string;
  fileExt: string;
  progress: any;

  imageFile: any;
  roundCropper = false;
  aspectRatio = 16 / 9;
  @ViewChild('croppedImageContainer', { read: ElementRef, static: true }) croppedImageContainer: ElementRef;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CropperDialogComponent>,
    private service: ProgressService
  ) {
    this.imageFile = data.imageFile;
    if (data.roundCropper) {
      this.roundCropper = data.roundCropper;
    }
    if (data.aspectRatio) {
      this.aspectRatio = data.aspectRatio;
    }
    this.fileExt = /[.]/.exec(this.imageFile.name) ? /[^.]+$/.exec(this.imageFile.name)[0] : undefined;
    if (this.fileExt) {
      this.fileExt = this.fileExt.toLowerCase();
      if (this.fileExt === 'jpg') {
        this.fileExt = 'jpeg';
      }
    }
  }

  ngOnInit() {
    this.imageChangedEvent = this.imageFile;
  }

  imageCropped(event: any) {
    this.croppedImage = event.file;
    this.croppedImageBase64 = event.base64;
    this.service.detach(this.progress);
  }

  upload() {
    this.dialogRef.close({
      croppedImage: this.croppedImage,
      croppedImageBase64: this.croppedImageBase64,
    });
  }
  imageLoaded() {
    this.progress = this.service.showProgress(this.croppedImageContainer);
  }
}
