import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

// import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from './../components/bottom-sheet/bottom-sheet.component';

import { MainSnackBarComponent } from '../components/snackbars/main-snackbar/main-snack-bar.component';
import { ReloadSnackBarComponent } from './../components/reload-snackbar/reload-snackbar.component';
import { SwUpdate } from '@angular/service-worker';
import { UnauthorizedSnackBarComponent } from './../components/unauthorized-snackbar/unauthorized-snackbar.component';
import { ContactSupportSnackBarComponent } from '../components/snackbars/contact-support-snack-bar/contact-support-snack-bar.component';
import { LinkSnackbarComponent } from '../components/snackbars/link-snackbar/link-snackbar.component';
@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
    // private bottomSheet: MatBottomSheet,
    private swUpdate: SwUpdate
  ) {}
  snackBarShown = false;
  showToaster(msg: string, title: string, duration?: number, type = 'info') {
    this.zone.run(() => {
      this.snackBar.open(msg, title, {
        duration: duration || 3000,
      });
    });
  }

  // open error snackbar
  openErrorSnackBar(msg, title, type = 'error', duration = 3000) {
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'right';
    config.data = { message: msg, title, type };
    config.duration = duration;

    this.snackBarShown = true;
    this.zone.run(() => {
      this.snackBar.openFromComponent(MainSnackBarComponent, config);
    });
  }

  // open info snackbar
  openSnackBar(message, action, type = 'info', duration = 0, isArray = false) {
    this.closeSnackBar();
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.data = { message, action, type, isArray };
    this.snackBar.openFromComponent(MainSnackBarComponent, config);
    if (duration) {
      setTimeout(() => {
        this.closeSnackBar();
      }, duration);
    }
  }

  // Open Link Snackbar
  openSnackBarWithLink(message, action, type = 'info', duration = 0, link, linkType, buttonLabel) {
    this.closeSnackBar();
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.data = { message, action, type, link, linkType, buttonLabel };
    this.snackBar.openFromComponent(LinkSnackbarComponent, config);
    if (duration) {
      setTimeout(() => {
        this.closeSnackBar();
      }, duration);
    }
  }

  openContactSupportSnackBar(message, type = 'info', notificationType = '') {
    this.closeSnackBar();
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.data = { message, type };
    this.snackBar.openFromComponent(ContactSupportSnackBarComponent, config);
  }

  openValidationErrorsSnackbar(message, errors, type = 'error', duration = 10000, notificationType = '') {
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.data = { message, errors, type };
    this.snackBar.openFromComponent(MainSnackBarComponent, config);
    if (duration) {
      setTimeout(() => {
        this.closeSnackBar();
      }, duration);
    }
  }

  // open error snackbar
  openSnackBarKeepOpen(message, action, type = 'info', notificationType = '') {
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.data = { message, action, type };
    this.snackBar.openFromComponent(MainSnackBarComponent, config);
  }

  // open error snackbar
  openReloadSnackBarKeepOpen(message, action, type = 'info', notificationType = '') {
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.data = { message, action, type };
    const snackBarRef = this.snackBar.openFromComponent(ReloadSnackBarComponent, config);
    snackBarRef.onAction().subscribe(() => {
      this.swUpdate.activateUpdate().then(() => document.location.reload());
    });
  }

  // close any opened snackbar ...
  closeSnackBar() {
    this.snackBarShown = false;
    this.snackBar.dismiss();
  }

  // open error snackbar
  openUnauthorizedSnackBar(msg, title) {
    this.snackBarShown = true;
    this.snackBar.openFromComponent(UnauthorizedSnackBarComponent, {
      data: { msg, title },
    });
  }
  /**
   * This Code is only for demo, in case more than two action's will be needed
   */
  // openBottomSheet(dataObject) {
  //   const sheetRef = this.bottomSheet.open(BottomSheetComponent, {
  //     data: dataObject,
  //   });

  //   sheetRef.afterDismissed().subscribe(data => {
  //     if (data && data.message === 'Cancel') {
  //       alert('Cancel was clicked in bottom sheet');
  //     }
  //     if (data && data.message === 'Status') {
  //       alert('Change Status was clicked in bottomsheet');
  //     }
  //   });
  // }
}
