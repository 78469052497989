import { Injectable, Input } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Like } from '../../app.datatypes';
import { environment } from '../../../environments';

@Injectable({
  providedIn: 'root',
})
export class LikeService {
  constructor(private apiService: ApiService) {}

  public getUserLikes(skip: number): Observable<[Like]> {
    return this.apiService.get(`api/user-likes?limit=${environment.grid_skip_limit}&skip=${skip}`);
  }
}
