import { Component } from '@angular/core';

@Component({
  selector: 'app-submission-term',
  templateUrl: './submission-term.component.html',
  styleUrls: ['./submission-term.component.scss'],
})
export class SubmissionTermComponent {
  constructor() {}
}
