import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { ProposalRound, SimpleList, Region } from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class ProposalRoundService {
  constructor(private apiService: ApiService) {}

  postProposalRound(round: ProposalRound): Observable<ProposalRound> {
    return this.apiService.post('api/proposal-rounds', round);
  }

  getProposalRoundResults(id: string): Observable<any> {
    return this.apiService.get('api/proposal-rounds/' + id + '/results');
  }

  // @todo add limit .. parameter (Currently controlled by server side )

  getProposalRounds(
    skip: number = 0,
    search: string = '',
    statuses: string = '',
    limit: number = 15
  ): Observable<ProposalRound[]> {
    return this.apiService.get(
      'api/proposal-rounds?skip=' +
        skip +
        '&limit=' +
        limit +
        (search ? '&search=' + encodeURIComponent(search) : '') +
        (statuses ? '&statuses=' + statuses : '')
    );
  }

  getOpenProposalRounds(): Observable<ProposalRound[]> {
    return this.apiService.get('api/proposal-rounds/open');
  }

  getActiveProposalRounds(
    field: string = '',
    skip: number = 0,
    search: string = null,
    statuses: string = null,
    limit: number = 15
  ): Observable<ProposalRound[]> {
    return this.apiService.get(
      'api/proposal-rounds/active' +
        '?field=' +
        field +
        '&direction=desc' +
        '&skip=' +
        skip +
        (search ? '&search=' + encodeURIComponent(search) : '') +
        (statuses ? '&statuses=' + statuses : '')
    );
  }

  getProposalRound(id: string): Observable<ProposalRound> {
    return this.apiService.get('api/proposal-rounds/' + id);
  }

  getProposalRoundStatuses(): Observable<SimpleList[]> {
    return this.apiService.get('api/proposal-round-statuses');
  }

  putProposalRound(id: string, round: ProposalRound): Observable<ProposalRound> {
    return this.apiService.put('api/proposal-rounds/' + id, round);
  }

  archiveProposalRound(id: string) {
    return this.apiService.post('api/proposal-rounds/' + id + '/archive', []);
  }

  getRegions(): Observable<Region[]> {
    return this.apiService.get('api/regions');
  }
}
