export class ProposalRound {
  _id: string;
  title: string;
  status: string;
  project_type: string;
  start_date: Date;
  end_date: Date;
  start_review_date: Date;
  minimum_bounty: number;
  submission_fee: number;
  reward: number;
  rules: string;
  scores: any[];
  proposals?: any[];
  total_proposals?: number;
  total_reviews?: number;
  total_likes?: number;
  top_proposal_score?: number;
  top_proposal_review_bounty?: number;
  demography?: any;
  is_demography?: boolean;
  is_open?: boolean;
  is_general_review_round?: boolean;
  total_stake?: number;
  avg_user_reputation?: number;
  is_finalized?: boolean;
  proposal_count: number;
  proposal_vote_count: number;
  showRoundDetails?: boolean;
}

export class CountryCode {
  name: string;
  twoLetterCode: string;
  threeLetterCode: string;
  countryCode: string;
}

export class Demography {
  age_group: string;
  region: string;
  gender: string;
}

export class UserReward {
  proposal: Proposal;
  user: User;
  reward: Reward;
}

export class Proposal {
  _id: string;
  title: string;
  summary: string;
  content: string;
  user_mongo_id: string;
  user_id: string;
  bounty: number;
  extra_bounty?: number;
  balance: number;
  status: string;
  proposal_finance_type: string;
  proposal_finance_tier: string;
  proposal_content_type: string;
  blockchain_id?: string;
  blockchain_confirmed?: boolean;
  submission_contribution: string;
  submission_transaction: string;
  resubmission_contribution?: string;
  resubmission_transaction?: string;
  extension_contribution?: string;
  extension_transaction?: string;
  score: number; // blockchain score
  avg_score: number; // average review score
  staked: number;
  created_at: Date;
  updated_at: Date;
  raw_updated_at: Date;
  raw_created_at: Date;

  /** Proposal round related */
  has_participated?: boolean; // is participated in proposal round
  proposal_round_id?: string;
  proposal_round_mongo_id?: string;
  is_finalist?: boolean; // is proposal round finalist (winner)
  /** Auth user related */
  is_own_proposal: boolean; // is current proposal owned by current auth user
  has_reviewed_proposal: boolean; // is current proposal reviewed by current user
  liked_related_review: boolean; // is current proposal.review liked by current user. User can like only one related to current proposal review.

  genres?: Genre[];
  statistics?: ProposalStatistic;
  user: User;
  reviews?: Review[];
  revisions?: Array<Revision>;
  proposal_content_type_full: ContentType;
  proposal_moderation_status: any; //  ???
  moderations?: Array<Moderation>;
  languages?: Language[];
  evaluations?: any[];
  contributions?: Array<Contribution>;

  /** Files related */
  cover_id: string; // foreign key
  cover?: UploadedFile; // cover object
  cover_type: string;
  cover_url: string;
  cover_image: string;
  video_url?: ProposalVideoItem[];
  images?: any; //
  docs?: any; //

  attached_crew: string;
  rewards: any;
  is_general_review: boolean;
  locked_by?: string;
  locked_at?: Date;

  // ADDED extra Fields  avoid type error
  proposal_round?: ProposalRound;
  last_extended_at?: string;
  approved_at?: Date;
  reviews_skip?: number;
  reviews_scroll?: number;
  reviews_api_reached: boolean;
  votes_score?: number;
  reviews_score?: number;
  evaluations_score?: number;
  likes_score?: number;
  stake_score?: number;
  ai_report_id?: string;
  submission_fee?: number;
  resubmission_fee?: number;
  is_finalized?: boolean;
  status_changes?: UserActivityLog[];
  is_expired?: boolean;

  // calculated attr
  total_stake?: number;
  total_contributed_bounty?: number;
  chat_channel_id?: string;
}

export interface ProposalVideoItem {
  file_id?: string;
  url: string;
  thumbnailUrl: string;
  mediaTitle: string;
  description?: string;
  isDirectVideoUpload?: boolean;
  converted?: boolean;
  failed?: boolean;
}

export interface ProposalStatistic {
  score: any;
  total_votes: number;
  balance: number;
  total_stake: number;
  reviews_score: number;
  evaluations_score: number;
  likes_score: number;
  average_votes: number;
  total_bounty: number;
  average_bounty: number;
  scores?: Array<any>;
  bounty?: Array<any>;
  votes?: Array<any>;
  stake?: Array<any>;
}

export class CreativeQuery {
  _id?: string;
  blockchain_id?: string;
  blockchain_confirmed?: boolean;
  submission_transaction: string;
  submission_fee: number;
  resubmission_transaction: string;
  resubmission_fee: number;
  title: string;
  bounty: number;
  balance: number;
  status: string;
  staked?: number;
  total_stake: number;
  total_votes: number;
  query_age: number;
  created_at?: Date;
  expired_at?: Date;
  approved_at?: Date;
  raw_created_at?: string;
  updated_at?: Date;
  raw_updated_at?: Date;
  current_user_submit?: any;
  cover?: string;
  cover_color?: string;
  locked_by?: string;
  locked_at?: Date;

  user: User;
  user_id: any;
  revisions?: Array<Revision>;
  questions?: Array<Question>;
  submissions?: Array<CreativeQuerySubmit>;
  demography?: any;
}

export class Question {
  _id?: string;
  index: number;
  description: string;
  type: string;
  correct?: Array<any>;
  creative_query_id: string;

  creative_query_mongo_id?: string;
  user_id?: string;
  created_at?: string;
  raw_created_at?: string;
  updated_at?: string;
  raw_updated_at?: string;
  options?: Array<Option>;
}

export class Option {
  _id?: string;
  index: number;
  value: string;
  title?: string;
  description?: string;
  type: string;
  question_id: string;
  question_mongo_id?: string;
  user_id?: string;
  created_at?: string;
  updated_at?: string;
  isSaved?: boolean;
  isEdit?: boolean;
  videoData?: any;
}

export class CreativeQuerySubmit {
  _id: string;
  creative_query_mongo_id: string;
  creative_query_id: string;
  user_mongo_id: string;
  user_id: string;
  updated_at: number;
  raw_updated_at: number;
  created_at: number;
  raw_created_at: number;
  choosen_option: Array<number>;
}

export class Quiz {
  _id: string;
  round_id?: string;
  title?: string;
  status?: string;
  seconds_per_question?: number;
  hours_per_quiz?: number;
  bounty?: number;
  question_num?: number;
  questions_qty: number;
  has_finished_quiz: boolean;
  questions?: QuizQuestion[];
  round_question?: QuizQuestion[];
  created_at: Date;
}

export class QuizQuestion {
  _id: string;
  question: string;
  answers?: QuizQuestionAnswer[];
}

export class QuizQuestionAnswer {
  _id: string;
  question_id?: string;
  question_mongo_id?: string;
  answer: string;
  correct?: boolean;
  updated_at: Date;
  raw_updated_at: Date;
  created_at: Date;
  raw_created_at: Date;
}

export interface QuizRoundQuestionResponse {
  quiz_id: string;
  round_id: string;
  question_num: number;
  total_questions: number;
  round_question: Array<QuizRoundQuestion>;
  correct_answers: Array<QuizRoundQuestionCorrectAnswer>;
  previous_question_validation: string;
}

export interface QuizRoundQuestionRequest {
  round_question_id: string;
  question_id: string;
  round_id: string;
  user_answers?: any;
}

export interface QuizRoundQuestion {
  _id: string;
  question_id: string;
  updated_at: Date;
  raw_updated_at: Date;
  created_at: Date;
  raw_created_at: Date;
  answers: Array<QuizQuestionAnswer>;
}

export interface QuizRoundQuestionCorrectAnswer {
  _id: string;
}

export interface QuizResult {
  _id: string;
  title: string;
  bounty: number;
  participants_qty: number;
  rounds: Array<QuizDetails>;
}

export interface QuizDetails {
  _id: string;
  user_mongo_id: string;
  user_id: string;
  quiz_mongo_id: string;
  quiz_id: string;
  quiz_questions?: Array<{ valid: string }>;
  score: number;
  user: {
    _id: string;
    name: string;
  };
}

export interface QuizRoundWinners {
  questions: Array<QuizRoundQuestionWithCorrectAnswers>;
  participants: Array<QuizRoundParticipant>;
}

export interface QuizRoundQuestionWithCorrectAnswers {
  question: string;
  correct_answers: Array<QuizQuestionAnswer>;
}

export interface QuizRoundParticipant {
  correct_answers_percentage: number;
  bounty_won: number;
  wallet_hash: string;
}

export class Review {
  _id: string;
  content: string;
  status: string;
  proposal_id: string;
  proposal_mongo_id: string;
  commercial_viability_score: number;
  creativity_score: number;
  overall_score: number;
  recommendation: string;
  user_id: string;
  bounty: number;
  stake: number;
  submission_contribution: string;
  submission_stake: string;
  reputation_at_submission: number;

  is_liked_by_current_user: boolean;
  is_current_user_waiting_for_confirmation: boolean;
  current_user_liked_review: boolean;

  users_that_liked: Array<string>;
  like_history: Array<Like>;
  total_number_of_likes: number;
  created_at: number;
  proposal_blockchain_id: string;

  blockchain_id: string;
  blockchain_confirmed: boolean;
  blockchain_score: number;

  user: User;
  proposal: Proposal;
  moderations: Array<Moderation>;

  locked_by?: string;
  locked_at?: Date;
  scores?: [ReviewDynamicScore];
  average_score?: number;
  is_own_review: boolean;
  open?: boolean;
}

export class Like {
  _id: string;
  is_like: boolean;
  review_id: string;
  proposal_id: string;
  type: string;
  blockchain_confirmed: boolean;
  reputation_at_submission: number;
  previous_like_id: string;
  proposal: Proposal;
  review: Review;
  user: User;
}

export interface ReviewDynamicScore {
  name: string;
  value: string;
}

export interface ReviewManipulateRequest {
  proposal_id: string;
  content?: string;
  commercial_viability_score: number;
  creativity_score: number;
  overall_score: number;
  recommendation: string;
  stake?: number;
  bounty?: number;
  video_url?: number;
  password?: string;
  nonce?: string;
  sig?: string;
  _id?: string;
}

export interface Like {
  created_at: number;
  user_id: string;
}

// export class ReviewResponse {
//   _id: string;
//   content: string;
//   proposal_id: string;
//   commercial_viability_score: number;
//   creativity_score: number;
//   overall_score: number;
//   user_id: string;
//   users_that_liked: string[];
//   total_number_of_likes: number;
//   created_at: number;
//   user: User;
//   proposal: Proposal;
//   round?: any;
//   locked_by?: string;
//   locked_at?: Date;
// }

export class ProposalRequest {
  title: string;
  project_type?: string;
  languages?: string[];
  genres?: string[];
  summary: string;
  content: string;
  bounty: number;
  extra_bounty?: number;
  attached_crew?: string;
  rewards?: string;
  video_url?: string[];
  images?: string[];
  docs?: string[];
  cover_url?: string;
  cover_type?: string;
  password?: string;
  submission_fee?: number;
  resubmission_fee?: number;
}

export class SubmitProposalRequest {
  proposalId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class SubmitCreativeQueryRequest {
  creativeQueryId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class SubmitTransactionRequest {
  transactionId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class TransferToMainnetRequest {
  password: string;
  main_address: string;
  amount: number;
}

export class SubmitReviewRequest {
  reviewId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class SubmitLikeRequest {
  reviewId: string;
  likeId: string;
  is_like: boolean;
  password: string;
  signature?: string;
  nonce?: number;
}

export class SubmitCreativeQuerySubmissionRequest {
  submitId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class ProposalHistory {
  key: string;
  old_value: string;
  new_value: string;
  created_at: number;
}

export class User {
  _id: string;
  email: string;
  name: string;
  user_type_id: number;
  user_verification_status: number;
  submission_id?: string;
  cognito_flow_id?: string;
  sms_verification_status: string;
  seed_public_address: string;
  has_client_key: boolean;
  wallet_restore_required: boolean;
  reputation: number;
  google2fa_enabled?: boolean;
  role: any;
  balance: number;
  locked?: number;
  avatar?: string;
  transactions_in?: Array<Transaction>;
  transactions_out?: Array<Transaction>;
  proposals?: Array<Proposal>;
  activity: Array<any>;
  reviews?: Review[];
  creative_queries?: CreativeQuery[];
  moderations?: Moderation[];
  mainnet_from_requested?: boolean;
  created_at: string;
  notifications_count: number;
  is_dark_theme: boolean;
  is_grayscale: boolean;
  is_suspended?: boolean;
  favoriteGenre?: string;
  favoriteFilm?: string;
  favoriteDirector?: string;
  bio?: string;
  ethnicity?: string;
  personal_website_url?: string;
  facebook_url?: string;
  youtube_url?: string;
  instagram_url?: string;
  twitter_url?: string;
  vimeo_url?: string;
  imdb_url?: string;
  visible_public_profile_data?: [];
  first_name?: string;
  last_name?: string;
  sex?: string;
  dob?: string;
  street?: string;
  zipPostal?: string;
  country?: string;
  avatar_url?: string;
  mobile?: string;
  moderation_in_progress: boolean;
  to_be_moderated_entity: any;
  user_engagement?: any[];
  reputation_earnings?: any[];
  token_earnings?: any[];
}

export class UserAccessLog {
  created_at: string;
  created_by: string;
  raw_created_at: string;
  raw_updated_at: string;
  updated_at: string;
  user_id: string;
  user_name: string;
  user_type_id: number;
  _id: string;
}

export class UserActivityLog {
  _id: string;
  type: string;
  action: string;
  model_id: string;
  created_at: string;
  updated_at: string;
  raw_created_at: string;
  raw_updated_at: string;

  updates?: any[];
  user_mongo_id?: string;
  user_id?: string;
  is_deleted?: boolean;
  key?: string;
  old_value?: string;
  new_value?: string;
}

export class Transaction {
  _id: string;
  type: string;
  status: string;
  amount: number;
  reference_mongo_id: string;
  reference_id: string;
  blockchain_reference_id: string;
  sender_user_mongo_id: string;
  sender_user_id: string;
  blockchain_id: string;
  recipient?: User;
  sender?: User;
}

export class UserVerification {
  _id: string;
  type: string;
  status: string;
  jumioAuthorizationToken: string;
  jumioIdScanReference: string;
  jumioTimestamp: number;
  cognito_profile_id: string;
  onfidoApplicantId: string;
}

export class Price {
  FILM: number;
  last_updated: number;
}

export class PaymentRequest {
  type: string;
  amount: number;
  tokens: number;
  price: number;
  is_fake: boolean;
  card_id?: string;
  key_id?: string;
}

export class Payment {
  _id: string;
  type: string;
  status: string;
  amount_usd: number;
  amount_fees: number;
  amount_total: number;
  token_amount: number;
  coinbase_pricing?: any;
  coinbase_addresses?: any;
  coinbase_hosted_url?: string;
  coinbase_metadata?: any;
  coinbase_expires_at?: Date;
  circle_id: string;
  circle_card_id?: string;
  recipient_address_to_dcp?: string; // on sell, dcp token destination
  recipient_address_mainnet_usdc?: string; // on sell, mainnet address to send usdc
  mainnet_status?: string; // on sell transfer, status of mainnet transfer
  mainnet_transaction_id?: string; // on completion of sell transfer, mainnet tx id
  blockchain_id?: string;
}

export class DashboardResponse {}

export class UserCreateRequest {
  email: string;
  name: string;
  password: string;
  password_confirmation: string;
}

export class UserRoleRequest {
  user_role_code: number;
}

export class UserVerificationStatusRequest {
  verification_status_code: number;
}

export class Language {
  _id: string;
  iso: string;
  name: string;
}

export class TokenEarning {
  _id: string;
  amount: number;
  user_id: string;
  type: string;
  proposal_id: string;
  user: User;
  review?: Review;
}

export class ReputationEarning {
  _id: string;
  amount: number;
  user_id: string;
  type: string;
  proposal_id: string;
  user: User;
  review?: Review;
}

export class Country {
  _id: string;
  iso_2: string;
  iso_3: string;
  name: string;
}

export class Ethnicity {
  _id: string;
  name: string;
}

export class UserVerificationStatus {
  _id: string;
  code: number;
  name: string;
}

export class UserRole {
  _id: string;
  code: number;
  name: string;
}

export class SimpleList {
  _id?: string;
  slug: string;
  name: string;
}

export class Genre extends SimpleList {}

export class Region extends SimpleList {}

export class FinanceType extends SimpleList {}

export class FinanceTier extends SimpleList {}

export class ContentType extends SimpleList {}

export class ReportReason extends SimpleList {}

export class UploadedFile {
  _id: string;
  file_url: string; // URL path
  file_rel: string; // server(AWS) path
  file_preview_url?: string;
  updated_at: number;
  raw_updated_at: number;
  created_at: number;
  raw_created_at: number;
}

export interface EmailChangeRequest {
  token: string;
  email: string;
  email_confirmation: string;
}

export interface PasswordChangeRequest {
  token: string;
  password: string;
  password_confirmation: string;
}

export interface ProposalRejectReason {
  _id: string;
  description: string;
  value: string;
}

export interface Moderation {
  _id: string;
  title: string;
  content: string;
  status: string;
  user_mongo_id: string;
  user_id: string;
  created_at: number;
  updated_at: number;

  /** Model related */
  proposal_mongo_id?: string;
  proposal_id?: string;

  review_id?: string;
  review_mongo_id?: string;

  creative_query_id?: string;
  creative_query_mongo_id?: string;
}

export interface ModerationRequest {
  moderation_type: string;
  model_id: string;
  status: string;
  comments: string;
  reason?: string;
}

export interface Contribution {
  _id: string;
  amount: number;
  message: string;
  status: string;
  reference_id: string;
  reference_mongo_id: string;
  proposal_id: string;
  proposal_mongo_id: string;
  user_id: string;
  user_mongo_id: string;
  blockchain_id: string;
  created_at: number;
  raw_created_at: number;
  updated_at: number;
  raw_updated_at: number;
}

export interface Revision {
  _id: string;
  revisionable_type: string;
  revisionable_id: string;
  key: string;
  old_value: string;
  new_value: string;
  user_id: string;
  created_at: Date;
  updated_at: Date;
}

export interface GlobalPricesResponse {
  proposal_submit: number;
  proposal_resubmit: number;
  creative_query_submit: number;
  like_review: number;
  general_review_extension: number;
}

export interface GlobalStatisticResponse {
  daily_quick_rewards: number;
  highest_rated_submission_in_round: number;
  highest_contribution_per_round: number;
  top_trending: number;
}

export interface ProposalRoundResponse {
  _id: string;
  title: string;
  status: string;
  project_type: string;
  start_date: Date;
  end_date: Date;
  start_review_date: Date;
  submission_fee: number;
  reward: number;
  proposals: any[];
  total_proposals?: number;
  total_reviews?: number;
  total_likes?: number;
  top_proposal_score?: number;
  top_proposal_review_bounty?: number;
  rules: string;
}

export interface LaravelPassportAuthResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface Google2FaResponse {
  qrCodeURI: string;
  secret: string;
}

export enum ProposalStatus {
  WAITING_FOR_SUBMISSION = 'waiting-for-submission',
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  TO_BE_MODERATED = 'to-be-moderated',
  APPROVED = 'approved',
  DENIED = 'denied',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  REMOVED = 'removed',
  FINISHED = 'finished',
  ARCHIVED = 'archived',
}

export enum ReviewStatus {
  TO_BE_MODERATED = 'to-be-moderated',
  WAITING_FOR_SUBMISSION = 'waiting-for-submission',
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  APPROVED = 'approved',
  DENIED = 'denied',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export enum CreativeQueryStatus {
  WAITING_FOR_SUBMISSION = 'waiting-for-submission',
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  TO_BE_MODERATED = 'to-be-moderated',
  APPROVED = 'approved',
  FINISHED = 'finished',
  DENIED = 'denied',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export enum SnackMessages {
  POST_REVIEW_SUCCESS = 'Thank you your review. Your review must be moderated next before becoming visible to other users',
  POST_REVIEW_EVALUATION_SUCCESS = 'Thank you for your evaluation.',
}

export enum UserBlockchainMiningStatus {
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  FAILED = 'failed',
}

export enum TransactionStatus {
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  CONFIRMED = 'confirmed',
  FAILED = 'failed',
}

export interface PricingFees {
  proposal_resubmission_fee: number;
  proposal_resubmission_fee_in_dollar: number;
  proposal_moderation_fee: number;
  review_moderation_fee: number;
  proposal_extension_fee: number;
  creative_query_submission_fee: number;
  creative_query_resubmission_fee: number;
  creative_query_moderation_fee: number;
  sign_up_fee: number;
  kyc_fee: number;
  price_of_film: number;
  price_of_film_last_updated: string;
  credit_card_percent_fee: number;
  credit_card_fee_minimum_in_dollar: number;
}

export class FileProgressObject {
  file: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  cover?: boolean;
  type: string; // pdf or image
}

export class ModerationApplication {
  _id?: string;
  reviewedBy: string;
  reviewDate: Date;
  status: string; // pending // approved // rejected
  reviewComments: string;
}

export interface Reward {
  _id: string;
  title: string;
  description: string;
  estimated_delivery_date: number;
  contribution: number;
}

export interface ReportReason {
  slug: string;
  name: string;
}

export interface Report {
  _id?: string;
  user_mongo_id: string;
  user_id: string;
  created_at: number;
  updated_at: number;

  /** Model related */
  proposal_mongo_id?: string;
  proposal_id?: string;

  review_id?: string;
  review_mongo_id?: string;

  creative_query_id?: string;
  creative_query_mongo_id?: string;
}

export interface ReportRequest {
  report_type: string;
  model_id: string;
  comments: string;
  reasons: any;
}

export interface UserNotification {
  _id: string;
  activity_id: string;
  user_id: string;
  title: string;
  description: string;
  channel: string;
  status: string;
  is_deleted: string;
  read_time: UserNotificationReadTime;
  read_device: string;
  updated_at: Date;
  raw_updated_at: string;
  created_at: Date;
  raw_created_at: string;
}

export interface UserNotificationReadTime {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface Activity {
  _id: string;
  type: string;
  action: string;
  id_deleted: boolean;
  model_id: string;
  updated_at: Date;
  raw_updated_at: string;
  created_at: Date;
  raw_created_at: string;
}

export interface ProposalAIReport {
  _id: string;
  result: any;
  status: string;
}

export interface CircleCard {
  _id: string;
  circle_id: string;
  circle_network: string;
  circle_last4: string;
  circle_exp_month: number;
  circle_exp_year: number;
}

export interface AuthData {
  grant_type: string;
  username?: string;
  password?: string;
  refresh_token?: string;
  client_id: string;
  client_secret: string;
}

export class SellRequest {
  amount: number;
  tokens: number;
  price: number;
  destination: string;
  password: string;
}

export class SubmitSellRequest {
  paymentId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class UserEngagementData {
  likes: number;
  likesRight: number;
  review: number;
  reviewRight: number;
  creativeQuery: number;
  creativeQueryRight: number;
  fillChart: number;
  fill: number;
  dasharay: string;
  all: number;
  taken: number;
  potentialEarningTime: any;
  potentialEarning?: number;
}

export class Poll {
  _id: string;
  title: string;
  description: string;
  questions: [PollQuestion];
  cover: PollCover;
}

export class PollCover {
  _id: string;
  alternativeText: string;
  url: string;
}

export class PollQuestion {
  _id: string;
  title: string;
  type: string;
  options: [PollQuestionOption];
}

export class PollQuestionOption {
  _id: string;
  answer: string;
  name: string; // same as answer
}

export class Quote {
  _id: string;
  author: string;
  content: string;
  Media: [Media];
}

export class Media {
  _id: string;
  url: string;
  ext: string;
  height: number;
  width: number;
  alternativeText: string;
  caption: string;
  formats: {
    thumbnail: MediaFormat;
    small: MediaFormat;
    medium: MediaFormat;
  };
}

export class MediaFormat {
  ext: string;
  height: number;
  width: number;
  mime: string;
  name: string;
  url: string;
}

export class Announcement {
  _id: string;
  content: string;
  type: string; // info system urgent;
  description: string;
  cover: Media;
}
