import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnDestroy {
  @Input() color: string;
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
