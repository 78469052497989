import { Component, Input, OnInit } from '@angular/core';
import { AuthenticatedUserQuery } from '../../state';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() title: string;

  constructor(public appService: AuthenticatedUserQuery) {}

  ngOnInit() {}
}
