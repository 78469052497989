import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { openDown } from '../../app.animations';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
  animations: [openDown],
})
export class FilterSelectComponent implements OnInit {
  @Input() lbl: string;
  @Input() isMultiple: any;

  @Input() propLabel: string;
  @Input() options: any;
  @Input() selected = '';

  @Input() searchOption: any;
  @Output() filter = new EventEmitter();
  isOpen = false;

  constructor() {}

  ngOnInit() {}

  openClose() {
    this.isOpen = !this.isOpen;
  }

  select(item: any) {
    if (this.isMultiple === false) {
      this.selectNonMultiple(item);
    } else {
      this.selectMultiple(item);
    }
  }

  selectMultiple(item: any) {
    if (item.selected === true) {
      item.selected = false;
      const replString = item[this.searchOption];
      this.selected = this.selected.replace(replString, '');
      this.selected = this.selected.substr(0, this.selected.length - 1);
    } else {
      item.selected = true;
      if (this.selected !== '') {
        this.selected = this.selected + ',';
      }
      this.selected = this.selected + item[this.searchOption];
    }
    this.filter.emit(this.selected);
  }

  selectNonMultiple(item: any) {
    for (let i = 0; i < this.options.length; i++) {
      this.options[i].selected = false;
    }
    item.selected = true;
    this.selected = item[this.searchOption];

    this.filter.emit(this.selected);
  }
}
