import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { timer } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { AuthenticationService } from '../state/authentication/authentication.service';
import { AuthenticatedUserService } from '../state/authenticated-user/authenticated-user.service';

export interface WindowSize {
  height: number;
  width: number;
  factor?: number;
}

// REF : https://stackoverflow.com/questions/34177221/angular2-how-to-inject-window-into-an-angular2-service
export interface ICustomWindow extends Window {
  __custom_global_stuff: string;
}

function getWindow(): any {
  return window;
}

@Injectable()
export class WindowSizeService {
  public get nativeWindow(): ICustomWindow {
    return getWindow();
  }
  constructor(
    private authenticationService: AuthenticationService,
    private authenticatedUserService: AuthenticatedUserService
  ) {
    fromEvent(this.nativeWindow, 'storage')
      .pipe(debounce(() => timer(100)))
      .subscribe((st: StorageEvent) => {
        if (st.key === 'token' && st.newValue === null) {
          this.authenticationService.logout();
        } else if (st.key === 'token' && st.newValue !== null) {
          this.authenticationService.updateToken();
          // Attempting auth with new token
          this.authenticatedUserService.userByToken().subscribe(user => {});
        }
      });
  }

  getHostname(): string {
    const { port, protocol, hostname } = this.nativeWindow.location;
    return port && port !== '' && port !== '80' ? `${protocol}//${hostname}:${port}/` : `${protocol}//${hostname}/`;
  }
}
