import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmbedVideoService } from '../../../../../../../src/app/shared';
import { PricingFees, Proposal, ProposalRound } from '../../../../../../../src/app/app.datatypes';
import { BountyData } from '../proposal-create-step-three/proposal-create-step-three.component';
import { AboutProposalData } from '../proposal-create-step-two/proposal-create-step-two.component';

@Component({
  selector: 'app-proposal-create-step-four',
  templateUrl: './proposal-create-step-four.component.html',
  styleUrls: ['./proposal-create-step-four.component.css'],
})
export class ProposalCreateStepFourComponent implements OnInit {
  @Input() proposal: Proposal;
  @Input() proposalReview: Proposal;
  @Input() selectedRound: ProposalRound;
  @Input() completed: boolean;
  @Input() days: number;
  @Input() prices: PricingFees;
  @Input() stepTwoData: AboutProposalData;
  @Input() stepThreeData: BountyData;

  stepFourForm: FormGroup;
  itemType = [];

  constructor(private formBuilder: FormBuilder, private embedService: EmbedVideoService) {}

  ngOnInit(): void {
    this.buildForm();
  }

  async buildForm() {
    this.stepFourForm = await this.formBuilder.group({
      video_url: this.proposal.video_url
        ? await this.formBuilder.array(
            await Promise.all(
              this.proposal.video_url.map(async video => {
                return await this.createVideoField(video);
              })
            )
          )
        : this.formBuilder.array([]),
      cover_id: [this.proposal.cover_id ? this.proposal.cover_id : null],
      images: this.proposal.images
        ? this.formBuilder.array(
            this.proposal.images.map(imageData => {
              return this.createImageField(imageData);
            })
          )
        : this.formBuilder.array([]),
      docs: this.proposal.docs
        ? this.formBuilder.array(
            this.proposal.docs.map(doc => {
              return this.createDocField(doc);
            })
          )
        : this.formBuilder.array([]),
      cover_url: [this.proposal.cover_url ? this.proposal.cover_url : null, Validators.required],
      cover_type: [this.proposal.cover_type ? this.proposal.cover_type : null, Validators.required],
    });
  }

  createVideoField(video): Promise<FormGroup> {
    return this.embedService.getThumbnailByURL(video.url).then(res => {
      const thumbnailUrl = res;
      this.itemType.push({
        item: video,
        type: 'video',
        video: this.embedService.embed(video.url),
      });
      return this.formBuilder.group({
        url: [
          video.url ? video.url : null,
          Validators.pattern(
            'https://(?:www.)?(vimeo.com|youtube.com|m.youtube.com|youtu.be)/(?:watch?v=)?(.*?)(?:z|$|&)'
          ),
        ],
        mediaTitle: [video.mediaTitle ? video.mediaTitle : null],
        description: [video.description ? video.description : null],
        thumbnailUrl: thumbnailUrl,
      });
    });
  }

  createImageField(imageData = null): FormGroup {
    const dt = new Date();
    if (imageData && imageData.url !== null) {
      this.itemType.push({
        item: imageData,
        type: 'image',
        thumb: imageData.url,
        id: dt.getTime(),
      });
      return this.formBuilder.group({
        url: [imageData.url ? imageData.url : null],
        mediaTitle: [imageData.mediaTitle ? imageData.mediaTitle : null],
        description: [imageData.description ? imageData.description : null],
      });
    }
    return this.formBuilder.group({
      url: [null],
      mediaTitle: [null],
      description: [null],
    });
  }

  createDocField(doc): FormGroup {
    if (doc && doc.url !== null) {
      return this.formBuilder.group({
        url: [doc.url ? doc.url : null],
        file_preview_url: [doc.file_preview_url ? doc.file_preview_url : null],
        docTitle: [doc.docTitle ? doc.docTitle : null],
        description: [doc.description ? doc.description : null],
      });
    }
    return this.formBuilder.group({
      url: [null],
      file_preview_url: [null],
      docTitle: [null],
      description: [null],
    });
  }
}
