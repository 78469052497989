import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments';

@Injectable({
  providedIn: 'root',
})
export class YouTubeService {
  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {}

  private validYouTubeOptions = ['default', 'mqdefault', 'hqdefault', 'sddefault', 'maxresdefault'];
  public urlPattern = 'https://(?:www.)?(youtube.com|m.youtube.com|youtu.be)/(?:watch?v=)?(.*?)(?:z|$|&)';
  public apiURL = 'https://www.googleapis.com/youtube/v3';

  public urlValidator(url: string): boolean {
    const regex = new RegExp(this.urlPattern);
    return regex.test(url);
  }

  public isEmbeddable(url: string) {
    if (this.urlValidator(url)) {
      const id = this.getVideoId(url);
      const metaDataUrl = this.apiURL + '/videos?part=status&key=' + environment.youtube_token + '&id=' + id;
      return this.http.get(metaDataUrl).pipe(
        map((metaData: any) => {
          // if response has items.status -> video is public
          const statusObject = metaData?.items[0]?.status;
          return statusObject ? statusObject.embeddable : null;
        })
      );
    } else {
      console.log('smth went wrong');
    }
  }

  public getVideoId(url: string): string {
    const urlPartArr = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return urlPartArr[2] !== undefined ? urlPartArr[2].split(/[^0-9a-z_\-]/i)[0] : urlPartArr[0];
  }

  public getThumbnailUrl(url: string): string {
    if (this.urlValidator(url)) {
      const videoId = this.getVideoId(url);
      return 'https://i3.ytimg.com/vi/' + videoId + '/hqdefault.jpg';
    }
    return '';
  }

  public embed(url: string, autoplay?: boolean) {
    let query = '?mute=1&rel=0&modestbranding=0&autohide=0&showinfo=0';
    if (autoplay) {
      query += '&autoplay=1';
    }

    if (this.urlValidator(url)) {
      const videoId = this.getVideoId(url);
      return this.sanitizer.bypassSecurityTrustHtml(
        '<iframe src="https://www.youtube.com/embed/' + videoId + query + '" frameborder="0" allowfullscreen></iframe>'
      );
    }
  }
}
