import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthenticatedUserState } from '../state/authenticated-user';
import {
  Review,
  ReviewManipulateRequest,
  SubmitReviewRequest,
  SubmitLikeRequest,
  Moderation,
  ModerationRequest,
} from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private apiService: ApiService) {}

  getReview(id: string): Observable<Review> {
    const url = `api/reviews/${id}`;
    return this.apiService.get(url);
  }

  getAuthenticatedUserStateReviews(userid, skip = 0, search = ''): Observable<any[]> {
    let apiUrl = 'api/reviews/user-reviews/';
    if (userid && userid !== null) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  getAuthenticatedUserStateEvaluations(userid, skip = 0, search = ''): Observable<any[]> {
    let apiUrl = 'api/users/evaluations/';
    if (userid && userid !== null) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  postReview(review: ReviewManipulateRequest): Observable<Review> {
    return this.apiService.post('api/reviews/', review);
  }

  submitReview(req: SubmitReviewRequest): Observable<any> {
    return this.apiService.post('api/reviews/' + req.reviewId + '/submit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  likeReview(reviewId: any, pass: string): Observable<any> {
    return this.apiService.post('api/reviews/' + reviewId + '/like', { password: pass }).pipe(
      map(response => {
        const reviews = response.reviewsArray;
        for (const review of reviews) {
          review.current_user_liked_review = review.is_liked_by_current_user;
        }
        return {
          reviews,
          sign: response.sign,
        };
      })
    );
  }

  submitLikeReview(req: SubmitLikeRequest): Observable<any> {
    return this.apiService.post('api/reviews/' + req.reviewId + '/like/submit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
      is_like: req.is_like ? 1 : 0,
    });
  }

  getReviews(id: string, orderingType?, direction = ''): Observable<Review[]> {
    const url = `api/proposals/${id}/reviews${orderingType ? `?field=${orderingType}&direction=${direction}` : ''}`;
    return this.apiService.get(url).pipe(catchError(error => this.apiService.catchError(error)));
  }

  getModeratableReviews(skip = 0): Observable<Review[]> {
    return this.apiService.get('api/reviews/moderate?skip=' + skip);
  }

  getModerations(skip = 0, search = ''): Observable<Moderation[]> {
    return this.apiService
      .get('api/moderations?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : ''))
      .pipe(catchError(error => this.apiService.catchError(error)));
  }

  postModerate(moderation: ModerationRequest): Observable<Moderation> {
    return this.apiService.post('api/moderations', moderation);
  }
}
