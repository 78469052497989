import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SEOService, ProgressService, CreativeQueryService, RoutingState } from '../../../shared/services';
import { AuthenticatedUserQuery } from '../../../shared/state/authenticated-user/authenticated-user.query';
import { LoaderService } from '../../../shared/components/loader/loader.component';
import { CreativeQuery } from '../../../app.datatypes';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-query-result',
  templateUrl: './query-result.component.html',
  styleUrls: ['./query-result.component.scss'],
})
export class QueryResultComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  creativeQuery: CreativeQuery;
  // Spinner variables
  progress: any;
  cqId: any;
  history: any[];

  constructor(
    private creativeQueryService: CreativeQueryService,
    public appService: AuthenticatedUserQuery,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public loader: LoaderService,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._seoService.createTitleForPage();
    // if (this.data.creativeQueryId !== undefined) {
    this.route.params.pipe(tap(params => (this.cqId = params['id']))).subscribe(cq => {
      this.creativeQueryService
        .getCreativeQuery(this.cqId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          cQuery => {
            this.progressService.hideSpinner(this.progress);
            this.creativeQuery = cQuery;
          },
          error => {
            this.progressService.hideSpinner(this.progress);
          }
        );
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
