import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { SEOService, ProgressService, CreativeQueryService, ThemeService } from '../../services';
import { AuthenticatedUserQuery } from '../../state';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Chart } from 'chart.js';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { chartColors } from '../../../../environments/chartColors';

@Component({
  selector: 'app-creative-query-graph',
  templateUrl: './creative-query-graph.component.html',
  styleUrls: ['./creative-query-graph.component.scss'],
})
export class CreativeQueryGraphComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  loading = false;
  panelOpenState = false;

  viewIsRendered = false;

  @Input() typeChart: string;

  @Input() creativeQueryId: string;

  @ViewChildren('basic')
  basicCanvas: QueryList<any>;
  @ViewChildren('age')
  ageCanves: QueryList<any>;
  @ViewChildren('gender')
  genderCanves: QueryList<any>;
  @ViewChildren('region')
  regionCanves: QueryList<any>;
  // @todo make result type once it gets standardised
  result: any;
  chartPlugin = {
    // colorschemes: {
    //   scheme: Aspect6,
    // },
  };

  // Spinner variables
  progress: any;
  isDarkTheme: boolean;

  constructor(
    private creativeQueryService: CreativeQueryService,
    public appService: AuthenticatedUserQuery,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this._seoService.createTitleForPage();
    this.loadData();
    this.themeService.currentTheme$.pipe(untilDestroyed(this)).subscribe(value => (this.isDarkTheme = value));
  }

  loadData() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.creativeQueryService
      .getCreativeQueryResults(this.creativeQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.result = result;
          // when it will be false
          if (this.result && !this.viewIsRendered) {
            this.drawGraphsOnCanvas();
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.creativeQueryId) {
      this.loadData();
    }
  }

  ngAfterViewInit() {
    if (this.result && this.result.length > 0) {
      this.viewIsRendered = true;
      this.drawGraphsOnCanvas();
    }
  }

  votesPerOptionGraph() {
    this.basicCanvas.changes.subscribe(list => {
      list.forEach((canves, index) => {
        const dataValue = [
          {
            label: 'Votes',
            data: [],
            fill: false,
            backgroundColor: chartColors.na,
          },
        ];

        const labels = [];

        for (let i = 0; i < this.result.basic_stat[0].options.length; i++) {
          labels.push('');
          dataValue[0].data.push(this.result.basic_stat[0].options[i].total_votes);
        }

        const graphData = {
          labels,
          datasets: dataValue,
        };

        this.drawGraph(canves, graphData, 'Votes per option', 'bar');
      });
    });
  }

  votesPerAgeGroupGraph() {
    this.ageCanves.changes.subscribe(list => {
      list.forEach((canves, index) => {
        const fifteen = {
          label: '15-24 years',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.age.age15_24,
          fill: false,
        };

        const twentyFive = {
          label: '25-54 years',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.age.age25_54,
          fill: false,
        };

        const fiftyFive = {
          label: '55 years above',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.age.over_55,
          fill: false,
        };

        const noData = {
          label: 'No age data',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.na,
          fill: false,
        };

        const labels = [];

        for (let i = 0; i < this.result.age_stat[0].options.length; i++) {
          labels.push('');

          for (let j = 0; j < this.result.age_stat[0].options[i].votes_by_age.length; j++) {
            if (this.result.age_stat[0].options[i].votes_by_age[j].demographic === '15-24 years') {
              fifteen.data.push(this.result.age_stat[0].options[i].votes_by_age[j].total_votes);
            } else if (this.result.age_stat[0].options[i].votes_by_age[j].demographic === '25-54 years') {
              twentyFive.data.push(this.result.age_stat[0].options[i].votes_by_age[j].total_votes);
            } else if (this.result.age_stat[0].options[i].votes_by_age[j].demographic === 'above 55') {
              fiftyFive.data.push(this.result.age_stat[0].options[i].votes_by_age[j].total_votes);
            } else {
              noData.data.push(this.result.age_stat[0].options[i].votes_by_age[j].total_votes);
            }
          }
        }

        const graphData = {
          labels,
          datasets: [fifteen, twentyFive, fiftyFive, noData],
        };

        this.drawGraph(canves, graphData, 'Votes by age');
      });
    });
  }

  votesPerGender() {
    this.genderCanves.changes.subscribe(list => {
      list.forEach((canves, index) => {
        const male = {
          label: 'Male',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.gender.male,
          fill: false,
        };

        const female = {
          label: 'Female',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.gender.female,
          fill: false,
        };

        const other = {
          label: 'Other',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.gender.other,
          fill: false,
        };

        const noData = {
          label: 'No gender data',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.na,
          fill: false,
        };

        const labels = [];

        for (let i = 0; i < this.result.gender_stat[0].options.length; i++) {
          labels.push('');
          for (let j = 0; j < this.result.gender_stat[0].options[i].votes_by_gender.length; j++) {
            if (this.result.gender_stat[0].options[i].votes_by_gender[j].demographic === 'M') {
              male.data.push(this.result.gender_stat[0].options[i].votes_by_gender[j].total_votes);
            } else if (this.result.gender_stat[0].options[i].votes_by_gender[j].demographic === 'F') {
              female.data.push(this.result.gender_stat[0].options[i].votes_by_gender[j].total_votes);
            } else if (this.result.gender_stat[0].options[i].votes_by_gender[j].demographic === 'O') {
              other.data.push(this.result.gender_stat[0].options[i].votes_by_gender[j].total_votes);
            } else {
              noData.data.push(this.result.gender_stat[0].options[i].votes_by_gender[j].total_votes);
            }
          }
        }

        const graphData = {
          labels,
          datasets: [male, female, other, noData],
        };
        this.drawGraph(canves, graphData, 'Votes by gender');
      });
    });
  }

  votesPerRegion() {
    this.regionCanves.changes.subscribe(list => {
      list.forEach((canves, index) => {
        const asia = {
          label: 'Asia',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.region.Asia,
          fill: false,
        };
        const europe = {
          label: 'Europe',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.region.Europe,
          fill: false,
        };
        const africa = {
          label: 'Africa',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.region.Africa,
          fill: false,
        };
        const americas = {
          label: 'Americas',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.region.Americas,
          fill: false,
        };
        const oceania = {
          label: 'Oceania',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.region.Oceania,
          fill: false,
        };
        const noData = {
          label: 'No region data',
          data: [],
          type: 'bar',
          backgroundColor: chartColors.na,
          fill: false,
        };
        const labels = [];

        for (let i = 0; i < this.result.region_stat[0].options.length; i++) {
          labels.push('');

          for (let j = 0; j < this.result.region_stat[0].options[i].votes_by_region.length; j++) {
            if (this.result.region_stat[0].options[i].votes_by_region[j].demographic === 'Asia') {
              asia.data.push(this.result.region_stat[0].options[i].votes_by_region[j].total_votes);
            } else if (this.result.region_stat[0].options[i].votes_by_region[j].demographic === 'Europe') {
              europe.data.push(this.result.region_stat[0].options[i].votes_by_region[j].total_votes);
            } else if (this.result.region_stat[0].options[i].votes_by_region[j].demographic === 'Africa') {
              africa.data.push(this.result.region_stat[0].options[i].votes_by_region[j].total_votes);
            } else if (this.result.region_stat[0].options[i].votes_by_region[j].demographic === 'Americas') {
              americas.data.push(this.result.region_stat[0].options[i].votes_by_region[j].total_votes);
            } else if (this.result.region_stat[0].options[i].votes_by_region[j].demographic === 'Oceania') {
              oceania.data.push(this.result.region_stat[0].options[i].votes_by_region[j].total_votes);
            } else {
              noData.data.push(this.result.region_stat[0].options[i].votes_by_region[j].total_votes);
            }
          }
        }

        const graphData = {
          labels,
          datasets: [asia, americas, africa, europe, oceania, noData],
          fill: false,
        };
        this.drawGraph(canves, graphData, 'Votes by region');
      });
    });
  }

  drawGraphsOnCanvas() {
    this.votesPerOptionGraph();
    this.votesPerAgeGroupGraph();
    this.votesPerGender();
    this.votesPerRegion();
  }

  drawGraph(element, dataVal, title, type = 'bar') {
    const ctx = element.nativeElement.getContext('2d');
    element = new Chart(ctx, {
      type,
      data: dataVal,
      options: {
        // legend: {
        //   labels: {
        //     fontColor: this.isDarkTheme ? chartColors.darkThemeCaption : chartColors.lightThemeCaption,
        //   },
        // },

        maintainAspectRatio: false,
        title: {
          display: true,
          text: title,
          fontColor: this.isDarkTheme ? chartColors.darkThemeCaption : chartColors.lightThemeCaption,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: this.isDarkTheme ? chartColors.darkThemeCaption : chartColors.lightThemeCaption,
              },
              // barPercentage: 0.3,
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: 'white',
                beginAtZero: true,
                fontSize: 14,
                callback: function(value: any) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
        plugins: this.chartPlugin,
      },
    });
  }
  formatLabel(str, maxwidth) {
    const sections = [];
    const words = str.split(' ');
    let temp = '';

    words.forEach(function(item, index) {
      if (temp.length > 0) {
        const concat = temp + ' ' + item;

        if (concat.length > maxwidth) {
          sections.push(temp);
          temp = '';
        } else {
          if (index === words.length - 1) {
            sections.push(concat);
            return;
          } else {
            temp = concat;
            return;
          }
        }
      }

      if (index === words.length - 1) {
        sections.push(item);
        return;
      }

      if (item.length < maxwidth) {
        temp = item;
      } else {
        sections.push(item);
      }
    });

    return sections;
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
