import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bounty-info',
  templateUrl: './bounty-info.component.html',
  styleUrls: ['./bounty-info.component.scss'],
})
export class BountyInfoComponent implements OnInit {
  @Input() title: any;
  @Input() submissionFee: any;
  @Input() moderationFee: any;

  @Input() resubmissionFee: any;
  @Input() minimumBounty: any;
  @Input() additional: any;

  isVisible = true;

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.isVisible = !this.isVisible;
  }
}
