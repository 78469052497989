import { Component, OnInit, Input, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { ApiService } from '../../services';
import { DcpModalService } from '../dcp-modal';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';

@Component({
  selector: 'app-instruction-card',
  templateUrl: './instruction-card.component.html',
  styleUrls: ['./instruction-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InstructionCardComponent implements OnInit {
  public instruction: any;
  @Input() page: string;
  @ViewChild(PlyrComponent, { static: true })
  plyr: PlyrComponent;
  player: Plyr;
  title: string;
  videoSources: Plyr.Source[] = [];
  element: Node;
  instructionDisplayed = false;
  options: Plyr.Options = {
    blankVideo: '/assets/videos/blank.mp4',
  };

  constructor(private api: ApiService, private el: ElementRef, private modalService: DcpModalService) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.api.get('api/instructions/' + this.page).subscribe((data: any) => {
      this.videoSources.push({
        src: data[0].video.url,
      });
      this.title = data[0].title;
    });
  }

  toggleInstructionVisibility(): void {
    this.instructionDisplayed = !this.instructionDisplayed;
  }
}
