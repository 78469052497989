import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { UserTypes, AuthenticatedUserQuery, AuthenticatedUserState } from '../../../shared';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() user: AuthenticatedUserState;
  @Input() dark = false;
  @Input() sticky = false;
  @Input() readedMsg = 0;
  @Input() wrapper: any;

  @Output() showInboxBar = new EventEmitter<any>();
  @Output() logout = new EventEmitter();
  @Output() changeTheme = new EventEmitter();

  // AFFIXED TO WINDOW TOP ON SCROLL
  isOpen = false;
  footerOpen = false;
  // VERTICAL OFFSET TO TRIGGER STICKY MENU
  stickyThreshold = 50;
  loginModalRef: any;
  statistics: any;
  profilePlaceholderURL = environment.profile_placeholder
    ? environment.profile_placeholder
    : 'https://www.gravatar.com/avatar/0.43227576652898625';

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public dialog: MatDialog,
    private authService: AuthService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.authenticatedUserQuery.select().subscribe();
  }

  openCloseLeftMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    if (wrapper.classList.contains('left-side-open')) {
      this.renderer.removeClass(wrapper, 'left-side-open');
    } else {
      this.renderer.addClass(wrapper, 'left-side-open');
    }
    this.isOpen = !this.isOpen;
  }

  toggleTheme() {
    this.authService.postSetUserSettings('is_dark_theme', !this.dark).subscribe(res => {
      this.changeTheme.emit();
    });
  }

  getUserRoleName() {
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
        return 'Moderator';
      case UserTypes.ADMINISTRATOR:
        return 'Administrator';
      default:
        return 'Member';
    }
  }

  onLogout() {
    this.logout.emit();
  }

  // showInboxBarFn() {
  //   this.showInboxBar.emit();
  // }

  showFooter() {
    this.footerOpen = !this.footerOpen;
  }

  ngOnDestroy() {}
}
