import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ProposalService, ProgressService } from '../../../services';
import { AuthenticatedUserState } from '../../../state';
import { UserReward } from '../../../../app.datatypes';

@Component({
  selector: 'app-user-rewards',
  templateUrl: './user-rewards.component.html',
  styleUrls: ['./user-rewards.component.scss'],
})
export class UserRewardsComponent implements OnInit, OnDestroy {
  userRewards: UserReward[];
  @Input() user: AuthenticatedUserState;
  dataSourceRewards: any;
  displayedColumnsRewards: Array<string>;
  isMoreAvailable = true;

  // scroll Pagination variables
  skip = 0;
  currentScrollPosition = 0;
  selectedTab: number;

  // Spinner variables
  progress: any;
  constructor(
    private proposalService: ProposalService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {
    this.displayedColumnsRewards = ['proposal', 'reward', 'username', 'email', 'delivery_date'];
  }

  ngOnInit() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.proposalService
      .getAllProposalUserRewards(this.user._id)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.userRewards = result;
          this.dataSourceRewards = new MatTableDataSource(result);
          if (result.length < 10) {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.proposalService
      .getAllProposalUserRewards(this.user._id)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.dataSourceRewards.data;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.dataSourceRewards.data = oldData;
            this.skip = this.skip + result.length;
            if (result.length < 10) {
              this.isMoreAvailable = false;
            }
            // IMPORTANT SERVER side depadancy (Should match LIMIT OF Server  )
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }
  ngOnDestroy() {}
}
