import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticatedUserState } from '../../../../../../../src/app/shared';
import { Proposal, ProposalRound, PricingFees } from '../../../../../../../src/app/app.datatypes';
import { Subject } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface BountyData {
  bounty: number;
  extraBounty: number;
}

@Component({
  selector: 'app-proposal-create-step-three',
  templateUrl: './proposal-create-step-three.component.html',
  styleUrls: ['./proposal-create-step-three.component.css'],
})
export class ProposalCreateStepThreeComponent implements OnInit, OnDestroy {
  @Input() proposal: Proposal;
  @Input() user: AuthenticatedUserState;
  @Input() prices: PricingFees;
  @Input() selectedRound: ProposalRound;
  @Output() setStepThree = new EventEmitter<BountyData>();

  stepThreeForm: FormGroup;

  minBountyToBeAdded: number;
  updateBounty$ = new Subject();
  submitFeeFilm = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.calculateMinBountyToBeAdded();
    this.refreshBountyValidation();
    this.stepThreeForm = this.formBuilder.group({
      bounty: [
        this.proposal.bounty ? this.proposal.bounty : 0,
        [Validators.required, Validators.min(this.minBountyToBeAdded), Validators.max(this.user.balance)],
      ],
      extraBounty: [
        !this.proposal.blockchain_confirmed && this.proposal.bounty
          ? this.proposal.bounty - this.selectedRound.minimum_bounty
          : 0,
        [Validators.required, Validators.min(0), Validators.max(this.user.balance)],
      ],
    });
  }

  calculateMinBountyToBeAdded(): void {
    const roundBounty = this.selectedRound ? this.selectedRound.minimum_bounty : 0;
    const proposalBounty = this.proposal && this.proposal.bounty ? this.proposal.bounty : 0;
    if (this.proposal.blockchain_confirmed) {
      this.minBountyToBeAdded = Math.max(roundBounty, proposalBounty);
    } else {
      this.minBountyToBeAdded = roundBounty;
    }
  }

  onBlur() {
    const val = this.stepThreeForm.get('extraBounty').value;
    if (val === '' || val < 0) {
      this.stepThreeForm.get('extraBounty').setValue(0);
      this.updateBounty();
    }
    this.stepThreeForm.updateValueAndValidity();
    const data = this.stepThreeForm.value;
    this.setStepThree.emit(data);
  }

  inputUpdateBounty(event) {
    this.updateBounty$.next(event);
  }

  AddBounty(bounty) {
    let newBounty = this.stepThreeForm.get('extraBounty').value + bounty;
    if (newBounty <= 0) {
      newBounty = 0;
    }
    this.stepThreeForm.get('extraBounty').setValue(newBounty);
    this.updateBounty();
  }

  updateBounty() {
    let bounty = Number(this.stepThreeForm.get('extraBounty').value) + Number(this.selectedRound.minimum_bounty || 0);
    if (this.proposal.blockchain_confirmed) {
      bounty += Number(this.proposal.bounty || 0);
      bounty -= Number(this.selectedRound.minimum_bounty || 0);
    }
    this.stepThreeForm.get('bounty').setValue(bounty);
    this.onBlur();
  }

  refreshBountyValidation() {
    if (this.stepThreeForm) {
      this.stepThreeForm.controls['bounty'].setValidators([
        Validators.required,
        Validators.min(this.minBountyToBeAdded),
        Validators.max(this.user.balance),
      ]);
      this.stepThreeForm.controls['bounty'].updateValueAndValidity();
      this.stepThreeForm.controls['extraBounty'].setValidators([
        Validators.required,
        Validators.min(0),
        Validators.max(
          this.user.balance - this.submitFeeFilm - (this.selectedRound ? this.selectedRound.minimum_bounty : 0)
        ),
      ]);
      this.stepThreeForm.controls['extraBounty'].updateValueAndValidity();
    }
  }

  initUpdateExtraBountyObservable() {
    this.updateBounty$.pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.updateBounty();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
