import { Injectable } from '@angular/core';
import { ProposalRoundStore, ProposalRoundState } from './proposal-round.store';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class ProposalRoundQuery extends QueryEntity<ProposalRoundState> {
  selectFilters$ = this.select('filters');
  selectSkip$ = this.select('skip');
  showFilter$ = this.select(state => state.ui.showFilters);

  get filters() {
    return this.getValue().filters;
  }

  constructor(protected store: ProposalRoundStore) {
    super(store);
  }

  getActiveIndex() {
    return this.getAll().indexOf(this.getActive());
  }
}
