import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AuthenticationQuery } from '../state/authentication/authentication.query';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import { ToasterService } from './toaster.service';
import { RoutingState } from './routing-state.service';
import { AuthenticationState } from '..';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  auth: boolean;
  authChange: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private authQuery: AuthenticationQuery,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    private routingState: RoutingState
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authQuery.isLoggedIn$.pipe(
      map(isAuth => {
        if (
          isAuth &&
          route.data.roles &&
          route.data.roles.indexOf(this.authenticatedUserQuery.user.user_type_id) === -1
        ) {
          if (!this.routingState.getPreviousUrl()) {
            this.router.navigateByUrl('/');
          }

          // server error
          this.toasterService.openSnackBar('Access denied', 'Unauthorized');
          this.auth = false;
          this.authChange.next(this.auth);
          return false;
        } else if (isAuth) {
          this.authChange.next(this.auth);
          this.auth = true;
          return true;
        }
        this.auth = false;
        this.authChange.next(this.auth);
        this.router.navigate(['/login'], { queryParams: { return: state.url } });
        return false;
      }),
      take(1)
    );
  }
}
