import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-template',
  templateUrl: './snack-bar-template.component.html',
  styleUrls: ['./snack-bar-template.component.scss'],
})
export class SnackBarTemplateComponent implements OnInit {
  constructor(
    private snackBarRef: MatSnackBarRef<SnackBarTemplateComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }

  ngOnInit() {}
}
