import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private updateOnHover = new Subject<any>();
  private pausePayingVideo = new Subject<any>();
  private pauseProposalVideo = new Subject<any>();
  private toggleUserMenu = new Subject<any>();
  private videoPlayMobile = new Subject<any>();
  private onPauseSliderVideo = new Subject<any>();
  private onCoinAnimation = new Subject<any>();
  private onRedirectActivity = new Subject<any>();
  private onToggleNav = new Subject<any>();
  private onToggleFilter = new Subject<any>();
  private closeToggleFilter = new Subject<any>();
  private resetFilter = new Subject<any>();
  private onUpdateNotificationCount = new Subject<any>();

  constructor() {}

  setOnHover(message: boolean, id: any) {
    this.updateOnHover.next({ isHoverCard: message, proposalId: id });
  }

  getOnHover(): Observable<any> {
    return this.updateOnHover.asObservable();
  }

  setOnMobile(message: boolean, id: any) {
    this.updateOnHover.next({ isMobileCard: message, proposalId: id });
  }

  getOnMobile(): Observable<any> {
    return this.updateOnHover.asObservable();
  }

  setPauseVideo(id: any) {
    this.pausePayingVideo.next({ proposalId: id });
  }

  getPauseVideo(): Observable<any> {
    return this.pausePayingVideo.asObservable();
  }

  setProposalPauseVideo(id: any) {
    this.pauseProposalVideo.next({ proposalId: id });
  }

  getProposalPauseVideo(): Observable<any> {
    return this.pauseProposalVideo.asObservable();
  }

  setToggleUserMenu(value: boolean) {
    this.toggleUserMenu.next({ userMenu: value });
  }

  getToggleUserMenu(): Observable<any> {
    return this.toggleUserMenu.asObservable();
  }

  setVideoPlayMobile(value: any) {
    this.videoPlayMobile.next({ proposalId: value });
  }

  getVideoPlayMobile(): Observable<any> {
    return this.videoPlayMobile.asObservable();
  }

  setOnSliderPauseVideo() {
    this.onPauseSliderVideo.next();
  }

  getOnSliderPauseVideo(): Observable<any> {
    return this.onPauseSliderVideo.asObservable();
  }
  setCoinAnimation() {
    this.onCoinAnimation.next();
  }

  getCoinAnimation(): Observable<any> {
    return this.onCoinAnimation.asObservable();
  }

  setOnRedirectActivity() {
    this.onRedirectActivity.next();
  }
  getOnRedirectActivity(): Observable<any> {
    return this.onRedirectActivity.asObservable();
  }

  setOnToggleNav() {
    this.onToggleNav.next();
  }

  getOnToggleNav(): Observable<any> {
    return this.onToggleNav.asObservable();
  }

  setOnToggleFilter() {
    this.onToggleFilter.next();
  }

  getOnToggleFilter(): Observable<any> {
    return this.onToggleFilter.asObservable();
  }

  setCloseToggleFilter() {
    this.closeToggleFilter.next();
  }

  getCloseToggleFilter(): Observable<any> {
    return this.closeToggleFilter.asObservable();
  }

  setResetFilter() {
    this.resetFilter.next();
  }

  getResetFilter(): Observable<any> {
    return this.resetFilter.asObservable();
  }

  setUpdateNotificationCount(isMessageRead: boolean, isAllMessageRead: boolean) {
    this.onUpdateNotificationCount.next({ isRead: isMessageRead, isAllRead: isAllMessageRead });
  }

  getUpdateNotificationCount(): Observable<any> {
    return this.onUpdateNotificationCount.asObservable();
  }
}
