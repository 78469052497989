import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { UserTypes, AuthenticatedUserQuery, AuthenticatedUserState, SharedService } from '../../../shared';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments';
import { dropdown } from '../../../app.animations';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  host: {
    class: 'flex items-center justify-between w-full h-64 border-b border-gray-border px-24',
  },
  animations: [dropdown],
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Input() user: AuthenticatedUserState;
  @Input() dark = false;
  @Input() gray_scale = false;
  @Input() sticky = false;
  @Input() wrapper: any;

  @Output() showInboxBar = new EventEmitter<any>();
  @Output() createProposal = new EventEmitter<any>();
  @Output() createCQ = new EventEmitter<any>();
  @Output() mobileMenuClosed = new EventEmitter<any>();
  @Output() logout = new EventEmitter();
  @Output() changeTheme = new EventEmitter();
  @Output() changeScale = new EventEmitter();

  openAddMenu = false;
  menuOpen = false;
  userMenuOpen = false;
  themePanelOpen = false;
  stickyThreshold = 50;
  loginModalRef: any;
  statistics: any;
  profilePlaceholderURL = environment.profile_placeholder
    ? environment.profile_placeholder
    : 'https://www.gravatar.com/avatar/0.43227576652898625';
  showProposal = !environment.limited_proposal_functionality;
  showCq = !environment.limited_cq_functionality;

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {
    this.sharedService.getToggleUserMenu().subscribe(message => {
      const messageReceived = message;
      if (messageReceived.userMenu === true) {
        this.openCloseUserMenu();
      }
    });
  }

  openMenu() {
    this.openAddMenu = !this.openAddMenu;
  }

  openLogin(): void {
    // this.router.navigate(['/login']);
  }

  ngOnInit() {
    this.authenticatedUserQuery.select().subscribe();
  }

  toggleTheme() {
    this.changeTheme.emit();
  }

  toggleScale() {
    this.changeScale.emit();
  }

  getUserRoleName() {
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
        return 'Moderator';
      case UserTypes.ADMINISTRATOR:
        return 'Administrator';
      default:
        return 'Member';
    }
  }

  onLogout() {
    this.logout.emit();
  }

  ngOnDestroy() {}

  showInboxBarFn() {
    this.showInboxBar.emit();
  }

  openCloseLeftMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    if (wrapper.classList.contains('left-side-open')) {
      this.renderer.removeClass(wrapper, 'left-side-open');
    } else {
      this.renderer.addClass(wrapper, 'left-side-open');
    }
    this.menuOpen = !this.menuOpen;
  }

  openCloseUserMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    if (wrapper.classList.contains('user-side-open')) {
      this.renderer.removeClass(wrapper, 'user-side-open');
    } else {
      this.renderer.addClass(wrapper, 'user-side-open');
      this.mobileMenuClosed.emit();
    }
    this.userMenuOpen = !this.userMenuOpen;
  }

  initMenu() {
    this.themePanelOpen = false;
  }

  toggleThemePanel() {
    this.themePanelOpen = !this.themePanelOpen;
  }

  openChat() {
    window.open(environment.discord_url, '_blank');
  }
}
