import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  host: {
    class: 'block',
  },
})
export class UserAvatarComponent implements OnInit {
  @Input() avatar_url: string;
  constructor() {}

  ngOnInit() {}
}
