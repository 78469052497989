import {
  Component,
  OnInit,
  ElementRef,
  Output,
  OnDestroy,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import {
  ToasterService,
  SEOService,
  ProgressService,
  AuthenticatedUserService,
  AuthenticatedUserQuery,
  AuthenticationQuery,
  AuthenticationState,
  AuthenticationService,
  PasswordService,
} from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, combineLatest } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CryptoUtil } from '../../shared/util/crypto-util';
import { panelIn } from '../../app.animations';

/* WANT TO MOVE TO SEPREAT SERVICE ->ERROR EXAMPLE FOR EMAIL INPUT*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    class: 'fixed top-0 left-0 w-full h-full z-10',
  },
  animations: [panelIn],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() showProgress = new EventEmitter();
  @Output() hideProgress = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() authClear = new EventEmitter();
  @Output() hidePage = new EventEmitter();

  @ViewChild('loginInput', { static: true }) loginInput: ElementRef;
  @ViewChild('goog2fa_code', { static: true }) goog2faInput: ElementRef;
  @ViewChild('goog2faAuth', { static: true }) goog2faAuth: ElementRef;
  @ViewChild('goog2fa_code1', { static: false }) goog2faCode1Elem: ElementRef;
  @ViewChild('goog2fa_code2', { static: false }) goog2faCode2Elem: ElementRef;
  @ViewChild('goog2fa_code3', { static: false }) goog2faCode3Elem: ElementRef;
  @ViewChild('goog2fa_code4', { static: false }) goog2faCode4Elem: ElementRef;
  @ViewChild('goog2fa_code5', { static: false }) goog2faCode5Elem: ElementRef;
  @ViewChild('goog2fa_code6', { static: false }) goog2faCode6Elem: ElementRef;
  @ViewChild('loginButton', { static: false }) loginButtonElem: ElementRef;
  @ViewChild('register', { static: false }) registerElem: ElementRef;
  @ViewChild('forgotPassword', { static: false }) forgotPasswordElem: ElementRef;

  hidden = true;
  form: FormGroup;
  goog2faRequired: boolean;
  matcher = new MyErrorStateMatcher();
  errors: any;
  rememberPassword = false;
  returnUrl: string = null;
  progress: any;
  loginClick = false;

  // Akita Changes
  authState$: Observable<AuthenticationState>;

  constructor(
    private authenticationQuery: AuthenticationQuery,
    private authenticationService: AuthenticationService,
    private authenticatedUserService: AuthenticatedUserService,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _seoService: SEOService,
    private passwordService: PasswordService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this._seoService.createTitleForPage();

    this.route.queryParams.subscribe(params => (this.returnUrl = params['return']));

    const auth = localStorage.getItem('Unauthorized');
    if (auth) {
      localStorage.removeItem('Unauthorized');
      this.authClear.emit();
    }

    combineLatest(this.authenticationQuery.selectLoading(), this.authenticatedUserQuery.selectLoading())
      .pipe(untilDestroyed(this))
      .subscribe(([loadingToken, loadingUser]) => {
        if (loadingToken || loadingUser) {
          this.showSpinner();
        } else {
          this.hideSpinner();
        }
      });

    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberPassword: [true, []],
      goog2fa_code: ['', Validators.maxLength(6)],
      goog2fa_code1: ['', Validators.maxLength(1)],
      goog2fa_code2: ['', Validators.maxLength(1)],
      goog2fa_code3: ['', Validators.maxLength(1)],
      goog2fa_code4: ['', Validators.maxLength(1)],
      goog2fa_code5: ['', Validators.maxLength(1)],
      goog2fa_code6: ['', Validators.maxLength(1)],
    });
    this.authenticationQuery.selectError().subscribe(error => {
      if (error) {
        this.errors = error;
      }
    });
    setTimeout(() => {
      this.hidden = false;
    }, 600);
  }

  login() {
    this.loginClick = true;

    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    const email = this.form.value.username.toLowerCase();
    this.passwordService.removePassword();
    this.authenticationService
      .login(
        email,
        CryptoUtil.bcryptHash(this.form.value.password),
        this.goog2faRequired ? this.form.value.goog2fa_code : null
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        token => {
          this.showSpinner();
          this.authenticatedUserService
            .userByToken()
            .pipe(untilDestroyed(this))
            .subscribe(
              authUser => {
                this.authenticatedUserService.resetStates();
                if (this.form.value.rememberPassword) {
                  this.passwordService.setPassword(CryptoUtil.bcryptHash(this.form.value.password));
                }

                if (
                  authUser.seed_public_address &&
                  !this.authenticatedUserQuery.clientWallet &&
                  authUser.has_client_key
                ) {
                  this.toasterService.openSnackBar(
                    'No wallet detected on your device!  Forwarding to wallet for recovery.',
                    'Client-Wallet Problem'
                  );
                  this.router.navigate(['/account', 'wallet']);
                } else if (authUser.wallet_restore_required) {
                  this.toasterService.openSnackBar(
                    'Your password was reset and requires that you recover your wallet!  Forwarding to wallet for recovery.',
                    'Recover Wallet'
                  );
                  this.router.navigate(['/account', 'wallet']);
                } else if (!authUser.seed_public_address && authUser.user_type_id > 1) {
                  this.toasterService.openSnackBar(
                    'No wallet detected on your device!  Forwarding to wallet page.',
                    'Wallet Problem'
                  );
                  this.router.navigate(['/account', 'wallet']);
                } else if (authUser.user_type_id < 2) {
                  this.router.navigate(['/account', 'user']);
                } else if (this.returnUrl !== null) {
                  this.router.navigateByUrl(this.returnUrl);
                } else if (authUser.user_type_id > 2 || this.router.url === '/login') {
                  this.router.navigate(['/']);
                }
                this.hidePage.emit();
                // remember password if checkbox is clicked
                if (this.form.value.rememberPassword) {
                  this.passwordService.setPassword(CryptoUtil.bcryptHash(this.form.value.password));
                }

                // to close if inside dialog
                this.close.emit();
              },
              error => {
                //
              }
            );
        },
        error => {
          this.loginClick = false;
          this.progressService.hideSpinner(this.progress);
          if (
            (error && typeof error.error === 'object' && error.error['error'] === 'invalid_credentials') ||
            error.error['message'] === 'The user credentials were incorrect.'
          ) {
            this.toasterService.openSnackBar('The user credentials were incorrect.', 'none', 'error', 3000);
          }
          if (error && typeof error.error === 'object' && error.error['error'] === 'goog2fa_required') {
            this.goog2faRequired = true;
            this.renderer.addClass(this.forgotPasswordElem?.nativeElement, 'hidden');
            this.renderer.addClass(this.registerElem?.nativeElement, 'hidden');

            setTimeout(() => {
              this.goog2faCode1Elem?.nativeElement.focus();
            }, 500);
          }

          if (error && typeof error.error === 'object' && error.error['error'] === 'invalid_goog2fa') {
            this.renderer.addClass(this.goog2faAuth?.nativeElement, 'error');
          }
        }
      );
  }

  check2faCode(val) {
    if (val.length === 6) {
      this.login();
    }
  }

  pasteCode(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.renderer.setProperty(this.goog2faCode1Elem?.nativeElement, 'value', pastedText.substr(0, 1));
    this.renderer.setProperty(this.goog2faCode2Elem?.nativeElement, 'value', pastedText.substr(1, 1));
    this.renderer.setProperty(this.goog2faCode3Elem?.nativeElement, 'value', pastedText.substr(2, 1));
    this.renderer.setProperty(this.goog2faCode4Elem?.nativeElement, 'value', pastedText.substr(3, 1));
    this.renderer.setProperty(this.goog2faCode5Elem?.nativeElement, 'value', pastedText.substr(4, 1));
    this.renderer.setProperty(this.goog2faCode6Elem?.nativeElement, 'value', pastedText.substr(5, 1));
    this.form.value.goog2fa_code1 = pastedText.substr(0, 1);
    this.form.value.goog2fa_code2 = pastedText.substr(1, 1);
    this.form.value.goog2fa_code3 = pastedText.substr(2, 1);
    this.form.value.goog2fa_code4 = pastedText.substr(3, 1);
    this.form.value.goog2fa_code5 = pastedText.substr(4, 1);
    this.form.value.goog2fa_code6 = pastedText.substr(5, 1);
    this.form.value.goog2fa_code =
      ' ' +
      this.form.value.goog2fa_code1 +
      this.form.value.goog2fa_code2 +
      this.form.value.goog2fa_code3 +
      this.form.value.goog2fa_code4 +
      this.form.value.goog2fa_code5 +
      this.form.value.goog2fa_code6;

    this.form.value.goog2fa_code = this.form.value.goog2fa_code.trim();
    this.login();
  }

  isNumberKey(evt) {
    const charCode = evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  keyDigit1DownFunction(event, val) {
    if (val.length === 1) {
      this.goog2faCode2Elem?.nativeElement.focus();
    }
  }

  keyDigit2DownFunction(event, val) {
    if (event.keyCode === 8) {
      this.renderer.setProperty(this.goog2faCode1Elem?.nativeElement, 'value', '');
      this.goog2faCode1Elem?.nativeElement.focus();
    }

    if (val.length === 1) {
      this.goog2faCode3Elem?.nativeElement.focus();
    }
  }

  keyDigit3DownFunction(event, val) {
    if (event.keyCode === 8) {
      this.renderer.setProperty(this.goog2faCode2Elem?.nativeElement, 'value', '');
      this.goog2faCode2Elem?.nativeElement.focus();
    }
    if (val.length === 1) {
      this.goog2faCode4Elem?.nativeElement.focus();
    }
  }

  keyDigit4DownFunction(event, val) {
    if (event.keyCode === 8) {
      this.renderer.setProperty(this.goog2faCode3Elem?.nativeElement, 'value', '');
      this.goog2faCode3Elem?.nativeElement.focus();
    }
    if (val.length === 1) {
      this.goog2faCode5Elem?.nativeElement.focus();
    }
  }

  keyDigit5DownFunction(event, val) {
    if (event.keyCode === 8) {
      this.renderer.setProperty(this.goog2faCode4Elem?.nativeElement, 'value', '');
      this.goog2faCode4Elem?.nativeElement.focus();
    }
    if (val.length === 1) {
      this.goog2faCode6Elem?.nativeElement.focus();
    }
  }

  keyDigit6DownFunction(event, val) {
    if (event.keyCode === 8) {
      this.renderer.setProperty(this.goog2faCode5Elem?.nativeElement, 'value', '');
      this.goog2faCode5Elem?.nativeElement.focus();
    }
    if (val.length === 1) {
      this.form.value.goog2fa_code =
        ' ' +
        this.form.value.goog2fa_code1 +
        this.form.value.goog2fa_code2 +
        this.form.value.goog2fa_code3 +
        this.form.value.goog2fa_code4 +
        this.form.value.goog2fa_code5 +
        this.form.value.goog2fa_code6;
      this.form.value.goog2fa_code = this.form.value.goog2fa_code.trim();
      this.login();
    }
  }

  keyDownFunction(event) {
    if (this.form.valid) {
      if (event.keyCode === 13) {
        this.login();
      }
    }
  }

  showSpinner() {
    this.showProgress.emit();
  }

  hideSpinner() {
    this.hideProgress.emit();
  }

  ngOnDestroy() {
    this.toasterService.closeSnackBar();
    this.hidden = false;
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.loginInput.nativeElement.focus();
    // }, 0);
  }
}
