import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-snackbar',
  templateUrl: './link-snackbar.component.html',
  styleUrls: ['./link-snackbar.component.scss'],
})
export class LinkSnackbarComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<LinkSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }

  onRedirect() {
    this.router.navigate(['/' + this.data?.link], { queryParams: { type: this.data?.linkType } });
  }
}
