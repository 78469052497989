import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  // Allow decimal numbers. The \. is only allowed once to occur
  private regex: RegExp = new RegExp(/^\d+.?\d{0,8}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const nativeEl = this.el.nativeElement;
    const current: string = nativeEl.value;
    let next: string;
    if (current.length > 0 && nativeEl.selectionStart === 0 && nativeEl.selectionEnd === current.length) {
      // Full selection, overwrite when all selected
      next = event.key;
    } else {
      next = current.concat(event.key);
    }
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
