import { Component, Input, OnInit } from '@angular/core';
import { Quote } from '../../../../app.datatypes';
import { ApiService } from '../../../services';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css'],
})
export class QuoteComponent implements OnInit {
  @Input() quote: Quote;
  isSeen = false;

  constructor(private api: ApiService) {}

  ngOnInit(): void {}

  close() {
    this.readQuote().subscribe(res => {
      this.isSeen = !this.isSeen;
    });
  }

  readQuote() {
    return this.api.get('api/quote-read');
  }
}
