import { Component, Input, OnInit } from '@angular/core';
import { LikeService } from '../../../services/like.service';
import { AuthenticatedUserState } from '../../../state';
import { environment } from '../../../../../environments';

@Component({
  selector: 'app-user-likes',
  templateUrl: './user-likes.component.html',
  styleUrls: ['./user-likes.component.css'],
})
export class UserLikesComponent implements OnInit {
  @Input() user: AuthenticatedUserState;
  likes = [];
  isMoreAvailable: boolean;
  skip = 0;

  constructor(private likeService: LikeService) {}

  ngOnInit(): void {
    this.loadLikes();
  }

  loadLikes(): void {
    this.likeService.getUserLikes(this.skip).subscribe(likes => {
      this.likes = this.likes.concat(likes);
      likes.length >= environment.grid_skip_limit ? (this.isMoreAvailable = true) : (this.isMoreAvailable = false);
      this.skip += likes.length;
    });
  }
}
