export const environment_common = {
  production: false,
  staging: false,
  local: true,
  build_id: null, // set during prod/staging build time
  echo_server_url: 'https://ws-staging.decentralized.pictures',
  echo_server_key: 'b640f3c6abc484e9ced0999d99f3f745',
  echo_enabled: true,
  largo_ai_enabled: true,
  mining_enabled: false,
  buy_enabled: false,
  url: 'https://staging.api.dcp.spruceid.xyz/',
  client_id: '5ae07cda48d3e9211c00478d',
  client_secret: 'zaMwdRanryWWZPYsmchqAbeFqGiG7nMhnZQt7pDY',
  contract_address: '0xac8838b77076846a4d1cd92f10cba488ee2f3825',
  explorer_url: 'https://explorer-staging.decentralized.pictures/',
  max_pdf_doc_size: 5, // currently in MB
  max_image_size: 2,
  max_images_allowed: 25,
  max_docs_allowed: 15,
  grid_skip_limit: 50, // skip limit for infinite scrolling
  chat_url: 'https://chat-staging.decentralized.pictures:8448/',
  chat_invite_url: 'https://discord.gg/ugMzZpeYxK',
  discord_url: 'https://discord.com/channels/362368707684597761/',
  profile_placeholder: 'https://www.gravatar.com/avatar/0.43227576652898625',
  vimeo_client_key:
    'FidtwZTcMqi79w+HnSZ73Odz0Udyms7RMZdDj5FMkVKRFoM7hxeX7r2UiBhwJM5S+6rpfngJLhhyDzHUeTxDb5SFf5dh3XpWEHs2B7XOEQVhwtiXyVgjU8BSIV4M96Zi',
  vimeo_client_id: '0bd733e4f7a078c3ecd3e1d5d128c5d3952d1140',
  vimeo_token: '564bb19d0836cffc97c16416c5edd88a',
  youtube_token: 'AIzaSyB_QSVzjqjgxmMp_uiI_fpPrwNQwNjgGJs',
  chart_colors: ['#00C851', '#16ABFF', '#9E00FF', '#FF6363', '#01F2D5', '#FFA500', '#FF1493', '#563BFD'],
  idle_timeout_seconds: 30 * 60,
  idle_warning_seconds: 60,
  limited_proposal_functionality: false,
  limited_cq_functionality: false,
  limited_quiz_functionality: false,
  limited_reputation_functionality: false,
  limited_moderation_functionality: false,
};
