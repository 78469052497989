import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';
import Plyr from 'plyr';
import { SharedService } from '../../services';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() slide: any;
  @ViewChild(PlyrComponent, { static: false })
  plyr: PlyrComponent;
  player: Plyr;
  options: Plyr.Options = {
    blankVideo: '/assets/videos/blank.mp4',
  };

  constructor(private sharedService: SharedService) {
    this.sharedService.getOnSliderPauseVideo().subscribe(data => {
      this.pause();
    });
  }

  ngOnInit(): void {}

  played(event: Plyr.PlyrEvent) {}

  play(): void {
    this.player.play();
  }

  pause(): void {
    this.player.pause();
  }
}
