import {untilDestroyed} from "ngx-take-until-destroy";

export const chartColors = {
  region: {
    Asia: '#ccc600',
    Europe: '#1451e7',
    Africa: '#000000',
    Americas: '#b43744',
    Oceania: '#298843',
  },
  age: {
    age15_24: '#298843',
    age25_54: '#ccc600',
    over_55: '#b43744',
  },
  gender: {
    male: '#3b55fd',
    female: '#ff1493',
    other: '#018e28'
  },
  na: '#949297',

  lightThemeCaption: '#000000',
  darkThemeCaption: '#ffffff'
};
