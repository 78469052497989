import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import {
  User,
  UserRole,
  UserVerification,
  Google2FaResponse,
  LaravelPassportAuthResponse,
  UserCreateRequest,
  UserRoleRequest,
  GlobalStatisticResponse,
  UserAccessLog,
  UserVerificationStatus,
} from '../../app.datatypes';
import { environment } from '../../../environments';
import { CryptoUtil } from '../util/crypto-util';
import { AuthenticatedUserState, AuthenticatedUserStore } from '../state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private client_id = environment.client_id;
  private client_secret = environment.client_secret;

  constructor(private apiService: ApiService, private authStore: AuthenticatedUserStore) {}

  getUsers(skip = 0, search = null): Observable<User[]> {
    return this.apiService.get('api/users' + '?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : ''));
  }

  getTopUsers(limit: number): Observable<User[]> {
    const url = `api/scoreboards/top-users?limit=${limit}`;
    return this.apiService.get(url);
  }

  getUserTypes(): Observable<UserRole[]> {
    return this.apiService.get('api/user-roles');
  }

  setUserRole(userId: string, userRole: UserRoleRequest): Observable<User> {
    return this.apiService.put('api/user/' + userId + '/role', userRole);
  }

  getUserVerificationStatus(): Observable<UserVerificationStatus[]> {
    return this.apiService.get('api/user-verification-statuses');
  }

  putReputation(id: string, reputation: number): Observable<User> {
    const payload = { reputation: reputation };
    return this.apiService.put('api/users/' + id + '/reputation', payload);
  }

  postRequestUserAttributeChange(attribute, data) {
    const copyData = { ...data };
    if (copyData.password) {
      copyData.password = CryptoUtil.bcryptHash(data.password);
    }
    return this.apiService.post('api/request-change/' + attribute, copyData);
  }

  postInviteUser(data) {
    return this.apiService.post('api/invite-user', data);
  }

  postInviteUsers(data) {
    return this.apiService.post('api/invite-user/upload-invite-list', data);
  }

  postApplyUserAttributeChange(attribute, data) {
    const copyData = { ...data };
    if (attribute === 'password') {
      copyData.password = CryptoUtil.bcryptHash(data.password);
      copyData.password_confirmation = CryptoUtil.bcryptHash(data.password_confirmation);
    }
    return this.apiService.post('api/apply-change/' + attribute, copyData).pipe(tap(res => this.authStore.update(res)));
  }

  getLoggedInUser(): Observable<User> {
    return this.apiService.get('api/auth').pipe(
      tap(user => {
        return user;
      })
    );
  }

  getUserVerifications(): Observable<Array<UserVerification>> {
    return this.apiService.get('api/verify');
  }

  createNewUserVerification(): Observable<AuthenticatedUserState> {
    return this.apiService.get('api/verify/create').pipe(tap(res => this.authStore.update(res)));
  }

  postSetUserSettings(settingName, settingValue): Observable<any> {
    return this.apiService
      .post('api/profile/settings', { settingName: settingName, settingValue: settingValue })
      .pipe(tap(res => this.authStore.update(res)))
      .pipe(catchError(error => this.apiService.catchError(error)));
  }

  getUserAdminVerifications(
    sort: string,
    search: string,
    createdAt: Date,
    direction: string,
    limit: number,
    skip: number
  ): Observable<UserAccessLog[]> {
    const date = createdAt ? `${createdAt.getFullYear()}-${createdAt.getMonth() + 1}-${createdAt.getDate()}` : '';
    const searchField = search ? `user_name=${search}` : '';
    const url = `api/user-verification-log?direction=${direction}&field=${sort}&created_at=${date}&limit=${limit}&skip=${skip}&${searchField}`;
    return this.apiService.get(url);
  }

  enableGoogle2FA(): Observable<Google2FaResponse> {
    return this.apiService.get('api/goog2fa');
  }

  confirmEmailVerification(token: string): Observable<any> {
    return this.apiService.get('api/verify/email?t=' + token);
  }

  sendSMSMobileVerification(mobile): Observable<any> {
    return this.apiService.post('api/verify/sms', { mobile }).pipe(tap(res => this.authStore.update(res)));
  }

  confirmSMSMobileVerification(code): Observable<any> {
    return this.apiService.post('api/verify/sms/confirm', { code });
  }

  confirmEnableGoogle2FA(code): Observable<any> {
    return this.apiService.post('api/goog2fa', { code: code });
  }

  postOauthToken(username: string, password: string, code: string): Observable<LaravelPassportAuthResponse> {
    const request = {
      grant_type: 'password',
      username: username,
      password: password,
      client_id: this.client_id,
      client_secret: this.client_secret,
    };

    if (code) {
      request['code'] = code;
    }

    // @todo move to app service
    return this.apiService.post('oauth/token', request);
  }

  postCheckUserUpdateToken(attribute, token: string) {
    return this.apiService
      .post('api/check-token/' + attribute + '?token=' + token, [])
      .pipe(catchError(error => this.apiService.catchError(error)));
  }

  postUsers(user: UserCreateRequest): Observable<User> {
    return this.apiService.post('api/users', user);
  }

  performVerifySearch(profile, phone): Observable<any> {
    return this.apiService.post('api/verify/search', { profile, phone });
  }

  resendUserEmailVerification(): Observable<any> {
    return this.apiService.put('api/verify/email', null);
  }

  getSearchJob(searchJobId) {
    return this.apiService.post('api/verify/searchResult', {
      // ???
      identity_search_job: searchJobId,
    });
  }

  assessSearch(identitySearchId, phone) {
    // ???
    return this.apiService
      .post('api/verify/assess', {
        identity_search: identitySearchId,
        phone,
      })
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            // On 404, do not use catchError
            // Handle failed assessment -- verification failure
            return throwError(error);
          }
          return this.apiService.catchError(error);
        })
      );
  }

  getOnfidoJWT(applicantId) {
    // ???
    return this.apiService.post('api/verify/onjwt', {
      applicant_id: applicantId,
    });
  }

  createCheck(applicantId, $shouldUpdateApplicant) {
    // ???
    return this.apiService.post('api/verify/check', {
      applicant_id: applicantId,
      update_applicant: $shouldUpdateApplicant === true,
    });
  }

  disableGoogle2FA(): Observable<any> {
    return this.apiService.delete('api/goog2fa');
  }

  getGlobalStatistics(): Observable<GlobalStatisticResponse> {
    return this.apiService.get('api/global-statistics');
  }
}
