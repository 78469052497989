import { Component } from '@angular/core';

@Component({
  selector: 'app-like-confirm-dialog',
  templateUrl: './like-confirm-dialog.component.html',
  styleUrls: ['./like-confirm-dialog.component.scss'],
})
export class LikeConfirmDialogComponent {
  constructor() {}
}
