import { Component, OnInit } from '@angular/core';

import { SEOService } from '../../../shared';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private route: ActivatedRoute, private _seoService: SEOService, private router: Router) {}

  ngOnInit() {
    this._seoService.createTitleForPage();
  }
}
