import { Component, Input, OnInit } from '@angular/core';
import { Poll } from '../../../../app.datatypes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, ToasterService } from '../../../services';
import { announceMsg } from '../../../../app.animations';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
  animations: [announceMsg],
})
export class PollComponent implements OnInit {
  @Input() poll: Poll;
  isExpanded = false;
  isSeen = false;
  pollForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private toaster: ToasterService) {}

  ngOnInit(): void {
    if (this.poll) {
      this.buildForm();
    }
  }

  buildForm() {
    const formData = {
      poll_id: [this.poll._id],
      questions: [
        this.poll.questions.map(question => {
          return {
            _id: question._id,
            title: question.title,
            answers: [],
          };
        }),
      ],
    };

    this.poll.questions.forEach(question => {
      formData[`answer_${question._id}`] = [null, Validators.required];
    });

    this.pollForm = this.formBuilder.group(formData);
  }

  expand() {
    this.isExpanded = !this.isExpanded;
  }

  close() {
    this.isSeen = !this.isSeen;
  }

  selectedAnswer(questionId, questionType, data) {
    const formData = this.pollForm.value;
    const questionIndex = formData.questions.findIndex(q => q._id === questionId);
    const questionElem = formData.questions[questionIndex];

    // Handle multiple and single answer poll
    if (questionType === 'single') {
      data = [data];
    }
    questionElem.answers = data;
    formData.questions[questionIndex] = questionElem;
    this.pollForm.get(`answer_${questionId}`).setValue(data);
  }

  answerPoll() {
    if (this.pollForm.valid) {
      this.sendPollAnswer(this.pollForm.value).subscribe(
        () => {
          this.close();
          this.toaster.openSnackBar('Thank you for your reply!', 'Poll answer');
        },
        () => {
          this.toaster.openErrorSnackBar('Something went wrong. Please try again.', 'Poll answer');
        }
      );
    }
  }

  private sendPollAnswer(data) {
    return this.api.post('api/poll-answer', data);
  }
}
