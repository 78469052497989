import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

import {
  Price,
  SubmitTransactionRequest,
  TransferToMainnetRequest,
  Payment,
  PricingFees,
  CircleCard,
  SellRequest,
  SubmitSellRequest,
} from '../../app.datatypes';
import { EncryptedValue } from '../util/circle';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private apiService: ApiService) {}

  getPrice(): Observable<Price> {
    return this.apiService.get('api/payments/price');
  }

  getUserTransactions(id = '', type = 'in', skip = 0, limit = 10, search = ''): Observable<any[]> {
    return this.apiService.get(
      'api/transactions/user-transactions/' +
        type +
        '/' +
        id +
        '?skip=' +
        skip +
        '&limit=' +
        limit +
        (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  getUserTransactionsIn(id = '', skip = 0, limit = 10, search = ''): Observable<any[]> {
    return this.apiService.get(
      'api/transactions/user-transactions/in/' +
        id +
        '?skip=' +
        skip +
        '&limit=' +
        limit +
        (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  getUserTransactionsOut(id = '', skip = 0, limit = 10, search = ''): Observable<any[]> {
    return this.apiService.get(
      'api/transactions/user-transactions/out/' +
        id +
        '&skip=' +
        skip +
        '&limit=' +
        limit +
        (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  saveUserWallet(publicAddress: string, password: string, seed?: string): Observable<any> {
    return this.apiService.post('api/wallet', { password, seed, publicAddress });
  }

  saveBlockchainMining(miningAddress: string, miningEnabled: boolean, password: string): Observable<any> {
    return this.apiService.post('api/wallet/mining', { password, miningEnabled, miningAddress });
  }

  getBlockchainMining(): Observable<any> {
    return this.apiService.get('api/wallet/mining');
  }

  postTransactions(transaction: any) {
    // ???
    return this.apiService.post('api/transactions', transaction);
  }

  submitTransaction(req: SubmitTransactionRequest): Observable<any> {
    // ???
    return this.apiService.post('api/transactions/' + req.transactionId + '/submit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  postTransferToMainNet(req: TransferToMainnetRequest) {
    // ???
    return this.apiService.post('api/transactions/main/to', req);
  }

  getFromMainNetWallet() {
    // ???
    return this.apiService.get('api/wallet/main');
  }

  getPayments(): Observable<Payment[]> {
    return this.apiService.get('api/payments');
  }

  getSellPayments(): Observable<Payment[]> {
    return this.apiService.get('api/payments/sell');
  }

  getPayment(id): Observable<Payment> {
    return this.apiService.get(`api/payments/${id}`);
  }

  postPayment(payment: any) {
    return this.apiService.post('api/payments', payment);
  }

  cancelPayment(payment: Payment) {
    return this.apiService.get(`api/payments/${payment._id}/cancel`);
  }

  getFees(): Observable<PricingFees> {
    return this.apiService.get('api/fees');
  }

  /**
   * Circle credit card related
   */
  getCirclePublicKey(): Observable<any> {
    return this.apiService.get('api/payments/circle/public');
  }

  createCard(encrypted: EncryptedValue, expMonth, expYear, billingDetails, ccEmail, ccPhone) {
    return this.apiService.post('api/payments/circle/cards', {
      key_id: encrypted.keyId,
      encrypted_data: encrypted.encryptedData,
      billing_details: billingDetails,
      exp_month: expMonth,
      exp_year: expYear,
      email: ccEmail,
      phone_number: ccPhone,
    });
  }

  getCircleCards(): Observable<CircleCard[]> {
    return this.apiService.get('api/payments/circle/cards');
  }

  /**
   * Sell Tokens
   */
  sell(req: SellRequest) {
    return this.apiService.post('api/payments/sell', req);
  }

  submitSell(req: SubmitSellRequest) {
    return this.apiService.post(`api/payments/sell/${req.paymentId}/submit`, {
      password: req.password,
      nonce: req.nonce,
      sig: req.signature,
    });
  }
}
