import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-support-snack-bar',
  templateUrl: './contact-support-snack-bar.component.html',
  styleUrls: ['./contact-support-snack-bar.component.scss'],
})
export class ContactSupportSnackBarComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit() {}
}
