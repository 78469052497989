import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { YouTubeService } from './you-tube.service';
import { VimeoService } from './vimeo.service';

@Injectable()
export class EmbedVideoService {
  constructor(private youTube: YouTubeService, private vimeo: VimeoService) {}

  isValidURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  ParseURL(url) {
    const parser = document.createElement('a');
    let queries, split, i;
    const searchObject = {};
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
      split = queries[i].split('=');
      searchObject[split[0]] = split[1];
    }
    return {
      protocol: parser.protocol,
      host: parser.host,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      searchObject: searchObject,
      hash: parser.hash,
    };
  }

  public embed(url: string, options?: any): any {
    if (this.detectYoutube(url)) {
      return this.youTube.embed(url);
    }
    if (this.detectVimeo(url)) {
      return this.vimeo.embed(url);
    }
    return false;
  }

  public isVideoEmbeddable(url: string) {
    if (this.detectYoutube(url)) {
      return this.youTube.isEmbeddable(url);
    }
    if (this.detectVimeo(url)) {
      return this.vimeo.isEmbeddable(url);
    }
    return new Observable();
  }

  public getThumbnailByURL(url): Promise<string> {
    if (this.youTube.urlValidator(url)) {
      return new Promise(resolve => {
        resolve(this.youTube.getThumbnailUrl(url));
      });
    }
    if (this.vimeo.urlValidator(url)) {
      return this.vimeo
        .getThumbnailUrl(url)
        .pipe(map(res => res.pictures.sizes[3].link))
        .toPromise();
    }
  }

  public detectVimeo(url: string): boolean {
    return this.vimeo.urlValidator(url);
  }

  public detectYoutube(url: string): boolean {
    return this.youTube.urlValidator(url);
  }

  public getVideoIdByUrl(url: string): string {
    if (this.youTube.urlValidator(url)) {
      return this.youTube.getVideoId(url);
    }
    if (this.vimeo.urlValidator(url)) {
      return this.vimeo.getVideoId(url);
    }
    return '';
  }

  // @todo  TESTing Code  for valdating image file ..
  public async isValidImage(file: File) {
    const promise = new Promise((resolve, reject) => {
      const imgElement = new Image();
      imgElement.onload = () => {
        resolve(true);
      };

      imgElement.onerror = () => {
        reject(false);
      };

      if (file) {
        imgElement.src = URL.createObjectURL(file);
      } else {
        reject(false);
      }
    });
    return await promise;
  }
}
