import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationQuery } from '../state/authentication';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable()
export class UnAuthGuardService implements CanActivate {
  constructor(private authQuery: AuthenticationQuery) {}

  canActivate(): Observable<boolean> {
    return this.authQuery.isLoggedIn$.pipe(
      map(isAuth => {
        if (isAuth) {
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
