import { Injectable } from '@angular/core';
import { Country } from '../interface/country.interface';

@Injectable()
export class CountryService {
  /* countries: Country[]; */
  countries: any;

  constructor() {
    this.countries = {
      az: {
        name: '',
        dialCode: '+994',
        countryCode: 'az',
      },
      bs: {
        name: '',
        dialCode: '+1242',
        countryCode: 'bs',
      },
      bh: {
        name: '',
        dialCode: '+973',
        countryCode: 'bh',
      },
      bd: {
        name: '',
        dialCode: '+880',
        countryCode: 'bd',
      },
      bb: {
        name: '',
        dialCode: '+1246',
        countryCode: 'bb',
      },
      by: {
        name: '',
        dialCode: '+375',
        countryCode: 'by',
      },
      be: {
        name: '',
        dialCode: '+32',
        countryCode: 'be',
      },
      bz: {
        name: '',
        dialCode: '+501',
        countryCode: 'bz',
      },
      bj: {
        name: '',
        dialCode: '+229',
        countryCode: 'bj',
      },
      bm: {
        name: '',
        dialCode: '+1441',
        countryCode: 'bm',
      },
      bt: {
        name: '',
        dialCode: '+975',
        countryCode: 'bt',
      },
      bo: {
        name: '',
        dialCode: '+591',
        countryCode: 'bo',
      },
      ba: {
        name: '',
        dialCode: '+387',
        countryCode: 'ba',
      },
      bw: {
        name: '',
        dialCode: '+267',
        countryCode: 'bw',
      },
      br: {
        name: '',
        dialCode: '+55',
        countryCode: 'br',
      },
      io: {
        name: '',
        dialCode: '+246',
        countryCode: 'io',
      },
      vg: {
        name: '',
        dialCode: '+1284',
        countryCode: 'vg',
      },
      bn: {
        name: '',
        dialCode: '+673',
        countryCode: 'bn',
      },
      bg: {
        name: '',
        dialCode: '+359',
        countryCode: 'bg',
      },
      bf: {
        name: '',
        dialCode: '+226',
        countryCode: 'bf',
      },
      bi: {
        name: '',
        dialCode: '+257',
        countryCode: 'bi',
      },
      kh: {
        name: '',
        dialCode: '+855',
        countryCode: 'kh',
      },
      cm: {
        name: '',
        dialCode: '+237',
        countryCode: 'cm',
      },
      ca: {
        name: '',
        dialCode: '+1',
        countryCode: 'ca',
      },
      cv: {
        name: '',
        dialCode: '+238',
        countryCode: 'cv',
      },
      bq: {
        name: '',
        dialCode: '+599',
        countryCode: 'bq',
      },
      ky: {
        name: '',
        dialCode: '+1345',
        countryCode: 'ky',
      },
      cf: {
        name: '',
        dialCode: '+236',
        countryCode: 'cf',
      },
      td: {
        name: '',
        dialCode: '+235',
        countryCode: 'td',
      },
      cl: {
        name: '',
        dialCode: '+56',
        countryCode: 'cl',
      },
      cn: {
        name: '',
        dialCode: '+86',
        countryCode: 'cn',
      },
      cx: {
        name: '',
        dialCode: '+61',
        countryCode: 'cx',
      },
      co: {
        name: '',
        dialCode: '+57',
        countryCode: 'co',
      },
      km: {
        name: '',
        dialCode: '+269',
        countryCode: 'km',
      },
      cd: {
        name: '',
        dialCode: '+243',
        countryCode: 'cd',
      },
      cg: {
        name: '',
        dialCode: '+242',
        countryCode: 'cg',
      },
      ck: {
        name: '',
        dialCode: '+682',
        countryCode: 'ck',
      },
      cr: {
        name: '',
        dialCode: '+506',
        countryCode: 'cr',
      },
      ci: {
        name: '',
        dialCode: '+225',
        countryCode: 'ci',
      },
      hr: {
        name: '',
        dialCode: '+385',
        countryCode: 'hr',
      },
      cu: {
        name: '',
        dialCode: '+53',
        countryCode: 'cu',
      },
      cw: {
        name: '',
        dialCode: '+599',
        countryCode: 'cw',
      },
      cy: {
        name: '',
        dialCode: '+357',
        countryCode: 'cy',
      },
      cz: {
        name: '',
        dialCode: '+420',
        countryCode: 'cz',
      },
      dk: {
        name: '',
        dialCode: '+45',
        countryCode: 'dk',
      },
      dj: {
        name: '',
        dialCode: '+253',
        countryCode: 'dj',
      },
      dm: {
        name: '',
        dialCode: '+1767',
        countryCode: 'dm',
      },
      do: {
        name: '',
        dialCode: '+1',
        countryCode: 'do',
      },
      ec: {
        name: '',
        dialCode: '+593',
        countryCode: 'ec',
      },
      eg: {
        name: '',
        dialCode: '+20',
        countryCode: 'eg',
      },
      sv: {
        name: '',
        dialCode: '+503',
        countryCode: 'sv',
      },
      gq: {
        name: '',
        dialCode: '+240',
        countryCode: 'gq',
      },
      er: {
        name: '',
        dialCode: '+291',
        countryCode: 'er',
      },
      ee: {
        name: '',
        dialCode: '+372',
        countryCode: 'ee',
      },
      et: {
        name: '',
        dialCode: '+251',
        countryCode: 'et',
      },
      fk: {
        name: '',
        dialCode: '+500',
        countryCode: 'fk',
      },
      fo: {
        name: '',
        dialCode: '+298',
        countryCode: 'fo',
      },
      fj: {
        name: '',
        dialCode: '+679',
        countryCode: 'fj',
      },
      fi: {
        name: '',
        dialCode: '+358',
        countryCode: 'fi',
      },
      fr: {
        name: '',
        dialCode: '+33',
        countryCode: 'fr',
      },
      gf: {
        name: '',
        dialCode: '+594',
        countryCode: 'gf',
      },
      pf: {
        name: '',
        dialCode: '+689',
        countryCode: 'pf',
      },
      ga: {
        name: '',
        dialCode: '+241',
        countryCode: 'ga',
      },
      gm: {
        name: '',
        dialCode: '+220',
        countryCode: 'gm',
      },
      ge: {
        name: '',
        dialCode: '+995',
        countryCode: 'ge',
      },
      de: {
        name: '',
        dialCode: '+49',
        countryCode: 'de',
      },
      gh: {
        name: '',
        dialCode: '+233',
        countryCode: 'gh',
      },
      gi: {
        name: '',
        dialCode: '+350',
        countryCode: 'gi',
      },
      gr: {
        name: '',
        dialCode: '+30',
        countryCode: 'gr',
      },
      gl: {
        name: '',
        dialCode: '+299',
        countryCode: 'gl',
      },
      gd: {
        name: '',
        dialCode: '+1473',
        countryCode: 'gd',
      },
      gp: {
        name: '',
        dialCode: '+590',
        countryCode: 'gp',
      },
      gu: {
        name: '',
        dialCode: '+1671',
        countryCode: 'gu',
      },
      gt: {
        name: '',
        dialCode: '+502',
        countryCode: 'gt',
      },
      gg: {
        name: '',
        dialCode: '+44',
        countryCode: 'gg',
      },
      gn: {
        name: '',
        dialCode: '+224',
        countryCode: 'gn',
      },
      gw: {
        name: '',
        dialCode: '+245',
        countryCode: 'gw',
      },
      gy: {
        name: '',
        dialCode: '+592',
        countryCode: 'gy',
      },
      ht: {
        name: '',
        dialCode: '+509',
        countryCode: 'ht',
      },
      hn: {
        name: '',
        dialCode: '+504',
        countryCode: 'hn',
      },
      hk: {
        name: '',
        dialCode: '+852',
        countryCode: 'hk',
      },
      hu: {
        name: '',
        dialCode: '+36',
        countryCode: 'hu',
      },
      is: {
        name: '',
        dialCode: '+354',
        countryCode: 'is',
      },
      in: {
        name: '',
        dialCode: '+91',
        countryCode: 'in',
      },
      id: {
        name: '',
        dialCode: '+62',
        countryCode: 'id',
      },
      ir: {
        name: '',
        dialCode: '+98',
        countryCode: 'ir',
      },
      iq: {
        name: '',
        dialCode: '+964',
        countryCode: 'iq',
      },
      ie: {
        name: '',
        dialCode: '+353',
        countryCode: 'ie',
      },
      im: {
        name: '',
        dialCode: '+44',
        countryCode: 'im',
      },
      il: {
        name: '',
        dialCode: '+972',
        countryCode: 'il',
      },
      it: {
        name: '',
        dialCode: '+39',
        countryCode: 'it',
      },
      jm: {
        name: '',
        dialCode: '+1876',
        countryCode: 'jm',
      },
      jp: {
        name: '',
        dialCode: '+81',
        countryCode: 'jp',
      },
      je: {
        name: '',
        dialCode: '+44',
        countryCode: 'je',
      },
      jo: {
        name: '',
        dialCode: '+962',
        countryCode: 'jo',
      },
      kz: {
        name: '',
        dialCode: '+7',
        countryCode: 'kz',
      },
      ke: {
        name: '',
        dialCode: '+254',
        countryCode: 'ke',
      },
      ki: {
        name: '',
        dialCode: '+686',
        countryCode: 'ki',
      },
      xk: {
        name: '',
        dialCode: '+383',
        countryCode: 'xk',
      },
      kw: {
        name: '',
        dialCode: '+965',
        countryCode: 'kw',
      },
      kg: {
        name: '',
        dialCode: '+996',
        countryCode: 'kg',
      },
      la: {
        name: '',
        dialCode: '+856',
        countryCode: 'la',
      },
      lv: {
        name: '',
        dialCode: '+371',
        countryCode: 'lv',
      },
      lb: {
        name: '',
        dialCode: '+961',
        countryCode: 'lb',
      },
      ls: {
        name: '',
        dialCode: '+266',
        countryCode: 'ls',
      },
      lr: {
        name: '',
        dialCode: '+231',
        countryCode: 'lr',
      },
      ly: {
        name: '',
        dialCode: '+218',
        countryCode: 'ly',
      },
      li: {
        name: '',
        dialCode: '+423',
        countryCode: 'li',
      },
      lt: {
        name: '',
        dialCode: '+370',
        countryCode: 'lt',
      },
      lu: {
        name: '',
        dialCode: '+352',
        countryCode: 'lu',
      },
      mo: {
        name: '',
        dialCode: '+853',
        countryCode: 'mo',
      },
      mk: {
        name: '',
        dialCode: '+389',
        countryCode: 'mk',
      },
      mg: {
        name: '',
        dialCode: '+261',
        countryCode: 'mg',
      },
      mw: {
        name: '',
        dialCode: '+265',
        countryCode: 'mw',
      },
      my: {
        name: '',
        dialCode: '+60',
        countryCode: 'my',
      },
      mv: {
        name: '',
        dialCode: '+960',
        countryCode: 'mv',
      },
      ml: {
        name: '',
        dialCode: '+223',
        countryCode: 'ml',
      },
      mt: {
        name: '',
        dialCode: '+356',
        countryCode: 'mt',
      },
      mh: {
        name: '',
        dialCode: '+692',
        countryCode: 'mh',
      },
      mq: {
        name: '',
        dialCode: '+596',
        countryCode: 'mq',
      },
      mr: {
        name: '',
        dialCode: '+222',
        countryCode: 'mr',
      },
      mu: {
        name: '',
        dialCode: '+230',
        countryCode: 'mu',
      },
      yt: {
        name: '',
        dialCode: '+262',
        countryCode: 'yt',
      },
      mx: {
        name: '',
        dialCode: '+52',
        countryCode: 'mx',
      },
      fm: {
        name: '',
        dialCode: '+691',
        countryCode: 'fm',
      },
      md: {
        name: '',
        dialCode: '+373',
        countryCode: 'md',
      },
      mc: {
        name: '',
        dialCode: '+377',
        countryCode: 'mc',
      },
      mn: {
        name: '',
        dialCode: '+976',
        countryCode: 'mn',
      },
      me: {
        name: '',
        dialCode: '+382',
        countryCode: 'me',
      },
      ms: {
        name: '',
        dialCode: '+1664',
        countryCode: 'ms',
      },
      ma: {
        name: '',
        dialCode: '+212',
        countryCode: 'ma',
      },
      mz: {
        name: '',
        dialCode: '+258',
        countryCode: 'mz',
      },
      mm: {
        name: '',
        dialCode: '+95',
        countryCode: 'mm',
      },
      na: {
        name: '',
        dialCode: '+264',
        countryCode: 'na',
      },
      nr: {
        name: '',
        dialCode: '+674',
        countryCode: 'nr',
      },
      np: {
        name: '',
        dialCode: '+977',
        countryCode: 'np',
      },
      nl: {
        name: '',
        dialCode: '+31',
        countryCode: 'nl',
      },
      nc: {
        name: '',
        dialCode: '+687',
        countryCode: 'nc',
      },
      nz: {
        name: '',
        dialCode: '+64',
        countryCode: 'nz',
      },
      ni: {
        name: '',
        dialCode: '+505',
        countryCode: 'ni',
      },
      ne: {
        name: '',
        dialCode: '+227',
        countryCode: 'ne',
      },
      ng: {
        name: '',
        dialCode: '+234',
        countryCode: 'ng',
      },
      nu: {
        name: '',
        dialCode: '+683',
        countryCode: 'nu',
      },
      nf: {
        name: '',
        dialCode: '+672',
        countryCode: 'nf',
      },
      kp: {
        name: '',
        dialCode: '+850',
        countryCode: 'kp',
      },
      mp: {
        name: '',
        dialCode: '+1670',
        countryCode: 'mp',
      },
      no: {
        name: '',
        dialCode: '+47',
        countryCode: 'no',
      },
      om: {
        name: '',
        dialCode: '+968',
        countryCode: 'om',
      },
      pk: {
        name: '',
        dialCode: '+92',
        countryCode: 'pk',
      },
      pw: {
        name: '',
        dialCode: '+680',
        countryCode: 'pw',
      },
      ps: {
        name: '',
        dialCode: '+970',
        countryCode: 'ps',
      },
      pa: {
        name: '',
        dialCode: '+507',
        countryCode: 'pa',
      },
      pg: {
        name: '',
        dialCode: '+675',
        countryCode: 'pg',
      },
      py: {
        name: '',
        dialCode: '+595',
        countryCode: 'py',
      },
      pe: {
        name: '',
        dialCode: '+51',
        countryCode: 'pe',
      },
      ph: {
        name: '',
        dialCode: '+63',
        countryCode: 'ph',
      },
      pl: {
        name: '',
        dialCode: '+48',
        countryCode: 'pl',
      },
      pt: {
        name: '',
        dialCode: '+351',
        countryCode: 'pt',
      },
      pr: {
        name: '',
        dialCode: '+1',
        countryCode: 'pr',
      },
      qa: {
        name: '',
        dialCode: '+974',
        countryCode: 'qa',
      },
      re: {
        name: '',
        dialCode: '+262',
        countryCode: 're',
      },
      ro: {
        name: '',
        dialCode: '+40',
        countryCode: 'ro',
      },
      ru: {
        name: '',
        dialCode: '+7',
        countryCode: 'ru',
      },
      rw: {
        name: '',
        dialCode: '+250',
        countryCode: 'rw',
      },
      bl: {
        name: '',
        dialCode: '+590',
        countryCode: 'bl',
      },
      sh: {
        name: '',
        dialCode: '+290',
        countryCode: 'sh',
      },
      kn: {
        name: '',
        dialCode: '+1869',
        countryCode: 'kn',
      },
      lc: {
        name: '',
        dialCode: '+1758',
        countryCode: 'lc',
      },
      pm: {
        name: '',
        dialCode: '+508',
        countryCode: 'pm',
      },
      vc: {
        name: '',
        dialCode: '+1784',
        countryCode: 'vc',
      },
      ws: {
        name: '',
        dialCode: '+685',
        countryCode: 'ws',
      },
      sm: {
        name: '',
        dialCode: '+378',
        countryCode: 'sm',
      },
      st: {
        name: '',
        dialCode: '+239',
        countryCode: 'st',
      },
      sa: {
        name: '',
        dialCode: '+966',
        countryCode: 'sa',
      },
      sn: {
        name: '',
        dialCode: '+221',
        countryCode: 'sn',
      },
      rs: {
        name: '',
        dialCode: '+381',
        countryCode: 'rs',
      },
      sc: {
        name: '',
        dialCode: '+248',
        countryCode: 'sc',
      },
      sl: {
        name: '',
        dialCode: '+232',
        countryCode: 'sl',
      },
      sg: {
        name: '',
        dialCode: '+65',
        countryCode: 'sg',
      },
      sx: {
        name: '',
        dialCode: '+1721',
        countryCode: 'sx',
      },
      sk: {
        name: '',
        dialCode: '+421',
        countryCode: 'sk',
      },
      si: {
        name: '',
        dialCode: '+386',
        countryCode: 'si',
      },
      sb: {
        name: '',
        dialCode: '+677',
        countryCode: 'sb',
      },
      so: {
        name: '',
        dialCode: '+252',
        countryCode: 'so',
      },
      za: {
        name: '',
        dialCode: '+27',
        countryCode: 'za',
      },
      kr: {
        name: '',
        dialCode: '+82',
        countryCode: 'kr',
      },
      ss: {
        name: '',
        dialCode: '+211',
        countryCode: 'ss',
      },
      es: {
        name: '',
        dialCode: '+34',
        countryCode: 'es',
      },
      lk: {
        name: '',
        dialCode: '+94',
        countryCode: 'lk',
      },
      sd: {
        name: '',
        dialCode: '+249',
        countryCode: 'sd',
      },
      sr: {
        name: '',
        dialCode: '+597',
        countryCode: 'sr',
      },
      sj: {
        name: '',
        dialCode: '+47',
        countryCode: 'sj',
      },
      sz: {
        name: '',
        dialCode: '+268',
        countryCode: 'sz',
      },
      se: {
        name: '',
        dialCode: '+46',
        countryCode: 'se',
      },
      ch: {
        name: '',
        dialCode: '+41',
        countryCode: 'ch',
      },
      sy: {
        name: '',
        dialCode: '+963',
        countryCode: 'sy',
      },
      tw: {
        name: '',
        dialCode: '+886',
        countryCode: 'tw',
      },
      tj: {
        name: '',
        dialCode: '+992',
        countryCode: 'tj',
      },
      tz: {
        name: '',
        dialCode: '+255',
        countryCode: 'tz',
      },
      th: {
        name: '',
        dialCode: '+66',
        countryCode: 'th',
      },
      tl: {
        name: '',
        dialCode: '+670',
        countryCode: 'tl',
      },
      tg: {
        name: '',
        dialCode: '+228',
        countryCode: 'tg',
      },
      tk: {
        name: '',
        dialCode: '+690',
        countryCode: 'tk',
      },
      to: {
        name: '',
        dialCode: '+676',
        countryCode: 'to',
      },
      tt: {
        name: '',
        dialCode: '+1868',
        countryCode: 'tt',
      },
      tn: {
        name: '',
        dialCode: '+216',
        countryCode: 'tn',
      },
      tr: {
        name: '',
        dialCode: '+90',
        countryCode: 'tr',
      },
      tm: {
        name: '',
        dialCode: '+993',
        countryCode: 'tm',
      },
      tc: {
        name: '',
        dialCode: '+1649',
        countryCode: 'tc',
      },
      tv: {
        name: '',
        dialCode: '+688',
        countryCode: 'tv',
      },
      vi: {
        name: '',
        dialCode: '+1340',
        countryCode: 'vi',
      },
      ug: {
        name: '',
        dialCode: '+256',
        countryCode: 'ug',
      },
      ua: {
        name: '',
        dialCode: '+380',
        countryCode: 'ua',
      },
      ae: {
        name: '',
        dialCode: '+971',
        countryCode: 'ae',
      },
      gb: {
        name: '',
        dialCode: '+44',
        countryCode: 'gb',
      },
      us: {
        name: '',
        dialCode: '+1',
        countryCode: 'us',
      },
      uy: {
        name: '',
        dialCode: '+598',
        countryCode: 'uy',
      },
      uz: {
        name: '',
        dialCode: '+998',
        countryCode: 'uz',
      },
      vu: {
        name: '',
        dialCode: '+678',
        countryCode: 'vu',
      },
      va: {
        name: '',
        dialCode: '+39',
        countryCode: 'va',
      },
      ve: {
        name: '',
        dialCode: '+58',
        countryCode: 've',
      },
      vn: {
        name: '',
        dialCode: '+84',
        countryCode: 'vn',
      },
      wf: {
        name: '',
        dialCode: '+681',
        countryCode: 'wf',
      },
      eh: {
        name: '',
        dialCode: '+212',
        countryCode: 'eh',
      },
      ye: {
        name: '',
        dialCode: '+967',
        countryCode: 'ye',
      },
      zm: {
        name: '',
        dialCode: '+260',
        countryCode: 'zm',
      },
      zw: {
        name: '',
        dialCode: '+263',
        countryCode: 'zw',
      },
      ax: {
        name: '',
        dialCode: '+358',
        countryCode: 'ax',
      },
    };
  }

  getCountries(): any[] {
    return this.countries;
  }
}
