import { Injectable } from '@angular/core';
import { ProposalStore, ProposalState } from './proposal.store';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ReviewQuery } from '../review/review.query';
import { Order } from '@datorama/akita';
import { combineQueries } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class ProposalQuery extends QueryEntity<ProposalState> {
  selectFilters$ = this.select('filters');
  selectModerationFilters$ = this.select('moderationFilters');
  showFilter$ = this.select(state => state.ui.showFilters);

  // skips
  selectSkip$ = this.select('skip');
  selectModerationProposalSkip$ = this.select('moderationProposalSkip');
  selectUserProposalSkip$ = this.select('userProposalSkip');

  // Scroll
  selectScroll$ = this.select('scroll');
  selectModerationProposalScroll$ = this.select('moderationProposalScroll');
  selectUserProposalScroll$ = this.select('userProposalScroll');

  // Loaded status
  selectLoaded$ = this.select('loaded');
  selectModerationProposalLoaded$ = this.select('moderationProposalLoaded');
  selectUserProposalLoaded$ = this.select('userProposalLoaded');

  // Active
  selectModerationProposalActive$ = this.select('moderationProposalActive');
  selectUserProposalActive$ = this.select('userProposalActive');

  reviews$ = this.select(state => state.reviews);
  proposalDetails$ = this.select(state => state.proposalDetails);

  get filters() {
    return this.getValue().filters;
  }

  get moderationFilters() {
    return this.getValue().moderationFilters;
  }

  constructor(protected store: ProposalStore, private reviewQuery: ReviewQuery) {
    super(store);
  }

  selectActiveProposal() {
    return combineQueries([this.selectActive(), this.reviewQuery.selectAll()]).pipe(
      map(([proposal, rev]) => {
        return proposal
          ? {
              ...proposal,
              reviews: rev.filter(review => review.proposal_id === proposal._id),
            }
          : null;
      })
    );
  }

  // Select
  getAllReviewProposal() {
    return this.selectAll({
      sortBy: (a, b) => {
        switch (this.getValue().filters.sortBy) {
          case 'latest':
            return new Date(a.created_at) < new Date(b.created_at) ? 1 : -1;
          case 'popularity':
            return a.votes_score < b.votes_score ? 1 : -1;
          case 'rating':
            return a.score < b.score ? 1 : -1;
          case 'bounty':
            return a.balance < b.balance ? 1 : -1;
        }
      },
      sortByOrder: Order.DESC,
      filterBy: entity => {
        let returnValue = entity.status === 'approved';
        returnValue = returnValue && !entity.is_finalized;
        if (this.getValue().filters.genreFilter.length > 0 && this.getValue().filters.genreFilter[0] !== 'all') {
          returnValue =
            returnValue && entity.genres.some(genre => this.getValue().filters.genreFilter.includes(genre.slug));
        }
        if (
          this.getValue().filters.proposalRoundFilter.length > 0 &&
          this.getValue().filters.proposalRoundFilter[0] !== 'all'
        ) {
          returnValue = returnValue && this.getValue().filters.proposalRoundFilter.includes(entity.proposal_round_id);
        }
        // Search Filter
        if (this.getValue().filters.search && this.getValue().filters.search !== '') {
          returnValue =
            returnValue &&
            (entity.summary.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.content.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1);
        }
        return returnValue;
      },
    });
  }

  getAllModerationProposal(userId) {
    return this.selectAll({
      filterBy: entity => {
        let returnValue = entity.status === 'to-be-moderated' && entity.user_id !== userId;

        if (this.getValue().moderationFilters.unlockedOnly) {
          returnValue = returnValue && !entity.locked_by;
        }
        return returnValue;
      },
      limitTo: this.getValue().moderationProposalSkip,
    });
  }

  getAllUserProposal(userId, limit = this.getValue().userProposalSkip) {
    return this.selectAll({
      filterBy: entity => entity.user_id === userId,
      limitTo: limit,
    });
  }

  // GET Value
  getAllReviewProposalValue() {
    return this.getAll({
      filterBy: entity => {
        let returnValue = entity.status === 'approved';
        if (this.getValue().filters.genreFilter.length > 0 && this.getValue().filters.genreFilter[0] !== 'all') {
          returnValue =
            returnValue && entity.genres.some(genre => this.getValue().filters.genreFilter.includes(genre.slug));
        }
        if (
          this.getValue().filters.proposalRoundFilter.length > 0 &&
          this.getValue().filters.proposalRoundFilter[0] !== 'all'
        ) {
          returnValue = returnValue && this.getValue().filters.proposalRoundFilter.includes(entity.proposal_round_id);
        }
        // Search Filter
        if (this.getValue().filters.search && this.getValue().filters.search !== '') {
          returnValue =
            returnValue &&
            (entity.summary.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.content.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1);
        }
        return returnValue;
      },
      sortBy: (a, b) => {
        switch (this.getValue().filters.sortBy) {
          case 'latest':
            return new Date(a.created_at) < new Date(b.created_at) ? 1 : 0;
        }
      },
      limitTo: this.getValue().skip,
    });
  }

  getAllModerationProposalValue() {
    return this.getAll({
      filterBy: entity => entity.status === 'to-be-moderated',
    });
  }

  getAllUserProposalValue(userId) {
    return this.getAll({ filterBy: entity => entity.user_id === userId });
  }

  getAllUserAndReviewProposalIds() {
    return this.getAll({ filterBy: entity => entity.status !== 'to-be-moderated' }).map(proposal => proposal._id);
  }

  getNext(id, allValues) {
    let currentIndex = 0;
    allValues.filter((obj, index) => {
      if (obj._id === id) {
        currentIndex = index;
        return obj;
      }
    });

    if (currentIndex + 1 < allValues.length) {
      return allValues[currentIndex + 1]._id;
    } else {
      return null;
    }
  }

  getPrevious(id, allValues) {
    let currentIndex = 0;
    allValues.filter((obj, index) => {
      if (obj._id === id) {
        currentIndex = index;
        return obj;
      }
    });

    if (currentIndex - 1 >= 0) {
      return allValues[currentIndex - 1]._id;
    } else {
      return null;
    }
  }

  getNextReviewProposalValue(id) {
    return this.getNext(id, this.getAllReviewProposalValue());
  }

  getPreviousReviewProposalValue(id) {
    return this.getPrevious(id, this.getAllReviewProposalValue());
  }

  getNextModerationProposalValue(id) {
    return this.getNext(id, this.getAllModerationProposalValue());
  }

  getPreviousModerationProposalValue(id) {
    return this.getPrevious(id, this.getAllModerationProposalValue());
  }

  getNextUserProposalValue(id, userId) {
    return this.getNext(id, this.getAllUserProposalValue(userId));
  }

  getPreviousUserProposalValue(id, userId) {
    return this.getPrevious(id, this.getAllUserProposalValue(userId));
  }

  getActiveIndex() {
    return this.getAll().indexOf(this.getActive());
  }

  isLast() {
    return this.getAll().indexOf(this.getActive()) === this.getAll().length - 1;
  }

  /**
   *
   * @param id : Proposal Id
   */
  getReviews(id) {
    return this.selectEntity(id, proposal => proposal.reviews);
  }

  getReviewScroll(id) {
    return this.selectEntity(id, proposal => proposal.reviews_scroll);
  }

  getReviewSkip(id) {
    return this.selectEntity(id, proposal => proposal.reviews_skip);
  }
}
