import { ProposalRoundStore } from './proposal-round.store';
import { ProposalRoundQuery } from './proposal-round.query';
import { ProposalRound } from '../../../app.datatypes';
import { Injectable } from '@angular/core';
import { ApiService } from '../../services';
import { Observable } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProposalRoundStateService {
  constructor(
    private proposalRoundStore: ProposalRoundStore,
    private proposalRoundQuery: ProposalRoundQuery,
    private apiService: ApiService
  ) {}

  getAll(): Observable<ProposalRound[]> {
    this.proposalRoundStore.setLoading(true);

    let api =
      'api/proposal-rounds?limit=' + environment.grid_skip_limit + '&skip=' + this.proposalRoundQuery.getValue().skip;

    if (this.proposalRoundQuery.getValue().filters.search) {
      api += '&search=' + encodeURIComponent(this.proposalRoundQuery.getValue().filters.search);
    }

    return this.apiService.get(api).pipe(
      tap(users => {
        if (this.proposalRoundQuery.getValue().skip === 0) {
          this.proposalRoundStore.set(users);
        } else {
          this.proposalRoundStore.add(users);
        }
        this.proposalRoundStore.setLoading(false);
        if (users.length < environment.grid_skip_limit) {
          this.proposalRoundStore.update({ apiEndReached: true });
        }
      }),
      catchError(error => {
        this.proposalRoundStore.setError(error);
        this.proposalRoundStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateSkip() {
    const skipItm = this.proposalRoundQuery.getValue().skip + environment.grid_skip_limit;
    this.proposalRoundStore.update({ skip: skipItm });
  }

  updateFilters(filters) {
    this.proposalRoundStore.update({ filters, skip: 0, apiEndReached: false });
  }

  invalidateCache() {
    this.proposalRoundStore.setHasCache(false);
    this.proposalRoundStore.setActive(null);
  }

  getProposalRound(id): Observable<ProposalRound> {
    return this.proposalRoundQuery.selectEntity(id).pipe(
      switchMap(proposalRound => {
        if (!proposalRound) {
          return this.apiService.get('api/proposal-rounds/' + id);
        } else {
          return this.proposalRoundQuery.selectEntity(id);
        }
      }),
      catchError(error => {
        return this.apiService.catchError(error);
      })
    );
  }
}
