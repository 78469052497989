import { Component, Input, OnInit } from '@angular/core';
import { Announcement } from '../../../../app.datatypes';
import { ApiService } from '../../../services';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
  @Input() announcement: Announcement;
  isSeen = false;
  isExpanded = false;
  constructor(private api: ApiService) {}

  ngOnInit(): void {}

  close() {
    this.readAnnouncement().subscribe(() => {
      this.isSeen = !this.isSeen;
    });
  }

  expand() {
    if ((this.announcement?.cover && this.announcement?.cover?.url) || this.announcement?.description) {
      this.isExpanded = !this.isExpanded;
    }
  }

  readAnnouncement() {
    return this.api.post('api/announcement-read', {
      announcement_id: this.announcement._id,
      announcement_type: this.announcement.type,
    });
  }
}
