import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  ElementRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { ThemeService, ProgressService } from '../../services';
import { CreativeQuery } from '../../../app.datatypes';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticatedUserQuery, CreativeQueryQuery, CreativeQueryServices } from '../../state';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { listAnimation, slideOpenAnimation } from '../../../app.animations';
@Component({
  selector: 'app-creative-query-teaser-view',
  templateUrl: './creative-query-teaser-view.component.html',
  styleUrls: ['./creative-query-teaser-view.component.scss'],
  host: {
    class: 'w-full min-w-full sm:w-teaser sm:min-w-teaser bg-sidebar h-full flex flex-col overflow-y-auto relative ',
  },
  animations: [listAnimation(), slideOpenAnimation()],
})
export class CreativeQueryTeaserViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() queryId?: string;
  @Input() creativeQuery?: CreativeQuery;

  @Output() close = new EventEmitter();
  @Output() leftNav = new EventEmitter();
  @Output() rightNav = new EventEmitter();
  @Output() loaded = new EventEmitter();
  @Output() moderationAction = new EventEmitter();

  isDarkTheme: boolean;
  // Spinner variables
  progress: any;
  days: number;

  constructor(
    private creativeQueryService: CreativeQueryServices,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public cqQuery: CreativeQueryQuery,
    public appService: AuthenticatedUserQuery,
    public dialog: MatDialog,
    public themeService: ThemeService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.themeService.currentTheme$.subscribe(value => {
      this.isDarkTheme = value;
    });
    // Initialize
    this.cqQuery.selectLoading().subscribe(loading => {
      if (loading) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      } else {
        this.progressService.hideSpinner(this.progress);
      }
    });

    this.days = this.creativeQueryService.calculateDaysRemaining(this.creativeQuery);
  }

  // isTextOverflow(elementId: string): boolean {
  //   const elem: HTMLElement = this.renderer.selectRootElement(`#${elementId}`, true);
  //   if (elem) {
  //     return elem.offsetWidth < elem.scrollWidth;
  //   } else {
  //     return false;
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.queryId) {
      this.creativeQueryService
        .getCreativeQuery(this.queryId)
        .pipe(untilDestroyed(this))
        .subscribe(
          query => {
            this.creativeQuery = query;
            this.loaded.emit(this.queryId);
          },
          error => {}
        );
    } else if (changes.creativeQuery) {
      this.loaded.emit(this.queryId);
    }
  }

  ngOnDestroy() {}
}
