import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wallet-check',
  styleUrls: ['./wallet-check.component.scss'],
  templateUrl: './wallet-check.component.html',
})
export class WalletCheckComponent {
  constructor(
    private router: Router,
    private dialogRef: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<WalletCheckComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  createWallet(event: MouseEvent) {
    this.bottomSheetRef.dismiss({ save: true });
  }

  cancel() {
    this.bottomSheetRef.dismiss();
  }
}
