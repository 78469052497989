import { Component, Input, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-spinner',
  template: `
    <div class="loader-overlay" [ngClass]="{ 'overlay-on': overlay, hidden: hideOnOff, active: this.isSpinnerVisible }">
      <svg
        version="1.1"
        [style.maxWidth]="width"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 150.1 201.9"
        xml:space="preserve"
        class="loader-svg"
      >
        <path
          [style.stroke]="color"
          class="loaderst line1"
          d="M23.7,201.6v-101l0,0.4c0-28.4,23-51.4,51.4-51.4s51.4,23,51.4,51.4"
        ></path>
        <path
          [style.stroke]="color"
          class="loaderst line2"
          d="M126.4,0v101c0,28.4-23,51.4-51.4,51.4s-51.4-23-51.4-51.4"
        ></path>
      </svg>
    </div>
  `,

  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true;
  @Input()
  public backgroundColor = 'rgba(0, 115, 170, 0.69)';
  @Input()
  color = 'black';
  @Input()
  width = '20px';
  @Input()
  hideOnOff = false;
  @Input()
  overlay = false;
  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          // this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
