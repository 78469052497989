import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { heightAnimate } from '../../../app.animations';
import { ApiService } from '../../services';
import { Announcement, Poll, Quote } from '../../../app.datatypes';

@Component({
  selector: 'app-announcements-list',
  templateUrl: './announcements-list.component.html',
  styleUrls: ['./announcements-list.component.scss'],
  animations: [heightAnimate],
})
export class AnnouncementsListComponent implements OnInit {
  quotes: [Quote];
  quotesMedia: any;
  announcements: [Announcement];
  pollsList: [Poll];
  isMessageSeen = false;
  isAnnounceExpanded = false;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.getMessageData();
  }

  getMessageData() {
    this.getQuotes().subscribe((data: any) => {
      this.quotes = data;
    });

    this.getAnnouncement().subscribe((data: any) => {
      this.announcements = data;
    });

    this.getPolls().subscribe((data: any) => {
      this.pollsList = data;
    });
  }

  getQuotes(): Observable<[Quote]> {
    return this.api.get('api/quotes');
  }

  getAnnouncement(): Observable<any> {
    return this.api.get('api/announcements');
  }

  getPolls(): Observable<[Poll]> {
    return this.api.get('api/polls');
  }

  announceExpand() {
    this.isAnnounceExpanded = !this.isAnnounceExpanded;
  }

  closeMessage() {
    this.isMessageSeen = true;
  }
}
