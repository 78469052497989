import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { SharedService } from '../../services';

@Component({
  selector: 'app-dcp-select',
  templateUrl: './dcp-select.component.html',
  styleUrls: ['./dcp-select.component.scss'],
})
export class DcpSelectComponent implements OnInit {
  @Input() id: any;
  @Input() options: any;
  @Input() placeholder: any;
  @Input() selectedDefault: any = '';
  @Input() filterOption = false;
  @Input() disabled = false;
  @Input() optionNameField = 'name';
  @Output() selected = new EventEmitter<any>();

  @ViewChild('dhCompId', { static: false }) dhCompId: ElementRef;
  @ViewChild('ddCompId', { static: false }) ddCompId: ElementRef;
  @ViewChild('bdCompId', { static: false }) bdCompId: ElementRef;
  @ViewChild('filterTextInputCompId', { static: false }) filterTextInputCompId: ElementRef;

  compId: any;
  selectedOption = null;
  isSelectOpen = false;
  filterText = '';

  constructor(private sharedService: SharedService, private renderer: Renderer2) {
    this.sharedService.getResetFilter().subscribe(data => {
      this.selectedOption = null;
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].selected = false;
      }
    });
  }

  ngOnInit() {
    const dateInit = new Date();
    setTimeout(() => {
      this.compId = dateInit.getTime() + '-' + Math.random();
    }, 100);

    if (this.selectedDefault?.toString() !== '') {
      for (let i = 0; i < this.options.length; i++) {
        // tslint:disable-next-line
        if (this.options[i].slug == this.selectedDefault) {
          // do not fix this lint error == to === cose here compare values without compare types - its completely legit.
          // fixing brakes prefill in select
          this.selectedOption = this.options[i];
          this.options[i].selected = true;
          break;
        }
      }
    }
  }

  openClose(evt) {
    evt.stopImmediatePropagation();
    this.isSelectOpen = !this.isSelectOpen;
    const ddWrapper: HTMLElement = this.renderer.selectRootElement('#dcp-dd-wrapper', true);
    if (this.isSelectOpen) {
      const dhId = 'dh-' + this.compId;
      const ddId = 'dd-' + this.compId;
      const bdId = 'bd-' + this.compId;
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;

      setTimeout(() => {
        const bd = this.bdCompId?.nativeElement;
        ddWrapper.append(bd);
      }, 1000);

      const dhTop = dh.getBoundingClientRect().top;
      const dhLeft = dh.getBoundingClientRect().left;
      if (document.getElementsByTagName('body')[0].offsetHeight < dhTop + 330) {
        dd.style['bottom'] = '40px';
        dd.style['top'] = 'inherit';
        dd.style['left'] = dhLeft + 'px';
      } else {
        dd.style['bottom'] = 'inherit';
        dd.style['top'] = dhTop + 40 + 'px';
        dd.style['left'] = dhLeft + 'px';
      }
      ddWrapper.append(dd);
    } else {
      this.selected.emit(this.selectedOption);
      const dhId = 'dh-' + this.compId;
      const bdId = 'bd-' + this.compId;
      const ddId = 'dd-' + this.compId;
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;
      const bd = this.bdCompId?.nativeElement;

      dh.append(dd);
      dh.append(bd);
    }
  }

  openCloseKey() {
    // evt.stopImmediatePropagation();
    this.isSelectOpen = !this.isSelectOpen;
    if (this.isSelectOpen) {
      const dhId = 'dh-' + this.compId;
      const ddId = 'dd-' + this.compId;
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;
      const dhTop = dh.getBoundingClientRect().top;

      if (document.getElementsByTagName('body')[0].offsetHeight < dhTop + 330) {
        dd.style['bottom'] = '40px';
        dd.style['top'] = 'inherit';
      } else {
        dd.style['bottom'] = 'inherit';
        dd.style['top'] = '40px';
      }
    } else {
      this.selected.emit(this.selectedOption);
    }
  }

  select(option, evt) {
    if (option.selected === true) {
      return;
    }
    for (let i = 0; i < this.options.length; i++) {
      this.options[i].selected = false;
    }
    if (option.selected === undefined) {
      option.selected = false;
    }
    option.selected = !option.selected;

    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].selected) {
        this.selectedOption = this.options[i];
      }
    }
    this.openClose(evt);
  }

  filterOptions(evt) {
    evt.stopImmediatePropagation();
    const text = evt.target.value;
    this.filterText = text.toLowerCase();

    if (this.filterText === '') {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].visible = true;
      }
    } else {
      for (let i = 0; i < this.options.length; i++) {
        const caseUnsensitive = this.options[i].name.toLowerCase();
        if (caseUnsensitive.indexOf(this.filterText) !== -1) {
          this.options[i].visible = true;
        } else {
          this.options[i].visible = false;
        }
      }
    }
  }

  clearText() {
    const id = 'filterTextInput-' + this.compId;
    this.renderer.setValue(this.filterTextInputCompId?.nativeElement, '');
    this.filterText = '';
    if (this.filterText === '') {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].visible = true;
      }
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const focusId = document.activeElement.id;
    if (focusId === this.id) {
      if (event.key === 'Enter') {
        this.openCloseKey();

        setTimeout(() => {
          const id = 'dd-' + this.compId;
          const dd = this.ddCompId?.nativeElement;
          const firstListItem = dd.getElementsByClassName('option')[0];
          const FLIid = firstListItem.id;
          const fliDOMEL: HTMLElement = this.renderer.selectRootElement(`#${FLIid}`, true);
          fliDOMEL.focus();
        }, 1000);
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      setTimeout(() => {
        const focusId = document.activeElement.id;
        if (this.renderer.selectRootElement(`#${this.id}`, true) !== null) {
          const ddEle = this.renderer.selectRootElement(`#${this.id}`, true);
          const dd = ddEle.getElementsByClassName('select-dropdown')[0];
          if (focusId !== this.id && dd.classList.contains('open') === true) {
            this.openCloseKey();
          }
        }
      }, 10);
    }
  }
}
