import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-entity-action',
  templateUrl: './sub-entity-action.component.html',
  styleUrls: ['./sub-entity-action.component.css'],
})
export class SubEntityActionComponent implements OnInit {
  @Input() entity;
  @Input() action;
  @Input() parent_entity;
  @Input() parent_entity_title;
  @Input() entityUrl;
  @Input() parentEntityUrl;
  @Input() actor;
  constructor() {}

  ngOnInit() {}
}
