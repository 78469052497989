import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import {
  Quiz,
  QuizRoundWinners,
  QuizResult,
  QuizRoundQuestionResponse,
  QuizRoundQuestionRequest,
} from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  constructor(private apiService: ApiService) {}

  getActiveQuiz(): Observable<Quiz> {
    return this.apiService.get('api/quizzes/active');
  }

  getUserQuizzes(): Observable<Quiz[]> {
    return this.apiService.get('api/quizzes/user-rounds');
  }
  getQuizResult(id: string): Observable<any> {
    //  <QuizResutl> {
    return this.apiService.get('api/quiz-round/' + id + '/results');
  }
  getQuizzes(user): Observable<Quiz[]> {
    return this.apiService.get('api/quizzes').pipe(
      map(quiz => {
        quiz.readyToModerate = user.isAdministrator && quiz.status === 'finished';
        quiz.isArchived = quiz.status === 'archived';
        return quiz;
      })
    );
  }

  getQuizWinners(id: string): Observable<QuizRoundWinners> {
    return this.apiService.get('api/quizzes/' + id + '/winners');
  }

  getAllQuizzes(): Observable<any[]> {
    return this.apiService.get('api/scoreboards/quizzes-list');
  }

  getQuizTopUsers(id: string): Observable<QuizResult[]> {
    const url = `api/scoreboards/quizzes-list/${id}/top-scorers`;
    return this.apiService.get(url);
  }

  getTopQuizzes(): Observable<any[]> {
    return this.apiService.get('api/quizzes/highscores');
  }

  closeQuiz(quizId): Observable<Quiz> {
    return this.apiService.put('api/quizzes/' + quizId + '/close', null);
  }

  postStartQuizQuestion(): Observable<QuizRoundQuestionResponse> {
    return this.apiService.post('api/quizzes/start', []);
  }

  postSubmitQuizQuestion(answers: QuizRoundQuestionRequest): Observable<QuizRoundQuestionResponse> {
    return this.apiService.put('api/quizzes/question/submit', answers);
  }
}
