import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class WindowScrollService {
  private scrollTop$ = new Subject();
  private scrollEndReached$ = new Subject();
  containerHeight$ = new Subject();
  contentHeight;
  maxPosition = 0;

  constructor(private router: Router) {
    this.initCheckingRoutes();
    this.checkEndPosition();
  }

  emitScrollPosition(position: number) {
    this.scrollTop$.next(position);
  }

  resetMaxPosition() {
    this.maxPosition = 0;
  }

  getScrollPosition(): Observable<any> {
    return this.scrollTop$.asObservable();
  }

  getScrollPositionEnd(): Observable<any> {
    return this.scrollEndReached$.asObservable();
  }

  init() {
    this.containerHeight$.pipe(distinctUntilChanged()).subscribe(height => {
      this.contentHeight = height;
    });
  }

  initCheckingRoutes() {
    this.router.events
      .pipe(
        map(() => this.router.url),
        distinctUntilChanged()
      )
      .subscribe(url => this.resetMaxPosition());
  }

  checkEndPosition() {
    this.scrollTop$.asObservable().subscribe((position: number) => {
      this.containerHeight$.pipe(distinctUntilChanged()).subscribe((height: any) => {
        const scrollHeight = height - document.documentElement.clientHeight;
        if (position >= scrollHeight && this.maxPosition < scrollHeight) {
          this.maxPosition = position;

          this.scrollEndReached$.next(this.maxPosition);
        }
      });
    });
  }

  showFooterOnScroll() {
    // if (document.getElementsByClassName('overflow-y-auto')[0] === undefined) {
    //   return;
    // }
    // const elementHeight = document.getElementsByClassName('overflow-y-auto')[0].clientHeight;
    // const scrollableContent = document.getElementsByClassName('scrollable-content')[0].clientHeight;
    // const pos = document.getElementsByClassName('overflow-y-auto')[0].scrollTop;
    // const wrapper = document.querySelector('.wrapper');
    // const footer = document.getElementById('footer');
    // const teaser = document.querySelector('.active-teaser-wrapper');
    // if (pos > scrollableContent - elementHeight - 60) {
    //   footer.classList.add('show');
    //   wrapper.classList.add('show');
    //   if (typeof teaser !== 'undefined' && teaser !== null) {
    //     teaser.classList.add('show');
    //   }
    // } else {
    //   footer.classList.remove('show');
    //   wrapper.classList.remove('show');
    //   if (typeof teaser !== 'undefined' && teaser !== null) {
    //     teaser.classList.remove('show');
    //   }
    // }
  }

  hideFooter() {
    const footer = document.getElementById('footer');
    // footer.classList.remove('show');
  }
}
