import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmbedVideoService } from '../../services';
import { EmbeddableVideoValidator } from '../../validators/EmbeddableVideoValidator';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
})
export class AddVideoComponent implements OnInit {
  addVideoForm: FormGroup;
  video = null;
  @Output() addVideo = new EventEmitter<any>();
  @Output() cancelVideo = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private embedService: EmbedVideoService) {}

  ngOnInit() {
    this.addVideoForm = this.formBuilder.group({
      url: [
        '',
        [
          Validators.required,
          Validators.pattern(
            'https://(?:www.)?(vimeo.com|youtube.com|m.youtube.com|youtu.be)/(?:watch?v=)?(.*?)(?:z|$|&)'
          ),
        ],
        [EmbeddableVideoValidator.createValidator(this.embedService)],
      ],
      mediaTitle: [''],
      description: [''],
    });
  }

  updateVideo(url: string) {
    if (!this.addVideoForm.valid) {
      this.video = null;
      return;
    }
    this.embedService.isVideoEmbeddable(url).subscribe(data => {
      if (data) {
        this.video = this.embedService.embed(url);
      }
    });
  }

  submit() {
    if (this.addVideoForm.valid) {
      this.addVideo.emit(this.addVideoForm.value);
    }
  }

  cancel() {
    this.cancelVideo.emit();
  }
}
