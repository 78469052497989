import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { ProgressService, CreativeQueryService } from '../../../services';
import { AuthenticatedUserQuery, AuthenticatedUserState } from '../../../state';
import { CreativeQueryStatus } from '../../../../app.datatypes';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-user-creative-query',
  templateUrl: './user-creative-query.component.html',
  styleUrls: ['./user-creative-query.component.scss'],
})
export class UserCreativeQueryComponent implements OnInit, OnDestroy {
  @Input() user: AuthenticatedUserState;
  userCreativeQueries: any[];
  authUser: any;
  isMoreAvailable = true;
  creativeQueryStatus = CreativeQueryStatus;

  // scroll Pagination variables
  skip = 0;
  currentScrollPosition = 0;
  selectedTab: number;

  // Spinner variables
  progress: any;
  constructor(
    private creativeQueryService: CreativeQueryService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private authenticatedUserQuery: AuthenticatedUserQuery
  ) {}

  ngOnInit() {
    this.authenticatedUserQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(async user => {
        this.authUser = user;
      });
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.creativeQueryService
      .getUserCreativeQuerys(this.authUser.user_type_id === 4 ? this.user._id : null)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.userCreativeQueries = result;
          this.skip = this.userCreativeQueries.length;

          if (result.length < 15) {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.creativeQueryService
      .getUserCreativeQuerys(this.user.user_type_id === 4 ? this.user._id : null, this.skip)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.userCreativeQueries;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.userCreativeQueries = oldData;
            this.skip = this.skip + result.length;
            if (result.length < 10) {
              this.isMoreAvailable = false;
            }
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadCQPage() {
    this.progressService.showSpinner(this.progress, this.elRef);
  }

  ngOnDestroy() {}
}
