import { Component, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments';
import { AuthenticatedUserQuery } from '../../../shared';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  @Input() loginModalRef: any;
  @Output() closeNav = new EventEmitter();

  liElements = [];
  scrollDelta = 100;
  width = 0;
  showProposal = !environment.limited_proposal_functionality;
  showCq = !environment.limited_cq_functionality;
  showModeration = !environment.limited_moderation_functionality;
  showQuiz = !environment.limited_quiz_functionality;

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  scrollRight(evt) {
    evt.stopPropagation();
    let element = evt.target['parentElement'];
    while (element['id'] !== 'navigation') {
      element = element['parentElement'];
    }
    let scrollLeft = element.scrollLeft;
    scrollLeft += this.scrollDelta;
    element.scrollTo({ left: scrollLeft, top: 0, behavior: 'smooth' });
  }

  scrollLeft(evt) {
    let element = evt.target['parentElement'];
    while (element['id'] !== 'navigation') {
      element = element['parentElement'];
    }
    let scrollLeft = element.scrollLeft;
    scrollLeft -= this.scrollDelta;
    element.scrollTo({ left: scrollLeft, top: 0, behavior: 'smooth' });
  }

  onScroll(event: Event) {
    if (this.width === 0) {
      this.liElements = event.target['children'];
      for (let i = 1; i < this.liElements.length - 1; i++) {
        this.width += this.liElements[i].clientWidth;
      }
    }

    const currentScroll = event.target['clientWidth'] + event.target['scrollLeft'];

    this.liElements = event.target['children'];
    if (this.width === currentScroll) {
      this.liElements[this.liElements.length - 1].classList.add('hide');
    } else {
      this.liElements[this.liElements.length - 1].classList.remove('hide');
    }

    if (event.target['scrollLeft'] === 0) {
      this.liElements[0].classList.add('hide');
    } else {
      this.liElements[0].classList.remove('hide');
    }
  }

  blockClick(event) {
    // if (this.showClose) {
    //   event.stopPropagation();
    // }
    this.closeLeftMenu();
  }

  buyEnabled() {
    return environment.buy_enabled;
  }

  close() {
    // if (this.showClose) {
    //   this.closeNav.emit();
    // }
  }

  closeLeftMenu() {
    const wrapper: HTMLElement = this.renderer.selectRootElement('#app-wrapper', true);
    this.renderer.removeClass(wrapper, 'left-side-open');
  }
}
