import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ReviewService, ProgressService } from '../../../services';
import { AuthenticatedUserQuery, AuthenticatedUserState } from '../../../state';
import { ReviewStatus } from '../../../../app.datatypes';

@Component({
  selector: 'app-user-reviews',
  templateUrl: './user-reviews.component.html',
  styleUrls: ['./user-reviews.component.scss'],
})
export class UserReviewsComponent implements OnInit, OnDestroy {
  userReviews: any[];
  @Input() user: AuthenticatedUserState;
  authUser: any;
  reviewStatus = ReviewStatus;
  isMoreAvailable = true;

  // scroll Pagination variables
  skip = 0;
  currentScrollPosition = 0;
  selectedTab: number;

  // Spinner variables
  progress: any;
  constructor(
    private reviewService: ReviewService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private authenticatedUserQuery: AuthenticatedUserQuery
  ) {}

  ngOnInit() {
    this.authenticatedUserQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(async user => {
        this.authUser = user;
      });
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.reviewService
      .getAuthenticatedUserStateReviews(this.authUser.user_type_id === 4 ? this.user._id : null)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.userReviews = result;
          this.skip = this.userReviews.length;
          if (result.length < 10) {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  loadReviewPage() {
    this.progressService.showSpinner(this.progress, this.elRef);
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.reviewService
      .getAuthenticatedUserStateReviews(this.user.user_type_id === 4 ? this.user._id : null, this.skip)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.userReviews;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }
            this.userReviews = oldData;
            this.skip = this.skip + result.length;
            if (result.length < 20) {
              this.isMoreAvailable = false;
            }
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }
  ngOnDestroy() {}
}
