import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';

/**
 *
 * SERVICE WILL BE USED FOR DEBUGING(REUSABE CODE ) Will be removed from prducion..
 */

@Injectable()
export class Debug {
  constructor() {}

  /**
   *
   * Get all array of all invalid controls of form
   * @param form
   */
  public findInvalidControls(form: FormGroup) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}
