import { Component, OnInit, ElementRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { LoaderService, ToasterService, PasswordValidator, ProgressService } from '../../shared';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';
import { fade, openDown, panelIn } from '../../app.animations';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],

  host: {
    class: 'fixed top-0 left-0 w-full h-full bg-sidebar z-10',
  },
  animations: [openDown, fade, panelIn],
})
export class ResetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  form: FormGroup;
  token: string;
  loading = false;
  charRule = false;
  is_valid = true;
  is_used = false;
  // Spinner variables
  progress: any;

  loadingOverlay = true;
  @Input()
  outerContainer?: boolean;
  @Output()
  showProgress = new EventEmitter();
  @Output()
  hideProgress = new EventEmitter();
  @Output()
  close = new EventEmitter();

  constructor(
    private authService: AuthService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public loader: LoaderService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('token');

    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.authService
      .postCheckUserUpdateToken('password', this.token)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        res => {
          this.is_valid = true;
          this.progressService.hideSpinner(this.progress);
        },
        error => {
          this.is_valid = false;
          this.progressService.hideSpinner(this.progress);
        }
      );

    const password = new FormControl('', [Validators.required, Validators.minLength(8), PasswordValidator.strong]);

    this.form = this.formBuilder.group(
      {
        password,
        password_confirmation: ['', [Validators.required]],
      },
      {
        validators: this.passwordConfirming,
      }
    );
  }

  passwordConfirming(c: AbstractControl): { password_confirmation: boolean } {
    if (c.get('password').value !== c.get('password_confirmation').value) {
      return { password_confirmation: true };
    }
  }

  submit() {
    if (this.form.valid) {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      const data = this.form.value;
      data.token = this.token;
      this.loading = true;
      this.authService
        .postApplyUserAttributeChange('password', data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          res => {
            this.progressService.hideSpinner(this.progress);
            this.loading = false;
            this.toasterService.openSnackBar('Your password has been changed.', 'Success', 'check', 5000);
            this.is_used = true;
            this.router.navigate(['/login']);
          },
          error => {
            this.loading = false;
            this.progressService.hideSpinner(this.progress);

            // server error
            // this.toasterService.openSnackBar(error.message, 'Error');
          }
        );
    }
  }

  showSpinner() {
    if (this.outerContainer) {
      this.showProgress.emit();
    } else {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    }
  }

  hideSpinner() {
    if (this.outerContainer) {
      this.hideProgress.emit();
    } else {
      this.progress = this.progressService.hideSpinner(this.progress);
    }
  }

  keyDownFunction($event) {
    $event.stopPropagation();
    if (this.form.valid) {
      this.submit();
    }
  }

  onStrengthChanged($event) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
