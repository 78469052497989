import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { CreativeQuery } from '../../../app.datatypes';

export class CreativeQueryFilter {
  field = 'latest';
  direction = 'desc';
  search = '';
  status = 'approved';
}

export interface CreativeQueryState extends EntityState<CreativeQuery>, ActiveState {
  ui: {
    showFilters: boolean;
  };
  moderationFilters: {
    unlockedOnly: boolean;
  };
  creativeQuery: CreativeQuery;
  filters: CreativeQueryFilter;
  limit: number;

  // Review Querys  specific
  skip: number;
  scroll: number;
  apiEndReached: boolean;
  loaded: boolean;

  // Moderation Querys  specific
  moderationQuerySkip: number;
  moderationQueryScroll: number;
  moderationQueryActive: string;
  moderationQueryApiEndReached: boolean;
  moderationQueryLoaded: boolean;

  // User Querys specific
  userQuerySkip: number;
  userQueryScroll: number;
  userQueryActive: string;
  userQueryApiEndReached: boolean;
  userQueryLoaded: boolean;
  locked_by: string;
  locked_at: string;
}

export function createInitialState(): CreativeQueryState {
  return {
    ui: {
      showFilters: false,
    },
    moderationFilters: {
      unlockedOnly: true,
    },
    filters: new CreativeQueryFilter(),
    apiEndReached: false,
    skip: 0,
    scroll: 1,
    loaded: false,
    moderationQueryLoaded: false,
    moderationQuerySkip: 0,
    moderationQueryScroll: 1,
    moderationQueryApiEndReached: false,

    userQueryLoaded: false,
    userQuerySkip: 0,
    userQueryScroll: 1,
    userQueryApiEndReached: false,
    // prevent loader showing on state initialization
    loading: false,
  } as CreativeQueryState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'creative-query',
  idKey: '_id',
  resettable: true,
})
export class CreativeQueryStore extends EntityStore<CreativeQueryState, CreativeQuery> {
  constructor() {
    super(createInitialState());
  }
}
