import { Injectable } from '@angular/core';
import { PasswordStore } from './password.store';
import { AuthenticatedUserQuery } from '../authenticated-user';
import { CryptoUtil } from '../../util/crypto-util';
import { AuthenticationQuery } from '../authentication';
import { PasswordDialogComponent } from '../../components/password-dialog/password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  constructor(
    private passStore: PasswordStore,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private authenticationQuery: AuthenticationQuery,
    public dialog: MatDialog
  ) {
    this.authenticationQuery.isLoggedIn$.subscribe(logedIn => {
      if (!logedIn) {
        this.passStore.reset();
        window.sessionStorage.removeItem('password');
      }
    });
  }

  async getPassword() {
    const pass = null;
    if (localStorage.getItem('password')) {
      try {
        return await CryptoUtil.decrypt(localStorage.getItem('password'), this.authenticatedUserQuery.user._id);
      } catch (ex) {
        localStorage.removeItem('password');
      }
    }
    return pass;
  }

  async setPassword(value) {
    // raw -> Qweert123
    // localStorage - bcrypt + encrypt
    // pass to be sent bcrypt = decrupt(localStorage)
    const encrypt = (await CryptoUtil.encrypt(value, this.authenticatedUserQuery.user._id)) as string;
    localStorage.setItem('password', encrypt);
    this.passStore.update({ password: encrypt });
  }

  async removePassword() {
    localStorage.removeItem('password');
    this.passStore.reset();
  }

  async openPasswordDialog(error = null) {
    if (error) {
      await this.removePassword();
    }
    const pass = await this.getPassword();
    if (pass) {
      return of(pass).toPromise();
    }

    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      maxWidth: '550px',
      maxHeight: '550px',
      data: { error },
    });
    return dialogRef
      .afterClosed()
      .pipe(
        filter(result => result !== true),
        tap(async result => {
          if (result && result.rememberPassword) {
            await this.setPassword(result.password);
          }
        }),
        map(result => result.password)
      )
      .toPromise();
  }
}
