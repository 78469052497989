import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilePreviewOverlayService, FilePreviewOverlayRef, EmbedVideoService } from '../../../../shared';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-media-block',
  templateUrl: './media-block.component.html',
  styleUrls: ['./media-block.component.scss'],
})
export class MediaBlockComponent implements OnInit {
  // @todo: add model for mediaData  type
  @Input()
  mediaData: any;

  @Input()
  index: any = 0;

  @Input()
  isCover?: boolean;
  isNft?: boolean;

  @Input() isPreview = false;
  @Input() protected = false;

  @Output()
  remove = new EventEmitter();
  @Output()
  mediaClick = new EventEmitter();
  @Output()
  mediaDataChange = new EventEmitter();
  @Output()
  makeCover = new EventEmitter();
  @Output()
  makeNft = new EventEmitter();
  @Output()
  croppedImageFile = new EventEmitter();

  openCropper = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  constructor(
    private formBuilder: FormBuilder,
    private previewDialog: FilePreviewOverlayService,
    private embedService: EmbedVideoService
  ) {}

  ngOnInit() {
    console.log('data', this.mediaData);
  }

  saveTitle(title) {
    this.mediaData.title = title;
    this.mediaDataChange.emit(this.mediaData);
  }

  saveDescription(description) {
    this.mediaData.description = description;
    this.mediaDataChange.emit(this.mediaData);
  }

  updateVideo(video) {
    this.mediaData.url = video;
    this.mediaData.video = this.embedService.embed(video);

    this.mediaDataChange.emit(this.mediaData);
  }

  updateNft() {
    this.isNft = !this.isNft;
  }

  showPreview() {
    if (this.mediaData.type) {
      const file: any = {};
      if (this.mediaData.type === 'pdf') {
        file.name = this.mediaData.docTitle ? this.mediaData.docTitle.docTitle : 'PDF Document';
        file.url = this.mediaData.url;
        file.type = 'pdf';
        file.description = this.mediaData.description;
        file.title = this.mediaData.title;
      } else if (this.mediaData.type === 'image') {
        file.name = this.mediaData.mediaTitle ? this.mediaData.mediaTitle : 'Image';
        file.url = this.mediaData.url;
        file.type = 'image';
        file.description = this.mediaData.description;
        file.title = this.mediaData.title;
      } else if (this.mediaData.type === 'video') {
        file.name = this.mediaData.mediaTitle ? this.mediaData.mediaTitle : 'Video';
        file.url = this.mediaData.url;
        file.type = 'video';
        file.description = this.mediaData.description;
        file.title = this.mediaData.title;
      }
      const dialogRef: FilePreviewOverlayRef = this.previewDialog.open({
        image: file,
      });
    } else {
      return;
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  imageCroppedReturn(event: ImageCroppedEvent) {
    const covverImg = {
      img: this.croppedImage,
      id: this.mediaData.id,
      index: this.index,
    };
    console.log('coverImg', covverImg);
    this.croppedImageFile.emit(covverImg);
    this.openCropper = false;
  }

  imageCroppedCancel() {
    this.openCropper = false;
  }

  chageCoverImage() {
    this.openCropper = true;
  }
}
