import { Injectable } from '@angular/core';
import { arrayUpdate } from '@datorama/akita';
import { Observable } from 'rxjs';
import { catchError, tap, switchMap, map, first } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { ProposalStore } from './proposal.store';
import { ProposalQuery } from './proposal.query';
import { ReviewStateService } from '../review/review-state.service';
import { ReviewQuery } from '../review/review.query';
import { ReviewStore } from '../review/review.store';
import { combineQueries } from '@datorama/akita';
import {
  Proposal,
  ReviewManipulateRequest,
  Review,
  SubmitLikeRequest,
  ProposalRequest,
  SubmitProposalRequest,
  Genre,
  Language,
} from '../../../app.datatypes';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { AuthenticatedUserService } from '../authenticated-user';

@Injectable({ providedIn: 'root' })
export class ProposalStateService {
  constructor(
    private proposalStore: ProposalStore,
    private proposalQuery: ProposalQuery,
    private apiService: ApiService,
    private reviewStateService: ReviewStateService,
    private reviewQuery: ReviewQuery,
    private reviewStore: ReviewStore,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  getAll(): Observable<Proposal[]> {
    this.proposalStore.setLoading(true);
    let api =
      'api/proposals?&limit=' +
      environment.grid_skip_limit +
      '&direction=desc&skip=' +
      this.proposalQuery.getValue().skip;
    if (this.proposalQuery.getValue().filters.sortBy) {
      api += '&field=' + this.proposalQuery.getValue().filters.sortBy;
    }
    if (this.proposalQuery.getValue().filters.reviewed) {
      api += '&reviewed=' + this.proposalQuery.getValue().filters.reviewed;
    }
    if (this.proposalQuery.getValue().filters.genreFilter) {
      api += '&genres=' + this.proposalQuery.getValue().filters.genreFilter;
    }
    if (this.proposalQuery.getValue().filters.proposalRoundFilter) {
      api += '&proposal_rounds=' + this.proposalQuery.getValue().filters.proposalRoundFilter;
    }
    if (this.proposalQuery.getValue().filters.search) {
      api += '&search=' + encodeURIComponent(this.proposalQuery.getValue().filters.search);
    }

    this.proposalStore.update({ loaded: false });

    return this.apiService.get(api).pipe(
      tap(proposals => {
        applyTransaction(() => {
          this.proposalStore.add(proposals);
          this.proposalStore.update({ skip: this.proposalQuery.getValue().skip + proposals.length });
          this.proposalStore.setLoading(false);
          if (proposals.length < environment.grid_skip_limit) {
            this.proposalStore.update({ apiEndReached: true });
          }

          this.proposalStore.update({ loaded: true });
        });
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        this.proposalStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  getAllUserProposal(
    limit = environment.grid_skip_limit,
    skip = 0,
    direction = 'desc',
    field = 'latest'
  ): Observable<Proposal[]> {
    this.proposalStore.setLoading(true);
    const api =
      'api/user-proposals?' +
      'limit=' +
      limit +
      '&direction=' +
      direction +
      '&field=' +
      field +
      '&skip=' +
      this.proposalQuery.getValue().userProposalSkip;

    this.proposalStore.update({
      userProposalLoaded: false,
    });

    return this.apiService.get(api).pipe(
      tap(proposals => {
        applyTransaction(() => {
          this.proposalStore.add(proposals);
          this.proposalStore.setLoading(false);

          if (proposals.length < environment.grid_skip_limit) {
            this.proposalStore.update({ userProposalApiEndReached: true });
          }
          this.proposalStore.update({
            userProposalLoaded: true,
          });
        });
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }

  getAllModerationProposal(): Observable<Proposal[]> {
    this.proposalStore.setLoading(true);
    const api =
      'api/proposals/moderate?&limit=' +
      environment.grid_skip_limit +
      '&skip=' +
      this.proposalQuery.getValue().moderationProposalSkip +
      (this.proposalQuery.getValue().moderationFilters.unlockedOnly ? '&unlocked=true' : '');
    return this.apiService.get(api).pipe(
      tap(proposals => {
        applyTransaction(() => {
          this.proposalStore.add(proposals);
          this.proposalStore.setLoading(false);

          if (proposals.length < environment.grid_skip_limit) {
            this.proposalStore.update({
              moderationProposalApiEndReached: true,
              moderationProposalSkip: this.proposalQuery.getValue().moderationProposalSkip + proposals.length,
            });
          }

          this.proposalStore.update({ moderationProposalLoaded: true });
        });
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        this.proposalStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  getProposal(id): Observable<Proposal> {
    return this.proposalQuery.selectEntity(id).pipe(
      switchMap(proposal => {
        if (!proposal) {
          return this.apiService.get('api/proposals/' + id).pipe(tap(pro => this.proposalStore.add(pro)));
        } else {
          return this.proposalQuery.selectEntity(id);
        }
      }),
      switchMap(p => {
        return combineQueries([this.proposalQuery.selectEntity(p._id), this.reviewQuery.selectAll()]).pipe(
          map(([pro, rev]) => {
            return pro
              ? {
                  ...pro,
                  reviews: rev.filter(review => review.proposal_id === p._id),
                }
              : null;
          })
        );
      }),
      catchError(error => {
        return this.apiService.catchError(error);
      })
    );
  }

  updateDraftProposal(id): Observable<any> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.get('api/proposals/' + id));
  }

  resetFailed(id): Observable<any> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put('api/proposals/' + id + '/reset', null));
  }

  reviewed(id) {
    this.proposalStore.update(id, { has_reviewed_proposal: true });
  }

  // Active state
  setActive(id) {
    this.proposalStore.setActive(id);
  }

  setUserProposalActive(id) {
    this.proposalStore.update({ userProposalActive: id });
  }

  setModerationProposalActive(id) {
    this.proposalStore.update({ moderationProposalActive: id });
  }

  proposalModerated(proposal) {
    this.proposalStore.update(proposal._id, proposal);
    this.authenticatedUserService.unlock();
  }

  unsetActiveProposal() {
    this.proposalStore.update({ moderationProposalActive: null, locked_at: undefined, locked_by: undefined });
  }

  setOnChangeStatus(id, status, blockchain_confirmed, blockchain_id) {
    this.proposalStore.update(id, { status, blockchain_id, blockchain_confirmed });
  }

  setLockedData(id, locked_by, locked_at) {
    this.proposalStore.update(id, { locked_by, locked_at });
  }

  setScores(id, votes_score, balance, stake_score, score) {
    this.proposalStore.update(id, { votes_score, balance, stake_score, score });
  }

  // Active Next
  setActiveNext() {
    this.proposalStore.setActive({ next: true, wrap: false });
  }

  // Active previous
  setActivePrev() {
    this.proposalStore.setActive({ prev: true, wrap: false });
  }

  updateScroll(scroll: number) {
    this.proposalStore.update({ scroll });
  }
  // Skips
  updateSkip(scroll) {
    this.proposalStore.update({ scroll });
  }

  updateReviewSkip(id, scroll) {
    if (id && this.proposalQuery.getEntity(id) && !this.proposalQuery.getEntity(id).reviews_api_reached) {
      const review_skip = this.proposalQuery.getEntity(id).reviews_skip
        ? this.proposalQuery.getEntity(id).reviews_skip
        : 0;
      this.proposalStore.update(id, entity => ({
        reviews_skip: review_skip + environment.grid_skip_limit,
        reviews_scroll: scroll,
      }));
      this.reviewStateService
        .getProposalReviews(id, review_skip + environment.grid_skip_limit)
        .pipe(first())
        .subscribe();
    }
  }

  updateModerationProposalSkip(moderationProposalScroll) {
    this.proposalStore.update({
      moderationProposalSkip: this.proposalQuery.getValue().moderationProposalSkip + environment.grid_skip_limit,
    });
  }

  updateModerationFilter(filter) {
    applyTransaction(() => {
      this.proposalStore.remove(this.getAllModerationProposal());
      this.proposalStore.update({
        moderationFilters: { unlockedOnly: filter.unlockedOnly },
        moderationProposalSkip: 0,
      });

      this.proposalStore.setLoading(false);
    });
  }

  updateUserProposalSkip(userProposalScroll) {
    this.proposalStore.update({
      userProposalSkip: this.proposalQuery.getValue().userProposalSkip + environment.grid_skip_limit,
      userProposalScroll,
    });
  }

  updateFilters(filters) {
    applyTransaction(() => {
      this.proposalStore.remove(this.proposalQuery.getAllUserAndReviewProposalIds());

      this.proposalStore.update({
        filters,
        skip: 0,
        scroll: 1,
        loaded: false,
        apiEndReached: false,
        userProposalSkip: 0,
        userProposalScroll: 1,
        userProposalApiEndReached: false,
        userProposalLoaded: false,
      });
    });
  }

  updateShowFilters(showFilters: boolean) {
    this.proposalStore.update({ ui: { showFilters } });
  }

  invalidateCache() {
    this.proposalStore.setActive(null);
  }

  postReview(review: ReviewManipulateRequest): Observable<Review> {
    return this.apiService.post('api/reviews/', review).pipe(
      tap(reviewResponse => {
        this.proposalStore.update(review.proposal_id, entity => ({
          has_reviewed_proposal: true,
          reviews: arrayUpdate(entity.reviews, reviewResponse._id, reviewResponse),
        }));
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        this.proposalStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateReview(id, reviewResponse) {
    this.proposalStore.update(id, entity => ({
      votes_score: reviewResponse.totalVotes,
      reviews_score: reviewResponse.reviews_score,
      evaluations_score: reviewResponse.evaluations_score,
      likes_score: reviewResponse.likes_score,
      statistics: { ...entity.statistics, total_votes: reviewResponse.totalVotes },
    }));
    this.reviewStore.upsertMany(reviewResponse.reviews);
  }

  putReview(review: ReviewManipulateRequest): Observable<Review> {
    return this.apiService.put(`api/reviews/${review._id}`, review).pipe(
      tap(() => {
        this.reviewQuery.getReviews(review.proposal_id).subscribe();
      })
    );
  }

  likeReview(reviewId: any, password: string, is_like: boolean): Observable<any> {
    return this.apiService.post(`api/reviews/${reviewId}/like`, { password, is_like }).pipe(
      map(response => {
        return {
          totalVotes: response.newProposalTotalVotes,
          reviews_score: response.newProposalTotalReviews,
          evaluations_score: response.newProposalTotalEvaluations,
          likes_score: response.newProposalTotalLikes,
          reviews: response.reviewsArray,
          sign: response.sign,
        };
      })
    );
  }

  submitLikeReview(req: SubmitLikeRequest): Observable<any> {
    const request = {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
      is_like: req.is_like ? 1 : 0,
    };
    return this.apiService.post(`api/reviews/${req.reviewId}/like/${req.likeId}/submit`, request);
  }

  /**
   * We need to update it on the Front End
   * since update proposal on each Like/Unlike will be redundant
   */
  updateTotalVotes(totalVotes: number) {
    this.proposalStore.updateActive(proposal => {
      return {
        ...proposal,
        votes_score: totalVotes,
        statistics: { ...proposal.statistics, total_votes: totalVotes },
      };
    });
  }

  putExtendProposal(id: string, request: any): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put('api/proposals/' + id + '/extend', request));
  }

  submitExtendProposal(req: any): Observable<any> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(
      this.apiService.post('api/proposals/' + req.proposalId + '/submit-extend', {
        password: req.password,
        sig: req.signature,
        nonce: req.nonce,
      })
    );
  }

  cancelProposal(proposal: Proposal) {
    this.proposalStore.setLoading(true);
    return this.apiService.get(`api/proposals/${proposal._id}/cancel`).pipe(
      tap(canceledProposal => {
        applyTransaction(() => {
          if (proposal.status === 'approved') {
            const skip = this.proposalQuery.getValue().skip - 1;

            if (this.proposalQuery.getActive() && this.proposalQuery.getActive()._id === proposal._id) {
              this.proposalStore.setActive(null);
            }
            this.proposalStore.update({ skip, userProposalApiEndReached: false });
          } else if (proposal.status === 'to-be-moderated') {
            const moderationProposalSkip = this.proposalQuery.getValue().moderationProposalSkip - 1;

            if (this.proposalQuery.getValue().moderationProposalActive === proposal._id) {
              this.proposalStore.update({
                moderationProposalSkip,
                moderationProposalActive: null,
                userProposalApiEndReached: false,
              });
            } else {
              this.proposalStore.update({
                moderationProposalSkip,
                userProposalApiEndReached: false,
              });
            }
          }
          this.proposalStore.update(proposal._id, { status: 'cancelled' });
          this.proposalStore.setLoading(false);
        });
      }),
      catchError(error => {
        this.proposalStore.setLoading(false);
        this.proposalStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }

  removeProposal(proposal: Proposal) {
    this.proposalStore.setLoading(true);
    return this.apiService.delete(`api/proposals/${proposal._id}/remove`).pipe(
      tap(removedProposal => {
        this.proposalStore.update({
          userProposalActive: null,
        });
        this.proposalStore.remove(proposal._id);
        this.proposalStore.setLoading(false);
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        this.proposalStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  prepareProposalForSubmit(request: ProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.post('api/proposals/prepare-for-submit', request));
  }

  lockProposal(id: string): Observable<any> {
    this.proposalStore.setLoading(true);
    const proposal = this._upsertProposalState(this.apiService.put('api/proposals/' + id + '/lock', null));
    this.authenticatedUserService.setModeratedEntity({ entity: 'proposal', id: id });
    return proposal;
  }

  unlockProposal(id: string): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this.apiService.put('api/proposals/' + id + '/unlock', null).pipe(
      tap(proposal => {
        this.authenticatedUserService.unlock();

        proposal.locked_at = undefined;
        proposal.locked_by = undefined;

        this.proposalStore.upsert(proposal._id, proposal);
        this.proposalStore.setLoading(false);
      }),
      catchError(error => {
        this.proposalStore.setLoading(false);
        this.proposalStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }

  prepareProposalForResubmit(id: string, request: ProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put('api/proposals/' + id + '/prepare-for-resubmit', request));
  }

  submitProposal(req: SubmitProposalRequest): Observable<any> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(
      this.apiService.post('api/proposals/' + req.proposalId + '/submit', {
        password: req.password,
        sig: req.signature,
        nonce: req.nonce,
      })
    );
  }

  resubmitProposal(req: SubmitProposalRequest): Observable<any> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(
      this.apiService.post('api/proposals/' + req.proposalId + '/resubmit', {
        password: req.password,
        sig: req.signature,
        nonce: req.nonce,
      })
    );
  }

  saveProposalAsDraft(request: ProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.post('api/proposals/', request));
  }

  updateProposalDraft(id: string, request: ProposalRequest): Observable<Proposal> {
    return this._upsertProposalState(this.apiService.put('api/proposals/' + id, request));
  }

  updateFullProposalState(id: string, proposal) {
    this.proposalStore.update(id, proposal);
  }

  // For comparing filter before update to store
  // @TODO move this to utils
  isEquivalent(a, b) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) {
      return false;
    }
    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  // getContentTypes(): Observable<ContentType[]> {
  //   return this.apiService.get('api/proposals/content-types');
  // }

  getGenres(): Observable<Genre[]> {
    return this.apiService.get('api/movie-genres');
  }

  getLanguages(): Observable<Language[]> {
    return this.apiService.get('api/languages');
  }

  createGenre(req: Genre): Observable<Genre> {
    return this.apiService.post('api/movie-genres', req);
  }

  private _upsertProposalState(observerable: Observable<Proposal>) {
    return observerable.pipe(
      tap(proposal => {
        this.proposalStore.upsert(proposal._id, proposal);
        this.proposalStore.setLoading(false);
      }),
      catchError(error => {
        this.proposalStore.setLoading(false);
        this.proposalStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }
}
