import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Reward } from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class RewardsService {
  constructor(private apiService: ApiService) {}

  getProposalRelatedRewards(proposal_id): Observable<Reward[]> {
    return this.apiService.get('api/proposals/' + proposal_id + '/rewards');
  }

  postRewardCreate(data): Observable<Reward> {
    return this.apiService.post('api/rewards', data);
  }

  putRewardUpdate(reward_id, data): Observable<Reward> {
    return this.apiService.put('api/rewards/' + reward_id, data);
  }

  deleteReward(reward_id): Observable<any> {
    return this.apiService.delete('api/rewards/' + reward_id);
  }
}
