import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiService } from '../../services';

@Component({
  selector: 'app-directors-quote-card',
  templateUrl: './directors-quote-card.component.html',
  styleUrls: ['./directors-quote-card.component.scss'],
})
export class DirectorsQuoteCardComponent implements OnInit {
  public quote;

  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;

  constructor(private api: ApiService) {}

  getQuote(): Observable<any> {
    return this.api.get('api/quotes');
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  ngOnInit() {
    this.quote = this.getQuote().subscribe((data: any) => {
      this.quote = data[0];
    });
  }
}
