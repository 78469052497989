import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationQuery } from '../state/authentication/authentication.query';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LoggedInAuthGuard implements CanActivate {
  constructor(private _router: Router, private authQuery: AuthenticationQuery) {}

  canActivate(): Observable<boolean> {
    return this.authQuery.isLoggedIn$.pipe(
      map(isAuth => {
        if (isAuth) {
          this._router.navigate(['/account']);
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
