import { Injectable } from '@angular/core';
import { AuthenticationStore, AuthenticationState } from './authentication.store';
import { tap, catchError } from 'rxjs/operators';
import { Observable, throwError, timer } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../../environments';
import { resetStores } from '@datorama/akita';
import { Router } from '@angular/router';
import { SocketService } from '../../services/socket.service';
import { AuthData } from '../../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private authStore: AuthenticationStore,
    private apiService: ApiService,
    private router: Router,
    private socketService: SocketService
  ) {}

  refreshJwtTimer: any;

  login(username, password, opt_2faCode = null) {
    const data: AuthData = {
      username,
      password,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      grant_type: 'password',
    };
    if (opt_2faCode) {
      data['code'] = opt_2faCode;
    }
    return this.getJWT(data).pipe(
      tap(authState => {
        const refresh_at = this.calculateJWTRefreshTime(authState.expires_in);
        localStorage.setItem('access_token', authState.access_token);
        localStorage.setItem('refresh_token', authState.refresh_token);
        localStorage.setItem('expires_in', authState.expires_in.toString());
        localStorage.setItem('refresh_at', refresh_at.toString());
        this.startRefreshJWTTimer();
        this.authStore.update(authState);
        if (typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
          this.socketService.connect(authState.access_token).then(socket => {
            socket.joinMandatoryChannels();
          });
        }
        resetStores({ exclude: ['authentication'] });
        this.authStore.setLoading(false);
      }),
      catchError(err => {
        this.authStore.setLoading(false);
        const text = err.error ? err.error.message : err.message;
        this.authStore.setError(text);
        return throwError(err);
      })
    );
  }

  refreshAccessToken() {
    if (!localStorage.getItem('refresh_token')) {
      return;
    }
    const data: AuthData = {
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      grant_type: 'refresh_dcp_token',
      refresh_token: localStorage.getItem('refresh_token'),
    };
    return this.getJWT(data)
      .toPromise()
      .then(authState => {
        const refresh_at = this.calculateJWTRefreshTime(authState.expires_in);
        localStorage.setItem('access_token', authState.access_token);
        localStorage.setItem('refresh_token', authState.refresh_token);
        localStorage.setItem('expires_in', authState.expires_in.toString());
        localStorage.setItem('refresh_at', refresh_at.toString());
        this.startRefreshJWTTimer();
        this.authStore.update(authState);
        if (typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
          this.socketService.connect(authState.access_token).then(socket => {
            socket.joinMandatoryChannels();
          });
        }
      })
      .catch(() => {
        this.clientSideLogOut();
      });
  }

  updateToken() {
    this.authStore.update({
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),
      // https://stackoverflow.com/questions/14667713/how-to-convert-a-string-to-number-in-typescript
      expires_in: +localStorage.getItem('expires_in'),
      refresh_at: new Date(localStorage.getItem('refresh_at')),
    });
  }

  serverSideLogOut() {
    // server logOut
    return this.apiService.post('oauth/logOut', []).toPromise();
  }

  clientSideLogOut() {
    // clear auth data
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_at');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('password');
    this.authStore.reset();
    resetStores();
    // disconnect web-socket
    if (this.socketService && typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
      this.socketService.disconnect();
    }
    this.router.navigate(['/login']);
  }

  logout() {
    this.serverSideLogOut()
      .then(() => {
        this.clientSideLogOut();

        this.refreshJwtTimer.unsubscribe();
      })
      .catch(() => {
        this.clientSideLogOut();
        this.refreshJwtTimer.unsubscribe();
      });
  }

  getJWT(data: AuthData): Observable<AuthenticationState> {
    return this.apiService.post('oauth/token', data);
  }

  calculateJWTRefreshTime(expires_in) {
    const secondsBeforeExpireToRefresh = expires_in > 60 ? 60 : Math.round(expires_in / 2);
    const now = new Date();
    return new Date(now.setSeconds(now.getSeconds() + expires_in - secondsBeforeExpireToRefresh));
  }

  startRefreshJWTTimer() {
    if ((this.refreshJwtTimer && !this.refreshJwtTimer.isStopped) || !localStorage.getItem('refresh_at')) {
      return;
    }
    const refreshAt = new Date(localStorage.getItem('refresh_at'));
    const milliSecondsToRefresh = refreshAt.getTime() - new Date().getTime();
    if (milliSecondsToRefresh < 0) {
      return;
    }
    this.refreshJwtTimer = timer(milliSecondsToRefresh).subscribe(() => {
      this.refreshAccessToken();
    });
  }
}
