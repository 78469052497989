import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { DcpModalService } from './dcp-modal.service';

@Component({
  selector: 'app-dcp-modal',
  templateUrl: 'dcp-modal.component.html',
  styleUrls: ['dcp-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DcpModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;

  constructor(private modalService: DcpModalService, private el: ElementRef, private renderer: Renderer2) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    this.renderer.appendChild(document.body, this.element);

    // close modal on background click
    this.element.addEventListener('click', el => {
      if (el.target.className === 'dcp-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    this.renderer.addClass(document.body, 'dcp-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    this.renderer.removeClass(document.body, 'dcp-modal-open');
  }
}
