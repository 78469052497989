import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FileProgressObject } from '../../../../app.datatypes';
import { ApiService, ProposalService } from '../../../../shared';
import { of, merge, Observable } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss'],
})
export class UploadMediaComponent implements OnInit, OnChanges {
  @Input()
  fileProgressObject: FileProgressObject;
  @Output()
  fileUploaded = new EventEmitter();
  @Output()
  fileUploadedError = new EventEmitter();
  constructor(private apiService: ApiService, private proposalService: ProposalService) {}
  ngOnInit() {
    this.uploadImageFile();
  }

  uploadImageFile() {
    const fd = new FormData();
    if (this.fileProgressObject.type === 'image') {
      fd.append('cover', this.fileProgressObject.file);
    } else {
      fd.append('pdf', this.fileProgressObject.file);
    }

    let req = this.proposalService.uploadCoverImage(fd);
    if (this.fileProgressObject.type === 'pdf') {
      req = this.proposalService.uploadPdfDoc(fd);
    }
    req
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.fileProgressObject.progress = Math.round(
                ((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1)
              );
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          this.fileProgressObject.inProgress = false;
          this.fileProgressObject.canRetry = true;
          this.fileUploadedError.emit(error);
          return of(`${this.fileProgressObject.file.name ? this.fileProgressObject.file.name : ''} upload failed.`);
        })
      )
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            this.fileUploaded.emit(event.body.data);
          }
        },
        error => {
          this.fileUploadedError.emit(error);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {}
}
