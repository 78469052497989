import { Component, Inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reload-snackbar',
  templateUrl: './reload-snackbar.component.html',
  styleUrls: ['./reload-snackbar.component.scss'],
})
export class ReloadSnackBarComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<ReloadSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }
  get hasAction(): boolean {
    return !!this.data.action;
  }

  onActionBtnClick() {
    this.snackBarRef.dismissWithAction();
  }
}
