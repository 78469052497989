import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-days-to-go',
  templateUrl: './days-to-go.component.html',
  styleUrls: ['./days-to-go.component.css'],
})
export class DaysToGoComponent implements OnInit {
  @Input() startDate;
  @Input() endDate;
  tooltip: string;
  progressPercentage: number;
  lifetimeInDays: number;
  currentLifeDay: number;
  daysRemaining: number;

  constructor() {}

  ngOnInit() {
    this.setProgressPercentage();
    this.setDaysRemaining();
  }

  setProgressPercentage() {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);
    /** Get full lifetime in days */
    this.lifetimeInDays = Math.ceil((endDate.valueOf() - startDate.valueOf()) / (3600000 * 24));
    /** Get current day of life */
    this.currentLifeDay = Math.ceil((Date.now() - startDate.valueOf()) / (3600000 * 24));
    this.progressPercentage = 100 - (this.currentLifeDay * 100) / this.lifetimeInDays;
  }

  setDaysRemaining() {
    this.daysRemaining = this.lifetimeInDays - this.currentLifeDay;
  }
}
