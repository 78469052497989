import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProgressService, UserService } from '../../shared';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  @ViewChild('container', { read: ElementRef, static: false }) container: ElementRef;

  selectedTab: any;
  registerAvailable: boolean;
  progress: any;
  activatedRoute: string;

  constructor(
    private progressService: ProgressService,
    private elRef: ElementRef,
    private userService: UserService,
    protected route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.activatedRoute = url[0].path;
    });
    this.userService.getPublicSystemSettings().subscribe(data => {
      data.forEach(el => {
        if (el.name === 'INVITE_ONLY_REGISTRATION') {
          this.registerAvailable = !el.value;
        }
      });
    });
  }

  showSpinner() {
    // this.progress = this.progressService.showSpinner(this.progress, this.elRef);
  }

  hideSpinner() {
    this.progress = this.progressService.hideSpinner(this.progress);
  }

  hidePage() {
    this.activatedRoute = '';
  }
}
