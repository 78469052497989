import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments';
import { AuthenticationQuery } from '../shared';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private authenticationQuery: AuthenticationQuery) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    const vimeoAPIUrl = /api.vimeo.com/gi;
    const youtubeAPIUrl = /googleapis.com/gi;

    if (req.url.search(environment.url) !== -1) {
      const token = this.authenticationQuery.getValue().access_token
        ? this.authenticationQuery.getValue().access_token
        : null;
      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
      }
    } else if (req.url.search(vimeoAPIUrl) !== -1) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          'basic ' + window.btoa(`${environment.vimeo_client_id}:${environment.vimeo_client_key}`)
        ),
      });
    } else if (req.url.search(youtubeAPIUrl) !== -1) {
      // Do nothing
    }
    return next.handle(request);
  }
}
