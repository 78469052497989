import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { SharedService } from '../services';

import { EmbedVideoService } from '../services/embed-video.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnChanges {
  isFullscreen = false;

  @Input() slides: any;
  constructor(private embedService: EmbedVideoService, private sharedService: SharedService) {}

  slideWidth = '0px';
  sliderPanelSelectedWidth = '0px';
  sliderPanelWidth = 0;
  leftPosition = 0;
  leftPositionSlider = 0;
  selectedImageSlide = 0;
  tumbsMaxRight = 0;

  ngOnInit() {
    this.slideWidth = this.slides.length * 25 + '%';
    this.sliderPanelWidth = this.slides.length * 25;

    this.sliderPanelSelectedWidth = this.slides.length * 100 + '%';

    const widthImage = 100 / this.slides.length;
    this.tumbsMaxRight = 100 - 4 * widthImage;
    this.tumbsMaxRight = this.tumbsMaxRight * -1;

    this.selectSlide(0);
  }

  ngOnChanges() {
    this.slideWidth = this.slides.length * 25 + '%';
    if (this.slides.length * 25 < 100) {
      this.slideWidth = '100%';
    }
    this.sliderPanelWidth = this.slides.length * 25;

    this.sliderPanelSelectedWidth = this.slides.length * 100 + '%';

    const widthImage = 100 / this.slides.length;
    this.tumbsMaxRight = 100 - 4 * widthImage;
    this.tumbsMaxRight = this.tumbsMaxRight * -1;

    this.selectSlide(0);
    this.leftPositionSlider = 0;
    this.leftPosition = 0;
    this.selectedImageSlide = 0;
  }

  moveLeftNavigation() {
    const widthImage = 100 / this.slides.length;

    if (Math.abs(this.leftPosition) > 0 && this.sliderPanelWidth > 100) {
      this.leftPosition = this.leftPosition + widthImage;
    }
  }

  moveLeftNavigationSwipe() {
    const widthImage = (100 / this.slides.length) * 4;

    if (Math.abs(this.leftPosition) > 0 && this.sliderPanelWidth > 100) {
      this.leftPosition = this.leftPosition + widthImage;
      if (this.leftPosition > 0) {
        this.leftPosition = 0;
      }
    }
  }

  moveRightNavigation() {
    const widthImage = 100 / this.slides.length;
    const maxRight = 100 - 4 * widthImage;

    if (Math.abs(this.leftPosition) < maxRight && this.sliderPanelWidth > 100) {
      this.leftPosition = this.leftPosition - widthImage;
    }
  }

  moveRightNavigationSwipe() {
    const widthImageSlide = (100 / this.slides.length) * 4;

    const widthImage = (100 / this.slides.length) * 4;
    const maxRight = 100 - widthImage;

    if (Math.abs(this.leftPosition) < maxRight && this.sliderPanelWidth > 100) {
      this.leftPosition = this.leftPosition - widthImageSlide;

      if (Math.abs(this.leftPosition) > maxRight) {
        this.leftPosition = -1 * maxRight;
      }
    }
  }

  moveLeftSlider(index, evt) {
    evt.stopImmediatePropagation();
    if (index === 0) {
      return;
    }
    this.selectedImageSlide--;
    this.selectSlide(this.selectedImageSlide);
    const widthImage = 100 / this.slides.length;

    if (Math.abs(this.leftPositionSlider) > 0) {
      this.leftPositionSlider = this.leftPositionSlider + widthImage;
      this.moveLeftNavigation();
    }
    this.sharedService.setOnSliderPauseVideo();
  }

  moveLeftSliderSwipe(index) {
    if (index === 0) {
      return;
    }
    this.selectedImageSlide--;
    this.selectSlide(this.selectedImageSlide);
    const widthImage = 100 / this.slides.length;

    if (Math.abs(this.leftPositionSlider) > 0) {
      this.leftPositionSlider = this.leftPositionSlider + widthImage;
      this.moveLeftNavigation();
    }
  }

  moveRightSlider(index, evt) {
    evt.stopImmediatePropagation();
    if (index === this.slides.length - 1) {
      return;
    }
    this.selectedImageSlide++;
    this.selectSlide(this.selectedImageSlide);
    const widthImage = 100 / this.slides.length;
    const maxRight = 100 - widthImage;

    if (Math.abs(this.leftPositionSlider) < maxRight) {
      this.moveRightNavigation();
      this.leftPositionSlider = this.leftPositionSlider - widthImage;
    }
    this.sharedService.setOnSliderPauseVideo();
  }

  moveRightSliderSwipe(index) {
    if (index === this.slides.length - 1) {
      return;
    }
    this.selectedImageSlide++;
    this.selectSlide(this.selectedImageSlide);
    const widthImage = 100 / this.slides.length;
    const maxRight = 100 - widthImage;

    if (Math.abs(this.leftPositionSlider) < maxRight) {
      this.moveRightNavigation();
      this.leftPositionSlider = this.leftPositionSlider - widthImage;
    }
  }

  selectImage(slide, index) {
    const slideWidth = 100 / this.slides.length;
    this.leftPositionSlider = -1 * index * slideWidth;
    this.selectSlide(index);
    this.selectedImageSlide = index;
    this.sharedService.setOnSliderPauseVideo();
  }

  selectSlide(index) {
    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].selected = false;
    }
    this.slides[index].selected = true;
  }

  makeFullscreen(type: string) {
    this.isFullscreen = !this.isFullscreen;
  }

  closeFullscreen(evt) {
    evt.stopImmediatePropagation();
    this.isFullscreen = false;
  }

  preventCloseFullscreen(evt) {
    if (this.isFullscreen) {
      evt.stopImmediatePropagation();
    }
  }

  closeEsc(evt) {}
}
