import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProgressService } from '../../services';
import { ActivatedRoute } from '@angular/router';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { UserTypes, UserVerificationStatus } from '../../enumerations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../shared/services/user.service';
import { AuthenticatedUserQuery, AuthenticatedUserState } from '../../state';

import { environment } from '../../../../environments';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class PublicProfileComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  user: AuthenticatedUserState;
  isOwner = false;

  @Output()
  tabChange = new EventEmitter();
  editable = true;

  profilePlaceholderURL = environment.profile_placeholder
    ? environment.profile_placeholder
    : 'https://www.gravatar.com/avatar/0.43227576652898625';

  isEmailVerified: boolean;
  isMobilePhoneVerified: boolean;
  isEditingMobile: boolean;
  isSMSMessageSent: boolean;
  isUserVerified: boolean;
  isWalletCreated: boolean;
  isProfileComplete: boolean;
  hideStepper = false;
  newEmailSent: string;
  mobileForm: FormGroup;
  mobileConfirmForm: FormGroup;
  emailVerify: FormGroup;
  profileComplete: FormGroup;
  identityComplete: FormGroup;
  walletComplete: FormGroup;

  // Spinner variables
  progress: any;

  constructor(
    private userService: UserService,
    public appService: AuthenticatedUserQuery,
    private route: ActivatedRoute,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {
    this.isEmailVerified = false;
    this.isMobilePhoneVerified = false;
    this.isEditingMobile = false;
    this.isSMSMessageSent = false;
    this.isUserVerified = false;
    this.isWalletCreated = false;
    this.newEmailSent = null;
    this.isProfileComplete = false;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (!this.user && params['id'] !== undefined) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
        this.userService
          .getUserProfile(params['id'])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            user => {
              this.user = user;
              this.progressService.hideSpinner(this.progress);
              this.isOwner = this.appService.isOwner(this.user._id);
              this.initilizeVariables();
            },
            error => {
              this.progressService.hideSpinner(this.progress);
            }
          );
      } else if (!this.user) {
        this.appService.select().subscribe(user => (this.user = user));
        this.initilizeVariables();
        this.isOwner = this.appService.isOwner(this.user._id);
      } else {
        this.isOwner = this.appService.isOwner(this.user._id);
        this.initilizeVariables();
      }
    });
  }
  initilizeVariables() {
    if (this.user.seed_public_address) {
      this.isWalletCreated = true;
    }
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
      case UserTypes.ADMINISTRATOR:
        this.isEmailVerified = true;
        this.isUserVerified = true;
    }

    switch (this.user.user_verification_status) {
      case UserVerificationStatus.UNVERIFIED:
        break;
      case UserVerificationStatus.EMAIL_VERIFIED:
        this.isEmailVerified = true;
        break;
      case UserVerificationStatus.KYC_VERIFIED:
    }

    if (this.user.sms_verification_status) {
      this.isMobilePhoneVerified =
        this.user.sms_verification_status && this.user.sms_verification_status === 'complete';
    }
    this.isEditingMobile = !this.isMobilePhoneVerified;
  }

  get role(): string {
    switch (this.user.user_type_id) {
      case UserTypes.MODERATOR:
        return 'moderator';
      case UserTypes.ADMINISTRATOR:
        return 'administrator';
      default:
        switch (this.user.user_verification_status) {
          case UserVerificationStatus.EMAIL_VERIFIED:
            return 'verified';
          case UserVerificationStatus.KYC_VERIFIED:
            return 'registered';
          default:
            return 'unverified';
        }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
