import { Component, OnInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ProgressService, UserService, AuthenticatedUserState, SharedService } from '../../shared';
import { takeUntil } from 'rxjs/operators';
import { itemsAnimation, notificationsIn } from '../../app.animations';

@Component({
  selector: 'app-inbox-sidebar',
  templateUrl: './inbox-sidebar.component.html',
  styleUrls: ['./inbox-sidebar.component.scss'],
  animations: [notificationsIn, itemsAnimation],
})
export class InboxSidebarComponent implements OnInit {
  @Input() user: AuthenticatedUserState;
  @Output() showInboxBar = new EventEmitter<any>();
  @Output() readMsg = new EventEmitter<any>();

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  userNotifications: any[] = [];
  progress: any;
  skip = 0;
  isMoreAvailable = true;
  notificationCount: number;

  constructor(
    private userService: UserService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.notificationCount = this.user.notifications_count;
    this.getNotifications();
  }

  getNotifications() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.userService
      .getUserNotifications(this.skip)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          const oldData = this.userNotifications;
          if (result.length > 0) {
            result.forEach(notification => {
              oldData.push(notification);
            });
            this.userNotifications = oldData;
            this.skip = this.skip + result.length;

            if (result.length < 10) {
              this.isMoreAvailable = false;
            }
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.getNotifications();
    }
  }

  readNotification(id) {
    this.userService.postReadUserNotification(id).subscribe(
      result => this.handleNotificationUpdateSuccess(result),
      error => this.handleNotificationUpdateError(error)
    );
  }

  handleNotificationUpdateSuccess(result) {
    const notificationIndex = this.userNotifications.findIndex(n => n._id === result._id);
    this.userNotifications[notificationIndex] = result;
    this.readMsg.emit();
    this.sharedService.setUpdateNotificationCount(true, false);
    --this.notificationCount;
  }

  handleNotificationUpdateError(error) {}

  showInboxBarFn() {
    this.showInboxBar.emit();
  }

  onMarkAllRead(): void {
    this.userService.readAllUserNotification().subscribe(
      result => {
        if (result) {
          this.userNotifications = [];
          this.skip = 0;
          this.getNotifications();
          this.notificationCount = 0;
          this.sharedService.setUpdateNotificationCount(true, true);
        }
      },
      error => {}
    );
  }
}
