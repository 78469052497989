import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CreativeQueryService } from '../../services';
@Component({
  selector: 'app-creative-query-result-table',
  templateUrl: './creative-query-result-table.component.html',
  styleUrls: ['./creative-query-result-table.component.scss'],
})
export class CreativeQueryResultTableComponent implements OnInit, OnDestroy {
  @Input() creativeQueryId;
  @Input() queryData;

  data: any;
  options: any;
  private destroyed = false;

  constructor(private creativeQuery: CreativeQueryService, private cdRef: ChangeDetectorRef) {}
  newDesignData = [];

  ngOnInit() {
    this.creativeQuery.getCreativeQueryTableResults(this.creativeQueryId).subscribe(res => {
      this.data = Object.values(res);
      console.log('data', this.data);
      console.log('queryData', this.queryData);
      for (let i = 0; i < this.data.length; i++) {
        const value = this.data[i].option_name;
        const item = this.queryData.find(x => x?.value === value);
        let videoUrl: any;
        if (item?.type === 'video') {
          const videoType = value.search('www.youtube.com');
          if (videoType > 0) {
            videoUrl = [{ provider: 'youtube', src: value }];
          } else {
            videoUrl = [{ provider: 'vimeo', src: value }];
          }
        }
        const option = {
          text: value,
          type: item?.type,
          source: videoUrl,
          queryId: item?._id,
          title: item?.title,
          values: new Object({
            reputation: this.data[i].statistic_data.reputation,
            score: this.data[i].statistic_data.score,
            stake: this.data[i].statistic_data.stake,
            votes: this.data[i].statistic_data.votes,
          }),
        };
        this.newDesignData.push(option);
      }
      console.log('newDesignData', this.newDesignData);
      // this.cdRef.detectChanges();
      if (!this.destroyed) {
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.destroyed = true;
  }

  played(event: Plyr.PlyrEvent) {
    //
  }
}
