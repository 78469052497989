import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import { ToasterService } from './toaster.service';
import { UserTypes } from '../enumerations/user-types.enum';
import { UserVerificationStatus } from '../enumerations';

@Injectable()
export class IdentityVerifiedGuardService implements CanActivate {
  constructor(
    private appService: AuthenticatedUserQuery,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  canActivate() {
    if (this.appService.user.user_verification_status < UserVerificationStatus.KYC_VERIFIED) {
      this.toasterService.openSnackBar('You must complete identity verification first', 'Identity Not Verified');
      this.router.navigate(['/account/user']);
    }
    return true;
  }
}
