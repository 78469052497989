import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.css'],
})
export class CopyToClipboardComponent implements OnInit {
  @Input() value: any;
  @ViewChild('clipboardValue', { static: true }) clipboardValue: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  copyToClipboard(value) {
    const textarea = this.renderer.createElement('textarea');
    textarea.textContent = value;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    this.renderer.appendChild(document.body, textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      this.renderer.removeChild(document.body, textarea);
    }
  }
}
