import { Injectable } from '@angular/core';
import { UserStore, UserState } from './user.store';
import { User } from '../../../../app.datatypes';
import { combineLatest } from 'rxjs';

import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserQuery extends QueryEntity<UserState> {
  selectFilters$ = this.select('filters');
  selectSkip$ = this.select('skip');
  showFilter$ = this.select(state => state.ui.showFilters);

  get filters() {
    return this.getValue().filters;
  }

  constructor(protected store: UserStore) {
    super(store);
  }

  getActiveIndex() {
    return this.getAll().indexOf(this.getActive());
  }
}
