import { Component, OnInit } from '@angular/core';
import { WindowScrollService } from '../../services';
import { filter, map, pairwise, tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

enum Position {
  Hidden = -80,
  Initial = 30,
  Fixed = 0,
}

@Component({
  selector: 'app-scroll-inherited',
  template: '',
})
export class ScrollInheritedComponent implements OnInit {
  public direction;
  scrollOnNotTop = false;
  scrollTopPosition = Position.Initial;

  constructor(public windowScrollService: WindowScrollService) {}

  windowScrollFilter = (position: any): any => {
    throwError('WindowScrollFilter is not implemented');
    return true;
  };
  setWindowScrollFilter(func: (position: any) => any) {
    this.windowScrollFilter = func;
  }

  appendItems = (scroll: any): void => {
    throwError('AppendItems is not implemented');
  };
  setAppendItems(func: (scroll: any) => void) {
    this.appendItems = func;
  }

  setItemsUpdateOnScroll() {
    this.windowScrollService
      .getScrollPositionEnd()
      .pipe(
        untilDestroyed(this),
        filter(this.windowScrollFilter)
      )
      .subscribe(position => {
        this.appendItems(position);
      });
  }

  setScrollSubscription() {
    this.windowScrollService
      .getScrollPosition()
      .pipe(
        tap(pos => (this.scrollOnNotTop = pos !== 0)),
        pairwise(),
        map(([y1, y2]) => (y2 < y1 ? [Direction.Up, y2] : [Direction.Down, y2])),
        untilDestroyed(this)
      )
      .subscribe(([direction, scrollTop]) => {
        if (direction === Direction.Down && scrollTop > Position.Initial) {
          this.scrollTopPosition = Position.Hidden;
        } else {
          this.scrollTopPosition = Position.Initial;

          // Experimental
          this.windowScrollService.resetMaxPosition();
        }
      });
  }

  ngOnInit() {
    this.setScrollSubscription();
    this.setItemsUpdateOnScroll();
  }
}
