import { Component } from '@angular/core';

@Component({
  selector: 'app-proposal-example-dialog',
  template: `
    <div style="color: #fff;padding: 80px;">
      <h3 class="mat-subheading-2">Proposal Title</h3>
      <h5 class="mat-caption">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras fringilla dolor a libero egestas gravida. Morbi
        ullamcorper, augue sed laoreet sodales, orci nisl ornare mauris, eu interdum mauris leo sit amet elit. Mauris
        feugiat accumsan dui et maximus. Vivamus tincidunt quam quis mi consectetur varius. Nulla sit amet turpis
        pulvinar, auctor sapien sed, gravida erat. Phasellus hendrerit, ante quis blandit laoreet, dolor nisl
        condimentum lectus, et ullamcorper nibh felis in mauris. Curabitur mauris ipsum, tempus vitae mauris nec, luctus
        molestie odio. Suspendisse volutpat in ipsum et ultrices. Nulla in elementum dolor.
      </h5>
    </div>
  `,
})
export class ProposalExampleDialogComponent {
  constructor() {}
}
