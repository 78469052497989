import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoSelect]',
})
export class AutoFocusDirective {
  constructor(private el: ElementRef) {
    if (!el.nativeElement['focus']) {
      throw new Error('Element does not accept focus.');
    }
  }

  @HostListener('focus') onFocus() {
    this.selecting();
  }

  @HostListener('click') onClick() {
    this.selecting();
  }

  selecting() {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    input.focus();
  }
}
