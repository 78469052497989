import { Component, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-no-items',
  templateUrl: 'no-items.component.html',
  styleUrls: ['no-items.component.scss'],
})
export class NoItemsComponent {
  @Input() itemName: string;
  @Input() image: string;

  constructor(private renderer: Renderer2) {}

  createQuery() {
    const createQueryElem: HTMLElement = this.renderer.selectRootElement('#createQuery', true);
    createQueryElem.click();
  }

  createProposal() {
    const createProposalElem: HTMLElement = this.renderer.selectRootElement('#createProposal', true);
    createProposalElem.click();
  }
}
