import { Component, OnInit, ElementRef, OnDestroy, Input } from '@angular/core';
import { AuthenticatedUserService, AuthenticatedUserQuery } from '../../state/authenticated-user/';
import { ProgressService } from '../../services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FileProgressObject } from '../../../app.datatypes';

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss'],
})
export class UploadAvatarComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageFile: any;
  cropping = false;
  fileProgressObject: FileProgressObject;
  croppedImageBase64: any = '';
  isImageOpened = false;
  isActiveUpload = false;

  profileUrl;

  progress: any;
  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    // Get URL
    this.authenticatedUserQuery.profileUrl$.subscribe(url => (this.profileUrl = url));
  }

  avatarUpload(userId) {
    if (this.croppedImage) {
      this.fileProgressObject = new FileProgressObject();
      const formData: FormData = new FormData();
      const fileName = new Date().getTime() + '_' + Math.floor(Math.random() * 10000);
      formData.append('avatar', this.croppedImage, fileName);
      formData.append('user_id', userId);
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      this.authenticatedUserService
        .updateAvatarImage(formData, this.fileProgressObject)
        .pipe(untilDestroyed(this))
        .subscribe(
          data => {
            if (typeof event === 'object') {
              this.imageChangedEvent = null;
              this.croppedImage = null;
              this.imageFile = null;
              this.fileProgressObject = null;
              this.cropping = false;
              this.progressService.hideSpinner(this.progress);
            }
          },
          error => {
            this.progressService.hideSpinner(this.progress);
          }
        );
    }
  }

  croppedImageOutput(image) {
    this.croppedImageBase64 = image.croppedImageBase64;
    this.croppedImage = image.croppedImage;
    this.isActiveUpload = true;
    this.uploadImage();
  }

  uploadImage() {
    this.avatarUpload(this.authenticatedUserQuery.getValue()._id);
    this.isImageOpened = false;
    this.isActiveUpload = false;
  }

  uploadImageEmit() {
    this.uploadImage();
  }

  closeCropper(close) {
    this.isImageOpened = false;

    console.log(this.isImageOpened);
  }

  openImage(evt) {
    this.isImageOpened = true;
  }

  onImageInputChange(event) {
    this.isImageOpened = true;
    this.imageFile = event;
    this.cropping = true;
  }

  ngOnDestroy() {}
}
