import { Review } from '../../../app.datatypes';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

export interface ReviewState extends EntityState<Review>, ActiveState {
  moderationFilters: {
    unlockedOnly: boolean;
  };
  skip: number;
  scroll: number;
  apiEndReached: boolean;
  loaded: boolean;

  // Moderation Querys  specific
  moderationReviewSkip: number;
  moderationReviewScroll: number;
  moderationReviewActive: string;
  moderationReviewApiEndReached: boolean;
  moderationReviewLoaded: boolean;
}

function createInitialState(): ReviewState {
  return {
    moderationFilters: {
      unlockedOnly: true,
    },
    skip: 0,
    scroll: 1,
    apiEndReached: false,
    loaded: false,
    moderationReviewLoaded: false,
    moderationReviewSkip: 0,
    moderationReviewScroll: 1,
    moderationReviewApiEndReached: false,
    // prevent loader showing on state initialization
    loading: false,
  } as ReviewState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'review', idKey: '_id', resettable: true })
export class ReviewStore extends EntityStore<ReviewState> {
  constructor() {
    super(createInitialState());
  }
}
