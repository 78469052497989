import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShareModule } from '@ngx-share/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgPipesModule } from 'ngx-pipes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DcpTooltipDirective } from '../components/dcp-tooltip/dcp-tooltip.directive';
import { DcpTooltipComponent } from '../components/dcp-tooltip/dcp-tooltip.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DcpMultiselectComponent } from './components/dcp-multiselect/dcp-multiselect.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {
  InvokeDirective,
  OnlyNumberDirective,
  ShowAuthedDirective,
  DetailRowDirective,
  DisableButtonOnActionDirective,
  TooltipDirective,
} from './directives';
import { TimeagoModule } from 'ngx-timeago';
import {
  UnAuthGuardService,
  LoggedInAuthGuard,
  IdentityVerifiedGuardService,
  WalletGuardService,
  AdministratorGuardService,
  ModeratorGuardService,
  ToasterService,
  WizardService,
  ErrorsHandlerService,
  EmbedVideoService,
  WindowSizeService,
  ThemeService,
  FilePreviewOverlayService,
  SEOService,
  CountryService,
  LocaleService,
  ProgressService,
  DynamicOverlay,
  DynamicOverlayContainer,
  WindowScrollService,
  SharedService,
} from './services';
import { CustomDropdownService } from './components/custom-select/custom-dropdown.service';
import {
  BannerComponent,
  BottomSheetComponent,
  LoaderComponent,
  LoaderService,
  MainSnackBarComponent,
  UnauthorizedSnackBarComponent,
  PasswordDialogComponent,
  ModerateComponent,
  LocalLoaderComponent,
  LowBalanceComponent,
  SpinnerComponent,
  CropperDialogComponent,
  ImageCropperComponent,
  RoundQuickViewComponent,
  ButtonLoaderComponent,
  FilePreviewOverlayComponent,
  FilePreviewOverlayToolbarComponent,
  UserActivityComponent,
  UserProposalComponent,
  UserCreativeQueryComponent,
  UserReviewsComponent,
  UserRewardsComponent,
  UserEvaluationsComponent,
  UserModerationsComponent,
  ActivityComponent,
  UserProfileComponent,
  DcpCropperComponent,
  CreativeQueryCardViewComponent,
  PasswordInputComponent,
  PhoneInputComponent,
  PublicProfileComponent,
  CreativeQueryGraphComponent,
  CreativeQueryViewComponent,
  NoItemsComponent,
  ReportDialogComponent,
  CreativeQueryTeaserViewComponent,
  CreativeQueryQuestionComponent,
  CreativeQueryActionsComponent,
  WalletCheckComponent,
  ScrollInheritedComponent,
  ReloadSnackBarComponent,
  LogoComponent,
  UserAvatarComponent,
  DirectorsQuoteCardComponent,
  InstructionCardComponent,
  InstructionVideoOnlyComponent,
  AddVideoComponent,
  CreativeQueryVideoComponent,
  UserTokenEarningsComponent,
  VideoPlayerComponent,
} from './components';
import { AccountSettingsComponent } from '../containers/account/two-factor/account-settings.component';
import { CountryPipe, FilmPricePipe, NBPSPipe, ScoreToPercentPipe } from './pipe';
import { CancelRemoveDialogComponent } from './components';
import { EmailVerifiedGuardService } from './services';
import { LikeConfirmDialogComponent } from './components';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { TimeLocalPipe } from './pipe/time-local.pipe';
import { DaysToGoComponent } from './components/days-to-go/days-to-go.component';
import { CreativeQueryResultTableComponent } from './components/creative-query-result-table/creative-query-result-table.component';
import { SwUpdateDialogComponent } from './components/sw-update-dialog/sw-update-dialog.component';
import { ModerationInProgressDialogComponent } from './components';
import { DcpHintComponent } from './components/dcp-hint/dcp-hint.component';
import { SnackBarTemplateComponent } from './components/snackbars/snack-bar-template/snack-bar-template.component';
import { ContactSupportSnackBarComponent } from './components/snackbars/contact-support-snack-bar/contact-support-snack-bar.component';
import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { StatusTextPipe } from './pipe/status-text.pipe';
import { CheckboxComponent } from './components';
import { IconComponent } from './icon/icon.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabItemComponent } from './tabs/tab-item.component';
import { TabLabelComponent } from './tabs/tab-label.component';
import { TabBodyComponent } from './tabs/tab-body.component';
import { SliderComponent } from './slider/slider.component';
import { PlyrModule } from 'ngx-plyr';
import { ModalComponent } from './modal/modal.component';
import { NotificationComponent } from '../containers/account/inbox/notification/notification.component';
import { EntityActionComponent } from '../containers/account/inbox/entity-action/entity-action.component';
import { SubEntityActionComponent } from '../containers/account/inbox/sub-entity-action/sub-entity-action.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { CustomSelectOptionComponent } from './components/custom-select/custom-select-option.component';
import { DropdownComponent } from './components/custom-select/dropdown.component';
import { CustomDropdownComponent } from './components/custom-select/customDropdown.component';
import { ProposalGalleryComponent } from '../containers/proposal/proposal-detail/proposal-gallery/proposal-gallery.component';
import { DcpSelectComponent } from './components/dcp-select/dcp-select.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DropdownMenuDirective } from './components/dropdown-menu/dropdown.directive';
import { DcpDatePickerComponent } from './components/dcp-date-picker/dcp-date-picker.component';
import { AutoresizeAreaDirective } from './directives/autoresize-area.directive';
import { Daterangepicker } from 'ng2-daterangepicker';
import { ConfirmAddressDialogComponent } from './components/confirm-address-dialog/confirm-address-dialog.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { DcpModalModule } from './components/dcp-modal';
import { PayoutTypeTextPipe } from './pipe/payout-type-text.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { WalletTransactionsComponent } from './components';

import { CoinAnimationDirective } from '../coin-animation.directive';

import { PollComponent } from './components/announcements/poll/poll.component';
import { QuoteComponent } from './components/announcements/quote/quote.component';
import { AnnouncementComponent } from './components/announcements/announcement/announcement.component';
import { UserLikesComponent } from './components/activity/user-likes/user-likes.component';
import { ReviewActivityDetailComponent } from './components/activity/parts/review-activity-detail/review-activity-detail.component';
import { LinkSnackbarComponent } from './components/snackbars/link-snackbar/link-snackbar.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';

import { DiscordChatService } from './services/discord-chat.service';
import { DcpAutocompleteComponent } from './components/dcp-autocomplete/dcp-autocomplete.component';

@NgModule({
  imports: [
    PlyrModule,
    NgxTrimDirectiveModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
    MatBottomSheetModule,
    FlexLayoutModule,
    DcpModalModule,
    // ShareButtonsModule,
    ShareModule,
    FontAwesomeModule,
    PdfViewerModule,
    NgPipesModule,
    InfiniteScrollModule,
    PickerModule,
    Daterangepicker,
    // NgxMatIntlTelInputModule,
    // CKEditorModule,
    LayoutModule,
  ],
  declarations: [
    // Custom Components
    ContactSupportSnackBarComponent,
    SnackBarTemplateComponent,
    BannerComponent,
    BottomSheetComponent,
    LoaderComponent,
    PasswordDialogComponent,
    ModerateComponent,

    // UserModerationSuggestionComponent,
    MainSnackBarComponent,
    UnauthorizedSnackBarComponent,
    LocalLoaderComponent,
    LowBalanceComponent,
    SpinnerComponent,
    ImageCropperComponent,
    CropperDialogComponent,
    RoundQuickViewComponent,
    ButtonLoaderComponent,
    FilePreviewOverlayComponent,
    FilePreviewOverlayToolbarComponent,
    UserActivityComponent,
    UserProposalComponent,
    UserCreativeQueryComponent,
    UserReviewsComponent,
    UserRewardsComponent,
    UserEvaluationsComponent,
    UserModerationsComponent,
    ActivityComponent,
    AccountSettingsComponent,
    UserProfileComponent,
    DcpCropperComponent,
    CreativeQueryCardViewComponent,
    PasswordInputComponent,
    PhoneInputComponent,
    PublicProfileComponent,
    CreativeQueryGraphComponent,
    CreativeQueryViewComponent,
    ReportDialogComponent,
    PublicProfileComponent,
    UserProfileComponent,
    CreativeQueryTeaserViewComponent,
    CreativeQueryQuestionComponent,
    CreativeQueryActionsComponent,
    LikeConfirmDialogComponent,
    ScrollInheritedComponent,
    WalletCheckComponent,
    ReloadSnackBarComponent,
    LogoComponent,
    UserAvatarComponent,
    NotificationComponent,

    EntityActionComponent,
    SubEntityActionComponent,
    DcpAutocompleteComponent,

    // pipes
    CountryPipe,
    FilmPricePipe,
    NBPSPipe,
    TimeLocalPipe,
    ScoreToPercentPipe,

    // Directives
    InvokeDirective,
    OnlyNumberDirective,
    CancelRemoveDialogComponent,
    ConfirmAddressDialogComponent,
    ShowAuthedDirective,
    PasswordInputComponent,
    NoItemsComponent,
    DetailRowDirective,
    AutoFocusDirective,
    DisableButtonOnActionDirective,
    TooltipDirective,
    CoinAnimationDirective,
    DaysToGoComponent,
    SwUpdateDialogComponent,
    CreativeQueryResultTableComponent,
    ModerationInProgressDialogComponent,
    DcpHintComponent,
    StatusTextPipe,
    PayoutTypeTextPipe,
    CheckboxComponent,
    IconComponent,
    FilterSelectComponent,
    TabsComponent,
    TabItemComponent,
    TabLabelComponent,
    TabBodyComponent,
    SliderComponent,
    ModalComponent,
    CustomSelectComponent,
    CustomSelectOptionComponent,
    DropdownComponent,
    CustomDropdownComponent,
    DcpMultiselectComponent,
    ProposalGalleryComponent,
    CreativeQueryVideoComponent,
    WalletTransactionsComponent,
    UserTokenEarningsComponent,

    DcpTooltipDirective,
    DcpTooltipComponent,
    DcpSelectComponent,
    DropdownMenuComponent,
    DcpDatePickerComponent,
    AutoresizeAreaDirective,
    DropdownMenuDirective,

    DirectorsQuoteCardComponent,
    InstructionCardComponent,
    InstructionVideoOnlyComponent,
    AddVideoComponent,
    CopyToClipboardComponent,
    VideoPlayerComponent,
    PollComponent,
    QuoteComponent,
    AnnouncementComponent,
    UploadDialogComponent,
    LinkSnackbarComponent,
    ToggleButtonComponent,
    UserLikesComponent,
    ReviewActivityDetailComponent,
  ],
  exports: [
    AddVideoComponent,
    NotificationComponent,
    NgxTrimDirectiveModule,
    ContactSupportSnackBarComponent,
    SnackBarTemplateComponent,
    BannerComponent,
    BottomSheetComponent,
    LoaderComponent,
    MainSnackBarComponent,
    UnauthorizedSnackBarComponent,
    ModerateComponent,
    LocalLoaderComponent,
    LowBalanceComponent,
    SpinnerComponent,
    CropperDialogComponent,
    ImageCropperComponent,
    RoundQuickViewComponent,
    ButtonLoaderComponent,
    FilePreviewOverlayComponent,
    FilePreviewOverlayToolbarComponent,
    UserActivityComponent,
    UserProposalComponent,
    UserCreativeQueryComponent,
    UserRewardsComponent,
    UserReviewsComponent,
    UserEvaluationsComponent,
    UserModerationsComponent,
    ActivityComponent,
    AccountSettingsComponent,
    UserProfileComponent,
    CreativeQueryCardViewComponent,
    PasswordInputComponent,
    PhoneInputComponent,
    PublicProfileComponent,
    CreativeQueryGraphComponent,
    CreativeQueryViewComponent,
    PublicProfileComponent,
    UserProfileComponent,
    CreativeQueryTeaserViewComponent,
    CreativeQueryQuestionComponent,
    CreativeQueryActionsComponent,
    ScrollInheritedComponent,
    WalletCheckComponent,
    ReloadSnackBarComponent,
    LogoComponent,
    CheckboxComponent,
    IconComponent,
    FilterSelectComponent,
    ModalComponent,
    CustomSelectComponent,
    CustomSelectOptionComponent,
    DropdownComponent,
    CustomDropdownComponent,
    DcpMultiselectComponent,
    ProposalGalleryComponent,
    DcpSelectComponent,
    DropdownMenuComponent,
    InstructionCardComponent,
    InstructionVideoOnlyComponent,
    DcpAutocompleteComponent,

    TabsComponent,
    TabItemComponent,
    TabLabelComponent,
    TabBodyComponent,
    SliderComponent,
    UserAvatarComponent,

    NoItemsComponent,
    ReportDialogComponent,
    DcpCropperComponent,

    // Pipes
    CountryPipe, // pipe used by dcp-phone-prefix
    FilmPricePipe,
    NBPSPipe,
    TimeagoModule,
    TimeLocalPipe,
    ScoreToPercentPipe,

    // Directives
    InvokeDirective,
    OnlyNumberDirective,
    ShowAuthedDirective,
    DetailRowDirective,
    AutoFocusDirective,
    DisableButtonOnActionDirective,
    AutoresizeAreaDirective,
    DropdownMenuDirective,
    TooltipDirective,
    CoinAnimationDirective,
    DcpTooltipDirective,

    // modules
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    MatDialogModule,
    MatSnackBarModule,
    CdkTableModule,
    A11yModule,
    BidiModule,
    CdkAccordionModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    InfiniteScrollModule,
    NgPipesModule,
    LayoutModule,
    InfiniteScrollModule,

    CreativeQueryResultTableComponent,
    StatusTextPipe,
    PayoutTypeTextPipe,
    DcpDatePickerComponent,
    CopyToClipboardComponent,
    CreativeQueryVideoComponent,
    WalletTransactionsComponent,
    UserTokenEarningsComponent,
    VideoPlayerComponent,
    PollComponent,
    QuoteComponent,
    AnnouncementComponent,
    UploadDialogComponent,
    LinkSnackbarComponent,
    ToggleButtonComponent,
  ],
  entryComponents: [
    ContactSupportSnackBarComponent,
    SnackBarTemplateComponent,
    MainSnackBarComponent,
    UnauthorizedSnackBarComponent,
    PasswordDialogComponent,
    CancelRemoveDialogComponent,
    ConfirmAddressDialogComponent,
    ModerationInProgressDialogComponent,
    ModerateComponent,
    LowBalanceComponent,
    CropperDialogComponent,
    FilePreviewOverlayComponent,
    BottomSheetComponent,
    LocalLoaderComponent,
    ReportDialogComponent,
    LikeConfirmDialogComponent,
    WalletCheckComponent,
    ReloadSnackBarComponent,
    DcpTooltipComponent,
    LinkSnackbarComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: 'windowObject', useValue: window },

        UnAuthGuardService,
        LoggedInAuthGuard,
        EmailVerifiedGuardService,
        ModeratorGuardService,
        IdentityVerifiedGuardService,
        WalletGuardService,
        AdministratorGuardService,
        ToasterService,
        WizardService,
        ErrorsHandlerService,
        LoaderService,
        EmbedVideoService,
        WindowSizeService,
        ThemeService,
        SEOService,
        ProgressService,
        DynamicOverlay,
        DynamicOverlayContainer,
        // dcp phone
        CountryService,
        LocaleService,
        FilePreviewOverlayService,
        WindowScrollService,
        CanDeactivateGuard,
        CustomDropdownService,
        DiscordChatService,
        SharedService,
      ],
    };
  }
}
