import { Component, Inject, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../app.datatypes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserQuery, UserStateService } from '../state';

@Component({
  selector: 'app-suspend-dialog',
  templateUrl: './suspend-dialog.component.html',
})
export class SuspendDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  // Spinner variables
  progress: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public user: User,
    private userStateService: UserStateService,
    private formBuilder: FormBuilder,

    public dialogRef: MatDialogRef<SuspendDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      reputation: [this.user.reputation, Validators.required],
    });
  }

  submit() {
    this.userStateService.toggleSuspended(this.user._id).subscribe(user => {});
    this.dialogRef.close(true);
  }
  ngOnDestroy() {}
}
