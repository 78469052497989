import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { ProposalStore } from '../proposal/proposal.store';
import { ProposalQuery } from '../proposal/proposal.query';
import { ReviewStore } from './review.store';
import { Proposal, ReviewManipulateRequest, Review, SubmitReviewRequest, CreativeQuery } from '../../../app.datatypes';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { AuthenticatedUserService } from '../authenticated-user';
import { ReviewQuery } from './review.query';

@Injectable({ providedIn: 'root' })
export class ReviewStateService {
  constructor(
    private reviewStore: ReviewStore,
    private reviewQuery: ReviewQuery,
    private proposalStore: ProposalStore,
    private proposalQuery: ProposalQuery,
    private apiService: ApiService,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  getProposalReviews(proposalId, skip): Observable<Proposal[]> {
    this.reviewStore.setLoading(true);
    const api = `api/proposals/${proposalId}/reviews?limit=${environment.grid_skip_limit}&skip=${skip}`;
    return this.apiService.get(api).pipe(
      tap(reviews => {
        this.reviewStore.setLoading(false);
        applyTransaction(() => {
          this.reviewStore.add(reviews);
          if (reviews.length < environment.grid_skip_limit) {
            this.proposalStore.update(proposalId, {
              reviews_api_reached: reviews.length < environment.grid_skip_limit,
            });
          }
        });
      }),
      catchError(error => {
        this.reviewStore.setError(error);
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  postReview(review: ReviewManipulateRequest): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.post('api/reviews/', review).pipe(
      tap(reviewResponse => {
        applyTransaction(() => {
          this.reviewStore.setLoading(false);
          this.reviewStore.add(reviewResponse, { prepend: true });
          this.updateScores(reviewResponse);
        });
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  putReview(review: ReviewManipulateRequest): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.put('api/reviews/' + review._id, review).pipe(
      tap(reviewResponse => {
        this.reviewStore.setLoading(false);
        reviewResponse.status = 'to-be-moderated';
        this.reviewStore.update(review._id, reviewResponse);
        this.updateScores(reviewResponse);
      }),
      catchError(error => {
        this.proposalStore.setError(error);
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  putResetFailed(id: string): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.put('api/reviews/' + id + '/reset', null).pipe(
      tap(reviewResponse => {
        console.log(reviewResponse.status);
        this.setStatus(id, reviewResponse.status);
        this.reviewStore.setLoading(false);
      }),
      catchError(error => {
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  cancel(id: string): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.get('api/reviews/' + id + '/cancel').pipe(
      tap(reviewResponse => {
        this.reviewStore.setLoading(false);
        reviewResponse.status = 'cancelled';
        this.reviewStore.update(id, reviewResponse);
      }),
      catchError(error => {
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  setStatus(id, status) {
    this.reviewStore.update(id, { status });
  }

  unlockReview(id): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService.put('api/reviews/' + id + '/unlock', null).pipe(
      tap(review => {
        this.authenticatedUserService.unlock();

        review.locked_at = undefined;
        review.locked_by = undefined;
        this.reviewStore.upsert(review._id, review);
        this.reviewStore.setLoading(false);
      }),
      catchError(error => {
        this.reviewStore.setError(error);
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  setLockedData(id, locked_by, locked_at) {
    this.reviewStore.update(id, { locked_by, locked_at });
  }

  submitReview(req: SubmitReviewRequest): Observable<Review> {
    this.reviewStore.setLoading(true);
    return this.apiService
      .post('api/reviews/' + req.reviewId + '/submit', {
        password: req.password,
        sig: req.signature,
        nonce: req.nonce,
      })
      .pipe(
        tap(reviewResponse => {
          applyTransaction(() => {
            this.reviewStore.setLoading(false);
            this.reviewStore.update(req.reviewId, reviewResponse);
            this.updateScores(reviewResponse);
          });
        }),
        catchError(error => {
          this.proposalStore.setError(error);
          this.reviewStore.setLoading(false);
          return this.apiService.catchError(error);
        })
      );
  }

  updateReview(id, data) {
    this.reviewStore.update(id, data);
  }

  updateScores(reviewResponse: any) {
    this.proposalStore.update(reviewResponse.proposal_id, entity => {
      return {
        has_reviewed_proposal: true,
        statistics: {
          ...entity.statistics,
          total_votes: reviewResponse.proposal.votes_score,
          reviews_score: reviewResponse.proposal.reviews_score,
          evaluations_score: reviewResponse.proposal.evaluations_score,
          likes_score: reviewResponse.proposal.likes_score,
        },
      };
    });
  }

  /** ==============================================================================
   * ========================== Review moderation related ==========================
   * ==============================================================================*/

  updateSkip(scroll) {
    this.reviewStore.update({
      skip: this.reviewQuery.getValue().skip + environment.grid_skip_limit,
      scroll,
    });
  }

  getAllToBeModerated(): Observable<Review[]> {
    this.reviewStore.setLoading(true);
    const api =
      'api/reviews/moderate?&limit=' +
      environment.grid_skip_limit +
      '&skip=' +
      this.reviewQuery.getValue().moderationReviewSkip +
      (this.reviewQuery.getValue().moderationFilters.unlockedOnly ? '&unlocked=true' : '');
    return this.apiService.get(api).pipe(
      tap(reviews => {
        this.reviewStore.add(reviews);
        this.reviewStore.setLoading(false);
        if (reviews.length < environment.grid_skip_limit) {
          this.reviewStore.update({ moderationQueryApiEndReached: true });
        }
        this.reviewStore.update({ moderationQueryLoaded: true });
      }),
      catchError(error => {
        this.reviewStore.setError(error);
        this.reviewStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateModerationFilter(filter) {
    applyTransaction(() => {
      this.reviewStore.remove(this.getAllToBeModerated());
      this.reviewStore.update({
        moderationFilters: { unlockedOnly: filter.unlockedOnly },
        moderationReviewSkip: 0,
      });
      this.reviewStore.setLoading(false);
    });
  }

  private _upsertState(observableReview: Observable<Review>) {
    return observableReview.pipe(
      tap(review => {
        this.reviewStore.upsert(review._id, review);
        this.reviewStore.setLoading(false);
      }),
      catchError(error => {
        this.reviewStore.setLoading(false);
        this.reviewStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }

  lockReview(id: string): Observable<any> {
    this.reviewStore.setLoading(true);
    const proposal = this._upsertState(this.apiService.put('api/reviews/' + id + '/lock', null));
    this.authenticatedUserService.setModeratedEntity({ entity: 'review', id: id });
    return proposal;
  }

  setModerationReviewActive(id) {
    this.reviewStore.update({ moderationReviewActive: id });
  }

  reviewModerated(id, status) {
    this.reviewStore.update(id, { status });
    this.authenticatedUserService.unlock();
  }

  remove(id) {
    this.reviewStore.setActive(null);
    this.reviewStore.remove(id);
  }

  getReview(id): Observable<any> {
    return this.reviewQuery.selectEntity(id).pipe(
      switchMap(review => {
        if (!review) {
          return this.apiService.get('api/reviews/' + id).pipe(tap(rev => this.reviewStore.add(rev)));
        } else {
          return this.reviewQuery.selectEntity(id);
        }
      }),
      catchError(error => {
        return this.apiService.catchError(error);
      })
    );
  }
}
