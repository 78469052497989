import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';

export interface Password {
  password: string;
}

function createInitialState(): Password {
  return { password: null };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'password',
  resettable: true,
})
export class PasswordStore extends Store<Password> {
  constructor() {
    super(createInitialState());
    this.loadFromStorage();
  }
  // load password from session storage
  loadFromStorage() {
    const password = window.sessionStorage.getItem('password');
    if (password) {
      this.update({ password });
    }
  }
}
