import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { NavComponent } from './components/layout/nav/nav.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ProposalCreateComponent } from './containers/proposal/proposal-create/proposal-create.component';
import { QueryCreateComponent } from './containers/creative-query/query-create/query-create.component';
import { QuestionComponent } from './containers/creative-query/query-create/question/question.component';
import { OptionComponent } from './containers/creative-query/query-create/option/option.component';
import { PreviewCreateQueryComponent } from './containers/creative-query/preview-create-query/preview-create-query.component';
import { MediaBlockComponent } from './containers/proposal/proposal-create/media-block/media-block.component';
import { UploadMediaComponent } from './containers/proposal/proposal-create/upload-media/upload-media.component';
import { AddImageComponent } from './containers/proposal/proposal-create/add-image/add-image.component';
import { BountyInfoComponent } from './containers/proposal/bounty-info/bounty-info.component';
import { Router, RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './containers/login/login.component';
import { RegisterComponent } from './containers/register/register.component';
import { ForgotComponent } from './containers/forgot/forgot.component';
import { ResetComponent } from './containers/reset/reset.component';
import { SharedModule, AuthenticatedUserService, AuthenticationService } from './shared';
import { TabsModule } from './shared/tabs/tabs.module';
import { SubmissionTermComponent } from './components/pages/submission-term/submission-term.component';

import { CKEditorModule } from 'ckeditor4-angular';
import { ProposalExampleDialogComponent } from './styleguide/proposal-example-dialog.component';
import { DialogOverviewExampleDialogComponent } from './styleguide/dialog-overview-example-dialog.component';
import { ReputationDialogComponent } from './containers/administration/user-management/reputation-dialog/reputation-dialog.component';
import { SuspendDialogComponent } from './containers/administration/user-management/suspend-dialog/suspend-dialog.component';
import { NotFoundComponent } from './containers/errors/not-found/not-found.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments';
import { ReputationBoardComponent } from './containers/reputation-board/reputation-board.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import * as Sentry from '@sentry/angular';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { TimeagoModule } from 'ngx-timeago';
import { UserMenuComponent } from './components/layout/sidebar/user-menu/user-menu.component';
import { DatePipe } from '@angular/common';
import { SwUpdateDialogComponent } from './shared/components/sw-update-dialog/sw-update-dialog.component';
import { TopbarMenuComponent } from './components/layout/topbar/topbar-menu/topbar-menu.component';
import { HomeComponent } from './containers/home/home.component';
import { InboxSidebarComponent } from './components/inbox-sidebar/inbox-sidebar.component';
import { PotentialEarningsComponent } from './components/layout/header/potential-earnings/potential-earnings.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MobileHeaderComponent } from './components/layout/mobile-header/mobile-header.component';
import { NotificationComponent } from './containers/account/inbox/notification/notification.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { CoinComponent } from './shared/coin/coin.component';
import { NgIdleModule } from '@ng-idle/core';
import { DcpModalModule } from './shared/components/dcp-modal';
import { AnnouncementsListComponent } from './shared/components/announcements/announcements-list.component';
import { ProposalCreateStepOneComponent } from './containers/proposal/proposal-create/proposal-create-steps/proposal-create-step-one/proposal-create-step-one.component';
import { ProposalCreateStepTwoComponent } from './containers/proposal/proposal-create/proposal-create-steps/proposal-create-step-two/proposal-create-step-two.component';
import { ProposalCreateStepThreeComponent } from './containers/proposal/proposal-create/proposal-create-steps/proposal-create-step-three/proposal-create-step-three.component';
import { ProposalCreateStepFourComponent } from './containers/proposal/proposal-create/proposal-create-steps/proposal-create-step-four/proposal-create-step-four.component';
import { ProposalCreateStepFiveComponent } from './containers/proposal/proposal-create/proposal-create-steps/proposal-create-step-five/proposal-create-step-five.component';

export function initializeUserState(appLoadService: AuthenticatedUserService, authService: AuthenticationService) {
  return async () => {
    if (authService) {
      await authService.startRefreshJWTTimer();
    }
    return appLoadService.initializeAppUserState();
  };
}
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    TopbarComponent,
    NavComponent,
    FooterComponent,
    ProposalCreateComponent,
    ProposalCreateStepOneComponent,
    ProposalCreateStepTwoComponent,
    ProposalCreateStepThreeComponent,
    ProposalCreateStepFourComponent,
    ProposalCreateStepFiveComponent,
    QueryCreateComponent,
    QuestionComponent,
    OptionComponent,
    PreviewCreateQueryComponent,
    // AddVideoComponent,
    AddImageComponent,
    BountyInfoComponent,
    MediaBlockComponent,
    UploadMediaComponent,
    LoginComponent,

    RegisterComponent,
    DialogOverviewExampleDialogComponent,
    ProposalExampleDialogComponent,
    ReputationDialogComponent,
    SuspendDialogComponent,
    ForgotComponent,
    ResetComponent,
    NotFoundComponent,
    ReputationBoardComponent,
    SubmissionTermComponent,
    // AccountComponent,
    UserMenuComponent,
    // IconComponent,
    TopbarMenuComponent,
    HomeComponent,
    InboxSidebarComponent,
    PotentialEarningsComponent,
    MobileHeaderComponent,
    LoginPageComponent,
    CoinComponent,
    AnnouncementsListComponent,
  ],
  imports: [
    SharedModule.forRoot(),
    // QueryModule,
    CKEditorModule,
    TabsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.staging,
    }),
    // MatPasswordStrengthModule.forRoot(),
    environment.production || environment.staging ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule.forRoot(),
    TimeagoModule.forRoot(),
    ImageCropperModule,
    NgIdleModule.forRoot(),
    DcpModalModule,
  ],
  providers: [
    ...(environment.staging
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: true,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
        ]
      : []),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserState,
      deps: [AuthenticatedUserService, AuthenticationService, ...(environment.staging ? [Sentry.TraceService] : [])],
      multi: true,
    },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    DatePipe,
  ],
  exports: [NotificationComponent, AnnouncementsListComponent],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogOverviewExampleDialogComponent,
    ReputationDialogComponent,
    SuspendDialogComponent,
    ProposalCreateComponent,
    ProposalCreateStepOneComponent,
    ProposalCreateStepTwoComponent,
    ProposalCreateStepThreeComponent,
    ProposalCreateStepFourComponent,
    ProposalCreateStepFiveComponent,
    QueryCreateComponent,
    QuestionComponent,
    OptionComponent,
    PreviewCreateQueryComponent,
    // AddVideoComponent,
    AddImageComponent,
    BountyInfoComponent,
    MediaBlockComponent,
    UploadMediaComponent,
    SwUpdateDialogComponent,
  ],
})
export class AppModule {}
