import { KeyValue } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ProgressService } from '../../services';

@Component({
  selector: 'app-dcp-autocomplete',
  templateUrl: './dcp-autocomplete.component.html',
  styleUrls: ['./dcp-autocomplete.component.scss'],
})
export class DcpAutocompleteComponent implements OnInit {
  @Input() label = '';
  @Input() options = new BehaviorSubject<KeyValue<any, any>[]>([]);
  @Output() keyUp = new EventEmitter<string>();
  @Output() select = new EventEmitter<KeyValue<any, any>>();

  search$ = new BehaviorSubject<string>('');
  selectedOption: KeyValue<any, any> = null;
  progress: any;
  optionsVisible = true;

  constructor(private progressService: ProgressService, private elRef: ElementRef) {}

  ngOnInit(): void {
    this.search$
      .pipe(
        debounceTime(1000),
        filter(term => term !== ''),
        filter(term => (this.selectedOption !== null ? term !== this.selectedOption.value : true))
      )
      .subscribe({
        next: (search: string) => {
          this.progress = this.progressService.showSpinner(this.progress, this.elRef);
          this.keyUp.emit(search);
          this.optionsVisible = true;
        },
      });

    this.options.subscribe(_ => this.progressService.hideSpinner(this.progress));
  }

  onSelect(option: KeyValue<any, any>, $event) {
    this.selectedOption = option;
    this.search$.next(option.value);
    this.optionsVisible = false;
    this.select.emit(option);
  }
}
