import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ToasterService, EmbedVideoService, ThemeService, FilePreviewOverlayService } from '../../services';
import { CreativeQueryServices } from '../../state';
import { FilePreviewOverlayRef } from '../file-preview-overlay/file-preview-overlay-ref';
import { LowBalanceComponent } from '../low-balance-sheet/low-balance.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CreativeQuery, SubmitCreativeQuerySubmissionRequest } from '../../../app.datatypes';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticatedUserQuery, PasswordService, PasswordQuery } from '../../state';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { listAnimation, slideOpenAnimation } from '../../../app.animations';
@Component({
  selector: 'app-creative-query-question',
  templateUrl: './creative-query-question.component.html',
  styleUrls: ['./creative-query-question.component.scss'],
  animations: [listAnimation(), slideOpenAnimation()],
})
export class CreativeQueryQuestionComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  submissions: any;
  changeMode = false;
  public instruction: any;
  form: FormGroup;
  @Input() creativeQuery?: CreativeQuery;
  @Input() days: number;

  videos = {};
  isDarkTheme: boolean;
  showStakeHelp = false;

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  // Spinner variables
  progress: any;

  private matBottomSheetRef: MatBottomSheetRef<LowBalanceComponent>;
  constructor(
    private creativeQueryService: CreativeQueryServices,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    private passwordService: PasswordService,
    private passwordQuery: PasswordQuery,
    private formBuilder: FormBuilder,
    private toastService: ToasterService,
    private embedVideoService: EmbedVideoService,
    public dialog: MatDialog,
    public themeService: ThemeService,
    private bottomSheet: MatBottomSheet,
    private previewDialog: FilePreviewOverlayService
  ) {}

  ngOnInit() {
    this.instruction = this.creativeQueryService.getReviewCqInstruction().subscribe((data: any) => {
      this.instruction = data[0];
    });
    this.themeService.currentTheme$.subscribe(value => {
      this.isDarkTheme = value;
    });
    this.buildForm();
  }

  initialize() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.creativeQuery) {
      this.changeMode = false;
      this.buildForm();
      this.initialize();
      this.initializeVideos();
    }
  }

  buildForm() {
    const stake = this.creativeQuery.current_user_submit ? this.creativeQuery.current_user_submit.stake : 0;
    this.form = this.formBuilder.group({
      chosen_option: this.getFormArray(),
      stake: [
        stake,
        [Validators.required, Validators.min(stake), Validators.max(this.authenticatedUserQuery.user.balance)],
      ],
    });
  }

  initializeVideos() {
    if (this.creativeQuery.questions) {
      this.creativeQuery.questions.forEach((question, index) => {
        if (question.options && question.options.length > 0) {
          question.options.forEach((option, indexOpt) => {
            if (option.type === 'video') {
              this.videos[option._id] = this.embedVideoService.embed(option.value);
            }
          });
        }
      });
    }
  }

  getFormArray(): FormArray {
    const formArray = new FormArray([]);
    if (this.creativeQuery.questions && this.creativeQuery.questions.length > 0) {
      this.creativeQuery.questions.forEach((x, index) => {
        if (x.type === 'single' || x.type === 'vote') {
          // query expired, own query, query finished
          if (
            this.days <= 0 ||
            this.authenticatedUserQuery.user._id === this.creativeQuery.user_id ||
            this.creativeQuery.status === 'finished'
          ) {
            const temp = new FormControl(
              {
                value: this.creativeQuery.current_user_submit
                  ? this.creativeQuery.current_user_submit.chosen_option[index]
                  : null,
              },
              Validators.required
            );
            formArray.push(temp);
          } else if (
            !this.changeMode &&
            this.creativeQuery.current_user_submit &&
            this.creativeQuery.current_user_submit._id
          ) {
            const temp = new FormControl(
              this.creativeQuery.current_user_submit.chosen_option[index],
              Validators.required
            );
            formArray.push(temp);
            temp.disable();
          } else {
            const temp = new FormControl(null, Validators.required);
            formArray.push(temp);
            setTimeout(() => {
              temp.enable();
            });
            this.changeMode = true;
          }
        } else {
          const tempMulti = this.formBuilder.array([]);
          x.options.forEach((element, innerIndex) => {
            if (this.creativeQuery.current_user_submit) {
              if (this.creativeQuery.current_user_submit.chosen_option[index].includes(innerIndex)) {
                tempMulti.push(new FormControl(true));
              } else {
                tempMulti.push(new FormControl(false));
              }
            } else {
              tempMulti.push(new FormControl(false));
            }
          });
          formArray.push(tempMulti);
        }
      });
    }
    return formArray;
  }

  initializeControls() {
    const formArray = <FormArray>this.form.controls['chosen_option'];
    for (let i = formArray.length - 1; i >= 0; i--) {
      if (this.changeMode) {
        setTimeout(() => formArray.at(i).enable());
        return;
      }
    }
  }

  disableOnSubmit() {
    const formArray = this.form.get('chosen_option') as FormArray;
    this.creativeQuery.questions.forEach((x, index) => {
      if (x.type === 'single' || x.type === 'vote') {
        if (this.creativeQuery.current_user_submit && this.creativeQuery.current_user_submit._id) {
          setTimeout(() => formArray.at(index).disable());
        }
      }
    });
  }

  async sendForm(operation = 'create', error = null) {
    if (this.form.valid) {
      const password = await this.passwordService.openPasswordDialog(error);
      if (!password) {
        return;
      }

      const value = this.form.value;
      value.password = password;
      this.creativeQuery.questions.forEach((element, index) => {
        if (element.type === 'multiple') {
          const chosen = [];
          value.chosen_option[index].forEach((e, innerIndex) => {
            if (e === true) {
              chosen.push(innerIndex);
            }
          });
          value.chosen_option[index] = chosen;
        } else {
          value.creative_query_question_id = this.creativeQuery.questions[0]._id;
          value.creative_query_id = this.creativeQuery._id;
        }
      });

      const APIMethod = operation === 'create' ? 'postCreativeQuerySubmitAnswer' : 'putCreativeQuerySubmitAnswer';
      const IDParam = operation === 'create' ? this.creativeQuery._id : this.creativeQuery.current_user_submit._id;

      this.creativeQueryService[APIMethod](IDParam, value)
        .pipe(untilDestroyed(this))
        .subscribe(
          async submitted => {
            // to disable form for better after submitting
            this.disableOnSubmit();
            const req = new SubmitCreativeQuerySubmissionRequest();
            req.submitId = submitted._id;
            req.password = password;
            this.changeMode = false;
            this.creativeQueryService
              .submitCreativeQuerySubmission(req)
              .pipe(untilDestroyed(this))
              .subscribe(
                res => {
                  this.toastService.openSnackBar('Your answer has been saved.', 'Creative query submit');
                  // @todo : redirect after submitting
                },
                err => {
                  if (err.status === 422 || err.status === 403) {
                    this.sendForm(operation, err.error);
                  }
                }
              );
          },
          err => {
            if (err.status === 422 || err.status === 403) {
              this.sendForm(operation, err.error);
            }
          }
        );
    }
  }

  trackQuestion(index: number, question) {
    return index.toString();
  }
  trackOption(index: number, option) {
    return index.toString();
  }

  ngAfterViewInit() {}

  openLowBalanceSheet(minBuy: number): void {
    const redirectUri = '/creative-query';
    this.matBottomSheetRef = this.bottomSheet.open(LowBalanceComponent, {
      data: { redirectUri, minBuy },
    });
    this.matBottomSheetRef.afterDismissed().subscribe(data => {});
  }

  changeResponse() {
    this.changeMode = true;
    this.initializeControls();
  }

  showPreview(event, file) {
    const dialogRef: FilePreviewOverlayRef = this.previewDialog.open({
      image: file,
    });
    event.stopPropagation();
  }

  changeOption(evt, val) {
    const selectedAnswer = [];
    selectedAnswer.push(val);
    this.form.get('chosen_option').setValue(selectedAnswer);
  }

  AddStake(stake) {
    let newStake = this.form.get('stake').value + stake;
    if (newStake <= 0) {
      newStake = 0;
    }
    this.form.get('stake').setValue(newStake);
  }
  ngOnDestroy() {}
}
