import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CreativeQueryState, CreativeQueryStore } from './creative-query.store';

@Injectable({
  providedIn: 'root',
})
export class CreativeQueryQuery extends QueryEntity<CreativeQueryState> {
  creativeQuery$ = this.select(state => state.creativeQuery);
  selectModerationFilters$ = this.select('moderationFilters');
  // selectSkip$ = this.select(state => state.skip);
  creativeQuery = this.getValue().creativeQuery;
  selectFilters$ = this.select('filters');

  // Active
  selectModerationQueryActive$ = this.select('moderationQueryActive');
  selectUserQueryActive$ = this.select('userQueryActive');

  // skips
  selectSkip$ = this.select('skip');
  selectUserSkip$ = this.select('userQuerySkip');
  selectModerationSkip$ = this.select('moderationQuerySkip');

  // Scroll
  selectScroll$ = this.select('scroll');
  selectModerationQueryScroll$ = this.select('moderationQueryScroll');
  selectUserQueryScroll$ = this.select('userQueryScroll');

  constructor(protected store: CreativeQueryStore) {
    super(store);
  }

  get filters() {
    return this.getValue().filters;
  }

  get moderationFilters() {
    return this.getValue().moderationFilters;
  }

  getActiveIndex() {
    return this.getAll().indexOf(this.getActive());
  }

  // Select
  getAllCreativeQueries() {
    return this.selectAll({
      filterBy: entity => {
        let returnValue = entity.status === this.getValue().filters.status;
        if (this.getValue().filters.search && this.getValue().filters.search !== '') {
          returnValue =
            returnValue &&
            (entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1);
        }
        return returnValue;
      },
      sortBy: (a, b) => {
        switch (this.getValue().filters.field) {
          case 'latest':
            return new Date(a.created_at) < new Date(b.created_at) ? 1 : 0;
        }
      },
      limitTo: this.getValue().skip,
    });
  }

  getAllCreativeQueriesValue() {
    return this.getAll({
      filterBy: entity => {
        let returnValue = entity.status === this.getValue().filters.status;
        if (this.getValue().filters.search && this.getValue().filters.search !== '') {
          returnValue =
            returnValue &&
            (entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1 ||
              entity.title.toLowerCase().indexOf(this.getValue().filters.search.toLowerCase()) > -1);
        }
        return returnValue;
      },
      sortBy: (a, b) => {
        switch (this.getValue().filters.field) {
          case 'latest':
            return new Date(a.created_at) < new Date(b.created_at) ? 1 : 0;
        }
      },
      limitTo: this.getValue().skip,
    });
  }

  getAllModerationCreativeQuery(userId) {
    return this.selectAll({
      filterBy: entity => {
        let returnValue = entity.status === 'to-be-moderated' && entity.user_id !== userId;

        if (this.getValue().moderationFilters.unlockedOnly) {
          returnValue = returnValue && !entity.locked_by;
        }
        return returnValue;
      },
      limitTo: this.getValue().moderationQuerySkip,
    });
  }

  getAllUserCreativeQuery(userId) {
    return this.selectAll({ filterBy: entity => entity.user_id === userId, limitTo: this.getValue().userQuerySkip });
  }

  getAllModerationCreativeQueryValue(userId) {
    return this.getAll({
      filterBy: entity => entity.status === 'to-be-moderated' && entity.user_id !== userId,
      limitTo: this.getValue().moderationQuerySkip,
    });
  }

  getAllUserCreativeQueryValue(userId) {
    return this.getAll({ filterBy: entity => entity.user_id === userId, limitTo: this.getValue().userQuerySkip });
  }

  getAllUserAndActiveQueryIds() {
    return this.getAll({ filterBy: entity => entity.status !== 'to-be-moderated' }).map(query => query._id);
  }

  getNextQueryValue(id) {
    return this.getNext(id, this.getAllCreativeQueriesValue());
  }
  getPreviousQueryValue(id) {
    return this.getPrevious(id, this.getAllCreativeQueriesValue());
  }

  getNextModerationQueryValue(id, userId) {
    return this.getNext(id, this.getAllModerationCreativeQueryValue(userId));
  }
  getPreviousModerationQueryValue(id, userId) {
    return this.getPrevious(id, this.getAllModerationCreativeQueryValue(userId));
  }

  getNextUserQueryValue(id, userId) {
    return this.getNext(id, this.getAllUserCreativeQueryValue(userId));
  }
  getPreviousUserQueryValue(id, userId) {
    return this.getPrevious(id, this.getAllUserCreativeQueryValue(userId));
  }

  // @TODO MOVE these methods in util methods
  getNext(id, allValues) {
    let currentIndex = 0;
    allValues.filter((obj, index) => {
      if (obj._id === id) {
        currentIndex = index;
        return obj;
      }
    });

    if (currentIndex + 1 < allValues.length) {
      return allValues[currentIndex + 1]._id;
    } else {
      return null;
    }
  }
  getPrevious(id, allValues) {
    let currentIndex = 0;
    allValues.filter((obj, index) => {
      if (obj._id === id) {
        currentIndex = index;
        return obj;
      }
    });

    if (currentIndex - 1 >= 0) {
      return allValues[currentIndex - 1]._id;
    } else {
      return null;
    }
  }
}
