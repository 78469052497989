import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ReviewService, ProgressService, EmbedVideoService } from '../../../services';
import { AuthenticatedUserQuery, AuthenticatedUserState } from '../../../state';
import { ReviewStatus } from '../../../../app.datatypes';

@Component({
  selector: 'app-user-evaluations',
  templateUrl: './user-evaluations.component.html',
  styleUrls: ['./user-evaluations.component.scss'],
})
export class UserEvaluationsComponent implements OnInit, OnDestroy {
  userEvaluations: any[];
  @Input() user: AuthenticatedUserState;
  authUser: any;
  displayedColumnsEvaluations: Array<string>;
  isMoreAvailable = true;
  reviewStatus = ReviewStatus;

  // scroll Pagination variables
  skip = 0;
  currentScrollPosition = 0;
  selectedTab: number;

  // Spinner variables
  progress: any;
  constructor(
    private evaluationService: ReviewService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private elRef: ElementRef,
    private embedService: EmbedVideoService,
    private authenticatedUserQuery: AuthenticatedUserQuery
  ) {
    this.displayedColumnsEvaluations = ['title', 'blockchain_score', 'recommendation', 'created', 'status'];
  }

  ngOnInit() {
    this.authenticatedUserQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(async user => {
        this.authUser = user;
      });
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.evaluationService
      .getAuthenticatedUserStateEvaluations(this.authUser.user_type_id === 4 ? this.user._id : null)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.userEvaluations = result;
          this.skip = this.userEvaluations.length;
          if (result.length < 20) {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.evaluationService
      .getAuthenticatedUserStateEvaluations(this.authUser.user_type_id === 4 ? this.user._id : null, this.skip)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.userEvaluations;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.userEvaluations = oldData;
            this.skip = this.skip + result.length;
            if (result.length < 20) {
              this.isMoreAvailable = false;
            }
            // IMPORTANT SERVER side depadancy (Should match LIMIT OF Server  )
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadPage() {
    this.progressService.showSpinner(this.progress, this.elRef);
  }

  ngOnDestroy() {}
}
