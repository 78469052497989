import { Component, OnInit, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ToasterService, ProgressService, ErrorsHandlerService } from '../../shared';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SEOService } from '../../shared';
import { AuthService } from '../../shared/services/auth.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fade, openDown, panelIn } from '../../app.animations';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],

  host: {
    class: 'fixed top-0 left-0 h-full bg-sidebar z-10',
  },
  animations: [openDown, fade, panelIn],
})
export class ForgotComponent implements OnInit, OnDestroy {
  form: FormGroup;
  progress: any;
  loadingOverlay = true;
  @Input()
  outerContainer?: boolean;
  @Output()
  showProgress = new EventEmitter();
  @Output()
  hideProgress = new EventEmitter();
  @Output()
  close = new EventEmitter();

  constructor(
    private authService: AuthService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private _seoService: SEOService,
    private progressService: ProgressService,
    private elRef: ElementRef,
    public errorHandler: ErrorsHandlerService
  ) {}

  ngOnInit() {
    this._seoService.createTitleForPage();
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit() {
    if (this.form.valid) {
      this.showSpinner();
      this.authService
        .postRequestUserAttributeChange('password', this.form.value)
        .pipe(untilDestroyed(this))
        .subscribe(
          res => {
            this.hideSpinner();
            this.toasterService.openSnackBar('Reset link has been sent. Check your email.', 'Info', 'info', 3000);
            this.close.emit(null);
          },
          err => {
            this.hideSpinner();
            if (err.status === 403) {
              this.errorHandler.handleSubmitError(err.error);
            } else {
              this.toasterService.openSnackBar('Something went wrong. Please, try again', 'Error', 'error', 3000);
            }
          }
        );
    }
  }

  keyDownFunction(event) {
    if (this.form.valid) {
      if (event.keyCode === 13) {
        this.submit();
      }
    }
  }
  ngOnDestroy() {}

  showSpinner() {
    if (this.outerContainer) {
      this.showProgress.emit();
    } else {
      this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    }
  }

  hideSpinner() {
    if (this.outerContainer) {
      this.hideProgress.emit();
    } else {
      this.progress = this.progressService.hideSpinner(this.progress);
    }
  }
}
