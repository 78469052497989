import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DcpModalComponent } from './dcp-modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DcpModalComponent],
  exports: [DcpModalComponent],
})
export class DcpModalModule {}
