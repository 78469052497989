import { Component, OnInit, Input } from '@angular/core';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-local-loader',
  templateUrl: './local-loader.component.html',
  styleUrls: ['./local-loader.component.scss'],
  host: {
    class: 'relative',
  },
})
export class LocalLoaderComponent implements OnInit {
  color = 'black';
  width = '20%';
  constructor() {}

  ngOnInit() {}
}
