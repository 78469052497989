import { Component, Inject, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModerationRequest } from '../../../app.datatypes';
import { ApiService, ProgressService, ReviewService, ProposalService } from '../../services';

@Component({
  selector: 'app-moderate',
  templateUrl: './moderate.component.html',
  styleUrls: ['./moderate.component.scss'],
})
export class ModerateComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  form: FormGroup;
  type: string;
  typeValue: string;

  // Spinner variables
  progress: any;

  public checks: Array<any>;

  /*
  = [
    {
      description: 'Content is not appropriate.',
      value: 'not_appropriate',
    },
    {
      description: 'Content is not properly categorized.',
      value: 'not_categorized',
    },
    {
      description: 'Content is not within the accepted content guidelines.',
      value: 'not_in_guidelines',
    },
    {
      description: 'Content is racist or hateful in nature.',
      value: 'racist_hateful',
    },
    {
      description: 'Content is pornographic in nature.',
      value: 'pornographic',
    },
  ];
  */

  constructor(
    private apiService: ApiService,
    private proposalService: ProposalService,
    private reviewService: ReviewService,
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModerateComponent>,
    private service: ProgressService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {
    this.type = data.type;
    if (this.type === 'proposal') {
      this.typeValue = 'proposal';
    } else if (this.type === 'creative_query') {
      this.typeValue = 'creative query';
    } else if (this.type === 'review') {
      this.typeValue = 'review ';
    } else if (this.type === 'quiz') {
      this.typeValue = 'quiz ';
    }
  }

  ngOnInit() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);

    this.proposalService
      .getProposalRejectReasons()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.progressService.hideSpinner(this.progress);
          this.checks = data;
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );

    this.form = this.formBuilder.group({
      acceptable: ['approved', Validators.required],
      reason: new FormArray([]),
      comments: [''],
    });
  }

  moderate() {
    const moderation = {} as ModerationRequest;
    moderation.moderation_type = this.type;
    moderation.model_id = this.data.entity._id;
    if (this.form.value.acceptable === 'approved') {
      moderation.status = this.form.value.acceptable;
      moderation.comments = this.form.value.comments;
    } else if (this.form.value.acceptable === 'denied') {
      moderation.status = this.form.value.acceptable;
      moderation.comments = this.form.value.comments;
      moderation.reason = this.form.value.reason;
    }

    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.reviewService
      .postModerate(moderation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.dialogRef.close({
            id: this.data.entity._id,
            status: this.form.value.acceptable,
            moderation: result,
          });
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  onCheck(event) {
    const formArray = this.form.get('reason') as FormArray;
    /* Selected */
    if (event.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.source.value));
    } else {
      /* unselected */
      // find the unselected element
      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.source.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
