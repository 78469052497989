import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticatedUserQuery, SharedService, AuthenticatedUserState } from '../../../../shared';
import { UserEngagementData } from '../../../../app.datatypes';

@Component({
  selector: 'app-topbar-menu',
  templateUrl: './topbar-menu.component.html',
  styleUrls: ['./topbar-menu.component.scss'],
})
export class TopbarMenuComponent implements OnInit {
  @Input() user: AuthenticatedUserState;
  @Output() showInboxBar = new EventEmitter<any>();

  refreshEngagementRetries = 0; // count engagement retries so we don't retry
  refreshEarningsRetries = 0; // count engagement retries so we don't retry
  hasRefreshTimeout = false;
  isCoinAnimation = false;
  notificationCount: number;

  info: UserEngagementData = {
    likes: 0,
    likesRight: 0,
    review: 0,
    reviewRight: 0,
    creativeQuery: 0,
    creativeQueryRight: 0,
    fillChart: 0,
    fill: 0,
    dasharay: '0',
    all: 0,
    taken: 0,
    potentialEarning: 0,
    potentialEarningTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  };

  constructor(public authenticatedUserQuery: AuthenticatedUserQuery, private sharedService: SharedService) {
    this.sharedService.getCoinAnimation().subscribe(data => {
      this.isCoinAnimation = true;
      setTimeout(() => {
        this.isCoinAnimation = false;
      }, 3000);
    });
    this.sharedService.getUpdateNotificationCount().subscribe(data => {
      if (data.isRead === true && data.isAllRead === false) {
        --this.notificationCount;
      }
      if (data.isRead === true && data.isAllRead === true) {
        this.notificationCount = 0;
      }
    });
  }

  ngOnInit() {
    this.notificationCount = this.user.notifications_count;
    this.authenticatedUserQuery.select().subscribe();

    this.info = {
      likes: 0,
      likesRight: 0,
      review: 0,
      reviewRight: 0,
      creativeQueryRight: 0,
      creativeQuery: 0,

      all: 0,
      taken: 0,
      fillChart: 0,
      fill: 0,
      dasharay: '0',
      potentialEarning: 0.0,
      potentialEarningTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };

    this.authenticatedUserQuery.authenticatedUser$.subscribe(state => {
      if (state.user_engagement_data && state.potential_earnings !== undefined) {
        this.info = Object.assign({}, state.user_engagement_data);
        this.info.potentialEarningTime = Object.assign({}, state.user_engagement_data.potentialEarningTime);
        this.info.potentialEarning = state.potential_earnings;
      }
    });
  }

  showInfoBoxFn() {
    this.showInboxBar.emit();
  }
}
