import { Directive, HostListener, HostBinding, EventEmitter, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDisableButtonOnAction]',
})
export class DisableButtonOnActionDirective implements OnInit {
  @Input('appDisableButtonOnAction') enableButton: EventEmitter<boolean>;
  @HostBinding('disabled')
  disabled: boolean;

  ngOnInit() {
    this.enableButton.subscribe(() => {
      this.disabled = false;
    });
  }

  @HostListener('click') disableButton() {
    this.disabled = true;
  }
}
