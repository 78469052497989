import { Component, Inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-unauthorized-snackbar',
  templateUrl: './unauthorized-snackbar.component.html',
  styles: [
    `
      .close-div {
        position: fixed;
        right: 0;
        top: 0;
      }
      mat-icon {
        font-size: 0.9rem;
      }
    `,
  ],
})
export class UnauthorizedSnackBarComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<UnauthorizedSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
