import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService, ProgressService } from '../../services';
import { AuthenticatedUserQuery, AuthenticatedUserService } from '../../state';

@Component({
  selector: 'app-user-token-earnings',
  templateUrl: './user-token-earnings.component.html',
  styleUrls: ['./user-token-earnings.component.scss'],
})
export class UserTokenEarningsComponent implements OnInit {
  @Input() user: any;
  earnings = null;
  dataSourceEarnings: any;
  isMoreAvailable = true;
  skip = 0;
  limit = 10;
  progress: any;
  service = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public authenticatedUserService: AuthenticatedUserService,
    private userService: UserService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.loadEarnings();
  }

  processEarnings(earnings) {
    this.earnings = earnings;
    this.dataSourceEarnings = new MatTableDataSource(earnings);
  }

  private loadEarnings() {
    this.userService
      .getUserTokenEarnings(this.user._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(earnings => {
        this.processEarnings(earnings);
        this.skip = earnings.length;
        if (earnings.length < this.limit) {
          this.isMoreAvailable = false;
        }
      });
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.userService
      .getUserTokenEarnings(this.user._id, this.skip, this.limit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.dataSourceEarnings.data;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.dataSourceEarnings.data = oldData;
            this.skip = this.skip + result.length;
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }
}
