import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class S3UploadService {
  constructor(private apiService: ApiService, private httpService: HttpClient) {}

  public getPreSignedURL(fileName: string, contentType): Observable<any> {
    return this.apiService.get(`api/get-pre-signed-url?fileName=${fileName}&contentType=${contentType}`);
  }

  public uploadToS3(preSignedUrl: string, file: Blob): Observable<any> {
    return this.httpService.put(preSignedUrl, file);
  }
}
