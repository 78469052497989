import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import { ToasterService } from './toaster.service';

@Injectable()
export class WalletGuardService implements CanActivate {
  constructor(
    private appService: AuthenticatedUserQuery,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  canActivate() {
    if (!this.appService.user.seed_public_address) {
      this.toasterService.openSnackBar('You must create a wallet first', 'Wallet not created');
      this.router.navigate(['/account/wallet']);
    }
    return true;
  }
}
