import { Component, ElementRef, EventEmitter, forwardRef, OnInit, Output, ViewChild } from '@angular/core';
import { dcpDateFormat } from '../../formatterFunctions';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedService } from '../../../../../src/app/shared/services';

/* tslint:disable:no-use-before-declare */
export const DATEPICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DcpDatePickerComponent),
  multi: true,
};

/* tslint:enable:no-use-before-declare */

@Component({
  selector: 'app-dcp-date-picker',
  templateUrl: './dcp-date-picker.component.html',
  styleUrls: ['./dcp-date-picker.component.scss'],
  providers: [DATEPICKER_CONTROL_VALUE_ACCESSOR],
})
export class DcpDatePickerComponent implements OnInit, ControlValueAccessor {
  constructor(private sharedService: SharedService) {
    this.sharedService.getResetFilter().subscribe(data => {
      this.el.nativeElement.value = '';
    });
  }

  optionsDatepicker: any = {
    parentEl: '.datepicker-parent',
    locale: { format: 'MM-DD-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false,
    autoApply: true,
  };

  @ViewChild('dateElem') el: ElementRef;
  @Output() selectedDateFilter: EventEmitter<string> = new EventEmitter<string>();

  value: any;
  disabled = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit() {}

  public selectedDate(value: any) {
    if (!value) {
      return;
    }
    const dateString = dcpDateFormat(value.start);
    this.el.nativeElement.value = dateString;
    this.onChange(dateString);
    this.selectedDateFilter.emit(dateString);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (value) {
      this.value = dcpDateFormat(value);
    }
  }
}
