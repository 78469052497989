import { Component, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor {
  private _password: string;

  hasNumber = false;
  hasLowerCase = false;
  hasUpperCase = false;
  hasEnoughLen = false;
  /** custom placeholder */
  @Input() placeholder: string;
  /** if defined - mat-form-field outline type */
  @Input() appearance = '';

  @Input() set password(value: string) {
    this._password = value;
    this.propagateChange(value);
  }

  typePass() {
    this.hasNumber = this.checkHasNumber(this._password);
    this.hasLowerCase = this.checkHasLowerCase(this._password);
    this.hasUpperCase = this.checkHasUpperCase(this._password);
    this.hasEnoughLen = this.checkLen(this._password);
  }

  get password() {
    return this._password;
  }

  constructor() {}

  public writeValue(value: string) {
    this._password = value;

    this.propagateChange(value);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {}

  private propagateChange = (_: any) => {};

  public checkLen(pass) {
    return pass.length >= 8;
  }

  public checkHasLowerCase(pass) {
    const pattern = /[a-z]/g;

    const check = pass.match(pattern);
    if (check == null) {
      return false;
    }
    return check.length > 0;
  }

  public checkHasUpperCase(pass) {
    const pattern = /[A-Z]/g;
    const check = pass.match(pattern);
    if (check == null) {
      return false;
    }
    return check.length > 0;
  }

  public checkHasNumber(pass) {
    const pattern = /[0-9]/g;

    const check = pass.match(pattern);
    if (check == null) {
      return false;
    }
    return check.length > 0;
  }
}
