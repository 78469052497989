import { Injectable } from '@angular/core';
import { StoreConfig, Store, ID } from '@datorama/akita';
import { UserTypes, UserVerificationStatus } from '../../enumerations/';
import { UserEngagementData } from '../../../app.datatypes';

export interface AuthenticatedUserState {
  _id: string;
  email: string;
  name: string;
  user_type_id: UserTypes;
  user_verification_status: UserVerificationStatus;
  sms_verification_status: string;
  is_verification_sms_sent: boolean;
  seed_public_address: string;
  has_client_key: boolean;
  wallet_restore_required: boolean;
  reputation: number;
  google2fa_enabled?: boolean;
  role: any;
  balance: number;
  locked?: number;
  avatar?: string;
  created_at?: string;
  can_be_moderator?: boolean;
  can_refuse_moderator_access?: boolean;

  proposals?: any;
  user_notifications?: any;
  notifications_count: number;
  awaiting_become_moderator_request?: boolean;

  is_dark_theme: boolean;
  is_grayscale: boolean;

  /** Profile related */
  first_name?: string;
  last_name?: string;
  sex?: string;
  dob?: string;
  street?: string;
  zipPostal?: string;
  country?: string;
  avatar_url?: string;
  mobile?: string;
  mainnet_from_requested?: boolean;
  moderation_in_progress: boolean;
  to_be_moderated_entity: any;
  submission_id?: string;
  cognito_flow_id?: string;

  ethnicity?: string;
  favoriteGenre?: string;
  favoriteFilm?: string;
  favoriteDirector?: string;
  bio?: string;
  personal_website_url?: string;
  facebook_url?: string;
  youtube_url?: string;
  instagram_url?: string;
  twitter_url?: string;
  vimeo_url?: string;
  imdb_url?: string;
  visible_public_profile_data?: [];
  user_engagement_data?: UserEngagementData;
  potential_earnings?: number;
  languages: [
    {
      _id;
      iso;
      name;
    }
  ];

  /** Discord integration */
  discord_user_id?: string;
  discord_username?: string;
}

function createInitialState(): AuthenticatedUserState {
  return {} as AuthenticatedUserState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'authenticated-user',
  resettable: true,
})
export class AuthenticatedUserStore extends Store<AuthenticatedUserState> {
  constructor() {
    super(createInitialState());
  }
}
