import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dcp-hint',
  templateUrl: './dcp-hint.component.html',
  styleUrls: ['./dcp-hint.component.css'],
})
export class DcpHintComponent implements OnInit {
  showStakeHelp = false;
  constructor() {}

  ngOnInit() {}
}
