import { Injectable } from '@angular/core';
import { AuthenticatedUserStore, AuthenticatedUserState } from './authenticated-user.store';

import { ApiService } from '../../services/api.service';
import { CryptoUtil } from '../../../shared/util/crypto-util';
import { UploadedFile, FileProgressObject } from '../../../app.datatypes';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { AuthenticationQuery } from '../authentication/authentication.query';
import { resetStores } from '@datorama/akita';
import { AuthenticationService } from '../authentication';
import { UserTypes } from '../../enumerations/user-types.enum';
import { AuthenticatedUserQuery } from './authenticated-user.query';
import { UserVerificationStatus } from '../../enumerations';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUserService {
  constructor(
    private authStore: AuthenticatedUserStore,
    private apiService: ApiService,
    private authenticationQuery: AuthenticationQuery,
    private authenticatedUserQuery: AuthenticatedUserQuery
  ) {}

  userByToken() {
    if (this.authenticationQuery.getValue().access_token) {
      this.authStore.setLoading(true);
      return this.get().pipe(
        tap(authUserState => {
          this.authStore.update(authUserState);
          this.authStore.setLoading(false);
        }),
        catchError(error => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
          return this.apiService.catchError(error);
        })
      );
    } else {
      return of(null);
    }
  }

  register(user): Observable<AuthenticatedUserState> {
    return this.apiService.post('api/users', user).pipe(
      tap(authUserState => {
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  resetStates() {
    return resetStores({ exclude: ['authenticated-user', 'authentication'] });
  }

  initializeAppUserState(): Promise<any> {
    const observable = this.userByToken();
    return observable
      ? observable.toPromise().catch(e => {
          return null;
        })
      : null;
  }

  updateUser(data): Observable<AuthenticatedUserState> {
    return this.update(data).pipe(tap(authUserState => this.authStore.update(authUserState)));
  }

  updateMainnetRequeste(value) {
    this.authStore.update({ mainnet_from_requested: value });
  }

  // create separate password state. and selector
  async setPassword(value, user) {
    window.localStorage['password'] = await CryptoUtil.encrypt(value, user._id);
  }

  setClientWallet(publicAddress, encryptedSeed) {
    localStorage.setItem(`${publicAddress}`, encryptedSeed);
  }

  setWallet(key) {
    this.authStore.update({ seed_public_address: key });
  }

  setUserBalance(balance) {
    this.authStore.update({ balance });
  }

  setUserLockedBalance(locked) {
    this.authStore.update({ locked });
  }

  setUserReputation(reputation) {
    this.authStore.update({ reputation });
  }

  setUserTypeID(user_type_id) {
    this.authStore.update({ user_type_id });
  }

  setModeratorAttributes(user_type_id, awaiting_become_moderator_request) {
    this.authStore.update({ user_type_id, awaiting_become_moderator_request });
  }

  updateAvatarImage(data, fileProgressObject: FileProgressObject): Observable<any> {
    const url = 'api/profile/set-avatar';
    return this.apiService.postWithAttachment(url, data).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileProgressObject.progress = Math.round(
              ((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1)
            );

            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap((event: any) => {
        if (typeof event === 'object') {
          this.authStore.update({ avatar_url: event.body.data.file_url });
        }
      }),
      last(),
      catchError((error: HttpErrorResponse) => {
        fileProgressObject.inProgress = false;
        fileProgressObject.canRetry = true;

        return of(`${fileProgressObject.file.name ? fileProgressObject.file.name : ''} upload failed.`);
      })
    );
  }

  confirmSMSMobileVerification(code): Observable<any> {
    return this.apiService.post('api/verify/sms/confirm', { code }).pipe(tap(res => this.authStore.update(res)));
  }

  confirmEmailVerification(token: string): Observable<any> {
    // @todo Test it for unauthorized user This may break
    return this.apiService
      .get('api/verify/email?t=' + token)
      .pipe(
        tap(res =>
          this.authStore.update({ user_verification_status: UserVerificationStatus.EMAIL_VERIFIED, role: 'Member' })
        )
      );
  }

  // Api Calls
  update(data: any): Observable<AuthenticatedUserState> {
    return this.apiService.put('api/profile', data).pipe(
      tap(authUserState => {
	console.log(authUserState);
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  createFlowSubmission(data: any): Observable<any> {
    return this.apiService.post('api/verify/create_flow_submission', data).pipe(
      tap(() => {
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateKYCDetails(data: any): Observable<AuthenticatedUserState> {
    return this.apiService.put('api/profile/kyc', data).pipe(
      tap(authUserState => {
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  postRequestUserAttributeChange(attribute: string, data: any): Observable<AuthenticatedUserState> {
    const copyData = { ...data };
    if (copyData.password) {
      copyData.password = CryptoUtil.bcryptHash(data.password);
    }
    return this.apiService.post('api/request-change/' + attribute, copyData).pipe(
      tap(authUserState => {
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  putChangeUsername(data: any): Observable<AuthenticatedUserState> {
    const copyData = { ...data };
    if (copyData.password) {
      copyData.password = CryptoUtil.bcryptHash(data.password);
    }
    return this.apiService.put('api/profile/username', copyData).pipe(
      tap(authUserState => {
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateProfile(data: any): Observable<AuthenticatedUserState> {
    return this.apiService.put('api/profile/personal-info', data).pipe(
      tap(authUserState => {
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateAbout(data: any): Observable<AuthenticatedUserState> {
    return this.apiService.put('api/profile/about', data).pipe(
      tap(authUserState => {
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updatePublicVisibility(field: string): void {
    const visibilityObject = this.authenticatedUserQuery.visiblePublicProfileData;
    const arrayIndex = visibilityObject.indexOf(field);
    if (arrayIndex === -1) {
      visibilityObject.push(field);
    } else {
      visibilityObject.splice(arrayIndex, 1);
    }
    if (visibilityObject.length === 0) {
      visibilityObject.push('name');
    }

    this.apiService
      .put('api/profile/public-data-visibility', { visible_public_profile_data: visibilityObject })
      .pipe(
        tap(authUserState => {
          console.dir(authUserState);
          this.authStore.update(authUserState);
          this.authStore.setLoading(false);
        }),
        catchError(error => {
          console.dir(error);
          this.authStore.setError(error);
          this.authStore.setLoading(false);
          return this.apiService.catchError(error);
        })
      )
      .subscribe();
  }

  updateSocialMedia(data: any): Observable<AuthenticatedUserState> {
    return this.apiService.put('api/profile/socials', data).pipe(
      tap(authUserState => {
        this.authStore.update(authUserState);
        this.authStore.setLoading(false);
      }),
      catchError(error => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  /** ToDo rename this function to something more descriptive */
  get(): Observable<AuthenticatedUserState> {
    const api = 'api/account/dashboard';
    return this.apiService.get(api);
  }

  postRequestModeratorAccess(type, data): Observable<AuthenticatedUserState> {
    return this.apiService.post(`api/users/${type}-moderator-access`, data).pipe(tap(user => this.updateUser(user)));
  }

  setModeratedEntity(to_be_moderated_entity) {
    const moderation_in_progress = !!to_be_moderated_entity;
    this.authStore.update({ to_be_moderated_entity, moderation_in_progress });
  }

  updateUserEngagement(user_engagement_data) {
    this.authStore.update({ user_engagement_data });
  }

  updatePotentialEarnings(potential_earnings) {
    this.authStore.update({ potential_earnings });
  }

  unlock() {
    this.authStore.update({
      moderation_in_progress: false,
      to_be_moderated_entity: null,
    });
  }

  updateDiscordIntegration(discord_user_id?: string, discord_username?: string) {
    this.authStore.update({
      discord_user_id,
      discord_username,
    });
  }
}
