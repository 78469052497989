import { Component, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Proposal } from './../../../app/app.datatypes';
import { ProgressService, ProposalQuery, ProposalStateService } from './../../../app/shared';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  progress: any;
  proposals$: Observable<Proposal[]>;

  scrollDistance = 1;
  scrollUpDistance = 2;
  scrollWindow = false;

  constructor(
    protected proposalQuery: ProposalQuery,
    protected proposalStateService: ProposalStateService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.proposalQuery.selectLoading().subscribe(loading => {
      if (loading) {
        this.progress = this.progressService.showSpinner(this.progress, this.elRef);
      } else {
        this.progressService.hideSpinner(this.progress);
      }
    });

    this.proposals$ = this.proposalQuery.getAllReviewProposal();
  }

  openProposal(proposal) {
    const id = proposal._id;

    if (this.proposalQuery.getActive() === id) {
      return;
    }
    this.proposalStateService.setActive(id);
  }
}
