import { Injectable } from '@angular/core';
import { Password, PasswordStore } from './password.store';
import { Query } from '@datorama/akita';
import { CryptoUtil } from '../../util/crypto-util';
import { AuthenticationService } from '../authentication/authentication.service';
import { AuthenticatedUserQuery } from '../authenticated-user';

@Injectable({ providedIn: 'root' })
export class PasswordQuery extends Query<Password> {
  constructor(
    protected store: PasswordStore,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private authenticationService: AuthenticationService
  ) {
    super(store);
  }

  async getPassword() {
    if (this.getValue().password) {
      try {
        return CryptoUtil.decrypt(window.localStorage['password'], this.authenticatedUserQuery.user._id);
      } catch (ex) {
        this.authenticationService.logout();
      }
    } else {
      return null;
    }
  }
}
