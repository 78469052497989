import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() notification;
  @Output() setRead = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  getEntityUrl(type, id) {
    let url = '/';
    switch (type) {
      case 'proposal':
      case 'proposal draft': {
        url = '/proposal/' + id;
        break;
      }
      case 'creative query':
        url = '/creative-query/' + id;
        break;
      case 'review':
        url = '/review/' + id;
        break;
    }

    return url;
  }

  readNotification(notification) {
    if (notification.read_time == null) {
      this.setRead.emit(notification._id);
    }
  }
}
