import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService, ApiService, ProgressService } from '../../../services';
import { AuthenticatedUserState } from '../../../state';
import { Activity } from '../../../../app.datatypes';
@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
})
export class UserActivityComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() user: AuthenticatedUserState;

  userActivities: Activity[];

  dataSourceActivity: any;
  displayedColumnsActivity: Array<string>;

  isMoreAvailable = true;

  // scroll Pagination variables
  skip = 0;
  currentScrollPosition = 0;
  selectedTab: number;
  // Spinner variables
  progress: any;
  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {
    this.displayedColumnsActivity = ['message', 'action', 'created_at'];
  }

  ngOnInit() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.userService
      .getUserActivity(this.user.user_type_id === 4 ? this.user._id : null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          this.userActivities = result;
          if (result.length < 10) {
            this.isMoreAvailable = false;
          }
          this.dataSourceActivity = new MatTableDataSource(result);
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }

  loadMore() {
    if (this.isMoreAvailable) {
      this.appendItems();
    }
  }

  appendItems() {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.userService
      .getUserActivity(this.user.user_type_id === 4 ? this.user._id : null, this.skip)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          this.progressService.hideSpinner(this.progress);
          let k = 0;
          const oldData = this.dataSourceActivity.data;
          if (result.length > 0) {
            for (let i = this.skip; i < this.skip + result.length; ++i) {
              oldData.push(result[k]);
              k++;
            }

            this.dataSourceActivity.data = oldData;
            this.skip = this.skip + result.length;
            // IMPORTANT SERVER side depadancy (Should match LIMIT OF Server  )
            if (result.length < 10) {
              this.isMoreAvailable = false;
            }
          } else {
            this.isMoreAvailable = false;
          }
        },
        error => {
          this.progressService.hideSpinner(this.progress);
        }
      );
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouteLink(activity) {
    if (!activity.model_id) {
      return [];
    }
    switch (activity.type) {
      case 'proposal':
        return ['/proposal', activity.model_id];
      case 'review':
        return ['/review', activity.model_id];
      case 'creative query':
        return ['/creative-query', activity.model_id];
      default:
        return [];
    }
  }
}
