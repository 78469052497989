import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscordChatUserSearchResponse } from '../interface/response/discord-chat-user-search-response';
import { DiscordChatCheckVerificationRequest } from '../interface/request/discord-chat-check-verification-request';
import { ApiService } from './api.service';
import { AuthenticatedUserService } from '..';
import { map, tap } from 'rxjs/operators';
import { DiscordChatVerificationSuccessResponse } from '../interface/response/discord-chat-verification-success-response';

@Injectable({
  providedIn: 'root',
})
export class DiscordChatService {
  constructor(private apiService: ApiService) {}

  searchUser(search: string): Observable<DiscordChatUserSearchResponse[]> {
    return this.apiService.get(`api/chats/search-user?searchString=${search}`).pipe(
      map(value => {
        return value.body;
      })
    );
  }

  sendVerification(user: DiscordChatUserSearchResponse): Observable<any> {
    return this.apiService.post('api/chats/verify/start', user);
  }

  checkVerification(token: string): Observable<any> {
    const request: DiscordChatCheckVerificationRequest = { token };
    return this.apiService.post('api/chats/verify/check', request);
  }

  resetVerification(): Observable<any> {
    return this.apiService.post('api/chats/verify/reset', null);
  }
}
