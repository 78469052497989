import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  CreativeQuery,
  CreativeQuerySubmit,
  Question,
  Option,
  SubmitCreativeQuerySubmissionRequest,
  SubmitCreativeQueryRequest,
} from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class CreativeQueryService {
  constructor(private apiService: ApiService) {}

  getCreativeQuery(id: string): Observable<CreativeQuery> {
    return this.apiService.get('api/creative-query/' + id);
  }

  // @todo :create result Response type
  getCreativeQueryResults(id: string): Observable<any> {
    return this.apiService.get('api/creative-query/' + id + '/results');
  }

  getCreativeQueryTableResults(id: string): Observable<any> {
    return this.apiService.get('api/creative-query/' + id + '/results-table');
  }

  getCreativeQuerySubmissions(id: string): Observable<any> {
    // this endpoint is not implemented in back-end (or deleted)
    return this.apiService.get('api/creative-query/' + id + '/all-submissions');
  }

  isCreativeQuerySubmitted(creative_query_id: string): Observable<CreativeQuerySubmit> {
    return this.apiService.get('api/creative-query-submissions/' + creative_query_id + '/is-submitted');
  }

  getCreativeQueryQuestionOptions(id: string): Observable<Option[]> {
    return this.apiService.get('api/creative-query-question-options/' + id);
  }

  getUserCreativeQuerys(userid, skip = 0, search = ''): Observable<CreativeQuery[]> {
    let apiUrl = 'api/creative-query/user/';
    if (userid && userid !== null) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  getListCreativeQueries(field = 'latest', skip = 0, search = '', status = ''): Observable<CreativeQuery[]> {
    return this.apiService.get(
      'api/creative-query/?' +
        'field=' +
        field +
        '&direction=desc' +
        '&skip=' +
        skip +
        (search ? '&search=' + encodeURIComponent(search) : '') +
        (status ? '&status=' + status : '')
    );
  }

  getOwnCreativeQueries(skip = 0, search = ''): Observable<CreativeQuery[]> {
    return this.apiService.get(
      'api/creative-query/user?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  postCreativeQuery(data): Observable<CreativeQuery> {
    return this.apiService.post('api/creative-query', data);
  }

  submitCreativeQueryBlockchain(req: SubmitCreativeQueryRequest): Observable<any> {
    // +
    return this.apiService.post('api/creative-query/' + req.creativeQueryId + '/submit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  cancelCreativeQuery(creativeQuery: CreativeQuery) {
    return this.apiService.get(`api/creative-query/${creativeQuery._id}/cancel`);
  }

  postCreativeQuerySubmit(id = null, data): Observable<any> {
    return this.apiService.post(`api/creative-query-submissions`, data);
  }

  putCreativeQuerySubmit(creative_query_submission_id, data): Observable<any> {
    return this.apiService.put(`api/creative-query-submissions/${creative_query_submission_id}`, data);
  }

  submitCreativeQuerySubmission(req: SubmitCreativeQuerySubmissionRequest): Observable<any> {
    // +
    return this.apiService.post('api/creative-query-submissions/' + req.submitId + '/submit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  resubmitCreativeQuerySubmission(req: SubmitCreativeQuerySubmissionRequest): Observable<any> {
    // +
    return this.apiService.post('api/creative-query-submissions/' + req.submitId + '/resubmit', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  postQuestion(question: Question): Observable<Question> {
    return this.apiService.post('api/creative-query-questions', question);
  }

  putQuestion(id: string, question: Question): Observable<Question> {
    return this.apiService.put('api/creative-query-questions/' + id, question);
  }

  deleteQuestion(id: string) {
    return this.apiService.delete('api/creative-query-questions/' + id);
  }

  postOption(option: Option): Observable<Option> {
    return this.apiService.post('api/creative-query-question-options', option);
  }

  putOption(id: string, option: Option): Observable<Option> {
    return this.apiService.put('api/creative-query-question-options/' + id, option);
  }

  deleteOption(id: string) {
    return this.apiService.delete('api/creative-query-question-options/' + id);
  }

  putCreativeQuery(id: string, data): Observable<CreativeQuery> {
    return this.apiService.put('api/creative-query/' + id, data);
  }

  getModeratableCreativeQueries(skip = 0): Observable<CreativeQuery[]> {
    return this.apiService.get('api/creative-query/moderate?skip=' + skip);
  }
}
