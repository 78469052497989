import { Component, OnInit, ElementRef, OnDestroy, AfterContentInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, Quiz } from '../../app.datatypes';
import { ProgressService, QuizService, WindowScrollService } from '../../shared';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-reputation-board',
  templateUrl: './reputation-board.component.html',
  styleUrls: ['./reputation-board.component.scss'],
})
export class ReputationBoardComponent implements OnInit, AfterContentInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  displayedColumns1 = ['number', 'name', 'reputation'];
  displayedColumns2 = ['number', 'name', 'score'];
  dataSource1: MatTableDataSource<User>;
  dataSource2: MatTableDataSource<User>;
  users: User[];
  quizzes: Quiz[];
  // Spinner variables
  progress: any;
  quizBoard = false;

  constructor(
    public windowScrollService: WindowScrollService,
    private quizService: QuizService,
    private authService: AuthService,
    private progressService: ProgressService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.getTopUsers();
    this.getTopQuizzes();
    this.windowScrollService.hideFooter();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTopUsers(): void {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.authService
      .getTopUsers(100)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(users => {
        this.progressService.hideSpinner(this.progress);
        this.users = users.sort((a, b) => b.reputation - a.reputation);
        this.dataSource1 = new MatTableDataSource(users);
      });
  }

  getTopQuizzes(): void {
    this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    this.quizService
      .getTopQuizzes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(quizzes => {
        this.progressService.hideSpinner(this.progress);
        this.quizzes = quizzes;
        this.dataSource2 = new MatTableDataSource(quizzes);
      });
  }
  ngAfterContentInit() {
    this.windowScrollService.showFooterOnScroll();
  }
  toggleBoard() {
    this.quizBoard = !this.quizBoard;
  }
}
