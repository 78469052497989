import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { dropdown } from '../../../app.animations';

@Component({
  selector: 'app-dcp-multiselect',
  templateUrl: './dcp-multiselect.component.html',
  styleUrls: ['./dcp-multiselect.component.scss'],
  animations: [dropdown],
})
export class DcpMultiselectComponent implements OnInit {
  @Input() options: any;
  @Input() placeholder: any;
  @Input() filterOption = false;
  @Input() selectedOptions = [];
  @Input() optionNameField = 'name';
  @Input() disabled = false;
  @Output() selected = new EventEmitter<any>();

  @ViewChild('dhCompId', { static: false }) dhCompId: ElementRef;
  @ViewChild('ddCompId', { static: false }) ddCompId: ElementRef;
  @ViewChild('filterTextInputCompId', { static: false }) filterTextInputCompId: ElementRef;

  filterText = '';
  isMultiselectOpen = false;
  compId: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    const dateInit = new Date();
    this.compId = dateInit.getTime();
    this.initializeVisible();
    this.initializeSelected();
  }

  initializeSelected() {
    this.selectedOptions.forEach(option => {
      this.options.find(elem => elem._id === option._id).selected = true;
    });
  }

  initializeVisible() {
    if (this.options) {
      for (let i = 0; i < this.options?.length; i++) {
        this.options[i].visible = true;
      }
    }
  }

  openClose(evt) {
    evt.stopImmediatePropagation();
    this.isMultiselectOpen = !this.isMultiselectOpen;
    if (this.isMultiselectOpen) {
      const dhId = 'dh-' + this.compId;
      const ddId = 'dd-' + this.compId;
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;

      const dhTop = dh.getBoundingClientRect().top;

      if (document.getElementsByTagName('body')[0].offsetHeight < dhTop + 330) {
        dd.style['bottom'] = '40px';
        dd.style['top'] = 'inherit';
      } else {
        dd.style['bottom'] = 'inherit';
        dd.style['top'] = '40px';
      }
    } else {
      this.selected.emit(this.selectedOptions);
    }
  }

  filterOptions(evt) {
    evt.stopImmediatePropagation();
    this.filterText = evt.target.value;

    if (this.filterText === '') {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].visible = true;
      }
    } else {
      for (let i = 0; i < this.options.length; i++) {
        const caseUnsensitive = this.options[i].name.toLowerCase();
        if (caseUnsensitive.indexOf(this.filterText) !== -1) {
          this.options[i].visible = true;
        } else {
          this.options[i].visible = false;
        }
      }
    }
  }

  clearText() {
    const id = 'filterTextInput-' + this.compId;
    this.renderer.setValue(this.filterTextInputCompId?.nativeElement, '');
    this.filterText = '';
    if (this.filterText === '') {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].visible = true;
      }
    }
  }

  select(option, evt) {
    evt.stopImmediatePropagation();
    if (option.selected === undefined) {
      option.selected = false;
    }
    option.selected = !option.selected;
    this.selectedOptions = [];
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].selected) {
        this.selectedOptions.push(this.options[i]);
      }
    }
  }

  removeSelected(option, evt) {
    evt.stopImmediatePropagation();
    option.selected = !option.selected;
    for (let i = 0; i < this.selectedOptions.length; i++) {
      if (this.selectedOptions[i]._id === option._id) {
        this.selectedOptions.splice(i, 1);
        setTimeout(() => {
          this.selected.emit(this.selectedOptions);
        }, 200);
        break;
      }
    }
  }
}
