import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { arrayUpdate, arrayRemove } from '@datorama/akita';
import { ApiService } from '../../../shared/services/api.service';
import {
  CreativeQuery,
  CreativeQuerySubmit,
  Question,
  Option,
  SubmitCreativeQuerySubmissionRequest,
  SubmitCreativeQueryRequest,
} from '../../../app.datatypes';
import { CreativeQueryStore } from './creative-query.store';
import { CreativeQueryQuery } from './creative-query.query';
import { environment } from '../../../../environments/environment';
import { AuthenticatedUserService } from '../authenticated-user';

@Injectable({
  providedIn: 'root',
})
export class CreativeQueryServices {
  constructor(
    private cqStore: CreativeQueryStore,
    private cqQuery: CreativeQueryQuery,
    private api: ApiService,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  setActive(id) {
    this.cqStore.setActive(id);
  }

  setUserQueryActive(id) {
    this.cqStore.update({ userQueryActive: id });
  }

  setModerationQueryActive(id) {
    this.cqStore.update({ moderationQueryActive: id });
  }

  setActiveNext() {
    this.cqStore.setActive({ next: true, wrap: false });
  }

  setActivePrev() {
    this.cqStore.setActive({ prev: true, wrap: false });
  }

  setStatus(id, status) {
    this.cqStore.update(id, { status });
  }

  setLockedData(id, locked_by, locked_at) {
    this.cqStore.update(id, { locked_by, locked_at });
  }

  onAnswerCreateUpdate(id, total_votes, total_stake) {
    this.cqStore.update(id, { total_votes, total_stake });
  }

  queryModerated(cq) {
    this.cqStore.update(cq._id, cq);
    this.authenticatedUserService.unlock();
  }

  unsetActiveCq() {
    this.cqStore.update({ moderationQueryActive: null, locked_at: undefined, locked_by: undefined });
  }

  updateModerationQuerySkip(moderationQueryScroll) {
    this.cqStore.update({
      moderationQuerySkip: this.cqQuery.getValue().moderationQuerySkip + environment.grid_skip_limit,
      moderationQueryScroll,
    });
  }

  updateUserQuerySkip(userQueryScroll) {
    this.cqStore.update({
      userQuerySkip: this.cqQuery.getValue().userQuerySkip + environment.grid_skip_limit,
      userQueryScroll,
    });
  }

  getCreativeQuery(id: string): Observable<CreativeQuery> {
    return id ? this.getQuery(id) : of(new CreativeQuery());
  }

  putCreativeQuery(id: string, data): Observable<CreativeQuery> {
    return this.api.put(`api/creative-query/${id}`, data).pipe(
      tap(creativeQuery => {
        this.cqStore.update(id, creativeQuery);
      })
    );
  }

  putPrepareForReSubmitCreativeQuery(id: string, data): Observable<CreativeQuery> {
    return this.api.put(`api/creative-query/${id}/prepare-for-resubmit`, data).pipe(
      tap(creativeQuery => {
        this.cqStore.update(id, creativeQuery);
      })
    );
  }

  postDraftCreativeQuery(data): Observable<CreativeQuery> {
    return this.api.post('api/creative-query', data).pipe(
      tap(creativeQuery => {
        if (data.creative_query_id) {
          this.cqStore.update(data.creative_query_id, creativeQuery);
        } else {
          this.cqStore.add(creativeQuery, { prepend: true });
        }
      })
    );
  }

  postPrepareForSubmitCreativeQuery(data): Observable<CreativeQuery> {
    return this.api.post('api/creative-query/prepare-for-submit', data).pipe(
      tap(creativeQuery => {
        if (data.creative_query_id) {
          this.cqStore.update(data.creative_query_id, creativeQuery);
        } else {
          this.cqStore.add(creativeQuery, { prepend: true });
        }
      })
    );
  }

  // Creative Query submitted to block chain
  postCreativeQuerySubmit(data: SubmitCreativeQueryRequest): Observable<any> {
    return this.api.post(`api/creative-query/${data.creativeQueryId}/submit`, data).pipe(
      tap(cq => {
        this.cqStore.update(data.creativeQueryId, cq);
      })
    );
  }

  // Creative Query resubmitted to block chain
  postCreativeQueryResubmit(data: SubmitCreativeQueryRequest): Observable<any> {
    return this.api.post(`api/creative-query/${data.creativeQueryId}/resubmit`, data).pipe(
      tap(cq => {
        this.cqStore.update(data.creativeQueryId, cq);
      })
    );
  }

  // Creative query submissions
  postCreativeQuerySubmitAnswer(id = null, data): Observable<any> {
    return this.api.post(`api/creative-query-submissions`, data).pipe(
      tap(submitted => {
        this.cqStore.update(submitted.creative_query_id, { current_user_submit: submitted });
      })
    );
  }

  putCreativeQuerySubmitAnswer(id, data): Observable<any> {
    return this.api.put(`api/creative-query-submissions/${id}`, data).pipe(
      tap(submitted => {
        this.cqStore.update(submitted.creative_query_id, { current_user_submit: submitted });
      })
    );
  }

  updateFilters(filters) {
    applyTransaction(() => {
      this.cqStore.remove(this.cqQuery.getAllUserAndActiveQueryIds());
      this.cqStore.update({
        filters,
        skip: 0,
        scroll: 1,
        loaded: false,
        apiEndReached: false,
        userQuerySkip: 0,
        userQueryScroll: 1,
        userQueryApiEndReached: false,
        userQueryLoaded: false,
      });
    });
  }
  updateShowFilters(showFilters: boolean) {
    this.cqStore.update({ ui: { showFilters } });
  }

  // @todo :create result Response type
  getCreativeQueryResults(id: string): Observable<any> {
    return this.api.get(`api/creative-query/${id}/results`);
  }

  getCreativeQuerySubmissions(id: string): Observable<any> {
    // this endpoint is not implemented in back-end (or deleted)
    return this.api.get(`api/creative-query/${id}/all-submissions`);
  }

  isCreativeQuerySubmitted(id: string): Observable<CreativeQuerySubmit> {
    return this.api.get(`api/creative-query/${id}/is-submitted`);
  }

  getCreativeQueryQuestion(id: string): Observable<Question[]> {
    return this.api.get(`api/creative-query/${id}/questions`);
  }

  getCreativeQueryQuestionOptions(id: string): Observable<Option[]> {
    return this.api.get(`api/creative-query/question/${id}/options`);
  }

  getUserCreativeQuerys(userid, skip = 0, search = ''): Observable<CreativeQuery[]> {
    return this.api.get(
      'api/creative-query/user/' + userid + '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  /**  Populate state with  creative queries */
  getListCreativeQueries(): Observable<CreativeQuery[]> {
    const filters = this.prepareFilters();
    return this.api.get(`api/creative-query/`, { params: filters }).pipe(
      tap(creativeQueries => {
        this.cqStore.add(creativeQueries);
        if (creativeQueries.length < environment.grid_skip_limit) {
          this.cqStore.update({ apiEndReached: true });
        }
        this.cqStore.setLoading(false);
      }),
      catchError(error => {
        this.cqStore.setError(error);
        return this.api.catchError(error);
      })
    );
  }

  /**  Populate state with user creative queries */
  getAll(): Observable<CreativeQuery[]> {
    this.cqStore.setLoading(true);
    const api =
      'api/creative-query/user?limit=' + environment.grid_skip_limit + '&skip=' + this.cqQuery.getValue().userQuerySkip;

    return this.api.get(api).pipe(
      tap(cq => {
        this.cqStore.add(cq);
        this.cqStore.update({
          userQueryLoaded: true,
        });
        this.cqStore.setLoading(false);
        if (cq.length < environment.grid_skip_limit) {
          this.cqStore.update({ userQueryApiEndReached: true });
        }
      }),
      catchError(error => {
        this.cqStore.setError(error);
        return this.api.catchError(error);
      })
    );
  }

  /**  Populate state with to be moderated creative queries */
  getAllForModeration(): Observable<CreativeQuery[]> {
    this.cqStore.setLoading(true);
    const api =
      'api/creative-query/moderate?limit=250' /*environment.grid_skip_limit + */ +
      '&skip=' +
      this.cqQuery.getValue().moderationQuerySkip +
      (this.cqQuery.getValue().moderationFilters.unlockedOnly ? '&unlocked=true' : '');
    return this.api.get(api).pipe(
      tap(creativeQuerys => {
        this.cqStore.add(creativeQuerys);
        this.cqStore.setLoading(false);
        if (creativeQuerys.length < environment.grid_skip_limit) {
          this.cqStore.update({ moderationQueryApiEndReached: true });
        }
        this.cqStore.update({ moderationQueryLoaded: false });
      }),
      catchError(error => {
        this.cqStore.setError(error);
        this.cqStore.setLoading(false);
        return this.api.catchError(error);
      })
    );
  }

  updateModerationFilter(filter) {
    applyTransaction(() => {
      this.cqStore.remove(this.getAll());
      this.cqStore.update({
        moderationFilters: { unlockedOnly: filter.unlockedOnly },
        moderationQuerySkip: 0,
      });

      this.cqStore.setLoading(false);
    });
  }

  calculateDaysRemaining(creativeQuery: CreativeQuery) {
    if (!creativeQuery.expired_at) {
      return null;
    }
    const sDate = new Date(creativeQuery.approved_at);
    const eDate = new Date(creativeQuery.expired_at);
    const diffTime = Math.abs(eDate.getTime() - sDate.getTime());
    const today = new Date();
    const completed = today.getTime() - sDate.getTime();
    let days = 0;
    if (diffTime - completed >= 0) {
      days = Math.ceil((diffTime - completed) / (1000 * 60 * 60 * 24));
    }

    return days;
  }

  prepareFilters() {
    const filters = { ...this.cqQuery.getValue().filters };
    Object.keys(filters).forEach(key => !!filters[key] || delete filters[key]);
    return { ...filters, skip: this.cqQuery.getValue().skip };
  }

  updateSkip(scroll) {
    this.cqStore.update({ skip: this.cqQuery.getValue().skip + environment.grid_skip_limit, scroll });
  }

  updateUserSkip(userQueryScroll) {
    this.cqStore.update({
      userQuerySkip: this.cqQuery.getValue().userQuerySkip + environment.grid_skip_limit,
      userQueryScroll,
    });
  }
  updateModerationSkip(moderationQueryScroll) {
    this.cqStore.update({
      moderationQuerySkip: this.cqQuery.getValue().moderationQuerySkip + environment.grid_skip_limit,
      moderationQueryScroll,
    });
  }

  getOwnCreativeQueries(skip = 0, search = ''): Observable<CreativeQuery[]> {
    return this.api.get(
      'api/creative-query/user?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '')
    );
  }

  submitCreativeQueryBlockchain(req: SubmitCreativeQueryRequest): Observable<any> {
    // +
    return this.api.post('api/creative-query/' + req.creativeQueryId + '/submit-bc', {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  lockCreativeQuery(id: string): Observable<any> {
    this.cqStore.setLoading(true);

    const proposal = this._upsertCqState(this.api.put('api/creative-query/' + id + '/lock', null));
    this.authenticatedUserService.setModeratedEntity({ entity: 'creative-query', id: id });
    return proposal;
  }

  unlockCreativeQuery(id: string): Observable<CreativeQuery> {
    this.cqStore.setLoading(true);
    return this.api.put('api/creative-query/' + id + '/unlock', null).pipe(
      tap(cq => {
        this.authenticatedUserService.unlock();

        cq.locked_at = undefined;
        cq.locked_by = undefined;

        this.cqStore.upsert(cq._id, cq);
        this.cqStore.setLoading(false);
      }),
      catchError(error => {
        this.cqStore.setError(error);
        this.cqStore.setLoading(false);
        return this.api.catchError(error);
      })
    );
  }

  cancelCreativeQuery(creativeQuery: CreativeQuery) {
    return this.api.get(`api/creative-query/${creativeQuery._id}/cancel`).pipe(
      tap(cQuery => {
        if (creativeQuery.status === 'approved') {
          const skip = this.cqQuery.getValue().skip - 1;

          if (this.cqQuery.getActive() && this.cqQuery.getActive()._id === creativeQuery._id) {
            this.cqStore.setActive(null);
          }
          this.cqStore.update({ skip, userQueryApiEndReached: false });
        } else if (creativeQuery.status === 'to-be-moderated') {
          const moderationQuerySkip = this.cqQuery.getValue().moderationQuerySkip - 1;

          if (this.cqQuery.getValue().moderationQueryActive === creativeQuery._id) {
            this.cqStore.update({
              moderationQuerySkip,
              moderationQueryActive: null,
              userQueryApiEndReached: false,
            });
          } else {
            this.cqStore.update({
              moderationQuerySkip,
              userQueryApiEndReached: false,
            });
          }
        }
        this.cqStore.update(creativeQuery._id, { status: 'cancelled' });
        this.cqStore.setLoading(false);
      }),
      catchError(error => {
        return this.api.catchError(error);
      })
    );
  }

  // Submit answers  blockchain transaction
  submitCreativeQuerySubmission(req: SubmitCreativeQuerySubmissionRequest): Observable<any> {
    // +
    return this.api.post(`api/creative-query-submissions/${req.submitId}/submit`, {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  // Resubmit answers  blockchain transaction
  resubmitCreativeQuerySubmission(req: SubmitCreativeQuerySubmissionRequest): Observable<any> {
    return this.api.post(`api/creative-query-submissions/${req.submitId}/resubmit`, {
      password: req.password,
      sig: req.signature,
      nonce: req.nonce,
    });
  }

  getModeratableCreativeQueries(skip = 0): Observable<CreativeQuery[]> {
    return this.api.get('api/creative-query/moderate?skip=' + skip);
  }

  invalidateCache() {
    this.cqStore.setHasCache(false);
  }

  remove(id) {
    this.cqStore.setActive(null);
    this.cqStore.remove(id);
  }

  /**
   *
   * @param id : creative query ID
   *
   * Get creative query entity from store. Or get it form API call and insert it into store,
   *  with returning its observable.
   *
   */

  getQuery(id): Observable<CreativeQuery> {
    return this.cqQuery.selectEntity(id).pipe(
      switchMap(query => {
        if (!query) {
          return this.api.get('api/creative-query/' + id).pipe(tap(cq => this.cqStore.add(cq)));
        } else {
          return this.cqQuery.selectEntity(id);
        }
      }),
      catchError(error => {
        return this.api.catchError(error);
      })
    );
  }

  updateQuestion(id: string, question, index) {
    this.cqStore.update(id, entity => ({
      questions: arrayUpdate(entity.questions, index, question, 'index'),
    }));
  }

  deleteQuestion(id: string, question, index) {
    this.cqStore.update(id, entity => ({
      questions: arrayRemove(entity.questions, index, 'index'),
    }));
  }

  resetFailed(id): Observable<any> {
    return this.api.put('api/creative-query/' + id + '/reset', null).pipe(
      switchMap(cq => {
        this.cqStore.update(id, cq);
        return of('');
      })
    );
  }

  private _upsertCqState(observerable: Observable<CreativeQuery>) {
    return observerable.pipe(
      tap(cq => {
        this.cqStore.upsert(cq._id, cq);
        this.cqStore.setLoading(false);
      }),
      catchError(error => {
        this.cqStore.setLoading(false);
        this.cqStore.setError(error);
        return this.api.catchError(error);
      })
    );
  }

  getReviewCqInstruction(): Observable<any> {
    return this.api.get('api/instructions/cq_review');
  }
}
