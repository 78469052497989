import { Injectable } from '@angular/core';
import { AuthenticatedUserState, AuthenticatedUserStore } from './authenticated-user.store';
import { UserTypes, UserVerificationStatus } from '../../enumerations';
import { Query } from '@datorama/akita';
import { CryptoUtil } from '../../../shared/util/crypto-util';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LowBalanceComponent } from '../../components/low-balance-sheet/low-balance.component';
import { WalletCheckComponent } from '../../components/wallet-check/wallet-check.component';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthenticatedUserQuery extends Query<AuthenticatedUserState> {
  authenticatedUser$ = this.select(user => user);
  profileUrl$ = this.select('avatar_url');
  private matBottomSheetRef: MatBottomSheetRef<LowBalanceComponent>;
  private matBottomWalletRef: MatBottomSheetRef<WalletCheckComponent>;
  constructor(protected store: AuthenticatedUserStore, private bottomSheet: MatBottomSheet, private router: Router) {
    super(store);
    this.authenticatedUser$.subscribe(
      user => {},
      error => {}
    );
  }

  get balance() {
    return this.getValue().balance ? this.getValue().balance : null;
  }

  get isUnverified(): boolean {
    return this.getValue().user_verification_status === UserVerificationStatus.UNVERIFIED;
  }

  get isEmailVerified() {
    return this.getValue().user_verification_status >= UserVerificationStatus.EMAIL_VERIFIED;
  }

  get isPhoneVerified(): boolean {
    return this.getValue().user_verification_status >= UserVerificationStatus.SMS_VERIFIED;
  }

  get isKycRegistered() {
    return this.getValue().user_verification_status >= UserVerificationStatus.KYC_VERIFIED;
  }

  get isModerator() {
    return this.getValue().user_type_id >= UserTypes.MODERATOR;
  }

  get isAdministrator() {
    return this.getValue().user_type_id >= UserTypes.ADMINISTRATOR;
  }

  get role(): string {
    return this.getRoleById(this.getValue().user_type_id);
  }

  get isDiscordEnabled() {
    return this.getValue().discord_user_id !== null && this.getValue().discord_user_id !== undefined;
  }

  get verificationStatus(): string {
    return this.getVerificationStatusById(this.getValue().user_verification_status);
  }

  get isRegistrationComplete(): boolean {
    return this.isEmailVerified && this.isPhoneVerified && this.isKycRegistered && this.hasWallet;
  }

  get isProfileComplete(): boolean {
    return !!(
      this.getValue() &&
      this.getValue().first_name &&
      this.getValue().last_name &&
      this.getValue().sex &&
      this.getValue().dob &&
      this.getValue().zipPostal &&
      this.getValue().country &&
      this.getValue().street
    );
  }

  getVerificationStatusById(user_verification_status: number): string {
    const wallet_required = this.getValue().seed_public_address ? '' : ' (wallet required)';
    switch (user_verification_status) {
      case UserVerificationStatus.UNVERIFIED:
        return 'unverified';
      case UserVerificationStatus.SMS_VERIFIED:
        return 'phone verified';
      case UserVerificationStatus.EMAIL_VERIFIED:
        return 'email verified';
      case UserVerificationStatus.KYC_FAILED:
        return `failed${wallet_required}`;
      case UserVerificationStatus.KYC_VERIFIED:
        return `verified${wallet_required}`;
    }
  }

  getRoleById(user_type_id: number): string {
    switch (user_type_id) {
      case UserTypes.USER:
        return 'member';
      case UserTypes.MODERATOR:
        return 'moderator';
      case UserTypes.ADMINISTRATOR:
        return 'administrator';
    }
  }

  isOwner(id): boolean {
    if (id === this.getValue()._id) {
      return true;
    } else {
      return false;
    }
  }

  get hasWallet(): boolean {
    return Boolean(this.getValue().seed_public_address);
  }

  get walletPublicAddress() {
    return this.getValue() ? this.getValue().seed_public_address : null;
  }

  // @todo remove these methods
  get user(): AuthenticatedUserState {
    return this.getValue();
  }

  get clientWallet() {
    if (!this.getValue()) {
      return null;
    }
    return localStorage.getItem(`${this.getValue().seed_public_address}`);
  }

  get visiblePublicProfileData(): any {
    return this.getValue().visible_public_profile_data
      ? Object.values(this.getValue().visible_public_profile_data)
      : [];
  }

  // weather Active
  isActiveWallet(): boolean {
    if (
      !this.getValue().seed_public_address ||
      (this.getValue().seed_public_address && !this.clientWallet && this.getValue().has_client_key) ||
      this.getValue().wallet_restore_required
    ) {
      return false;
    } else {
      return true;
    }
  }

  // Get Wallet Status in string
  getWalletStatus(): String {
    if (
      !this.getValue().seed_public_address ||
      (this.getValue().seed_public_address && !this.clientWallet && this.getValue().has_client_key)
    ) {
      return 'is not created';
    } else if (this.getValue().wallet_restore_required) {
      return 'requires recovery';
    } else {
      return 'is active';
    }
  }

  getPassword() {
    const pass = null;
    if (localStorage.getItem('password')) {
      return localStorage.getItem('password');
      try {
        return CryptoUtil.decrypt(localStorage.getItem('password'), this.user._id);
      } catch (ex) {
        localStorage.removeItem('password');
      }
    }
    return pass;
  }

  /**
   *  Utility functions
   */

  openLowBalanceSheet(minBuy: number, redirectUri = '/proposal'): void {
    this.matBottomSheetRef = this.bottomSheet.open(LowBalanceComponent, {
      data: { redirectUri, minBuy },
    });
    this.matBottomSheetRef.afterDismissed().subscribe(data => {});
  }

  openWalletCreate(redirectUri = '/proposal', action = null): void {
    this.matBottomWalletRef = this.bottomSheet.open(WalletCheckComponent, {
      data: { walletStatus: this.getWalletStatus(), action },
    });
    this.matBottomWalletRef.afterDismissed().subscribe(data => {
      if (data && data.save) {
        this.router.navigate(['/account/wallet'], {
          queryParams: {
            redirectUri: redirectUri,
          },
        });
      }
    });
  }
}
