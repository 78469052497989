import { User } from '../../../../app.datatypes';

import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

export interface UserState extends EntityState<User>, ActiveState {
  ui: {
    showFilters: boolean;
  };
  filters: {
    search: string;
  };
  skip: number;
  apiEndReached: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', idKey: '_id', resettable: true })
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super({
      ui: { showFilters: false },
      filters: {
        search: '',
      },
      skip: 0,
      apiEndReached: false,
    });
  }
}
