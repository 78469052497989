import { Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { ReportDialogComponent } from '../report-dialog/report-dialog.component';
import { ModerateComponent } from '../moderate/moderate.component';
import { CreativeQuery, CreativeQueryStatus } from '../../../app.datatypes';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticatedUserQuery, CreativeQueryServices } from '../../state';
import { CancelRemoveDialogComponent } from '../cancel-remove-dialog/cancel-remove-dialog.component';
import { ToasterService } from '../../services';
import { QueryResultComponent } from '../../../containers/creative-query/query-result/query-result.component';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-creative-query-actions',
  templateUrl: './creative-query-actions.component.html',
  styleUrls: ['./creative-query-actions.component.scss'],
})
export class CreativeQueryActionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() creativeQuery?: CreativeQuery;
  @Output() moderationAction = new EventEmitter();
  resetButton: boolean;
  unlockButton: boolean;
  moderateButton: boolean;
  public reenableResetButton = new EventEmitter<boolean>();
  public reenableUnlockButton = new EventEmitter<boolean>();
  public reenableModerateButton = new EventEmitter<boolean>();

  constructor(
    private creativeQueryStateService: CreativeQueryServices,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public appService: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resetButton = this.showResetButton();
    this.unlockButton = this.showUnlockButton();
    this.moderateButton = this.showModerateButton();
  }

  openCancelDialog(creativeQuery: CreativeQuery, event: Event) {
    const dialogRef = this.dialog.open(CancelRemoveDialogComponent, {
      maxWidth: '750px',
      maxHeight: '750px',

      data: { entity: creativeQuery, type: 'creative query', confirmCancelRequired: true },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'submit') {
        this.creativeQueryStateService
          .cancelCreativeQuery(creativeQuery)
          .pipe(untilDestroyed(this))
          .subscribe(res => {
            if (res && res.cancelled) {
              this.toasterService.showToaster(
                'Successfully cancelled the creative query.  It will no longer be available.',
                'Cancelled Successfully'
              );
            }
          });
      }
    });
    event.stopPropagation();
    event.preventDefault();
  }

  showResetButton() {
    return this.creativeQuery.status === CreativeQueryStatus.FAILED;
  }

  openModerateDialog() {
    const dialogRef = this.dialog.open(ModerateComponent, {
      maxWidth: '750px',
      maxHeight: '750px',

      data: { entity: this.creativeQuery, type: 'creative_query' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true && result) {
        this.moderationAction.emit(result);
      }
    });
  }

  openReportDialog() {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      maxWidth: '750px',
      maxHeight: '750px',

      disableClose: true,
      hasBackdrop: true,
      data: { entity: this.creativeQuery, type: 'creative_query' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true && result) {
      }
    });
  }

  openResultDialog(id) {
    const dialogRef = this.dialog.open(QueryResultComponent, {
      maxWidth: '1050px',
      maxHeight: '750px',
      minWidth: '550px',
      data: { creativeQueryId: id },
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then();
  }

  putResetFailed() {
    const errorHandler = async err => {
      this.toasterService.openSnackBar('There was a problem resetting your creative query', 'Proposal Reset Error');
      this.reenableResetButton.emit();
    };
    this.creativeQueryStateService
      .resetFailed(this.creativeQuery._id)
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        this.toasterService.openSnackBar(
          'Reset of failed creative query is successful. You can now edit and resubmit your creative query.',
          'Reset successfully',
          'check',
          5000
        );
        this.reenableResetButton.emit();
      }, errorHandler);
  }

  showUnlockButton() {
    const show =
      this.creativeQuery &&
      this.creativeQuery.locked_by &&
      this.authenticatedUserQuery.isAdministrator &&
      this.creativeQuery.status === CreativeQueryStatus.TO_BE_MODERATED;
    return show;
  }

  showModerateButton() {
    const result =
      this.creativeQuery &&
      this.authenticatedUserQuery.user._id !== this.creativeQuery.user_id &&
      (!this.creativeQuery.locked_by || this.creativeQuery.locked_by === this.authenticatedUserQuery.user._id) &&
      this.creativeQuery.status === CreativeQueryStatus.TO_BE_MODERATED;
    return result;
  }

  openUnlockDialog(id: string) {
    this.creativeQueryStateService.unlockCreativeQuery(id).subscribe();
  }

  ngOnDestroy() {}

  ngOnChanges() {
    this.resetButton = this.showResetButton();
    this.unlockButton = this.showUnlockButton();
    this.moderateButton = this.showModerateButton();
  }
}
