import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Question, Option } from '../../../../app.datatypes';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnDestroy, OnChanges {
  questionForm: FormGroup;
  qMode = 'edit';

  @Input() question?: any;
  @Output() isValid = new EventEmitter();
  @Output() questionChanged = new EventEmitter();
  @Output() deleteQuestion = new EventEmitter();
  @Output() optionChanged = new EventEmitter();
  @ViewChild('inputQuestion', { static: false }) inputQuestion: ElementRef;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {}

  buildForm() {
    this.questionForm = this.formBuilder.group({
      description: [
        this.question && this.question.description ? this.question.description : '',
        [Validators.required, Validators.minLength(6)],
      ],
      type: [this.question && this.question.type ? this.question.type : 'vote', [Validators.required]],
    });
  }

  updateQuestion() {
    if (this.questionForm.value.description.length < 6) {
      return;
    }

    const dataForUpdate = { ...this.question, ...this.questionForm.value } as Question;
    this.questionChanged.emit(dataForUpdate);
    this.questionForm.value.description = '';
    this.qMode = 'view';
  }

  addOption(type) {
    const option = {
      type,
      value: null,
      question_id: this.question._id ? this.question._id : null,
      index: this.question.options ? this.question.options.length : 0,
    };
    const options = this.question.options ? this.question.options : [];
    options.push(option);
    const dataForUpdate = { ...this.question, options } as Question;
    this.questionChanged.emit(dataForUpdate);
  }

  saveOption(option: Option, index) {
    const options = this.question.options ? this.question.options : [];
    options[index] = option;
    const dataForUpdate = { ...this.question, options } as Question;
    this.questionChanged.emit(dataForUpdate);
  }

  trackOptions(index: number, option) {
    return index.toString();
  }

  edit() {
    this.qMode = 'edit';
    setTimeout(() => {
      this.inputQuestion?.nativeElement.focus();
    }, 1000);
    this.deleteQuestion.emit(this.question);
  }

  deleteOption(option, index) {
    this.question.options.splice(index, 1);
    this.questionChanged.emit(this.question);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.question && this.question.options && this.question.options.length >= 2) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
    if (this.question._id) {
      this.buildForm();
      this.qMode = 'view';
    }
  }
}
